import React from 'react'

import moment from "moment"

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'
import Currency from '../utils/Currency'

import DashboardEngagementCard from './components/cards/DashboardEngagementCard'

import Card from './components/Card'

import Chart from './components/chart/Chart'

import RegistrationsTable from './components/tables/RegistrationsTable'
import StatCard from './components/chart/StatCard'

import $ from 'jquery'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      labels: [],
      stats: [],
      loading: true,
      website: AuthManager.currentWebsite,
      company: AuthManager.currentUser.company,
    }
  }

  componentDidMount(){
    let {
      website,
      company
    } = this.state

    Backend.getStats()
    .then(stats => {
      let displayStats = []
      if(website.show_page_views_stat){
        displayStats.push({
          title: stats.page_views.count + 1,
          subtitle: "Page Views",
          color: "#fd3995",
          type: "danger",
          kind: "page_views"
        })
      }

      if(website.show_leads_stat){
        displayStats.push({
          title: website.leads_no,
          subtitle: "Leads/Enquiries",
          color: "#333333",
          type: "brand",
          kind: "leads_no"
        })
      }

      if(website.show_sales_stat){
        displayStats.push({
          title: Currency.format(company.gross_sales, ""),
          subtitle: "Sales",
          color: "#34bfa3",
          type: "success",
          kind: "sales"
        })
      }

      this.setState({
        stats: displayStats,
        loading:false,
        pageViews:
        {
          subtitle: "Page Views",
          color: "#34bfa3",
          type: "success",
          data: stats.page_views.data.map((stat, index) => {
            if(moment(stat.date).isSame(company.created_at, 'date')){
              stat.count += 1
            }
            return stat.count
          }),
          labels: stats.page_views.data.map(stat => moment(stat.date).format("DD MMM"))
        },
      })
    })
  }

  _renderStats(){
    let { stats } = this.state
    return stats.map((stat, index) => {
      return (
        <StatCard
          key={index}
          title={stat.title}
          subtitle={stat.subtitle}
          text={stat.text}
          onClick={() => {
            let page = null
            if(stat.kind == "page_views"){
              page = "/pages"
            }
            else if(stat.kind == "leads_no"){
              page = "/leads"
            }
            else if(stat.kind == "sales"){
              let paymentsConnected = AuthManager.currentUser.company.payments_connected
              if(paymentsConnected){
                page = "/sales"
              }
              else{
                page = "/shop"
              }
            }
            this.props.history.push(page)
          }}
        />
      )
    })
  }

  render() {
    let {
      stats,
      labels,
      loading,
      pageViews,
      website,
      company
    } = this.state

    return (
      <>
        <div className="row">
          <DashboardEngagementCard
            onActivateShopPressed={() => this.props.history.push("/shop/activate")}
            onActivatePaymentPressed={() => this.props.history.push("/payment/activate")}
            onSelectDomainPressed={() => this.props.history.push("/domain/purchased")}
          />
        </div>
        { stats.length > 0 &&
          <div className="row mt-5 pt-2 mb-4 justify-content-center">
            { this._renderStats() }
          </div>
        }
        { website.show_page_views_stat &&
          <div className="row">
            <Card
              title="Page Views"
              loading={loading}
            >
              <Chart
                type="bar"
                data={pageViews ? pageViews.data : []}
                labels={pageViews ? pageViews.labels : []}
                color="#333"
                backgroundColor="#333"
                minimal={false}
              />
            </Card>
          </div>
        }
      </>
    )
  }
}
