import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from "../layouts/Div"

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

let stripePromise = null

let CARD_ELEMENT_STYLE = {
  base: {
    iconColor: '#666666',
    color: '#fff',
    fontSize: '16px',
    '::placeholder': {
      color: '#87BBFD',
    },
  },
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  },
}

export default class CardEntryForm extends React.Component {
  constructor(props){
    super(props)
    let cardHolderName = ""
    let cardHolderEmail = ""
    const user = AuthManager.currentUser?.user
    if(user){
      cardHolderEmail = user.email
    }

    this.state = {
      loading: false,
      cardHolderName,
      cardHolderEmail,
      uiType:props.uiType
    }
    stripePromise = loadStripe(window.Api.StripePubishableKey)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  collectCardDetails(){
    let {
      paymentIntentId
    } = this.state

    let data = {}

    this._getPaymentMethod()
    .then(paymentMethod => {
      data.payment_method_id = paymentMethod.id
      if(paymentIntentId){
        data.payment_intent_id = paymentIntentId
      }
      return this.props.onCollected(data)
    })
    .catch(error => {
      this.props.onCollectionFailed(error)
    })
  }

  _handleAction(response){
    this._validatePayment(response)
  }

  async _getPaymentMethod(){
    if(!this.stripe || !this.elements){
      throw { message: "Unexpected error, please try again" }
    }
    else if(!this.state.cardHolderName){
      throw { message: "Please specify the cardholder name" }
    }

    const cardElement = this.elements.getElement(CardElement)

    return this.stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: this.state.cardHolderName,
        email: this.state.cardHolderEmail
      },
    })
    .then(({paymentMethod, error}) => {
      if(paymentMethod == null){
        throw { message: "Please check your card details and try again later" }
      }
      return paymentMethod
    });
  }

  _validatePayment(response){
    this._hanndleStripeAction(response)
    .then(result => {
      if(result.error){
        this.props.onCollectionFailed(result.error)
        return
      }

      this.setState({
        paymentIntentId: result.paymentIntent.id
      }, () => this.collectCardDetails())
    })
  }

  _hanndleStripeAction(response){
    if(response.recurring_payment){
      return this.stripe.confirmCardPayment(response.client_secret)
    }
    else{
      return this.stripe.handleCardAction(response.client_secret)
    }
  }

  _renderCardHolderName(){
    let {
      cardHolderName,
      uiType
    } = this.state

    let formControlCSS = "form-control form-control-solid"
    
    if(uiType === "horizontal"){
      formControlCSS = formControlCSS+ " h-auto py-7 px-6 rounded-lg font-size-h6"
    }

    return(
      <>
        <input
            className={formControlCSS}
            value={cardHolderName}
            placeholder="Cardholder Name"
            onChange={e => this.setState({ cardHolderName: e.target.value })}
          />
      </>
    )
  }

  _renderCardHolderEmail(){
    let {
      cardHolderEmail,
      uiType
    } = this.state

    let formControlCSS = "form-control form-control-solid"
    
    if(uiType === "horizontal"){
      formControlCSS = formControlCSS+ " h-auto py-7 px-6 rounded-lg font-size-h6"
    }

    return(
      <>
        <input
          className={formControlCSS}
          value={cardHolderEmail}
          placeholder="Email"
          onChange={e => this.setState({ cardHolderEmail: e.target.value })}
        />
      </>
    )
  }

  _renderStripe(){
    let {
      uiType
    } = this.state

    let style = {
      base: {
        iconColor: '#000',
        marginTop: '20px',
        fontSize: '1rem',
        color: '#333',
        '::placeholder': {
          color: '#c5c5c5',
          iconColor: "red",
        },
      },
      invalid: {
        color: '#fa6160',
      }
    }

    let formControlCSS = "form-control form-control-solid"
    
    if(uiType === "horizontal"){
      style.base.iconColor='#3F4254';
      style.base.fontSize='15.3px';
      style.base.color='#3F4254';
      style.base["::placeholder"].color='#B5B5C3';
      formControlCSS = formControlCSS+ " h-auto py-7 px-6 rounded-lg font-size-h6"
    }

    return(
      <>
        { stripePromise &&
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({stripe, elements}) => {
                this.stripe = stripe
                this.elements = elements

                return (
                  <CardElement
                    stripe={this.stripe}
                    options={{
                      style,
                    }}
                    className={formControlCSS}
                  />
                )
              }}
            </ElementsConsumer>
          </Elements>
        }
      </>
    )
  }

  render(){
    let {
      cardHolderName,
      cardHolderEmail,
      uiType
    } = this.state

    let formControlCSS = "form-control form-control-solid"
    if(uiType === "horizontal"){
      formControlCSS = formControlCSS+ " h-auto py-7 px-6 rounded-lg font-size-h6"
    }

    return (
      <>
        { uiType != "horizontal" && 
          <>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">Card Holder Name:</label>
              <div className="col-lg-8 my-auto">
                { this._renderCardHolderName() }
              </div>
            </div>
            { this.props.showEmail &&
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">Card Holder Email:</label>
                <div className="col-lg-8 my-auto">
                  { this._renderCardHolderEmail() }
                </div>
              </div>
            }
            <div className="form-group row">
              <label className="col-lg-4 col-form-label my-auto">Card Number:</label>
              <div className="col-lg-8 my-auto">
                <div class="card-input-container my-auto">
                  { this._renderStripe() }
                </div>
              </div>
            </div>
          </>
        }

        { uiType === "horizontal" && 
          <div className='row card-entry-form'>
            <div className='col-md'>
              <div className="form-group">
                <label><span class="title">Card Holder Name</span></label>
                { this._renderCardHolderName() }
              </div>
            </div>
            { this.props.showEmail &&
              <div className='col-md'>
                <div className="form-group">
                  <label><span class="title">Card Holder Email</span></label>
                  { this._renderCardHolderEmail() }
                </div>
              </div>
            }
            <div className='col-md'>
              <div className="form-group">
                <label><span class="title">Card Number</span></label>
                <div class="card-input-container my-auto w-100">
                    { this._renderStripe() }
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

CardEntryForm.defaultProps = {
  showEmail: true,
}
