import React from 'react'

import Creatable from "react-select/creatable";

import AsyncPaginate from 'react-select-async-paginate';

import FetchHelper from '../../../utils/FetchHelper'

export default class AsyncSelect extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      endpoint: props.endpoint,
      search: '',
      prevSearch: '',
      items: [],
      nextPageUrl: null,
      value: this.props.value,
    }
  }

  _loadOptions(search, prevOptions){
    let {
      endpoint,
      prevSearch,
      nextPageUrl,
      items
    } = this.state


    let url = endpoint
    let joiner = "?"
    if(endpoint.indexOf("?") > -1){
       joiner = "&"
    }

    url += joiner + `search_term=${search}&order_by=name`

    let nextPage = false
    if(search === prevSearch && nextPageUrl){
      url = nextPageUrl
      nextPage = true
    }

    return FetchHelper.get(url)
    .then(response => {
      let newOptions = this.props.getOptions(response.results)
      if(nextPage){
        items = [
          ...items,
          ...response.results
        ]
      }
      else{
        items = response.results
      }

      this.setState({
        items,
        nextPageUrl: response.next,
        prevSearch: search
      })
      return {
        options: newOptions,
        hasMore: response.next != null
      }
    })
  };

  render(){
    let props = {}

    if(this.props.creatable){
      props.SelectComponent = Creatable
    }
    return (
      <AsyncPaginate
        { ...props }
        className="async-select-paginate"
        isMulti={this.props.isMulti}
        isClearable={this.props.isClearable}
        value={this.state.value}
        closeMenuOnSelect={!this.props.isMulti}
        loadOptions={this._loadOptions.bind(this)}
        debounceTimeout={300}
        onChange={value => {
          this.setState({ value })
          if(!value){
            this.props.onSelected(value)
          }
          else if(this.props.isMulti){
            this.props.onSelected(value)
          }
          else if(value.__isNew__){
            this.props.onCreated(value)
          }
          else{
            this.props.onSelected(value.data)
          }
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#F5F5F5",
            primary: "#F5F5F5",
            neutral0: "white",
          }
        })}
        styles={{
          option: (provided, state) => ({
            ...provided,
            color: null,
          }),
        }}
      />
    )
  }
}

AsyncSelect.defaultProps = {
  isMulti: false,
  creatable: false
}
