import React from "react"

import AuthManager from "../../utils/AuthManager"
import Website from "../../utils/Website"


export default class Footer extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      website: AuthManager.currentWebsite
    }
  }

  componentDidMount(){

  }

  render() {
    let {
      website
    } = this.state

    let text = "2020 © " + window.General.Branding.Name
    if(website){
      text += ` | ${Website.getCleanDomain(website)}`
    }

    return (
      <div className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
        <div class="kt-container  kt-container--fluid ">
          <div class="kt-footer__copyright">
            { text }
          </div>
        </div>
			</div>
    )
  }
}
