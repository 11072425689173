import React from 'react'

import Select from "../common/Select"

import Div from "../layouts/Div"

import Backend from "../../../utils/Backend"
import Notify from "../../../utils/Notify"
import AuthManager from "../../../utils/AuthManager"

const LIVE_CHAT_ENAABLED_OPTIONS = [
  {
    label: "Yes, I Want My Visitors To Be Able To Message Me",
    value: false
  },
  {
    label: "No, I Don't Want My Visitors To Be Able To Message Me",
    value: true
  },
]

export default class ConversationSettings extends React.Component {
  constructor(props){
    super(props)


    this.state = {
      loading: false,
      data: {
        hide_live_chat: AuthManager.currentWebsite.hide_live_chat,
      }
    }

  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _handleUpdate(){
    let {
      data
    } = this.state
    this.setState({ loading: true })

    Backend.updateWebsite(AuthManager.currentWebsite, data)
    .then(() => {
      this.setState({ loading: false })
      Notify.success("Chat Settings Updated")
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render(){
    let {
      data,
      loading,
    } = this.state

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
        >
          <div className="kt-heading kt-heading--md">
            Live Chat Settings
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label mt-auto">Can Visitors Message You?</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={LIVE_CHAT_ENAABLED_OPTIONS.find(option => option.value === data.hide_live_chat)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                placeholder="Please Select An Option"
                onChange={option => {
                  data.hide_live_chat = option.value
                  this.setState({ data })
                }}
                options={LIVE_CHAT_ENAABLED_OPTIONS}
              />
            </div>
          </div>

        </Div>
        <div className="kt-form__actions pull-right">
          <a
            href="javascript:void()"
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            onClick={e => this._handleUpdate()}>
              Update
            </a>
        </div>
      </>
    )
  }
}
