import React from 'react'

export default class ArticleActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      article: props.article
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let { article } = this.state
    return (
      <>
        <a
          href="javascript:;"
          class="btn btn-icon btn-light btn-hover-primary btn-sm"
          title="View"
          onClick={() => this.props.onViewPressed(article) }
        >
          <i class="la la-eye icon-sm"></i>
        </a>
        <a
          href="javascript:;"
          class="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
          title="Edit"
          onClick={() => this.props.onEditPressed(article) }
        >
          <i class="flaticon2-pen text-primary icon-sm"></i>
        </a>
      </>
    )
  }
}
