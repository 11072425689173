import React from 'react'
import $ from 'jquery'

import moment from "moment"

import { Helmet } from "react-helmet";

import AuthManager from '../utils/AuthManager'
import Email from '../utils/Email'
import Notify from '../utils/Notify'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Website from '../utils/Website'
import Currency from '../utils/Currency'

import ColorPicker from './components/ColorPicker'
import Separator from "./components/common/Separator"

import CardEntryForm from "./components/common/CardEntryForm"

import Div from './components/layouts/Div'

import ImagePickerModal from './components/modal/ImagePickerModal'

import FontBoxWidth from './components/icons/login/v2/font/width.jpg'

import ProgressBar from 'react-bootstrap/ProgressBar';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useSpring, animated } from 'react-spring';

import SiteBuilder, {
  BUTTON_STYLES,
  FONTS,
  LOGO_POSITIONS,
  BANNER_STYLES,
  BLOCKS,
  ADD_ONS
} from '../utils/SiteBuilder'
import SuccessModal from "./components/modal/SuccessModal";

const INITIAL_LOADING_STEP_TIME_DELAY = 450
const SUBSEQUENT_LOADING_STEP_TIME_DELAY = 1200
const MIN_LOADING_TIME = 8000

const platforms_infrastructure = [
  {
    id: '1',
    icon: 'table_chart',
    title: 'Web Builder',
    tooltip : 'The core platform that enables you and your customers to create cutting-edge websites.',
    key: 'web_builder',
    defaultChecked: false,
    prevent: "",
  },
  {
    id: '2',
    icon: 'content_copy',
    title: 'AI Content Generator',
    tooltip : 'An AI machine that auto generates the draft content for each new website (lack of content is usually the key issue for a customer when creating a new website).',
    key: 'ai_content_creator',
    defaultChecked: false,
    prevent: "",
  },
  {
    id: '3',
    icon: 'dashboard',
    title: 'Customer Dashboard',
    tooltip : 'A dashboard which your customers can use to view their stats and analytics, along with enabling new add-ons (in which you can charge your customers for).',
    key: 'client_dashboard',
    defaultChecked: false,
    prevent: "",
  },
  {
    id: '4',
    icon: 'admin_panel_settings',
    title: 'Admin Console',
    tooltip : 'A powerful dashboard that gives you full insights and control over your customers websites.',
    key: 'admin_dashboard',
    defaultChecked: false,
    prevent: "",
  },
  {
    id: '5',
    icon: 'webhook',
    title: 'Web Generator API',
    tooltip : 'Connect your existing platforms or products to the Web Generator API to automatically create new websites for your customers on the fly (in which you can then auto send the new website to your customers, without them needing to start the process).',
    key: 'web_generator_api',
    defaultChecked: false,
    prevent: "",
  },
  {
    id: '6',
    icon: 'api',
    title: 'Open API',
    tooltip : 'If your customers already have a website, you can empower them to supercharge their existing website by dropping in any of your new Add-Ons or features into their existing website, using the Open API & Add-Ons.',
    key: 'open_ai',
    defaultChecked: false,
    prevent: "",
  }
]

const add_ons = [
  {
    id: '1',
    icon: 'shopping_cart',
    title: 'eCommerce Shop',
    key: 'shop',
    tooltip : 'Spin up an online shop & start selling products & services within minutes.'
  },
  {
    id: '2',
    icon: 'question_answer',
    title: 'Live Chat',
    key: 'chat',
    tooltip : 'Chat to your visitors live on your site and turn them into customers.'
  },
  {
    id: '3',
    icon: 'contact_page',
    title: 'CRM',
    key: 'crm',
    tooltip : 'Bolt on our CRM system & manage leads directly from your new site.'
  },
  {
    id: '4',
    icon: 'payments',
    title: 'Website Payment Widget',
    key: 'payment_widget',
    tooltip : 'Take payment from your customers with ease with a simple Payment Widget.'
  },
  {
    id: '5',
    icon: 'apps',
    title: 'Booking System',
    key: 'bookings',
    tooltip : 'Let your customers book and pay for various services that you provide.'
  },
  {
    id: '6',
    icon: 'event',
    title: 'Event Registration',
    key: 'events',
    tooltip : 'Run an event... send, receive & manage event registrations with ease.'
  },
  {
    id: '7',
    icon: 'web',
    title: 'AI Blog',
    key: 'blog',
    tooltip : 'Post your latest updates and news, with the added option to auto generae posts.'
  },
  {
    id: '8',
    icon: 'analytics',
    title: 'Analytics & Trends',
    key: 'stats',
    tooltip : "Detailed insights into your customer's behaviours and trends, which help you improve your overall website and experience."
  },
  {
    id: '9',
    icon: 'memory',
    title: 'Digital Marketing Wizard',
    key: 'marketing',
    tooltip : 'Customers can run digital marketing campaigns directly from their dashboard.'
  }
]

const mobile_app = [
  {
    id: '1',
    title: 'Yes Please',
    value: true
  },
  {
    id: '2',
    title: 'No Thank You',
    value: false
  }
]

const customer_support = [
  {
    id: '2',
    title: 'XYZ To Provide Support',
    value: true
  },
  {
    id: '1',
    title: 'You Will Provide Support',
    value: false
  },
  {
    id: '3',
    title: 'No Support Required (Customers Self Serve)',
    value: false
  },
  {
    id: '4',
    title: 'Customers Can Pay For Support',
    value: false
  }
]

const future_innovation = [
  {
    id: '1',
    title: 'Yes Please',
    value: true
  },
  {
    id: '2',
    title: "No Thank You (We'll Build Our Own Features)",
    value: false
  }
]

function Number({oldVal, newVal}){
  const { number } = useSpring({
    from: {number: oldVal},
    number: newVal,
    delay: 200,
    config: {mass: 1, tension: 20, friction: 10}
  })

  return <animated.span>{number.to((newVal) => newVal.toFixed(0))}</animated.span>
}

export default class Partnership extends React.Component {
  constructor(props) {
    super(props)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get('s') || "website"
    const premium = params.get("p") != null
    const u = params.get("u") || "s"

    this.state = {
      premium,
      source,
      isLoading: false,
      showEmailAddressInfo : false,
      mobileApp: false,
      customerSupport: false,
      futureUpdates: false,
      addOnSelected: false,
      platformSelected: false,
      leadData: {
        source: "website",
        data: {
          source,
          setup_price: 50000,
          monthly_price: 75000,
          support_price: 0,
          customer_support: false,
          future_updates: false,
          no_websites: 1000,
          client_base: 1000,
          add_ons: {
            shop: false,
            chat: false,
            crm: false,
            payment_widget: false,
            bookings: false,
            events: false,
            blog: false,
            stats: false,
            marketing: false,
            mobile_app: false
          },
          platforms: {
            web_builder: false,
            ai_content_creator: false,
            client_dashboard: false,
            admin_dashboard: false,
            web_generator_api: false,
            open_api: false
          }
        }
      }
    }

    this.cardEntryForm = React.createRef()
  }

  componentDidMount(){
    this._setPackage()
  }

  _setPackage(){
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const pkgSlug = this.props.match.params.pkg_slug

    Backend.getPackageBySlug(pkgSlug)
    .then(pkg => {

      delete pkg.add_ons["package"]
      for (const key in pkg.add_ons) {

          let data = ADD_ONS.find(o => o.key == key) || {}
          pkg.add_ons[key] = {
            ...data,
            ...pkg.add_ons[key],
            visible: false
          }

      }

      this.setState({ pkg })
    })
  }

  _isSignUpFormValid(){
    let {
      leadData,
      mobileApp,
      futureUpdates,
      customerSupport
    } = this.state

    let error = null

    if(!Object.values(leadData.data.add_ons).includes(true)){
      error = "Please select at least one add on."
    }
    else if(!Object.values(leadData.data.platforms).includes(true)){
      error = "Please select at least one platform offering"
    }
    else if(!mobileApp){
      error = "Please indicate if you would like a mobile app"
    }
    else if(!customerSupport){
      error = "Please indicate if you would like XYZ to provide support"
    }
    else if(!futureUpdates){
      error = "Please indicate if you would like to receive future updates"
    }
    else if(leadData.website_name == null || leadData.website_name.length === 0){
      error = "Please enter your business name"
    }
    else if(leadData.contact_name == null || leadData.contact_name.length === 0){
      error = "Please enter your name"
    }
    else if(leadData.email == null || leadData.email.length === 0){
      error = "Please enter your email"
    }
    else if(!Email.isValid(leadData.email)){
      error = "Please enter a valid email"
    }
    else if(leadData.data.target_market == null || leadData.data.target_market.length === 0){
      error = "Please enter your customer market"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _submitLead(){
    let { source, leadData } = this.state

    if(!this._isSignUpFormValid()){
      return
    }

    let startTime = moment()
    this.setState({ isLoading: true })

    Backend.submitLead(leadData).then(response => {
      this.setState({
        isLoading: false,
        showSuccessModal: true,
      })
    }).catch(e => {
      this.setState({
        isLoading: false,
      })
      Notify.error(e.message)
    })
  }

  _renderMeta() {
    let { pkg, website } = this.state;

    if (pkg == null) {
      return null;
    }

    let favIconUrl = window.General.Branding.Logo;

    let title = `${window.General.Branding.Name} | Partnership Quote Builder`;
    let ogTitle = `${window.General.Branding.Name} | Launch Your Own Bespoke Website Builder`;
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Innovate and generate new revenue by offering simple cutting-edge websites and online tools to new & existing customers." />
        <link rel="icon" href={favIconUrl} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content="Innovate and generate new revenue by offering simple cutting-edge websites and online tools to new & existing customers." />
        <meta property="og:image" content={favIconUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:image" content={favIconUrl} />
      </Helmet>
    );
  }

  _renderCheckMarkSvg() {
    return (
      <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    )
  }

  _renderloadingText(){

    let points = [
      "Configuring Your Style",
      "Adding Graphics & Photos",
      "Creating Your Banner",
      "Enabling Your Add-Ons"
    ]

    return (
      <div className="loading-text">
        <ul className="points">
          <li className='p-default'>{this._renderCheckMarkSvg()} <span>Building Your Site</span></li>
          {points.map(point => {
            return (
              <li>{this._renderCheckMarkSvg()} <span>{point}</span></li>
            );
          })}
          <li className="b-text">
              Finalising your quote <span>...</span>
          </li>
        </ul>
      </div>
    )
  }

  _renderWebsiteCountSlider() {
    let {
      leadData
    } = this.state

    return (
      <div className="slidecontainer col-xl-10 v4">
        <div className='row'>
          <div className="col-12 col-md r-field">
            <input
              className="form-control w-100"
              id="rangeInputv4"
              type="range"
              step="200"
              min="0"
              max="10000"
              name="v4"
              value={leadData.data.no_websites}
              disabled
              onChange={e => {
                leadData.data.no_websites = e.target.value
                if(parseInt(e.target.value) > 1000) {
                  let overAmount = e.target.value - 1000
                  let price = 1000 * 200
                  price += overAmount * 100
                  leadData.data.support_price = price
                }else {
                  leadData.data.support_price = e.target.value * 200
                }
                this.setState({leadData})
              }}
            />
            <div className='progress'>
              <span className='bar'></span>
              <span id="rangeInputv4-r-val" className='r-val progress-bar progress-bar-animated progress-bar-striped' />
            </div>
          </div>
          <div className="col-8 col-md-2">
            <input type="text" id="rangeOutputv4" readOnly value={`${leadData.data.no_websites}${leadData.data.no_websites === "10000" ? '+' : ''}`} />
          </div>
          <label className="col-auto p-0"><span className="title">Websites</span></label>
        </div>
      </div>
    )
  }

  _renderCustomerBaseSlider() {
    let {
      leadData
    } = this.state

    return (
      <div className="slidecontainer col-xl-12 v4">
        <div className='row mr-0'>
          <div className="col-12 col-md r-field">
            <input
              className="form-control w-100"
              id="rangeInputv5"
              type="range"
              step="200"
              min="0"
              max="10000"
              name="v4"
              value={leadData.data.client_base}
              disabled
              onChange={e => {
                leadData.data.client_base = e.target.value
                this.setState({leadData})
              }}
            />
            <div className='progress'>
              <span className='bar'></span>
              <span id="rangeInputv5-r-val" className='r-val progress-bar progress-bar-animated progress-bar-striped' />
            </div>
          </div>
          <div className="col-5 col-md-2">
            <input type="text" id="rangeOutputv5" readOnly value={`${leadData.data.client_base}${leadData.data.client_base === "10000" ? '+' : ''}`} />
          </div>
          <label className="col-auto p-0"><span className="title">Existing Customers</span></label>
        </div>
      </div>
    )
  }

  _renderContent(){
    let {
      isLoading,
      leadData,
      addOnSelected,
      platformSelected,
      customerSupport,
      futureUpdates,
      mobileApp,
      showEmailAddressInfo
    } = this.state

    let showEmailAddressInfoCSS;
    if(showEmailAddressInfo){
      showEmailAddressInfoCSS = "form-text"
    }else{
      showEmailAddressInfoCSS = "d-none"
    }

    return (
      <form
        className="form"
        novalidate="novalidate"
      >
        <Div
          disabled={isLoading}
          spinner={true}
          disableViewClassName="disable-view-absolute"
          loadingText={ this._renderloadingText() }
          onReady={() => {
            setTimeout(function () {
              $('.loading-text .points li.p-default').addClass('active');
            }, INITIAL_LOADING_STEP_TIME_DELAY);
            let counter = 0;
            setInterval(function () {
              let element = $('.loading-text .points li:not(.p-default)');
              let c = counter % $(element).length;
              $(element).eq(c).addClass('active');
              ++counter
            }, SUBSEQUENT_LOADING_STEP_TIME_DELAY);
          }}
        >
          <div className="pb-13 pt-lg-0 pt- d-none d-md-block">
            <h3 className="form-heading">Create Your Own Custom Quote</h3>
            <p className="form-title">You can change all of these options later...</p>
          </div>

          {/* Starts : 01 - Estimate No Of Websites */}
          <div className="form-group form-card">
            <label>
              <span className='title'>01 - Estimate No Of Websites Per Annum</span>
              <span className='sub-title'>How many websites do you want to sell or give to your customers each year?</span>
            </label>
            <div className='field'>
              {this._renderWebsiteCountSlider()}
            </div>
          </div>
          {/* End */}

          {/* Starts : 02 - Add-Ons */}
          <div className="form-group form-card">
            <label>
              <span className='title'>02 - Add-Ons</span>
              <span className='sub-title'>Which Add-Ons do you want to be able to offer your customers?</span>
            </label>
            <div className='field'>
              <div className='choose-multiple-options p-0'>
                {
                  add_ons.map((item) => {

                    return (
                      <div key={item.id} className="form-group option m-0">
                        <div className='row'>
                          <div className='col-auto d-flex'>
                            <span className="material-icons-outlined">
                              {item.icon}
                            </span>
                          </div>
                          <label className="col col-md-4 col-form-label c-tooltip">
                            <span className='title'>{item.title}</span>
                            <OverlayTrigger
                              placement="top"
                              delay={{ hide: 115, show: 100 }}
                              overlay={(props) => (
                                <Tooltip {...props}>{item.tooltip}</Tooltip>
                              )}
                            ><span className="material-icons icon-help">info_outline</span>
                            </OverlayTrigger>
                          </label>
                          <div className="col-auto col-md-3">
                            <span className="kt-switch">
                              <label>
                                <input
                                  type="checkbox"
                                  id={item.title}
                                  checked={leadData.data.add_ons[item.key]}
                                  onChange={e => {
                                    leadData.data.add_ons[item.key] = e.target.checked
                                    if(e.target.checked) {
                                      leadData.data.monthly_price += 20000
                                    }else {
                                      leadData.data.monthly_price -= 20000
                                    }
                                    if(Object.values(leadData.data.add_ons).includes(true)){
                                      addOnSelected = true
                                    }
                                    if(!Object.values(leadData.data.add_ons).includes(true)){
                                      addOnSelected = false
                                    }
                                    this.setState({leadData, addOnSelected})
                                  }}
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {/* End */}

          {/* Starts : 03 - Platforms & Infrastructure */}
          <div className="form-group form-card">
            <label>
              <span className='title'>03 - Platforms & Infrastructure</span>
              <span className='sub-title'>Which platforms and infrastructrure do you need?</span>
            </label>
            <div className='field'>
              <div className='choose-multiple-options p-0'>
                {
                  platforms_infrastructure.map((item) => {

                    return (
                      <div key={item.id} className="form-group option m-0">
                        <div className='row'>
                          <div className='col-auto d-flex'>
                            <span className="material-icons-outlined">
                              {item.icon}
                            </span>
                          </div>
                          <label className="col col-md-4 col-form-label c-tooltip">
                            <span className='title'>{item.title}</span>
                            <OverlayTrigger
                              placement="top"
                              delay={{ hide: 115, show: 100 }}
                              overlay={(props) => (
                                <Tooltip {...props}>{item.tooltip}</Tooltip>
                              )}
                            ><span className="material-icons icon-help">info_outline</span>
                            </OverlayTrigger>
                          </label>
                          <div className="col-auto col-md-3">
                            <span className="kt-switch">
                              <label>
                                <input
                                  type="checkbox"
                                  id={item.title}
                                  checked={leadData.data.platforms[item.key]}
                                  onChange={(e) => {
                                    leadData.data.platforms[item.key] = e.target.checked
                                    if(e.target.checked) {
                                      leadData.data.monthly_price += 30000
                                    }else {
                                      leadData.data.monthly_price -= 30000
                                    }
                                    if(Object.values(leadData.data.platforms).includes(true)){
                                      platformSelected = true
                                    }
                                    if(!Object.values(leadData.data.platforms).includes(true)){
                                      platformSelected = false
                                    }
                                    this.setState({leadData, platformSelected})
                                  }}
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {/* End */}

          {/* Starts : 04 - Mobile App (Your Brand) */}
          <div className="form-group form-card">
            <label>
              <span className='title'>04 - Mobile App (Your Brand)</span>
              <span className='sub-title'>Do you want a shiny new mobile app that your customers can download to manage their website and interact with their customers on the move?</span>
            </label>
            <div className='field'>
              {mobile_app.map((item) => (
                <div key={item.id} className="form-group option m-0">
                  <div className='row align-items-center'>
                    <div className="col-auto">
                      <span className="kt-switch">
                        <label className='m-0'>
                          <input
                            type="radio"
                            name="mobile_app"
                            id={item.title}
                            value={item.value}
                            onChange={e => {
                              if(e.target.value === "true") {
                                leadData.data.monthly_price += 50000
                                leadData.data.setup_price += 150000
                              }else {
                                if(leadData.data.add_ons.mobile_app) {
                                  leadData.data.monthly_price -= 50000
                                  leadData.data.setup_price -= 150000
                                }
                              }
                              mobileApp = true
                              leadData.data.add_ons.mobile_app = e.target.value === "true"
                              this.setState({leadData, mobileApp})
                            }}
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                    <label className="col col-md-3 m-0">
                      <span className='title'>{item.title}</span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* End */}

          {/* Starts : 05 - Customer Support */}
          <div className="form-group form-card">
            <label>
              <span className='title'>05 - Customer Support</span>
              <span className='sub-title'>Who do you want to provide the support to your customers?</span>
            </label>
            <div className='field'>
              {customer_support.map((item) => (
                <div key={item.id} className="form-group option m-0">
                  <div className='row align-items-center'>
                    <div className="col-auto">
                      <span className="kt-switch">
                        <label className='m-0'>
                          <input
                            type="radio"
                            name="customer_support"
                            id={item.title}
                            value={item.value}
                            onChange={e => {
                              leadData.data.customer_support = e.target.value === "true"
                              customerSupport = true
                              if(leadData.data.customer_support) {
                                if(leadData.data.no_websites > 1000) {
                                  let overAmount = leadData.data.no_websites - 1000
                                  let price = 1000 * 200
                                  if(overAmount > 0) {
                                    price += overAmount * 100
                                  }
                                  leadData.data.support_price = price
                                }else {
                                  leadData.data.support_price = leadData.data.no_websites * 200
                                }
                              }else {
                                leadData.data.support_price = 0
                              }
                              this.setState({leadData, customerSupport})
                            }}
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                    <label className="col m-0">
                      <span className='title'>{item.title}</span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* End */}

          {/* Starts : 06 - Future Innovation, Updates & Features */}
          <div className="form-group form-card">
            <label>
              <span className='title'>06 - Future Innovation, Updates & Features</span>
              <span className='sub-title'>Do you want to obtain all new product features and updates </span>
            </label>
            <div className='field'>
              {future_innovation.map((item) => (
                <div key={item.id} className="form-group option m-0">
                  <div className='row align-items-center'>
                    <div className="col-auto">
                      <span className="kt-switch">
                        <label className='m-0'>
                          <input
                            type="radio"
                            name="future_innovation"
                            id={item.title}
                            value={item.value}
                            onChange={e => {
                              if(e.target.value === "true") {
                                leadData.data.monthly_price += 50000
                              }else {
                                if(leadData.data.future_updates) {
                                  leadData.data.monthly_price -= 50000
                                }
                              }
                              futureUpdates = true
                              leadData.data.future_updates = e.target.value === "true"
                              this.setState({leadData, futureUpdates})
                            }}
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                    <label className="col m-0">
                      <span className='title'>{item.title}</span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* End */}

          <Separator
            text="Your Details"
            css="color-charcoal v2"
          />

          {/* Starts : 07 - Your Business Details */}
          <div className="form-group form-card">
            <label>
              <span className='title'>07 - Your Business Details</span>
              <span className='sub-title'>Please provide details about your business</span>
            </label>
            <div className='field'>
              <div className='row'>
                <div className='col-md'>
                  <div className="form-group mb-md-1">
                    <label>
                      <span className='title'>Business Name</span>
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                      type="text"
                      placeholder="Business Name"
                      name="business_name"
                      autocomplete="off"
                      value={leadData.website_name}
                      onChange={e => {
                        leadData.website_name = e.target.value
                        this.setState({leadData})
                      }}
                    />
                  </div>
                </div>
                <div className='col-md'>
                  <div className="form-group mb-md-1">
                    <label>
                      <span className='title'>Your Name</span>
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                      type="text"
                      placeholder="Your Name"
                      name="your_name"
                      autocomplete="off"
                      value={leadData.contact_name}
                      onChange={e => {
                        leadData.contact_name = e.target.value
                        this.setState({leadData})
                      }}
                    />
                  </div>
                </div>
                <div className='col-md'>
                  <div className="form-group mb-1">
                    <label>
                      <span className='title'>Email Address</span>
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                      type="eamil"
                      placeholder="Email Address"
                      name="email"
                      autocomplete="off"
                      onFocus={() => this.setState({ showEmailAddressInfo: true })}
                      value={leadData.email}
                      onChange={e => {
                        leadData.email = e.target.value
                        this.setState({leadData})
                      }}
                    />
                    <span
                      className={`note-email ${showEmailAddressInfoCSS}`}
                    >Please use a live email address as the formal quote will be sent to this email</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End */}

          {/* Starts : 08 - Your Customer Market */}
          <div className="form-group form-card">
            <label>
              <span className='title'>08 - Your Customer Market</span>
              <span className='sub-title'>What is your use case, or what type of websites will your customers need?</span>
            </label>
            <div className='field'>
              <div className='row'>
                <div className='col-md'>
                  <div className="form-group mb-md-1">
                    <textarea
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                      type="text"
                      placeholder="Type of websites will your customers need"
                      name="customer_market"
                      autocomplete="off"
                      value={leadData.data.target_market}
                      onChange={e => {
                        leadData.data.target_market = e.target.value
                        this.setState({leadData})
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End */}


          {/* Starts : 09 - Your Existing Customer Bases */}
          <div className="form-group form-card">
            <label>
              <span className='title'>09 - Your Existing Customer Base</span>
              <span className='sub-title'>What size is your existing customer base that you plan on marketing this to?</span>
            </label>
            <div className='field'>
              {this._renderCustomerBaseSlider()}
            </div>
          </div>
          {/* End */}

          <div className="form-group mb-0">
            <button
              type="button"
              className="btn btn-green font-weight-bolder font-size-h6 px-8 py-4 w-100"
              onClick={(e) => {
                e.preventDefault()
                this._submitLead()
              }}
              disabled={isLoading}
            >
              Generate & Email Formal Quote
            </button>
          </div>

        </Div>
      </form>
    )
  }

  render() {
    let {
      addOnSelected,
      platformSelected,
      customerSupport,
      futureUpdates,
      mobileApp,
      leadData,
      isLoading,
      showSuccessModal,
      pkg,
    } = this.state

    if(!pkg){
      return null
    }

    let loginContentClassName = "login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden w-100"

    if(isLoading){
      loginContentClassName += " login-content-loading"
    }

    let {
      website_name,
      contact_name,
      email,
    } = leadData

    let progress = 30
    if(addOnSelected){
      progress += 10
    }
    if(platformSelected){
      progress += 10
    }
    if(mobileApp){
      progress += 10
    }
    if(customerSupport){
      progress += 10
    }
    if(futureUpdates){
      progress += 10
    }
    if(website_name){
      progress += 3
    }
    if(contact_name){
      progress += 3
    }
    if(Email.isValid(email)){
      progress += 4
    }
    if(leadData.data.target_market){
      progress += 10
    }

    // aside points
    let asidePoints = {
      title: pkg.subline || "Build Your Own Site",
      points: pkg.benefits.map(benefit => benefit.title)
    }

    asidePoints = { // cleanup
      title: "Your Custom Quote",
      points : [
        {
          name : "Setup",
          value : leadData.data.setup_price/100
        },
        {
          name : "Monthly Licence",
          value : leadData.data.monthly_price/100
        },
        {
          name : "Support",
          value : leadData.data.customer_support ? leadData.data.support_price/100 : 0
        },
      ],
      btmText: " all prices are starting from",
    }

    let loginAsideClassName = "login-aside d-flex flex-column flex-row-auto pt-lg-40 pt-10 pl-25 pr-25 ";
    if(pkg.overlay){
      loginAsideClassName += " dark-overlay"
    }

    let loginAsideBgColor = "#ccc"

    if(!pkg.cta_background_color){
      pkg.cta_background_color =  pkg.primary_color
    }

    return (
      <>
        {this._renderMeta()}

        <div className="metr_v702 d-flex flex-column flex-root">
          <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white v2 page partnership" id="kt_login">

            {/* begin : Login Aside */}
            <div className={loginAsideClassName}>
              <div>
              <div className="d-flex flex-column-auto flex-column text-center">
                <a href={`//${window.General.Branding.Domain}`} className="mb-10">
                  <img src={window.General.Branding.Logo} alt="Logo" className="branding-logo" />
                </a>
                <h3 className="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10">Build Bespoke Quote</h3>
              </div>
              <div className='aside-progress-bar'>
                <ProgressBar
                  striped
                  animated
                  now={progress}
                  //label={`${now}%`}
                  className="wrapper"
                  barContainerClassName="container"
                  completedClassName="barCompleted"
                  labelClassName="label"
                />
                <h5 className='pt-2'>{progress}% Completed</h5>
              </div>
              <div className='aside-points'>
                <div
                  className={`details`}
                >
                  <h4 className='font-weight-bolder mb-4 text-white'>
                    {asidePoints.title}
                  </h4>
                  {asidePoints.points.map((point) => {
                    return (
                      <h4 className='font-weight-bolder'>
                        {point.name}: <span className='text-white'>€<Number oldVal={0} newVal={point.value}/>{point.name === "Monthly Licence" ? 'pm' : ''}</span>
                      </h4>
                    );
                  })}
                  <div className='btm-text'>*{asidePoints.btmText}</div>
                </div>
              </div>
              </div>
            </div>
            {/* end : Login Aside */}

            {/* <!--begin::Content--> */}
            <div className={loginContentClassName}>


              <div className="d-flex flex-column-fluid flex-center">

                {/* <!--begin::Signup--> */}
                <div className="login-form login-signup"
                  style={{
                    display: "block"
                  }}
                >
                  { this._renderContent() }
                </div>
                {/* <!--end::Signup--> */}

              </div>
              {/* <!--end::Content body--> */}

            </div>
            {/* end::Content */}

          </div>

        </div>

        <style
          dangerouslySetInnerHTML={{ __html: `
            :root {
              --pkg_primary_color: ` + pkg.primary_color + ` ;
              --pkg_title_color: ` + pkg.title_color + ` ;
              --pkg_cta_background_color: ` + pkg.cta_background_color + ` ;
              --pkg_cta_text_color: ` + pkg.cta_text_color + ` ;
              --login_aside_bg_color: ` + loginAsideBgColor + ` ;
            }
          `}}
        />

        <SuccessModal
          show={showSuccessModal}
          title={"Thank you."}
          message={"One of our team will review your requirements and will email you within the next hour."}
          confirmButtonText={"Ok"}
          onHide={() => {
            window.location = 'https://site.xyz'
          }}
          onConfirm={() => {
            window.location = 'https://site.xyz'
          }}
        />
      </>
    )
  }
}
