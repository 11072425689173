import React from 'react'
import moment from "moment";

export default class CRMSubmissionCard extends React.Component {
  render() {
    let { submission } = this.props
    return (
      <>
        <span className="font-weight-bold inline-text-ellipsis">
          {`${submission.data.contact_first_name} ${submission.data.contact_last_name}` }
        </span>

        { submission.data.contact_phone &&
          <span>{submission.data.contact_phone}</span>
        }

        <span className="inline-text-ellipsis">{submission.data.contact_email}</span>

        <span>{moment(submission.created_at).format('DD/MM/YYYY')}</span>
      </>
    )
  }
}

CRMSubmissionCard.defaultProps = {}
