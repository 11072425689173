import React, { Component } from "react";

import moment from "moment";

import LazyLoadingList from "./common/LazyLoadingList";

import LoadingCard from "./LoadingCard";

import PageViewListItem from "./PageViewListItem";

export default class PageViewsList extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.lazyLoadingList = React.createRef();
  }

  refresh() {
    this.lazyLoadingList.current.refresh();
  }

  _renderPlaceholderCards() {
    return (
      <>
        <LoadingCard />
        <LoadingCard />
      </>
    );
  }

  _renderEmptyState() {
    return (
      <div className="no-content visitors">
        <div className="no-content-section mt-6">
          <p>Oops, no analytics found...</p>
        </div>
      </div>
    );
  }

  render() {
    const { pageViews } = this.state;

    return (
        <div className="card-body card-body-scroll-lock">
          {/*begin::Timeline*/}
          <div className="timeline timeline-6 mt-3">
          <LazyLoadingList
            ref={this.lazyLoadingList}
            endpoint={`${window.Api.PageViews}?visitor_id=${this.props.visitor.id}&paginated=true`}
            onItemUpdated={(pageViews) => {
              this.setState({ pageViews });
            }}
            renderItem={(pageView, index) => {
              let notOnSameDayAsPreviousItem = index === 0;

              if (index > 0) {
                let previousPageView = pageViews[index - 1]
                let currentDate = moment(pageView.created_at)
                let previousDate = moment(previousPageView.created_at)
                notOnSameDayAsPreviousItem = !currentDate.isSame(previousDate, "date")
              }

              let formattedDate = moment(pageView.created_at).format("dddd, Do MMM")

              return (
                <>
                  {notOnSameDayAsPreviousItem && (
                    <p
                      style={{ marginLeft: 75, marginTop: 40, marginBottom: 40 }}
                    >
                      { formattedDate }
                    </p>
                  )}

                  <PageViewListItem
                  visitor={this.props.visitor}
                    pageView={pageView}
                    onUpdated={() => this.lazyLoadingList.current.refresh()}
                  />
                </>
              );
            }}
            renderNoContent={() => this._renderEmptyState()}
            renderInitialLoading={() => this._renderPlaceholderCards()}
            renderLoadingMore={() => this._renderPlaceholderCards()}
          />

        </div>
        {/*end::Timeline*/}.
      </div>
    );
  }
}
