import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

export default class CollectionModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      sale: this.props.sale,
      data: {
        status: "ready",
        note: "",
      },
      loading: false
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _collectable(){
    let {
      sale,
      data
    } = this.state

    data.sale = sale.id

    this.setState({ loading: true })
    Backend.collectable(data)
    .then(() => {
      this.setState({ loading: false })
      this.props.onCollectable()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      data,
      loading
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {
          if(!loading){
            this.props.onCancel()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure?</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <p>Your customer will receive an emal alerting them of this</p>
          <div className="form-group">
            <label>Note (optional)</label>
            <textarea
              type="text"
              name="note"
              className="form-control"
              value={ data.note }
              onChange={e => this._handleChange(e)}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            disabled={loading}
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-danger`}
            disabled={loading}
            onClick={() => this._collectable()}
          >
            Notify Collectable
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

CollectionModal.defaultProps = {
}
