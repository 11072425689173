import React from "react";
import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import Switch from "react-switch";
import SmartList from "../common/SmartList";
import {
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  OutlinedInput
}  from "@material-ui/core";


import TextEditor from "../common/TextEditor";

export default class FormSettingsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      loading: false,
      error: null,
      form: JSON.parse(JSON.stringify(props.form)),
      companyMembers: [],
    };
  }

  componentDidMount() {
    this._load();
  }

  _load() {
    Backend.getCompanyMembers().then((members) => {
      this.setState({ companyMembers: members.results });
    });
  }

  _handleChange(e) {
    let form = this.state.form;
    form[e.target.name] = e.target.value;
    this.setState({ form, error: null });
  }

  _isFormValid() {
    let { name } = this.state.form;

    let error = null;
    if (name == null || name.length === 0) {
      error = "Please enter a valid form name";
    }

    if (error) {
      this.setState({ error });
      return false;
    }
    return true;
  }

  _handleUpdateForm() {
    if (!this._isFormValid()) {
      return;
    }
    this._updateForm();
  }

  _updateForm() {
    let { form } = this.state;

    this.props.updateFormSettings(form);
  }

  render() {
    let { show, loading, form, error, companyMembers } = this.state;

    if (!show) return null;

    return (
      <Modal show={show} backdrop={"static"} onHide={() => this.props.onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Form Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {error && (
            <div className="form-group row">
              <span className="error-message">{error}</span>
            </div>
          )}

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <TextField
                label={"Form Name"}
                fullWidth
                variant={"outlined"}
                value={form.name}
                onChange={(event) => {
                  form.name = event.target.value;
                  this.setState({ form });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <TextField
                label={"Submit Button"}
                fullWidth
                variant={"outlined"}
                value={form.submit_text}
                onChange={(event) => {
                  form.submit_text = event.target.value;
                  this.setState({ form });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <TextField
                label={"Message Sent Popup Title"}
                fullWidth
                variant={"outlined"}
                value={form.popup_title}
                onChange={(event) => {
                  form.popup_title = event.target.value;
                  this.setState({ form });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <TextField
                multiline
                label={"Message Sent Popup Body"}
                rows={4}
                fullWidth
                value={form.popup_body}
                variant={"outlined"}
                onChange={(event) => {
                  form.popup_body = event.target.value;
                  this.setState({ form });
                }}
              />
            </div>
          </div>

          <div className={"form-group row"}>
            <div className={"col-lg-12 my-auto"}>
              <label className="col-form-label">Send Confirmation Email</label>
              <Switch
                className="react-switch c-switch float-right"
                onChange={(checked) => {
                  form.send_confirmation_email = checked;
                  this.setState({ form });
                }}
                checked={form.send_confirmation_email}
                aria-labelledby="neat-label"
                onColor="#333333"
                offColor="#F3F6F9"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <TextField
                label={"Confirmation Email Subject"}
                fullWidth
                variant={"outlined"}
                value={form.email_subject}
                onChange={(event) => {
                  form.email_subject = event.target.value;
                  this.setState({ form });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 my-auto">
              <FormControl fullWidth>
                <InputLabel htmlFor="confirmation-email-body" className=" MuiInputLabel-outlined MuiFormLabel-filled">
                  Confirmation Email Body
                </InputLabel>
                <OutlinedInput
                  id="confirmation-email-body"
                  startAdornment={
                    <div className="col-lg-12">
                      <TextEditor
                        label={true}
                        text={form.email_body}
                        onUpdated={(text) => {
                          form.email_body = text;
                          this.setState({ form });
                        }}
                      />
                    </div>
                  }
                  label="Confirmation Email Body"
                />
              </FormControl>
            </div>
          </div>

          <div className={"form-group row"}>
            <div className={"col-lg-12 my-auto"}>
              <label className="col-form-label">
                Form Submission Recipients
              </label>
            </div>
            <div className={"col-lg-12 my-auto"}>
              <div className="kt-checkbox-list">
                {companyMembers.map((member, index) => {
                  let checked =
                    form.company_members.find(
                      (selectedMember) =>
                        selectedMember.user.id === member.user.id
                    ) != null;
                  return (
                    <label className="kt-checkbox kt-checkbox--brand">
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => {
                          if (checked) {
                            let index = form.company_members.findIndex(
                              (selectedMember) =>
                                selectedMember.user.id === member.user.id
                            );
                            form.company_members.splice(index, 1);
                          } else {
                            form.company_members.push(member);
                          }
                          this.setState({ form });
                        }}
                      />
                      {`${member.user.first_name} ${member.user.last_name}`}
                      <span></span>
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._handleUpdateForm()}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
