import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

export default class CRMEngagementCard extends React.Component {
  render(){
    // to do set the props for sizing here
    return (
      <EngagementCard
        { ...this.props }
        title="Contact Form Leads"
        renderContent={() => "This is where you can manage all the leads that you receive from your contact form."}
      />
    )
  }
}
