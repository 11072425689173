import React from "react"

import AsyncSelect from '../common/AsyncSelect'

import CreatableSelect from 'react-select/creatable';

import CreateArticleCategoryModal from '../modal/CreateArticleCategoryModal'

import AuthManager from '../../../utils/AuthManager';
import Backend from '../../../utils/Backend';

export default class Category extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      shop: props.shop,
      categories: [],
      selectedCategories: props.selectedCategories,
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    Backend.getArticleCategories()
    .then(categories => {
      this.setState({ categories })
    })
  }

  _getOptions(categories){
    return categories.map(category => ({
      value: category.id,
      label: category.name,
      data: category
    }))
  }

  render(){
    let {
      shop,
      categories,
      selectedCategories,
      showCreateArticleCategoryModal
    } = this.state

    let marginClass = categories.length > 0 ? "mt-4" : ""
    return (
      <>
        <div class="kt-checkbox-list">
          {
             categories.map((category, index) => {
              let checked = selectedCategories.find(selectedCategory => selectedCategory.id == category.id) != null
              return (
        				<label class="kt-checkbox kt-checkbox--brand">
        					<input
                    type="checkbox"
                    checked={checked}
                    onChange={e => {
                      if(checked){
                        let index = selectedCategories.findIndex(selectedCategory => selectedCategory.id == category.id)
                        selectedCategories.splice(index, 1)
                      }
                      else{
                        selectedCategories.push(category)
                      }
                      this.props.onUpdated(selectedCategories)
                    }}
                  />
                  {category.name}
        					<span></span>
        				</label>
              )
            })
          }
        </div>
        <a
          className={`btn btn-bold btn-sm btn-label-brand ${marginClass}`}
          onClick={e => {
            e.preventDefault()
            this.setState({ showCreateArticleCategoryModal: true })
          }}
        >
          + Add New Category
        </a>
        <div/>
        <CreateArticleCategoryModal
          show={showCreateArticleCategoryModal}
          onHide={() => this.setState({ showCreateArticleCategoryModal: false })}
          onAdded={category => {
            selectedCategories.push(category)
            categories.push(category)
            this.setState({
              categories,
              selectedCategories,
              showCreateArticleCategoryModal: false
            })
          }}
        />
      </>

    )
  }
}
