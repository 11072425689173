import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ReactTooltip from "react-tooltip";

import moment from 'moment'

import Switch from "react-switch";

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import ExplainerAlert from "./components/alert/ExplainerAlert"
import ConfirmModal from "./components/modal/ConfirmModal"

import ImageEdit from "./components/common/ImageEdit"
import AsyncSelect from './components/common/AsyncSelect'
import SmartList from "./components/common/SmartList"

import Div from "./components/layouts/Div"

import ProductSection from './components/product/Section'



import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Website from "../utils/Website"
import Notify from "../utils/Notify"
import General from "../utils/General"

export default class Service extends React.Component {
  constructor(props){
    super(props)

    let serviceId = this.props.match.params.service_id

    this.state = {
      serviceId,
      loading: true,
    }
  }

  componentDidMount(){
    let {
      serviceId
    } = this.state

    if(serviceId){
      this._load()
    }
    else{
      this.setState({
        loading: false ,
        ...this._getState()
      })
    }
  }

  _getState(service){

    let data = service || {
      name: null,
      description: null,
      image: null,
      resources: [],
    }

    return {
      data,
      editMode: data.id != null,
    }
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value || null
    this.setState({ data, error: null })
  }

  _load(){
    let {
      serviceId
    } = this.state

    Backend.getService(serviceId)
    .then(service => {
      this.setState({
        ...this._getState(service),
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _isFormValid(){
    let {
      data,
      shop,
    } = this.state

    let {
      name,
      description,
      resources,
    } = data

    let error = null

    if(!name){
      error = "Please specify the service name"
    }
    else if(!description){
      error = "Please provie a description for the service"
    }
    else{
      for(var i = 0; i < resources.length; i++){
        let serviceResource = resources[i]
        let identifier = `Resource #${i + 1}`
        if(!serviceResource.resource){
          error = `${identifier} not specified`
        }
        else if(!serviceResource.duration){
          error = `${identifier} must specify a duration`
        }
        else if(parseInt(serviceResource.duration) % 15 != 0){
          error = `${identifier} duration must be a multiple of 15, e.g. 30min, 45min, 150min etc...`
        }
      }
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleSave(){
    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })

    this._handleBackend()
    .then(service => {
      if(!this.state.editMode){
        this.props.history.push("/services?service_added=true")
        Notify.success("Your Service has been added")
      }
      else{
        Notify.success("Your Service has been updated")
      }
      this.setState({
        loading: false,
        ...this._getState(service),
      })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _handleBackend(){
    let {
      data,
      editMode
    } = this.state

    data = General.clone(data)
    if(editMode){
      return Backend.editService(data)
    }
    else{
      return Backend.createService(data)
    }
  }

  _renderSection(title, content, collapsed=false, collapsible=false, subtitle=null){

    return (
      <ProductSection
        title={title}
        subtitle={subtitle}
        content={content}
        collapsed={collapsed}
        collapsible={collapsible}
      />
    )

  }

  _getResourceOption(resource){
    if(!resource){
      return null
    }
    return {
      value: resource.id,
      label: resource.name,
      data: resource
    }
  }

  _getResourceOptions(resources){
    return resources.map(resource => this._getResourceOption(resource))
  }

  _renderResources(){
    let { data } = this.state

    let { resources } = data

    return (
      <>
        <ExplainerAlert
          text="Define the resources that can perform this service below."
        />
        <div className="resources w-100">
          <SmartList
            title="Resources"
            minimumItems={0}
            items={resources || []}
            itemName="Resource"
            value={resource => resource.id}
            disabled={resource => false}
            renderItem={(serviceResource, index) => {
              return (
                <div className="service-resource">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Resource</label>
                    <div className="col-lg-9 my-auto">
                      <AsyncSelect
                        endpoint={window.Api.Resources}
                        value={ this._getResourceOption(serviceResource.resource) }
                        onSelected={resource => {
                          serviceResource.resource = resource
                          data.resources[index] = serviceResource
                          this.setState({ data })
                        }}
                        getOptions={resources => this._getResourceOptions(resources)}
                        noOptionsMessage="You Have No Resources"
                        className="resource-selector"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Duration (mins)</label>
                    <div className="col-lg-9 my-auto">
                      <input
                        type="number"
                        name="duration"
                        className="form-control"
                        defaultValue={ serviceResource.duration }
                        onChange={e => {
                          serviceResource.duration = e.target.value ? parseInt(e.target.value) : null
                          data.resources[index] = serviceResource
                          this.setState({ data })
                        }}
                      />
                      <span class="text-muted">How long, in minutes, it takes the resource to perform the service</span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Price</label>
                    <div className="col-lg-9 my-auto">
                      <input
                        type="number"
                        name="duration"
                        className="form-control"
                        defaultValue={ serviceResource.price ? serviceResource.price / 100 : null }
                        onChange={e => {
                          serviceResource.price = e.target.value ? parseInt(e.target.value) * 100 : null
                          data.resources[index] = serviceResource
                          this.setState({ data })
                        }}
                      />
                      <span class="text-muted">Charge for the resource to perform the service</span>
                    </div>
                  </div>

                </div>
              )
            }}
            onUpdated={serviceResources => {
              data.resources = serviceResources
              this.setState({ data })
            }}
            onAddItemPressed={() => {
              if(!data.resources) {
                data.resources = []
              }
              data.resources.push({
                resource: null,
                price: null,
                duration: null
              })
              this.setState({ data })
            }}
          />
        </div>
      </>
    )
  }

  _renderForm(){
    let {
      data,
    } = this.state

    return (
      <>
      { this._renderSection("Service Details", (
          <>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Service Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="form-control form-control-solid"
                  value={ data.name }
                  onChange={e => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Service Description</label>
              <div className="col-lg-9 my-auto">
                <textarea
                  value={ data.description }
                  name="description"
                  rows={6}
                  className="form-control form-control-solid"
                  placeholder="Service description goes here..."
                  onChange={e => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Service Image</label>
              <div className="col-lg-9 my-auto">
                <ImageEdit
                  image={data.image}
                  type="photo"
                  cropImage={true}
                  aspectRatio={1}
                  onUpdated={image => {
                    data.image = image
                    this.setState({ data })
                  }}
                />
              </div>
            </div>
          </>
        ))
      }
      {
        this._renderSection("Resources", this._renderResources(), false, true)
      }

      </>
    )
  }

  render() {
    let {
      show,
      data,
      loading,
      service,
      editMode,
    } = this.state

    if(!data){
      return null
    }

    let title = editMode ? "Update Service" : "Add Service"
    let saveButtonTitle = editMode ? "Update Service" : "Add Service"

    return (
      <div className="row">
				<Div
          className="col-lg-8"
          disabled={loading}
        >

          { this._renderForm() }


          <div className="kt-portlet__foot">
            <div className="kt-form__actions pull-right">

              <button
                type="button"
                className="btn btn-primary ml-3"
                disabled={loading}
                onClick={() => this._handleSave()}
              >
                { saveButtonTitle }
              </button>
            </div>
          </div>
        </Div>

        <ReactTooltip effect="solid"/>
      </div>
    )
  }
}
