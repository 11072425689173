import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Gallery from "../common/Gallery";


export default class ProductVariantGalleryModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      variant: props.variant,
      loading: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      loading,
      variant,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <Gallery
            images={variant.images}
            cropImage={true}
            aspectRatio={1.52}
            onUpdated={images => {
              variant.images = images
              this.setState({variant})
            }}
            referrer="product-variant-gallery"
          />

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this.props.onUpdated(variant.images)}
          >
            Save Changes
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
