import React, { Component } from 'react';

import General from '../../../utils/General'

import {Line, Bar} from 'react-chartjs-2';

export default class Chart extends Component  {

  constructor(props){
    super(props);
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    let labels = props.labels || props.data.map(point => "")
    return {
      data: this._getData(props.data, labels, props.color),
      minimal: props.minimal,
    }
  }

  _getData(data, labels, color){
    let border = 3

    return  {
      labels,
      datasets: [{
          label: "",
          lineTension: 0.5,
          borderColor: color,
          borderWidth: border,
          backgroundColor: this.props.backgroundColor,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 12,
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: color,
          fill: this.props.backgroundColor != null,
          data: data,
      }]
    }
  }

  _renderBar(data, options, props){
    return (
      <Bar
        { ...props }
        data={data}
        options={options}
      />
    )
  }

  _renderLine(data, options, props){
    return (
      <Line
        { ...props }
        data={data}
        options={options}
      />
    )
  }

  render(){
    let {
      data,
      minimal
    } = this.state

    let options = { ...this.props.options }

    options.scales.xAxes[0] = {
      ...options.scales.xAxes[0],
      display: !minimal,
      gridLines: !minimal,
    }

    options.scales.yAxes[0] = {
      ...options.scales.yAxes[0],
      display: !minimal,
      gridLines: !minimal,

    }

    let props = {}

    if(minimal){
      props.width = 65
      props.height = 30
      //options.maintainAspectRatio = false
    }

    if(this.props.type === "line"){
      return this._renderLine(data, options, props)
    }
    else if(this.props.type === "bar"){
      return this._renderBar(data, options, props)
    }
    return null
  }
}

Chart.defaultProps = {
  type: "line",
  minimal: true,
  options: {
    title: {
      display: false,
    },
    tooltips: {
      enabled: false,
      intersect: false,
      mode: 'nearest',
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: false
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    hover: {
      mode: 'index'
    },
    scales: {
      xAxes: [{
        display: false,
        gridLines: false,
        scaleLabel: {
          display: true,
          labelString: ''
        },
        ticks: {
          padding: 10
        }
      }],
      yAxes: [{
        display: false,
        gridLines: false,
        scaleLabel: {
          display: true,
          labelString: ''
        },
        ticks: {
          beginAtZero: true,
          suggestedMax: 5,
          callback: function(value) {if (value % 1 === 0) {return value;}},
          padding: 10
        }
      }]
    },

    elements: {
      point: {
        radius: 4,
        borderWidth: 12
      },
    },

    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 5,
        bottom: 0
      }
    }
  }
}

const styles = {
  container: {
    width: 100,
    height: 50
  },
  canvas: {
    display: 'block',
    width: 100,
    height: 50
  }
}
