import React from 'react'

import Modal from 'react-bootstrap/Modal'

import LazyLoadingList from "../common/LazyLoadingList"

import General from '../../../utils/General'
import FetchHelper from '../../../utils/FetchHelper'
import Notify from '../../../utils/Notify'

const SIMILAR_IMAGES_NO = 8

export default class ImagePickerModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      images: [],
      searchTerm: '',
      endpoint: window.Api.ImagesSearch,
      showLoadMore: false
    }
  }

  componentWillReceiveProps(nextProps){
    let refresh = nextProps.show !== this.state.show
    this.setState(nextProps, () => {
      if(refresh){
        this._loadImages()
      }
    })
  }

  _search = General.debounce(searchTerm => {
    console.log("3333", searchTerm)
    this.setState({
      searchTerm,
      images: [],
      endpoint: `${window.Api.ImagesSearch}?search_term=${searchTerm}` ,
      showLoadMore: false,
    }, () => this._loadImages())
  }, 500, false)

  _loadImages(){
    let {
      images,
      endpoint
    } = this.state

    this.setState({ loading: true })
    FetchHelper.get(endpoint, false)
    .then(response => {
      endpoint = response.next || endpoint

      let showLoadMore = response.next != null
      images = [
        ...images,
        ...response.results
      ]
      this.setState({
        images,
        endpoint,
        showLoadMore,
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _renderLoadMore(){
    let {
      loading,
      showLoadMore,
    } = this.state

    if(loading){
      return <p className="w-100 text-center">Loading...</p>
    }

    if(!showLoadMore){
      return null
    }

    return (
      <div className="col-12">
        <a
          className="btn btn--secondary w-100"
          style={{
            color: "#222",
            borderColor: "#222",
            backgroundColor: "transparent"
          }}
          onClick={() => this._loadImages()}
          disabled={!showLoadMore}
        >
            Load More
        </a>
      </div>
    )
  }

  render() {
    let {
      show,
      images,
      loading,
      searchTerm,
      showLoadMore,
    } = this.state

    let attribution = "?utm_source=XYZ&utm_medium=referral"
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-70"
      >
        <Modal.Header closeButton>
          <Modal.Title>{window.General.Branding.Name} Image Library</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group">
            <input
              type="text"
              name="search"
              placeholder="Search"
              className="form-control form-control-lg mb-3"
              defaultValue={searchTerm}
              onChange={e => {
                let searchTerm = e.target.value
                this._search(searchTerm)
              }}
            />
          </div>

          <div className="row" style={{maxHeight: 400, overflowY: 'scroll'}}>
            { images.map((image, index) => (
                <div className="col-md-3 mb-3">
                  <img
                    src={image.banner}
                    className="image-search-thumbnail"
                    onClick={() => {
                      let similarImages = []
                      if(index + SIMILAR_IMAGES_NO <= images.length - 1){
                        for(var i = 1; i <= SIMILAR_IMAGES_NO; i++){
                          similarImages.push(
                            images[index + i]
                          )
                        }
                      }else if(index - SIMILAR_IMAGES_NO >= 0){
                        for(var i = 1; i <= SIMILAR_IMAGES_NO; i++){
                          similarImages.push(
                            images[index - i]
                          )
                        }
                      }
                      if(this.props.onImagesSelected){
                        this.props.onImagesSelected(image, similarImages)
                      }
                      else{
                        this.props.onSelected(image)
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <span>
                    <span className="image-search-thumbnail-text">{ "by " }</span>
                    <a
                      href={image.photographer.url}
                      target="_blank"
                      className="image-search-thumbnail-text"
                    >
                     {image.photographer.full_name}
                    </a>
                    <span className="image-search-thumbnail-text">{ " on " }</span>
                    <a
                      href={`//unsplash.com${attribution}`}
                      target="_blank"
                      className="image-search-thumbnail-text"
                    >
                    Unsplash
                    </a>
                  </span>
                </div>
              )
            )}
            { this._renderLoadMore() }
          </div>

        </Modal.Body>

        <Modal.Footer>

          <div className="">
            <a
              className="btn btn--primary w-100 mt-4 ml-0"
              style={{
                color: "white",
                borderColor: "#222",
                backgroundColor: "#222"
              }}
              onClick={() => this.props.onHide()}
            >
                Cancel
            </a>

          </div>

        </Modal.Footer>
      </Modal>
    )
  }
}
