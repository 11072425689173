import React from 'react'

export default class CouponActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      coupon: props.coupon
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let { coupon } = this.state
    return (
      <>
        <a
          href="javascript:;"
          class="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
          title="Edit"
          onClick={() => this.props.onEditPressed(coupon) }
        >
          <i class="flaticon2-pen text-primary icon-sm"></i>
        </a>
      </>
    )
  }
}
