import React from "react"

import AsyncSelect from '../common/AsyncSelect'

export default class Select extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      product: props.product,
      products: props.products || [],
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState({
      products: nextProps.products || [],
    })
  }

  _getOptions(products){
    return products.map(product => ({
      value: product.id,
      label: product.name,
      data: product
    }))
  }


  render(){
    let {
      product,
      products
    } = this.state

    let endpoint = window.Api.Products
    if(product && product.id){
      endpoint += `?exclude_product_id=${product.id}`
    }
    return (
      <AsyncSelect
        isMulti
        endpoint={endpoint}
        value={ this._getOptions(products) }
        onSelected={productsData => {
          products = []
          if(productsData){
            products = productsData.map(productData => productData.data)
          }
          this.props.onUpdated(products)
        }}
        getOptions={products => this._getOptions(products)}
        noOptionsMessage="You Have No Products"
      />
    )
  }
}
