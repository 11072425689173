import React, { Component } from "react";

import moment from "moment"

import Chart from './components/chart/Chart'

import Conversation from "./components/conversation/Conversation";

import Backend from "../utils/Backend";
import AuthManager from '../utils/AuthManager'
import Currency from '../utils/Currency'

const SHOW_STATS = false
export class AdminChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: null,
      website: AuthManager.currentWebsite,
      company: AuthManager.currentUser.company,
    };
  }

  componentDidMount() {
    this._setStats();
    this._setAdminConversation();
  }

  _setStats(){
    let {
      website,
      company
    } = this.state

    Backend.getStats()
    .then(stats => {

      this.setState({
        stats,
        loading:false,
        pageViewsNo: stats.page_views.count + 1,
        grossSales: Currency.format(company.gross_sales, ""),
        salesNo: stats.sales.count,
        leadsNo: website.leads_no,
        pageViews:
        {
          subtitle: "Page Views",
          color: "#34bfa3",
          type: "success",
          data: stats.page_views.data.map((stat, index) => {
            if(moment(stat.date).isSame(company.created_at, 'date')){
              stat.count += 1
            }
            return stat.count
          }),
          labels: stats.page_views.data.map(stat => moment(stat.date).format("DD MMM"))
        },
      })
    })
  }



  _setAdminConversation() {
    this.setState({ loading: true });

    Backend.getAdminConversations()
      .then((adminConversations) => {
        this.setState({
          selectedAdminConversation: adminConversations[0],
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  render() {
    let { selectedAdminConversation, pageViews, stats, pageViewsNo, grossSales, leadsNo, salesNo } = this.state;

    return (
      <>
        {/* Begin::App */}
        <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app c-chats">
          {/* Begin:: App Aside */}
          { SHOW_STATS &&
          <div
            className="kt-grid__item kt-app__toggle kt-app__aside kt-app__aside--lg kt-app__aside--fit"
            id="kt_chat_aside"
          >
            {/* begin::Portlet */}
            <div className="col">
             {/* TODO: STATS CARDS */}

              <div className="kt-portlet__body metr_v702">
                <div className="kt-widget kt-widget--users">
                  <div className="kt-scroll kt-scroll--pull">

                  { stats &&
                    <div className="card card-custom gutter-b card-stretch stats-break-down">
                      <div className="card-header border-0 pt-4">
                        <div className="card-title font-weight-bolder">
                          <div className="card-label">
                            Visitors & Traffic Breakdown
                            <div className="font-size-sm text-muted mt-2">
                              { pageViewsNo } Pageviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0 d-flex flex-column" style={{position: 'relative'}}>
                        <div className="flex-grow-1 card-spacer">
                          <div className="row mt-5 mb-10 flex-wrap stats">

                            <div className="col-auto">
                              <div className="d-flex align-items-center mr-2">
                                <div className="symbol symbol-45 symbol-light-brand mr-4 flex-shrink-0">
                                  <div className="symbol-label">
                                    <span className="svg-icon svg-icon-info svg-icon-2x">
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                           xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                           viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                          <rect x="0" y="0" width="24" height="24"></rect>
                                          <rect fill="#000000" opacity="0.3" x="2" y="5" width="20" height="14"
                                                rx="2"></rect>
                                          <rect fill="#000000" x="2" y="8" width="20" height="3"></rect>
                                          <rect fill="#000000" opacity="0.3" x="16" y="14" width="4" height="2"
                                                rx="1"></rect>
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <div className="font-size-h4 text-dark-75 font-weight-bolder">{salesNo}</div>
                                  <div className="font-size-sm text-muted font-weight-bold mt-1">Payments</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="d-flex align-items-center mr-2">
                                <div className="symbol symbol-45 symbol-light-brand mr-4 flex-shrink-0">
                                  <div className="symbol-label">
                                    <span className="svg-icon svg-icon-danger svg-icon-2x">
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                           xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                           viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                          <rect x="0" y="0" width="24" height="24"></rect>
                                          <path
                                            d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                                            fill="#000000"></path>
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <div className="font-size-h4 text-dark-75 font-weight-bolder">{leadsNo}</div>
                                  <div className="font-size-sm text-muted font-weight-bold mt-1">Leads</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="d-flex align-items-center mr-2">
                                <div className="symbol symbol-45 symbol-light-brand mr-4 flex-shrink-0">
                                  <div className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-success">
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                           xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                           viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                          <rect x="0" y="0" width="24" height="24"></rect>
                                          <path
                                            d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z"
                                            fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                                          <path
                                            d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z"
                                            fill="#000000"></path>
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <div className="font-size-h4 text-dark-75 font-weight-bolder">{grossSales}</div>
                                  <div className="font-size-sm text-muted font-weight-bold mt-1">Gross Sales</div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>

                        <Chart
                          type="line"
                          data={pageViews ? pageViews.data : []}
                          labels={pageViews ? pageViews.labels : []}
                          color="#333"
                          backgroundColor="#E5E5E5"
                        />
                      </div>
                    </div>
                  }
                  </div>
                </div>
              </div>
            </div>
            {/* end::Portlet */}
          </div>
          }
          {/* End:: App Aside */}

          {/* Begin:: App Content */}

          <div
            className="kt-grid__item kt-grid__item--fluid kt-app__content mx-0"
            id="kt_chat_content"
          >
            <div className="kt-chat">
              <div className="kt-portlet kt-portlet--head-lg kt-portlet--last">
                <Conversation conversation={selectedAdminConversation} />
              </div>
            </div>
          </div>

          {/* End:: App Content */}
        </div>
        {/* End::App */}
      </>
    );
  }
}

export default AdminChat;
