import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import Alert from './Alert'

import Time from '../../../utils/Time'
import AuthManager from '../../../utils/AuthManager'

export default class SubscriptionAlert extends React.Component {

  render(){
    if(window.location.href.indexOf("subscribed") === -1){
      return null
    }

    let text = "Your subscription has been successfully updated."

    return (
      <Alert
        text={text}
      />
    )
  }
}
