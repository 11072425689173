import React from 'react'

import AuthManager from '../utils/AuthManager'
import Email from '../utils/Email'
import Notify from '../utils/Notify'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Website from '../utils/Website'

import ColorPicker from './components/ColorPicker'

import Div from './components/layouts/Div'

import ImagePickerModal from './components/modal/ImagePickerModal'

const LOGIN_VISUAL = require('../assets/media/svg/illustrations/login-visual-1.svg')
const LOGIN_VISUAL_2 = require('../assets/media/svg/illustrations/features.svg')

const MODE_SIGNUP = "signup"
const MODE_SIGNIN = "signin"
const MODE_FORGOT_PASSWORD = "forgot_password"
const MODE_RESET_PASSWORD = "reset_password"

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get('s') || "organic"
    const premium = params.get("p") != null
    const u = params.get("u") || "s"

    this.state = {
      premium,
      source,
      color: "#3d85c6",
      email: '',
      password: '',
      isLoading: false,
      signUpStep: 1,
      mode: u === "c" ? MODE_SIGNUP : MODE_SIGNIN
    }
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state

    if (!Email.isValid(email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }

    if(mode == MODE_RESET_PASSWORD){
      if(!code){
        this.setState({ error: 'Please enter a valid code' })
        return false
      }
      if(password.length < 6){
        this.setState({ error: 'Password must be at least 6 characters' })
        return false
      }
      if(password != confirmPassword){
        this.setState({ error: 'Passwords do not match' })
        return false
      }
    }

    return true
  }

  _handleLogInClicked(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password } = this.state

    this.setState({ isLoading: true })
    AuthManager.login(email, password, false)
    .then(() => {
      if(AuthManager.currentWebsite){
          window.location = '/'
          return
      }

      this.setState({
        mode: MODE_SIGNUP,
        signUpStep: 2,
        company_name: AuthManager.currentUser.company.name
        }, () => {
        this._setSlug()
        this.setState({
          isLoading: false,
        })
      })
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _handleRequestResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, confirmPassword } = this.state

    this.setState({ isLoading: true })
    AuthManager.requestResetPassword(email)
    .then(() => {
      this.setState({
        isLoading: false,
        mode: MODE_RESET_PASSWORD,
        password: "",
        confirmPassword: "",
      })
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _handleResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, code } = this.state

    this.setState({ isLoading: true })
    AuthManager.resetPassword(email, password, code)
    .then(() => {
      this.setState({
        isLoading: false,
        mode: MODE_SIGNIN,
        email: "",
        password: "",
        confirmPassword: "",
      })
      Notify.success("Your password has been reset")
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })

  }

  _isSignUpFormValid(){
    let {
      first_name,
      last_name,
      email,
      phone_number,
      agree,
      password,
      company_name,
      image,
      slug,
      errorSlug,
      color
    } = this.state

    let error = null


    if(company_name == null || company_name.length == 0){
      error = "Please enter a valid company name"
    }
    else if(errorSlug){
      error = "This website url is not available, please try a different one"
    }
    else if(!slug || slug == ""){
      error = "Please enter a valid website url"
    }
    else if(!image){
      error = "Please select an image"
    }
    else if(!color){
      error = "Please select a website theme colour"
    }
    else if(first_name == null || first_name.length == 0){
      error = "Please enter a valid first name"
    }
    else if(last_name == null || last_name.length == 0){
      error = "Please enter a valid last name"
    }
    else if(email == null || email.length == 0){
      error = "Please enter a valid email"
    }
    else if(phone_number == null || phone_number.length < 6){
      error = "Please enter a valid phone number"
    }
    else if(password == null || password.length == 0){
      error = "Please enter a valid password"
    }
    else if(agree == null || !agree){
      error = "You must agree to the terms and conditions"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _isCreateWebsiteFormValid(){
    let {
      image,
      slug,
      errorSlug,
      color
    } = this.state

    let error = null
    if(errorSlug){
      error = "This website url is not available, please try a different one"
    }
    else if(!slug || slug == ""){
      error = "Please enter a valid website url"
    }
    else if(!image){
      error = "Please select an image"
    }
    else if(!color){
      error = "Please select a website theme colour"
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _createWebsite(){

    if(!this._isCreateWebsiteFormValid()){
      return
    }

    let {
      company_name,
      slug,
      image,
      color
    } = this.state

    let data = {
      slug,
      name: company_name,
      primary_color: color,
      banner_image_id: image.id,
      template_id: 1,
      status: "live"
    }

    let website = null
    this.setState({ isLoading: true })
    Backend.createWebsite(data)
    .then(response => {
      website = response
      return Backend.createLoginHash()
    })
    .then(hash => {
      console.log("ffff", hash)
      let url = Website.getNativeDomain(website) + "?edit=true&h="+hash.value
      window.location = url
    })
    .catch(error => {
      if(website){
        window.location = "/"
        return
      }
      this.setState({ isLoading: false })
      Notify.error(error.meessage)
    })
  }

  _signUpPressed(e){
    e.preventDefault()

    if(!this._isSignUpFormValid()){
      return
    }

    let {
      first_name,
      last_name,
      company_name,
      email,
      phone_number,
      password,
      premium,
      source,
      slug,
      image,
      color
    } = this.state

    this.setState({ isLoading: true })

    let data = {
      first_name,
      last_name,
      company_name,
      email,
      phone_number,
      password,
      premium,
      source,
      website: {
        slug,
        name: company_name,
        primary_color: color,
        banner_image_id: image.id,
        template_id: 1,
        status: "live"
      }
    }

    let website = null
    AuthManager.register(data)
    .then(response => {
      website = response.company_member.company.websites[0]
      return Backend.createLoginHash()
    })
    .then(hash => {
      let url = Website.getNativeDomain(website) + "?edit=true&h="+hash.value
      window.location = url
    })
    .catch(error => {
      this.setState({
        isLoading: false,
      })
      Notify.error(error.message)
    })
  }

  _setSlug = General.debounce(() => {
    let {
      slug,
      company_name
    } = this.state

    if(slug){
      return
    }

    return Backend.slugify(company_name)
    .then(response => {
      let errorSlug = false
      if(slug && slug != company_name){
        errorSlug = true
      }
      if(!slug){
        slug = response.slug
      }
      this.setState({ errorSlug, slug, isLoading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoading: false })
    })
  }, 500)

  _checkSlug(){
    let {
      slug
    } = this.state

    this.setState({ isLoadingSlug: true })
    Backend.slugify(slug)
    .then(response => {
      if(response.slug != slug){
        this.setState({ errorSlug: true, isLoadingSlug: false })
      }
      else{
        this.setState({ errorSlug: false, isLoadingSlug: false })
      }
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoadingSlug: false })
    })
  }

  _renderError() {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    )
  }

  _renderSignUpStep1(){
    let {
      mode,
      signUpStep,
      slug,
      errorSlug,
      isLoading,
      isLoadingSlug,
      image,
      color,
      imageLoading
    } = this.state

    if(mode == MODE_SIGNUP && signUpStep !== 1){
      return
    }


    let className = "input-group-append-bg-dark "

    if(slug){
      className += errorSlug ? "input-group-append-bg-dark-error" : "input-group-append-bg-dark"
    }


    return (
      <form
        class="form fv-plugins-bootstrap fv-plugins-framework"
        novalidate="novalidate"
      >
        <Div
          disabled={isLoading}
          spinner={true}
          disableViewClassName="disable-view-absolute"
          loadingText="Creating Your Site..."
        >

        <div class="pb-13 pt-lg-0 pt- d-none d-md-block">
          <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Create Your Site</h3>
        </div>

          <div class="form-group fv-plugins-icon-container mb-5">
            <label className="mb-2"><span className='font-size-h6 font-weight-bolder text-dark'>01 - Company / Site Name</span></label>
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              placeholder="Company / Site Name"
              name="company_name"
              autocomplete="off"
              onChange={e => this.setState({ company_name: e.target.value })}
              onBlur={() => this._setSlug()}
              autoFocus
            />
            <div class="fv-plugins-message-container">
            </div>
          </div>

         {/*
          <div className="form-group fv-plugins-icon-container mb-5">
            <label className="mb-2"><span className='font-size-h6 font-weight-bolder text-dark'>02 - Your Free {window.General.Branding.Name} Site Link</span> (You Can Change Later)</label>
            <div className="input-group">
              <input
                type="text"
                name="slug"
                className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 login-slug-field text-right"
                value={ slug }
                placeholder="mywebsite"
                onChange={e => this.setState({ slug: e.target.value })}
                onBlur={e => {
                  this._checkSlug()
                }}
              />
              <div class={`input-group-append ${className}`}>
                <span class="input-group-text font-size-h6">.site.xyz</span>
              </div>
            </div>
          </div>
          */}

          <div class="form-group fv-plugins-icon-container mb-5">
            <label className="mb-2"><span className='font-size-h6 font-weight-bolder text-dark'>02 - Pick A Random Image</span> (You Can Change Later)</label>
             <div
              className="form-control form-control-solid text-center p-0 d-flex justify-content-center align-items-center cursor-pointer choose-image"
              style={{ height: 300, overflow: 'hidden' }}
              onClick={() => this.setState({ showImagePicker: true })}
             >
              { !image &&
                <>
                  <div className="choose-image-overlay"/>
                  <div className="bg-text">
                    <p className="my-auto">Select Random Image</p>
                  </div>
                </>
              }
              { (image || imageLoading) &&
                <div className="w-100 shine shine-overlay">
                  { image &&
                    <>
                    <div className="uploaded-image" style={{ backgroundImage: "url("+image.original+")" }} ></div>
                    </>
                  }
                </div>
              }
             </div>
          </div>

          <ColorPicker
            title={<><span className='font-size-h6 font-weight-bolder text-dark'>03 - Your Site Theme Colour </span> (You Can Change Later)</>}
            color={ color }
            onColorSelected={color => this.setState({ color })}
          />

          <div className="row mb-3">
            <div className="col-lg-6">
              <div class="form-group fv-plugins-icon-container">
                <label className="mb-2">First Name</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  autocomplete="off"
                  onChange={e => this.setState({ first_name: e.target.value })}
                />
                <div class="fv-plugins-message-container">
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="form-group fv-plugins-icon-container">
                <label className="mb-2">Last Name</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  autocomplete="off"
                  onChange={e => this.setState({ last_name: e.target.value })}
                />
                <div class="fv-plugins-message-container">
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div className="col-lg-6">
              <div class="form-group fv-plugins-icon-container">
                <label className="mb-2">Email</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <div class="fv-plugins-message-container">
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="form-group fv-plugins-icon-container">
                <label className="mb-2">Phone</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Phone Number"
                  name="phone_number"
                  autocomplete="off"
                  onChange={e => this.setState({ phone_number: e.target.value })}
                />
                <div class="fv-plugins-message-container">
                </div>
              </div>
            </div>
          </div>

          <div class="form-group fv-plugins-icon-container">
            <label className="mb-2">Password</label>
            <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="password"
              placeholder="Password"
              name="password"
              autocomplete="off"
              onChange={e => this.setState({ password: e.target.value })}
             />
             <div class="fv-plugins-message-container">
             </div>
          </div>

          <div class="form-group fv-plugins-icon-container">

            <label class="kt-checkbox c-checkbox mb-0">
              <input
                type="checkbox"
                name="agree"
                className="mr-1"
                onClick={e => this.setState({ agree: e.target.checked })}
              />I Agree to the
              <a
                href="javascript:void(0);"
                target="_blank"
                className="text-primary ml-1"
              >
                terms and conditions
              </a>
              <span></span>
            </label>

            <div class="fv-plugins-message-container">
            </div>

          </div>

          <div class="form-group d-flex flex-wrap pb-lg-0 pb-3 btn-m-block">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100"
              onClick={(e) => this._signUpPressed(e)}
              disabled={isLoading}
            >
              Create My Site
            </button>
          </div>

          <div class="c-separator form-group row my-8 align-items-center text-center">
            <div class="col-3">
              <hr/>
            </div>
            <div class="col-6">
              <span>
                <strong>
                  Already Have A Site?
                </strong>
              </span>
            </div>
            <div class="col-3">
              <hr/>
            </div>
          </div>

          <div className="pb-lg-0 pb-5">
            <button
              className="btn btn-outline-dark w-100 font-weight-bolder font-size-h6 px-8 py-4 my-3"
              onClick={e => {
                e.preventDefault()
                this.setState({mode: MODE_SIGNIN, signUpStep: 1})
                window.scrollTo(0, 0)
              }}
            >
              Sign In
            </button>

          </div>
        </Div>
      </form>
    )
  }

  _renderSignUpStep2(){
    let {
      mode,
      signUpStep,
      slug,
      errorSlug,
      isLoading,
      isLoadingSlug,
      image,
      color,
      imageLoading
    } = this.state

    if(mode == MODE_SIGNUP && signUpStep != 2){
      return
    }

    let className = "input-group-append-bg-dark "

    if(slug){
      className += errorSlug ? "input-group-append-bg-dark-error" : "input-group-append-bg-dark"
    }

    return (
      <form class="form fv-plugins-bootstrap fv-plugins-framework" novalidate="novalidate" id="kt_login_signup_form">
      <Div
        disabled={isLoading}
        spinner={true}
        disableViewClassName="disable-view-absolute"
        loadingText="Creating Your Site..."
      >

        <div class="pb-5 pt-lg-0 pt-5">
          <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Final Step</h3>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label className="mb-4">Your Free {window.General.Branding.Name} Site Link (You Can Choose Your Link Later)</label>
          <div className="input-group">
            <input
              type="text"
              name="slug"
              className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 login-slug-field text-right"
              value={ slug }
              placeholder="mywebsite"
              onChange={e => this.setState({ slug: e.target.value })}
              onBlur={e => {
                this._checkSlug()
              }}
            />
            <div class={`input-group-append ${className}`}>
              <span class="input-group-text font-size-h6">.site.xyz</span>
            </div>
          </div>
        </div>

        <div class="form-group fv-plugins-icon-container">
          <label className="mb-4">Pick A Random Image (You Can Change Later)</label>
           <div
            className="form-control form-control-solid text-center p-0 d-flex justify-content-center align-items-center cursor-pointer choose-image"
            style={{ height: 300, overflow: 'hidden' }}
            onClick={() => this.setState({ showImagePicker: true })}
           >
            { !image &&
              <>
                <div className="choose-image-overlay"/>
                <div className="bg-text">
                  <p className="my-auto">Select Random Image</p>
                </div>
              </>
            }
            { (image || imageLoading) &&
              <div className="w-100 shine shine-overlay">
                { image &&
                  <>
                  <div className="uploaded-image" style={{ backgroundImage: "url("+image.original+")" }} ></div>
                  </>
                }
              </div>
            }
           </div>
        </div>
        <ColorPicker
          title={ "Your Site Theme Colour (You Can Change Later)" }
          color={ color }
          onColorSelected={color => this.setState({ color })}
        />

        <div class="form-group d-flex flex-wrap pb-lg-0 pb-3 btn-m-block">
          <button
            type="button"
            id="kt_login_signup_submit"
            class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100"
            onClick={e => this._createWebsite()}
            disabled={isLoading || isLoadingSlug}
          >
            Create My Site
          </button>
        </div>
        <div>
        </div>
        </Div>
      </form>
    )
  }

  render() {
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      signUpStep,
      code,
      showImagePicker
    } = this.state

    let loginContentClassName = "login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden w-100"

    if(isLoading){
      loginContentClassName += " login-content-loading"
    }

    let loginAsideClassName = "login-aside d-flex flex-column flex-row-auto pt-lg-40 pt-10";

    return (
      <>
        <div className="metr_v702 d-flex flex-column flex-root">
          <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white v1 v2" id="kt_login">

            {/* begin : Login Aside */}
            <div className={loginAsideClassName}>
              <div className="d-flex flex-column-auto flex-column pl-25 pr-25 text-center">
                <a href={`//${window.General.Branding.Domain}`} className="mb-10">
                <img src={window.General.Branding.Logo} alt="Logo" className="max-h-100px" />
                </a>
                <h3 className="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10" style={{ color: '#986923' }}>Make Any Type Of Site In 3 Minutes</h3>
              </div>
              <div className="d-none aside-img d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" style={{ backgroundImage:`url(${signUpStep == 1 ? LOGIN_VISUAL : LOGIN_VISUAL_2})` }}></div>
            </div>
            {/* end : Login Aside */}

            {/* <!--begin::Content--> */}
            <div className={loginContentClassName}>


              <div className="d-flex flex-column-fluid flex-center">

                {/* <!--begin::Signin--> */}
                <div className="login-form login-signin"
                  style={{
                    display: mode == MODE_SIGNIN ? "block" : "none"
                  }}
                >
                  <form className="form" novalidate="novalidate">

                    <div className="pb-13 pt-lg-0 pt-5">
                      <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Welcome To {window.General.Branding.Name}</h3>
                    </div>

                    { this._renderError() }

                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="text"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value, error: null })}
                        autoFocus
                        tabIndex="1"
                      />
                    </div>

                    <div className="form-group">
                      <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                        <a href="javascript:;" tabIndex="5" className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                          onClick={() => this.setState({ email: "", mode: MODE_FORGOT_PASSWORD, error: null})}
                        >Forgot Password ?</a>
                      </div>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={e => this.setState({ password: e.target.value, error: null })}
                        tabIndex="2"
                      />
                    </div>

                    <div className="pb-lg-0 pb-5">
                      <button
                        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100"
                        onClick={e => this._handleLogInClicked(e)}
                        disabled={isLoading}
                        tabIndex="3"
                      >
                        { isLoading  ? "Loading... " : "Sign In" }
                      </button>

                    </div>

                    <div class="c-separator form-group row my-8 align-items-center text-center">
                      <div class="col-3">
                        <hr/>
                      </div>
                      <div class="col-6">
                        <span>
                          <strong>
                            Don't Have An Account?
                          </strong>
                        </span>
                      </div>
                      <div class="col-3">
                        <hr/>
                      </div>
                    </div>

                    <div className="pb-lg-0 pb-5">
                      <button
                        className="btn btn-outline-dark w-100 font-weight-bolder font-size-h6 px-8 py-4 my-3"
                        href="/create"
                        onClick={e => {
                          e.preventDefault()
                          window.location.href = "/create"
                        }}
                        tabIndex="4"
                      >
                        Create A Site
                      </button>

                    </div>

                  </form>
                </div>
                {/* <!--end::Signin-->

                {/* <!--begin::Signup--> */}
                <div class="login-form login-signup"
                  style={{
                    display: mode == MODE_SIGNUP ? "block" : "none"
                  }}
                >
                  { this._renderSignUpStep1() }
                  { this._renderSignUpStep2() }
    						</div>
                {/* <!--end::Signup-->

                <!--begin::Forgot--> */}
                <div className="login-form login-forgot"
                  style={{
                    display: mode == MODE_FORGOT_PASSWORD ? "block" : "none"
                  }}
                >
                  <form className="form" novalidate="novalidate" id="kt_login_forgot_form">

                    <div className="pb-13 pt-lg-0 pt-5">
                      <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Forgotten Password ?</h3>
                      <p className="text-muted font-weight-bold font-size-h4">Enter your email to reset your password</p>
                    </div>

                    { this._renderError() }

                    <div className="form-group">

                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                        type="email"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value, error: null })}
                      />
                    </div>

                    <div className="form-group d-flex flex-wrap pb-lg-0 pull-right">

                      <button
                        className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({ email: "", password: "", mode: MODE_SIGNIN, error: null })
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                        onClick={e => this._handleRequestResetPassword(e)}
                        disabled={isLoading}
                      >
                        { isLoading  ? "Loading... " : "Request" }
                      </button>

                    </div>

                  </form>
                </div>
                {/* <!--end::Forgot--> */}

                {/* <!--begin::reset--> */}
                <div className="login-form login-reset"
                  style={{
                    display: mode == MODE_RESET_PASSWORD ? "block" : "none"
                  }}
                >
                  <form className="form" novalidate="novalidate">

                    <div className="pb-13 pt-lg-0 pt-5">
                      <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Reset Password</h3>
                      <p className="text-muted font-weight-bold font-size-h4">A verification code was sent to your email, please enter it below along with your new password</p>
                    </div>

                    { this._renderError() }

                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Code</label>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="text"
                        placeholder="Code"
                        name="code"
                        autoComplete="off"
                        value={code}
                        onChange={e => this.setState({ code: e.target.value, error: null })}
                      />
                    </div>

                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Password</label>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="password"
                        placeholder="Password"
                        name="password"
                        autoComplete="off"
                        value={password}
                        onChange={e => this.setState({ password: e.target.value, error: null })}
                      />
                    </div>

                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Confirm Password</label>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        autoComplete="off"
                        value={confirmPassword}
                        onChange={e => this.setState({ confirmPassword: e.target.value, error: null })}
                      />
                    </div>

                    <div className="form-group d-flex flex-wrap pb-lg-0">

                      <button
                        className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({ email: "", password: "", mode: MODE_SIGNIN, error: null })
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                        onClick={e => this._handleResetPassword(e)}
                        disabled={isLoading}
                      >
                        { isLoading  ? "Loading... " : "Update Password" }
                      </button>

                    </div>

                  </form>
                </div>
                {/* <!--end::reset--> */}

              </div>
              {/* <!--end::Content body--> */}

            </div>
            {/* end::Content */}

          </div>
          <ImagePickerModal
            show={showImagePicker}
            onSelected={image =>  {
              this.setState({ imageLoading: true, showImagePicker: false })
              Backend.addImage(image, false)
              .then(image => {
                this.setState({ image, imageLoading: false })
              })
              .catch(error => {
                Notify.error(error.message)
                this.setState({ imageLoading: false })
              })
            }}
            onHide={() => this.setState({ showImagePicker: false })}
          />
        </div>

        <style dangerouslySetInnerHTML={{ __html: `
                :root {
                  --pkg_primary_color: #000000;
                  --pkg_title_color: #000000;
                  --pkg_cta_background_color: #333333;
                  --pkg_cta_text_color: #ffffff;
                  --login_aside_bg_color: #f2c98a;
                }
          `}}
        />

      </>
    )
  }
}
