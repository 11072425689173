import React, { Component } from "react";

import moment from "moment";
import "moment-duration-format";

import Website from "../../utils/Website";
import AuthManager from "../../utils/AuthManager";

export class PageViewListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitor: props.visitor,
      pageView: props.pageView,
    };
  }

  _getPageTitle(pageView) {
    let pageUrl = `${Website.getDomain(AuthManager.currentWebsite)}/${
      pageView.page?.slug
    }`;

    let articleUrl = `${Website.getDomain(
      AuthManager.currentWebsite
    )}/news/article/${pageView.article?.slug}`;

    let shopUrl = `${Website.getDomain(AuthManager.currentWebsite)}/shop`;

    let blogUrl = `${Website.getDomain(AuthManager.currentWebsite)}/news`;

    let productUrl = `${Website.getDomain(
      AuthManager.currentWebsite
    )}/shop/product/${pageView.product?.slug}`;

    if (!pageView) {
      return null;
    }

    if (pageView.page) {
      return (
        <a href="javascript:;" onClick={() => window.open(pageUrl)}>
          {pageView.page.title}
        </a>
      );
    } else if (pageView.product) {
      return (
        <a href="javascript:;" onClick={() => window.open(productUrl)}>
          {pageView.product.name}
        </a>
      );
    } else if (pageView.article) {
      return (
        <a href="javascript:;" onClick={() => window.open(articleUrl)}>
          {pageView.article.title}
        </a>
      );
    } else if (pageView.shop) {
      return (
        <a href="javascript:;" onClick={() => window.open(shopUrl)}>
          Shop
        </a>
      );
    } else if (pageView.blog) {
      return (
        <a href="javascript:;" onClick={() => window.open(blogUrl)}>
          Blog
        </a>
      );
    }
    return "Site";
  }

  _getFormattedPageTitle(pageView) {
    if (!pageView) {
      return null;
    }

    if (pageView.page) {
      return !pageView.page.title.includes("Homepage") && "page";
    } else if (pageView.product) {
      return "Product";
    } else if (pageView.article) {
      return !pageView.article.title.includes("Article") && "blog article";
    }
    return "page";
  }

  _getBadgeColor(pageView) {
    const { visitor } = this.state;

    if (!pageView) {
      return null;
    }

    let isSamePageId = visitor.last_page_view?.id == pageView?.id;

    let isVisitorStatusOnline = visitor.status == "online";

    if (isSamePageId && isVisitorStatusOnline) {
      return "text-success";
    }
    return "text-dark";
  }

  _getPageViewTime(pageView) {
    if (!pageView) {
      return null;
    }

    if (pageView) {
      return pageView?.created_at;
    }
  }

  _getDuration(pageView) {
    if (!pageView) {
      return null;
    }
    return pageView.duration;
  }

  _renderVisitorAnalytics(pageView) {
    let duration = this._getDuration(pageView);


    let formattedDuration = null

    if (duration && duration > 0) {
      formattedDuration = moment.duration(duration, "seconds")
                                .format("h [hours] m [min] s [sec]");
    }

    return (
      <>
        {/*begin::Item*/}
        <div className="timeline-item align-items-start text-size-lg">
          {/*begin::Label*/}
          <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
            {moment(this._getPageViewTime(pageView)).format("HH:mm")}
          </div>
          {/*end::Label*/}
          {/*begin::Badge*/}
          <div className="timeline-badge">
            <i
              className={`fa fa-genderless ${this._getBadgeColor(
                pageView
              )} icon-xl`}
            />
          </div>
          {/*end::Badge*/}
          {/*begin::Text*/}
          <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
            Viewed
            <span className="ml-2 font-weight-boldest font-size-h1-lg text-darker">
              {this._getPageTitle(pageView)}
            </span>
            <span className="ml-1">
              {this._getFormattedPageTitle(pageView)}
            </span>
            { formattedDuration &&
              <>
                <span className="ml-1">for</span>
                <span className="ml-2 font-weight-bolder text-darker">
                  {formattedDuration}
                </span>
              </>
            }
          </div>
          {/*end::Text*/}
        </div>
        {/*end::Item*/}
      </>
    );
  }
  render() {
    const { pageView } = this.state;

    return this._renderVisitorAnalytics(pageView);
  }
}

export default PageViewListItem;
