import React from "react"

export default class Infos extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      infos: props.infos,
    }
  }

  _renderInfos(){
    let {
      infos
    } = this.state

    return infos.map((info, index) => {
      let tabNo = index + 1
      return (
        <>
          { index > 0 &&
            <div class="separator separator-solid separator-border-2 separator-custom mb-4"></div>
          }
          <div className="form-group row">
            <h5 className="col-lg-3 col-form-label">{`Info Tab ${tabNo}`}</h5>
            <div className="col-lg-9 my-auto text-right">
              <a
                className="btn btn-sm btn-label-danger ml-auto"
                onClick={() => {
                  infos.splice(index, 1)
                  this.setState({ infos })
                  this.props.onUpdated(infos)
                }}
              >
                Delete
              </a>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">{`Tab ${tabNo} Title`}</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="title"
                className="form-control form-control-solid"
                value={ info.title }
                onChange={e => {
                  let title = e.target.value
                  info.title = title
                  infos[index] = info
                  this.setState({ infos })
                  this.props.onUpdated(infos)
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">{`Tab ${tabNo} Text`}</label>
            <div className="col-lg-9 my-auto">
              <textarea
                type="text"
                name="text"
                className="form-control form-control-solid"
                value={ info.text }
                rows={6}
                onChange={e => {
                  let text = e.target.value
                  info.text = text
                  infos[index] = info
                  this.setState({ infos })
                  this.props.onUpdated(infos)
                }}
              />
            </div>
          </div>
        </>
      )
    })
  }

  render(){
    let {
      infos
    } = this.state

    let addText = infos.length > 0 ? "+ Add Another Info Tab" : "+ Add Info Tab"
    return (
      <>
        { this._renderInfos() }
        <div className="form-group">
          <a
            className="btn btn-bold btn-sm btn-label-brand"
            onClick={() => {
              infos.push({
                title: "",
                text: ""
              })
              this.setState({ infos })
              this.props.onUpdated(infos)
            }}
          >
            { addText }
          </a>
        </div>
      </>
    )
  }
}
