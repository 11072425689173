import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from "../layouts/Div"

import ExplainerAlert from "../alert/ExplainerAlert"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"
import Currency from "../../../utils/Currency"
import General from "../../../utils/General"

import Select from "../common/Select"
import CardEntryForm from "../common/CardEntryForm"

export default class SubscriptionAlertModal extends React.Component {
  constructor(props){
    super(props)
    const user = AuthManager.currentUser.user

    this.state = {
      loading: false,
      show: props.show,
      addOn: props.addOn,
      subscription: AuthManager.currentUser.company.subscription
    }
    this.cardEntryForm = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render() {
    let {
      show,
      addOn,
      subscription
    } = this.state

    if(!addOn){
      return null
    }

    let title = "This is a premium feature, "
    let buttonTitle = "Activate Subscription"
    if(!subscription.active && addOn && !addOn.enabled && addOn.toggleable){
      title += "please activate your subscription to get access"
      buttonTitle = "Activate Subscription"
    }
    else if(subscription.active && addOn && !addOn.enabled && addOn.toggleable){
      title += "please upgrade your subscription to get access"
      buttonTitle = "Upgrade Subscription"
    }
    else if(!addOn.enabled && addOn && !addOn.toggleable){
      title += "currently this feature is not available on your subscription. Please upgrade your package to get access"
      buttonTitle = "Upgrade Package"
    }
    else{
      title += "please update your subscription to get access"
      buttonTitle = "Update Subscription"
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
      >

        <Modal.Body>
            <Modal.Header>
              <Modal.Title>
                Manage Add-Ons
              </Modal.Title>
            </Modal.Header>
            <ExplainerAlert
              type="note"
              text="This is a premium feature, manage your add-ons to enable it."
            />
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            onClick={() => this.props.onConfirm()}
          >
            Manage Add-Ons
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

SubscriptionAlertModal.defaultProps = {
}
