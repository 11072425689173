import React from "react"

import { withRouter } from "react-router-dom";

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import ArticleActionsCell from "./cells/ArticleActionsCell"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Submission from '../../../utils/Submission'
import Currency from '../../../utils/Currency'
import Website from '../../../utils/Website'

import moment from 'moment'

class ArticlesTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      website: props.website,
      categories: [{
        label: "All",
        value: "all"
      }]
    }

    this.table = React.createRef()

  }

  componentDidMount(){
    this._setCategories()
  }

  _setCategories(){
    let {
      categories
    } = this.state

    Backend.getArticleCategories()
    .then(returnedCategories => {
      returnedCategories.forEach(category => {
        categories.push({
          label: category.name,
          value: category.id
        })
      })

      this.setState({
        categories
      })
    })
  }

  _getColumns() {
    return [
      {
        Header: 'Article Name',
        accessor: 'name',
        width: 300,
        Cell: rowInfo => {
          let article = rowInfo.original
          return (
            <div class="d-flex align-items-center">
            { article.thumbnail &&
              <div class="symbol symbol-50 flex-shrink-0">
                  <img class="article-thumbnail" src={article.thumbnail.thumbnail} alt="photo"/>
              </div>
            }
              <div class={article.thumbnail ? "ml-3" : ""}>
                  <span>{article.title}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Published At',
        id: "published_at",
        accessor: article => moment(article.published_at).format("DD/MM/YYYY"),
      },
      {
        Header: 'Categories',
        id: "categories",
        show: false,
        sortable: false,
        accessor: article => {
          return article.categories.map(category => category.name).join(", ")
        }
      },
      {
        Header: 'Featured',
        id: "featured",
        accessor: article => article.featured ? "YES" : "NO",
      },
      {
        Header: 'Views',
        accessor: 'views_no',
      },
      {
        Header: 'Date Added',
        id: 'created_at',
        accessor: article => moment(article.created_at).format('DD/MM/YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          return (
            <ArticleActionsCell
              article={rowInfo.original}
              onViewPressed={article => {
                let url = `${Website.getDomain(AuthManager.currentWebsite)}/news/article/${article.slug}`
                window.open(url)
              }}
              onEditPressed={article => this.props.history.push(`/articles/${article.slug}`)}
            />
          )
        }
      }
    ]
  }


  render() {
    let {
      website,
      article,
      categories,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Articles}
          params={{
            website_id: website.id
          }}
          title="Articles"
          columns={columns}
          filters={categories}
          searchFilterName="category_id"
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          exportButtonsEnabled={false}
          renderToolbar={() => {
            if(AuthManager.currentUser.user.role !== "company_member"){
              return null
            }
            return (
              <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">&nbsp;
                  <button
                    type="button"
                    class="btn btn-brand btn-icon-sm"
                    onClick={() => this.props.history.push(`/articles/add`)}
                  >
                    <i class="flaticon2-plus"></i>
                    Add Article
                  </button>
                </div>
              </div>
            )
          }}
        />
      </>
    )
  }
}

export default withRouter(ArticlesTable);
