import React from "react"

import Chips, { theme } from "react-chips"

import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'

export default class Tags extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      tags: props.tags || [],
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState({
      tags: nextProps.tags || [],
    })
  }


  render() {
    let {
      tags,
      theme
    } = this.state


    let tagNames = tags.map(tag => tag.name)
    return (
      <div className="form-group form-group-solid chip-container">
        <Chips
          value={tagNames}
          fetchSuggestions={value => Backend.getTags(value)}
          onChange={tagNames => {
            tags = tagNames.map(tagName => {
              return { name: tagName }
            })
            this.setState({ tags })
            this.props.onUpdated(tags)
          }}
          chipTheme={{
            chip: {
              padding: 5,
              background: "rgba(93, 120, 238, 0.1)",
              margin: "2.5px",
              borderRadius: 3,
              cursor: 'default',
            }
          }}
        />
        <span>{this.props.subtitle}</span>
      </div>
    )
  }
}

Tags.defaultProps = {
  subtitle: "comma separated list of tags"
}
