import React from 'react'

import { Portal } from 'react-portal'
import InvitesTable from './components/tables/InvitesTable'

import InviteGuestModal from "./components/modal/InviteGuestModal"
import ImportGuestsModal from "./components/modal/ImportGuestsModal"

import Backend from '../utils/Backend'

export default class Invites extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showInviteModal: false,
      showUploadInvitesModal: false
    }
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({ update: true })
    }, 100)
  }

  _export(){
    this.setState({ loading: true })
    Backend.exportInvites()
    .then(() => {
      this.setState({
        loading: false,
      })
    })
    .catch(error => {
      this.setState({
        loading: false,
        error: error.message
      })
    })
  }

  render() {
    let {
      loading,
      showInviteModal,
      showUploadInvitesModal
    } = this.state

    return (
      <>
        <InvitesTable/>
        <Portal node={document && document.getElementById('kt_header_menu_nav')}>
            <li className="kt-menu__item kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--here">
              <button
                className="btn btn-label-primary"
                disabled={loading}
                onClick={() => this.setState({ showInviteModal: true })}
              >
                Invite Guests
              </button>
            </li>
            <li className="kt-menu__item kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--here">
              <button
                className="btn btn-label-primary"
                disabled={loading}
                onClick={() => this.setState({ showUploadInvitesModal: true })}
              >
                Import Guests
              </button>
            </li>
            <li className="kt-menu__item kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--here">
              <button
                className="btn btn-label-primary"
                disabled={loading}
                onClick={() => this._export()}
              >
                  Export Guests
              </button>
            </li>
        </Portal>
        <InviteGuestModal
          show={showInviteModal}
          onHide={() => this.setState({ showInviteModal: false })}
          onInviteCreated={invite => {
            this.setState({ showInviteModal: false })
          }}
        />
        <ImportGuestsModal
          show={showUploadInvitesModal}
          onHide={() => this.setState({ showUploadInvitesModal: false })}
          onInvitesUploaded={() => {
            this.setState({ showUploadInvitesModal: false })
          }}
        />
      </>
    )
  }
}
