import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import CouponsTable from './components/tables/CouponsTable'

export default class Coupons extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      showAddCouponEngagementCard: true
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    this.setState({ loading: true })
    Backend.getShop(AuthManager.currentWebsite)
    .then(shop => {
      this.setState({
        shop,
        loading: false
      })
    })
    .catch(error => {
      //Notify.error(error.message)
      this.setState({ loading: false })
    })
  }


  render() {
    let {
      shop,
      loading,
    } = this.state

    if(loading){
      return null
    }

    return (
      <div className="row">
        <CouponsTable
          shop={shop}
        />
      </div>
    )
  }
}
