import React, { Component } from "react";

import LazyLoadingList from "../common/LazyLoadingList";

import LoadingCard from "../LoadingCard";

import VisitorListItem from "./VisitorListItem";

import General from "../../../utils/General";

export default class VistorsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: props.status,
      searchTerm: ""
    };

    this.lazyLoadingList = React.createRef();
  }

  refresh() {
    this.lazyLoadingList.current.refresh();
  }

  _updateSearchTerm = General.debounce(
    (searchTerm) => {
      this.setState({
        searchTerm,
      });
    },
    1000,
    false
  );

  _renderPlaceholderCards() {
    return (
      <>
        <LoadingCard />
        <LoadingCard />
      </>
    );
  }

  _renderEmptyState() {
    return (
      <div className="no-content visitors">
        <div className="no-content-section mt-6">
          <p className="text-primary">Ooops, no visitors found...</p>
          <p className="text-primary">This is where your visitors will be displayed</p>
        </div>
      </div>
    );
  }

  render() {
    const { status, searchTerm } = this.state;

    return (
      <>
        <div className="search kt-input-icon kt-input-icon--left">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
            onChange={(e) => this._updateSearchTerm(e.target.value)}
          />
          <span className="kt-input-icon__icon kt-input-icon__icon--left">
            <span>
              <i className="la la-search"></i>
            </span>
          </span>
        </div>
        <div className="kt-widget kt-widget--users kt-mt-20">
          <div className="kt-scroll kt-scroll--pull">
            <div className={`kt-widget__items`}>
              <LazyLoadingList
                ref={this.lazyLoadingList}
                endpoint={`${window.Api.Visitors}?status=${status}&search_term=${searchTerm}`}
                onItemUpdated={(visitors) => {
                  if (this.props.selectedVisitor == null && visitors.length > 0) {
                    this.props.onClick(visitors[0]);
                  }
                }}
                renderItem={(visitor) => {
                  return (
                    <VisitorListItem
                      visitor={visitor}
                      onUpdated={() => this.lazyLoadingList.current.refresh()}
                      onClick={() => this.props.onClick(visitor)}
                      selectedVisitor={this.props.selectedVisitor}
                    />
                  );
                }}
                renderNoContent={() => this._renderEmptyState()}
                renderInitialLoading={() => this._renderPlaceholderCards()}
                renderLoadingMore={() => this._renderPlaceholderCards()}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
