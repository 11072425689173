import React, { Component } from "react";

import moment from "moment";

export class VisitorListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitor: props.visitor,
    };
  }

  _getPageTitle(pageView) {
    if (!pageView) {
      return "Site";
    }
    if (pageView.page) {
      return pageView.page.title;
    } else if (pageView.product) {
      return pageView.product.name;
    } else if (pageView.article) {
      return pageView.article.title;
    } else if (pageView.shop) {
      return "Shop";
    } else if (pageView.blog) {
      return "Blog";
    }
    return "Site";
  }

  _renderVisitorInfo(visitor) {
    let title = visitor?.first_name || `Visitor: #${visitor.id}`;

    return (
      <>
        <div
          className={`kt-widget__item ${
            this.props.selectedVisitor?.id == visitor?.id && "selected"
          }`}
          onClick={() => this.props.onClick(visitor)}
        >
          <div className="kt-widget__info">
            <span className="kt-widget__desc">
              {this._getPageTitle(visitor.last_page_view)}
            </span>
            <div className="kt-widget__section">
              <a href="javascript:;" className="kt-widget__username">
                {title}
              </a>
              {visitor?.status === "online" && (
                <span className="kt-badge kt-badge--success kt-badge--dot"></span>
              )}
            </div>
          </div>
          <div className="kt-widget__action">
            <span className="kt-widget__date">
              {moment(visitor.last_page_view?.created_at).fromNow()}
            </span>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { visitor } = this.state;

    return this._renderVisitorInfo(visitor);
  }
}

export default VisitorListItem;
