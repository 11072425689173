import React from "react"

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import SaleActionsCell from "./cells/SaleActionsCell"

import ConfirmModal from '../modal/ConfirmModal'
import SubmissionModal from "../modal/SubmissionModal"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Currency from '../../../utils/Currency'
import Notify from '../../../utils/Notify'
import FetchHelper from "../../../utils/FetchHelper"

import moment from 'moment'

export default class SalesTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      websiteId: AuthManager.currentWebsite.id,
    }

    this.table = React.createRef()
  }

  _refund(sale){
    this.setState({ refunding: true })
    Backend.refund(sale)
    .then(() => {
      this.setState({
        sale: null,
        showConfirmRefundModal: false,
        refunding: false
      })
      this.table.current.refresh()
      Notify.success("Refund successfully processed")
    })
    .catch(error => {
      this.setState({ refunding: false })
      Notify.error(error.message)
    })
  }

  _getColumns() {
    let {
      shop
    } = this.state

    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: submission => moment(submission.created_at).format('DD/MM/YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Amount',
        id: 'sale.amount',
        accessor: submission => Currency.format(submission.sale.amount, submission.sale.currency)
      },
      {
        Header: 'Name',
        id: 'sale.first_name',
        accessor: submission => submission.sale.first_name + " " + submission.sale.last_name
      },
      {
        Header: 'Email',
        id: 'sale.email',
        accessor: submission => submission.sale.email
      },

      {
        Header: 'Status',
        id: 'sale.status',
        Cell: rowInfo => {
          let sale = rowInfo.original.sale

          let label = "Paid"
          let type = "success"

          if(sale.status === "refunded"){
            label = "Refunded"
            type = "danger"
          }

          return (
            <span
              className={`kt-badge kt-badge--${type} kt-badge--inline kt-badge--pill`}
            >
              { label }
            </span>
          )
        }
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let submission = rowInfo.original
          let { sale } = submission
          return (
            <>
              <a
                href="javascript:;"
                class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                title="View"
                onClick={() => this.setState({
                    selectedSubmission: submission,
                    showSubmissionEditModal: true,
                  })
                }
              >
                <i class="flaticon2-list-2 text-primary icon-sm"></i>
              </a>
              { sale.status !== "refunded" &&
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  href="javascript:void(0)"
                  title="Refund"
                  onClick={() => {
                    this.setState({ sale, showConfirmRefundModal: true })
                  }}
                >
                  <i className="la la-exchange"/>
                </a>
              }
            </>
          )
        }
      }
    ]
  }

  render() {
    let {
      sale,
      websiteId,
      refunding,
      selectedSubmission,
      showConfirmRefundModal,
      showSubmissionEditModal
    } = this.state

    const columns = this._getColumns()

    let params = {
      website_id: websiteId,
      form_type: "payment"
    }

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Submissions}
          title="Payments"
          params={params}
          columns={columns}
          filters={""}
          searchFilterName="status"
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
        />
        <ConfirmModal
          show={showConfirmRefundModal}
          title={`Are you sure?`}
          message="You cannot undo a refund, the customer will get back all fees."
          loading={refunding}
          confirmButtonText="Refund"
          buttonClassName="danger"
          onCancel={() => {
            this.setState({
              sale: null,
              showConfirmRefundModal: false
            })
          }}
          onConfirm={() => this._refund(sale)}
        />
        {
          showSubmissionEditModal &&
          <SubmissionModal
            viewOnly={true}
            show={showSubmissionEditModal}
            submissionId={selectedSubmission.id}
            onUpdated={() => this.setState({selectedSubmission: null, showSubmissionEditModal: false})}
            onHide={() => this.setState({selectedSubmission: null, showSubmissionEditModal: false})}
            modalClassName={"from-payments-table"}
          />
        }
      </>
    )
  }
}
