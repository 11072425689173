import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import Div from "../components/layouts/Div"

import ActivateShopForm from '../components/product/ActivateShopForm'

import Aside from '../components/Aside'

import ConnectForm from '../components/kyc/ConnectForm'

import Backend from "../../utils/Backend"
import AuthManager from "../../utils/AuthManager"
import Website from "../../utils/Website"
import Notify from "../../utils/Notify"


class Activate extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      mode: props.mode
    }

    this.activateShopForm = React.createRef()
  }

  componentDidMount(){
    if(AuthManager.currentUser.company.payments_connected){
      if(this.state.mode === "payment"){
        this.props.history.push("/payment/settings")
      }else{
        this.props.history.push("/shop/settings")
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _handleActivate(){
    this.setState({ loading: true })
    this.activateShopForm.current.save()
    .then(response => {
      if(response.processor_data?.activation_link){
        window.location = response.processor_data.activation_link
      }else{
        if(this.state.mode === "payment"){
          this.props.history.push("/payment/settings")
        }else{
          this.props.history.push("/shop/settings")
        }
      }
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      mode,
      loading,
    } = this.state

    return (
      <div className="row">
        <ActivateShopForm
          key={mode}
          ref={this.activateShopForm}
          mode={mode}
          loadingText="Creating Your Shop..."
          confirmButtonText="Connect Bank Account"
          onConfirmPressed={() => this._handleActivate()}
        />
      </div>
    )
  }
}

export default withRouter(Activate);
