import React from 'react'

import Div from "../components/layouts/Div"

import ConnectForm from "../components/kyc/ConnectForm"

import Backend from "../../utils/Backend"
import Notify from "../../utils/Notify"

export default class BusinessInfo extends React.Component {
  constructor(props){
    super(props)

    this.state = {
    }
  }


  render(){
    return <ConnectForm/>
  }
}
