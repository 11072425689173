import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

import AuthManager from '../../../utils/AuthManager'

export default class DashboardEngagementCard extends React.Component {

  _showActivateShopButton(){
    return !AuthManager.currentUser.company.payments_connected
  }

  _showSelectDomainButton(){
    return !AuthManager.currentWebsite.domain
  }

  render(){

    if(!this._showActivateShopButton() && !this._showSelectDomainButton()){
      return null
    }
    return (
      <EngagementCard
        { ...this.props }
        title="Welcome To Your Dashboard"
        renderContent={() => "Your dashboard will enable you to control the backend of your website, such as reviewing stats, enabling new Add-Ons to power your website."}
        cta={() => {
          return (
            <div className="c-btn-group">
              { this._showActivateShopButton() &&
                <a
                  href={"javascript:void(0);"}
                  className="btn btn-success font-weight-bold py-2 mr-3"
                  onClick={() => this.props.onActivatePaymentPressed()}
                >
                  Activate Payment Widget
                </a>
              }
              { this._showActivateShopButton() &&
                <a
                  href={"javascript:void(0);"}
                  className="btn btn-danger font-weight-bold py-2 mr-3"
                  onClick={() => this.props.onActivateShopPressed()}
                >
                  Activate Shop
                </a>
              }
              { this._showSelectDomainButton() &&
                <a
                  href={"javascript:void(0);"}
                  className="btn btn-info font-weight-bold py-2"
                  onClick={() => this.props.onSelectDomainPressed()}
                >
                  Choose A Domain Name
                </a>
              }
            </div>
          )
        }}
        contentContainerClassName="col-md-9"
      />
    )
  }
}
