import React from "react";

import ActivateShopForm from "../components/product/ActivateShopForm";

import Div from "../components/layouts/Div";

import Select from "../components/common/Select";

import Backend from "../../utils/Backend";
import Notify from "../../utils/Notify";

const STOCK_OPTIONS = [
  {
    label: "Yes, I Need To Control My Stock",
    value: true,
  },
  {
    label: "No, I Have Unlimited Stock",
    value: false,
  },
];

const RESERVATION_OPTIONS = [
  {
    label: "Yes, I Want To Reserve Stock",
    value: true,
  },
  {
    label: "No, No Need To Reserve Stock",
    value: false,
  },
];

const RESERVATION_DELTA_OPTIONS = [
  {
    label: "5 mins",
    value: 60 * 5,
  },
  
  {
    label: "10 mins",
    value: 60 * 10,
  },

  {
    label: "15 mins",
    value: 60 * 15,
  },

  {
    label: "20 mins",
    value: 60 * 20,
  },

  {
    label: "30 mins",
    value: 60 * 30,
  },

  {
    label: "40 mins",
    value: 60 * 40,
  },

  {
    label: "50 mins",
    value: 60 * 50,
  },

  {
    label: "1 hour",
    value: 60 * 60,
  },

  {
    label: "2 hours",
    value: 120 * 60,
  },

  {
    label: "3 hours",
    value: 180 * 60,
  },

  {
    label: "4 hours",
    value: 240 * 60,
  },

  {
    label: "5 hours",
    value: 300 * 60,
  },

  {
    label: "6 hours",
    value: 360 * 60,
  },

  {
    label: "7 hours",
    value: 420 * 60,
  },

  {
    label: "8 hours",
    value: 480 * 60,
  },

  {
    label: "9 hours",
    value: 540 * 60,
  },

  {
    label: "10 hours",
    value: 600 * 60,
  },

  {
    label: "11 hours",
    value: 660 * 60,
  },

  {
    label: "12 hours",
    value: 720 * 60,
  },
];

export default class CartSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      shop: props.shop,
      data: {
        stock_enabled: props.shop.stock_enabled,
        reservation_delta: props.shop.reservation_delta,
      },
    };

    this.activateShopForm = React.createRef();
  }

  _updateShop() {
    let { data } = this.state;

    this.setState({ loading: true });
    return Backend.updateShop(this.props.shop, data)
      .then((shop) => {
        this.setState({ loading: false });
        this.props.onUpdated(shop);
        Notify.success("Cart Settings Updated");
        return shop;
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  }

  render() {
    let { title, loading, data } = this.state;
    return (
      <>
        <Div disabled={loading} spinner={true}>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label mt-auto">
              Do You Need To Manage Your Stock?
            </label>
            <div className="col-lg-9 my-auto">
              <Select
                value={STOCK_OPTIONS.find(
                  (option) => option.value === data.stock_enabled
                )}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                placeholder="Please Select An Option"
                onChange={(option) => {
                  data.stock_enabled = option.value;
                  if (option.value === false) {
                    data.reservation_delta = null;
                  }
                  this.setState({ data });
                }}
                options={STOCK_OPTIONS}
              />
            </div>
          </div>

          {data.stock_enabled && (
            <div className="form-group row">
              <label className="col-lg-3 col-form-label mt-auto">
                Stock Reservation
              </label>
              <div className="col-lg-9 my-auto">
                <label>
                  Do you want to reserve stock when a customer adds the product
                  to their cart, so that it is guaranteed to be available when
                  they checkout?
                </label>
                <Select
                  value={RESERVATION_OPTIONS.find(
                    (option) =>
                      option.value === (data.reservation_delta != null)
                  )}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  placeholder="Please Select An Option"
                  onChange={(option) => {
                    console.log("++++", option);
                    if (option.value === true) {
                      data.reservation_delta =
                        RESERVATION_DELTA_OPTIONS[0].value;
                    } else {
                      data.reservation_delta = null;
                    }
                    this.setState({ data });
                  }}
                  options={RESERVATION_OPTIONS}
                />
              </div>
            </div>
          )}

          {data.reservation_delta != null && (
            <div className="form-group row">
              <label className="col-lg-3 col-form-label mt-auto">
                Reservation Timeframe
              </label>
              <div className="col-lg-9 my-auto">
                <label>
                  How long do you want the stock held for before it is released
                  again?
                </label>
                <Select
                  value={RESERVATION_DELTA_OPTIONS.find(
                    (option) => option.value === data.reservation_delta
                  )}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  placeholder="Please Select An Option"
                  onChange={(option) => {
                    data.reservation_delta = option.value;
                    this.setState({ data });
                  }}
                  options={RESERVATION_DELTA_OPTIONS}
                />
              </div>
            </div>
          )}

          <div className="kt-portlet__foot" style={{ borderTop: 0 }}>
            <div className="kt-form__actions pull-right">
              <button
                type="button"
                className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                disabled={loading}
                onClick={() => this._updateShop()}
              >
                Update
              </button>
            </div>
          </div>
        </Div>
      </>
    );
  }
}

CartSettings.defaultProps = {};
