import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import Div from "./components/layouts/Div"

import ActivateShopForm from './components/product/ActivateShopForm'
import ActivateShopEngagementCard from './components/cards/ActivateShopEngagementCard'

import Aside from './components/Aside'

import ChatSettings from './components/conversation/ConversationSettings'

import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Website from "../utils/Website"
import Notify from "../utils/Notify"

const TABS = [
  {
    title:"Live Chat Settings",
    subtitle:"Configure whether visitors can message you or not",
    icon:"gear",
    url: "/chat/settings"
  },
]

class ConversationSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      activeTabIndex: this._getActiveTabIndex(),
    }

  }

  _getActiveTabIndex(){
    const { pathname } = this.props.location;
    for(var i=0; i < TABS.length; i++){
      let tab = TABS[i]
      if(pathname === tab.url){
        return i
      }
    }
    return 0
  }

  render(){
    let {
      activeTabIndex
    } = this.state

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">

            <Aside
              tabs={TABS}
              activeTabIndex={activeTabIndex}
              onClick={(tab, index) => {
                this.setState({
                  activeTabIndex: index
                }, () =>{
                  this.props.history.push(tab.url)
                })
              }}
            />

            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
              <form className="kt-form w-100" id="kt_form">

                <Switch>
                  <Route
                    exact
                    path="/chats/settings"
                    render={(props) => {
                      return (
                        <ChatSettings {...props}/>
                      )
                    }}
                  />
                </Switch>

              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ConversationSettings);
