import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

import AuthManager from '../../../utils/AuthManager'

export default class BookingEngagementCard extends React.Component {

  _showActivateShopButton(){
    return !AuthManager.currentUser.company.payments_connected
  }

  _showSelectDomainButton(){
    return !AuthManager.currentWebsite.domain
  }

  _showAddArticleButton(){
    return AuthManager.currentWebsite.articles_no === 0
  }

  render(){
    let { mode } = this.props
    if(!this._showActivateShopButton() && !this._showSelectDomainButton()){
      return null
    }

    let title = "Welcome To Your Booking System"
    let subtitle = (
      <>
        Your Booking System will allow you to add <b className="text-info">Services</b> and <b className="text-danger">Resources</b> that your customers can book.
      </>
    )

    if(mode == "services"){
      title = <>What Is A <b className="text-info">Service?</b></>
      subtitle = (
        <p>
          <b className="text-info">Services</b> are the bookable items that your customer can book.
          <br/><br/>
          <b className="text-white">Examples:</b>
          <br/><br/>
          <ul>
            <li className="text-info"><b>Yoga</b></li>
            <li className="text-info"><b>Appointment</b></li>
            <li className="text-info"><b>Car Rental</b></li>
          </ul>
        </p>
      )
    }else if(mode == "resources"){
      title = <>What Is A <b className="text-danger">Resource?</b></>
      subtitle = (
        <p>
          <b className="text-danger">Resources</b> are the items you need to provide the <b className="text-info">Service.</b>
          <br/><br/>
          <b className="text-white">Examples:</b>
          <br/><br/>
          <ul>
            <li>To offer <b className="text-info">Yoga</b> as a Service, you need add <b className="text-danger">Classes</b> as a Resource.</li>
            <li>To offer <b className="text-info">Appointments</b> as a Service, you need an <b className="text-danger">Employee</b> as a Resource.</li>
            <li>To offer <b className="text-info">Car Rental</b> as a Service, you need to have <b className="text-danger">Cars</b> as a Resource.</li>
          </ul>
        </p>
      )
    }
    return (
      <EngagementCard
        { ...this.props }
        title={title}
        renderContent={() => subtitle}
        cta={() => {
          return (
            <div className="c-btn-group">

            </div>
          )
        }}
        contentContainerClassName="col-md-9"
      />
    )
  }
}
