import React from 'react'

export default class SaleActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      sale: props.sale
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let { sale } = this.state
    return (
      <>
        { sale.status !== "refunded" &&
          <>
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              href="javascript:void(0)"
              title="Refund"
              onClick={() => this.props.onRefundPressed(sale) }
            >
              <i className="la la-exchange"/>
            </a>
            { (sale.shipping_method === "delivery" && !sale.delivery) &&
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                href="javascript:void(0)"
                title="Mark As Dispatched"
                onClick={() => this.props.onDispatchPressed(sale) }
              >
                <i className="la la-ship"/>
              </a>
            }
            { (sale.shipping_method === "delivery" && sale.delivery && sale.delivery.status === "dispatched") &&
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                href="javascript:void(0)"
                title="Mark As Delivered"
                onClick={() => this.props.onDeliveredPressed(sale) }
              >
                <i className="la la-ship"/>
              </a>
            }
            { (sale.shipping_method === "collection" && !sale.collection) &&
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                href="javascript:void(0)"
                title="Notify Collectable"
                onClick={() => this.props.onCollectablePressed(sale) }
              >
                <i className="la la-ship"/>
              </a>
            }
            { (sale.shipping_method === "collection" && sale.collection && sale.collection.status === "ready") &&
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                href="javascript:void(0)"
                title="Mark As Collected"
                onClick={() => this.props.onCollectedPressed(sale) }
              >
                <i className="la la-ship"/>
              </a>
            }
          </>
        }
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
          href={`mailto:${sale.email}`}
          title="Email"
        >
          <i className="la la-envelope"/>
        </a>
      </>
    )
  }
}
