import React from "react";

import { withRouter } from "react-router-dom";

import moment from "moment";

import BaseTable from "./BaseTable";

import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

import AddNewFormModal from "../modal/AddNewFormModal"
import ConfirmModal from "../modal/ConfirmModal";

class FormsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }


  _getColumns() {
    let columns = [
      {
        Header: "Created",
        id: "created_at",
        width: 100,
        accessor: (form) => moment(form.created_at).format("DD/MM/YYYY"),
      },
      {
        Header: "Name",
        id: "name",
        width: 320,
        Cell: (rowInfo) => {
          let form = rowInfo.original;

          return (
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name">{form.name}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Submissions No",
        id: "current_submissions",
        sortable: false,
        Cell: (rowInfo) => {
          let form = rowInfo.original;

          return <span>{form.current_submissions}</span>;
        },
      },

      {
        Header: "Actions",
        id: "id",
        width: 130,
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let form = rowInfo.original;

          return (
            <>
              <span>
              <a
                href="javascript:;"
                class="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                title="Edit"
                onClick={() => this.props.history.push("/forms/"+form.id) }
              >
                <i class="flaticon2-pen text-primary icon-sm"></i>
              </a>
              </span>
            </>
          );
        },
      },
    ];

    return columns;
  }

  render() {
    let { showAddNewFormModal, showConfirmModal, form } = this.state;

    const columns = this._getColumns();

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Forms}
          params={{
            website_id: AuthManager.currentWebsite.id,
          }}
          showSearch={false}
          noDataMessage={"No forms found"}
          columns={columns}
          exportButtonsEnabled={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: column.Header === "Actions" ? 0 : 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
          renderToolbar={() => {
            return (
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-brand btn-icon-sm"
                    onClick={() => {
                      this.setState({ showAddNewFormModal: true });
                    }}
                  >
                    <i className="flaticon2-plus" />
                    Add New Form
                  </button>
                </div>
              </div>
            );
          }}
        />
        <AddNewFormModal
          show={showAddNewFormModal}
          onHide={() => {
              this.setState({ showAddNewFormModal: false });
            }}
          onAdded={form =>  this.props.history.push("/forms/"+form.id)}
        />

      </>
    );
  }
}

FormsTable.defaultProps = {
  title: "",
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
};

export default withRouter(FormsTable)
