import React, { Component } from "react";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export class VisitorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitor: General.clone(props.visitor),
    };
  }

  _handleChange(e) {
    let { visitor } = this.state;

    let value = e.target.value || null;

    visitor[e.target.name] = value;

    this.setState({ visitor });
  }

  _updateVisitorProfile() {
    let { visitor } = this.state;

    this.setState({ isLoading: true });

    let data = visitor;

    data = {
      id: visitor.id,
      first_name: visitor.first_name,
      last_name: visitor.last_name,
      email: visitor.email,
      phone: visitor.phone,
      notes: visitor.notes
    };

    Backend.updateVisitorProfile(data)
      .then((data) => {
        this.setState({ data, isLoading: false });
        this.props.onUpdated(data);
        Notify.success("Profile has been updated successfully.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }


  render() {
    let { visitor, isLoading } = this.state;

    if (!visitor) {
      return null;
    }

    return (
      <div className="card-body card-body-scroll-lock">

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">First Name</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="first_name"
              placeholder="First Name"
              className="form-control form-control-solid"
              value={visitor.first_name}
              onChange={(e) => this._handleChange(e)}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Last Name</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="last_name"
              placeholder="Last Name"
              className="form-control form-control-solid"
              value={visitor.last_name}
              onChange={(e) => this._handleChange(e)}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Email</label>
          <div className="col-lg-9 my-auto">
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="form-control form-control-solid"
              value={visitor.email}
              onChange={(e) => this._handleChange(e)}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Phone</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              className="form-control form-control-solid"
              value={visitor.phone}
              onChange={(e) => this._handleChange(e)}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Notes</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="notes"
              placeholder="Notes"
              className="form-control form-control-solid"
              value={visitor.notes}
              onChange={(e) => this._handleChange(e)}
            />
          </div>
        </div>

        <div className="form-group row form-footer">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={isLoading}
            onClick={() => {
              this._updateVisitorProfile();
            }}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default VisitorForm;
