import React from "react"

import { withRouter } from "react-router-dom";

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import ProductActionsCell from "./cells/ProductActionsCell"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Submission from '../../../utils/Submission'
import Currency from '../../../utils/Currency'
import Website from '../../../utils/Website'

import moment from 'moment'

class ProductsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      shop: props.shop,
      categories: [{
        label: "All",
        value: "all"
      }]
    }

    this.table = React.createRef()
  }

  componentDidMount(){
    this._setCategories()
  }

  _setCategories(){
    let {
      categories
    } = this.state

    Backend.getProductCategories()
    .then(returnedCategories => {
      returnedCategories.forEach(category => {
        categories.push({
          label: category.name,
          value: category.id
        })
      })

      this.setState({
        categories
      })
    })
  }

  _getColumns() {
    let {
      shop
    } = this.state

    return [
      {
        Header: 'Product Name',
        accessor: 'name',
        width: 300,
        Cell: rowInfo => {
          let product = rowInfo.original
          return (
            <div class="d-flex align-items-center">
            { product.featured_image &&
              <div class="symbol symbol-50 flex-shrink-0">
                  <img class="product-thumbnail" src={product.featured_image.thumbnail} alt="photo"/>
              </div>
            }
              <div class={product.featured_image ? "ml-3" : ""}>
                  <span>{product.name}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Price',
        id: 'price',
        Cell: rowInfo => {
          let product = rowInfo.original
          if(!product.on_sale){
            return Currency.format(product.price, shop.currency)
          }

          return (
            <>
              <strike>{ Currency.format(product.price, shop.currency) }</strike>
              <span className="ml-1">{Currency.format(product.sale_price, shop.currency)}</span>
            </>
          )
        }
      },
      {
        Header: 'Categories',
        id: "categories",
        show: false,
        sortable: false,
        accessor: product => {
          return product.categories.map(category => category.name).join(", ")
        }
      },
      {
        Header: 'Orders',
        accessor: 'sales_no',
      },
      {
        Header: 'Sales',
        id: 'gross_sales',
        accessor: product => Currency.format(product.gross_sales, shop.currency)
      },
      {
        Header: 'Date Added',
        id: 'created_at',
        accessor: product => moment(product.created_at).format('DD/MM/YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          return (
            <ProductActionsCell
              product={rowInfo.original}
              onViewPressed={product => {
                let url = `${Website.getDomain(AuthManager.currentWebsite)}/shop/product/${product.slug}`
                window.open(url)
              }}
              onEditPressed={product => this.props.history.push(`/products/${product.slug}`)}
            />
          )
        }
      }
    ]
  }


  render() {
    let {
      shop,
      product,
      categories,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Products}
          params={{
            shop_id: shop.website.id
          }}
          title="Products"
          columns={columns}
          filters={categories}
          searchFilterName="category_id"
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          renderToolbar={() => {
            if(AuthManager.currentUser.user.role !== "company_member"){
              return null
            }
            return (
              <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">
                  <button
                    type="button"
                    class="btn btn-brand btn-icon-sm"
                    onClick={() => {
                      let url = `${Website.getDomain(AuthManager.currentWebsite)}/shop`
                      window.open(url)
                    }}
                  >
                    <i class="la la-eye icon-sm"></i>
                    View Shop
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-brand btn-icon-sm"
                    onClick={() => this.props.history.push(`/products/add`)}
                  >
                    <i class="flaticon2-plus lh-1"></i>
                    Add Product
                  </button>
                </div>
              </div>
            )
          }}
        />
      </>
    )
  }
}

export default withRouter(ProductsTable);
