import React from "react"

import ImageEdit from "../common/ImageEdit"

import Select from '../common/Select';

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

const STATUSES = [
  {
    label: "Display",
    value: true
  },
  {
    label: "Don't Display",
    value: false
  }
]

export default class DashboardSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      website: { ...AuthManager.currentWebsite }
    }
  }

  _update(){
    let {
      website
    } = this.state

    this.setState({ loading: true })

    let data = {
      show_leads_stat: website.show_leads_stat,
      show_sales_stat: website.show_sales_stat,
      show_page_views_stat: website.show_page_views_stat
    }

    Backend.updateWebsite(website, data)
    .then(website => {
      this.setState({
        website,
        loading: false
      })
      AuthManager.currentWebsite = {
        ...AuthManager.currentWebsite,
        ...data
      }
      Notify.success("Settings updated!")
    })
    .catch(error => {
      this.setState({
        loading: false
      })
      Notify.error(error.message)
    })
  }

  _handleChange(e) {
    let website = this.state.website
    website[e.target.name] = e.target.value
    this.setState({ website, error: null })
  }

  render() {
    let {
      website,
      loading
    } = this.state

    return (
      <>
        <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
          <div className="kt-heading kt-heading--md">Dashboard Display Settings</div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Page Views</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={STATUSES.find(option => option.value == website.show_page_views_stat)}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    name="show_page_views_stat"
                    onChange={option => {
                      website.show_page_views_stat = option.value
                      this.setState({ website })
                    }}
                    options={STATUSES}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Leads Stats</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={STATUSES.find(option => option.value == website.show_leads_stat)}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    name="show_page_views_stat"
                    onChange={option => {
                      website.show_leads_stat = option.value
                      this.setState({ website })
                    }}
                    options={STATUSES}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Sales Stats</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={STATUSES.find(option => option.value == website.show_sales_stat)}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    name="show_sales_stat"
                    onChange={option => {
                      website.show_sales_stat = option.value
                      this.setState({ website })
                    }}
                    options={STATUSES}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="kt-form__actions pull-right">
          <button
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            disabled={loading}
            onClick={() => this._update()}
          >
            Save Changes
          </button>
        </div>
      </>
    )
  }
}
