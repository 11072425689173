import React from 'react'
import Modal from 'react-bootstrap/Modal'

import ConfirmModal from "./ConfirmModal"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from '../../../utils/Notify'

export default class PageDeleteModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      page: props.page,
      loading: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _deletePage(){
    let {
      show,
      page,
      loading
    } = this.state

    this.setState({ loading: true })
    Backend.deletePage(page)
    .then(() => {
      this.props.onDeleted()
    })
    .catch(error => {
      Notify.error(error.message);
      this.setState({ loading: false })
    })
  }

  render() {
    let {
      show,
      page,
      loading
    } = this.state

    if(page == null){
      return null
    }
    
    return (
      <ConfirmModal
        show={show}
        title={`Delete ${page.title}` }
        message="Are you sure? This action cannot be undone"
        loading={loading}
        buttonClassName="danger"
        confirmButtonText="Delete"
        onCancel={() => this.props.onCancel()}
        onConfirm={() => this._deletePage()}
      />
    )
  }
}
