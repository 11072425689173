import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"

export default class InviteGuestModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onConfirmPressed(){
    let {
      first_name,
      last_name,
      email
    } = this.state


    let data = {
      first_name,
      last_name,
      email
    }

    this.setState({ loading: true })
    Backend.createInvite(data)
    .then(invite => {
      this.props.onInviteCreated(invite)
      this.setState({
        loading: false
      })
    })
    .catch(error => [
      this.setState({
        error: error.message,
        loading: false
      })
    ])

  }

  render() {
    let {
      email,
      first_name,
      last_name,
      error,
      loading,
      show
     } = this.state

     if(!show){
       return null
     }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite Guest</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <>
              <span className="error-message">{ error }</span>
              <br/><br/>
            </>
          }

          <div className="form-group mt-3">
            <label>First Name</label>
            <input
                type="text"
                className="form-control"
                onChange={e => this.setState({ first_name: e.target.value })}
            />
          </div>

          <div className="form-group mt-3">
            <label>Last Name</label>
            <input
                type="text"
                className="form-control"
                onChange={e => this.setState({ last_name: e.target.value })}
            />
          </div>

          <div className="form-group mt-3">
            <label>Email</label>
            <input
                type="text"
                className="form-control"
                onChange={e => this.setState({ email: e.target.value })}
            />
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._onConfirmPressed()}
          >
            Add
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
