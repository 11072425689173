import React from "react"

import moment from 'moment'

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable"

import SubmissionActionsCell from "./cells/SubmissionActionsCell"

import AuthManager from '../../../utils/AuthManager'
import General from '../../../utils/General'
import Currency from '../../../utils/Currency'

import SubmissionModal from "../modal/SubmissionModal";

const STATUSES = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "New",
    value: "new"
  },
  {
    label: "Open",
    value: "open"
  },
  {
    label: "Closed",
    value: "closed"
  }
]

class SubmissionsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      websiteId: AuthManager.currentWebsite.id,
      statuses: STATUSES,
      crm: props.crm,
      bookings: props.bookings
    }

    this.table = React.createRef()
  }

  _getColumns() {
    let {
      bookings
    } = this.state

    let columns = [
      {
        Header: 'Date',
        id: bookings ? 'booking.starts_at' : 'created_at',
        accessor: submission => {
          if(bookings){
            return moment(submission.booking.starts_at).format('HH:mm DD/MM/YYYY')
          }
          return moment(submission.created_at).format('DD/MM/YYYY')
        },
        type: "date",
        format: "%d %b %Y"
      },
    ]

    if(!bookings){
      columns = [
        ...columns,
        {
          Header: 'Status',
          id: "status.name",
          accessor: submission => General.toTitleCase(submission.status.name),
          exportable: false
        },
      ]
    }
    else{
      columns = [
        {
          Header: 'Resource',
          id: "booking.service_resource.resource.name",
          Cell: rowInfo => {
            let submission = rowInfo.original
            let { resource } = submission.booking.service_resource
            return (
              <div class="d-flex align-items-center">
              { resource.image &&
                <div class="symbol symbol-50 flex-shrink-0">
                    <img class="resource-thumbnail" src={resource.image.thumbnail} alt="photo"/>
                </div>
              }
                <div class={resource.image ? "ml-3" : ""}>
                    <span>{resource.name}</span>
                </div>
              </div>
            )
          }
        },
        ...columns,
        {
          Header: 'Service',
          accessor: "booking.service_resource.service.name",
        },
        {
          Header: 'Amount',
          id: 'sale.amount',
          accessor: submission => {
            if(!submission.sale){
              return "-"
            }
            return Currency.format(submission.sale.amount, submission.sale.currency)
          }
        },
      ]
    }

    columns = [
      ...columns,
      {
        Header: 'Name',
        id: "data.contact_first_name",
        accessor: submission => submission.data.contact_first_name + " " + submission.data.contact_last_name,
        exportable: false
      },
      {
        Header: 'Email',
        accessor: "data.contact_email",
        exportable: false
      },
      {
        Header: 'Phone',
        accessor: "data.contact_phone",
        exportable: false
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let submission = rowInfo.original
          return (
            <SubmissionActionsCell
              submission={submission}
              onEditPressed={submission => this.setState({ selectedSubmission: submission, showSubmissionEditModal: true })}
            />
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {
      crm,
      bookings,
      websiteId,
      selectedSubmission,
      showSubmissionEditModal: showSubmissionEditModal,
    } = this.state

    const columns = this._getColumns()

    let params = {
      website_id: websiteId,
    }

    if(crm){
      params = {
        ...params,
        form_type: "crm",
      }
    }
    else if(bookings){
      params = {
        ...params,
        booking: true,
      }
    }

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Submissions}
          title={ bookings ? "Bookings" : "Leads"}
          columns={columns}
          searchFilterName="status"
          params={params}
          exportParams={params}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
        />
        {
          showSubmissionEditModal &&
          <SubmissionModal
            show={showSubmissionEditModal}
            submissionId={selectedSubmission.id}
            viewOnly={selectedSubmission.sale || selectedSubmission.booking}
            onUpdated={() => this.setState({selectedSubmission: null, showSubmissionEditModal: false})}
            onHide={() => this.setState({selectedSubmission: null, showSubmissionEditModal: false})}
          />
        }
      </>
    )
  }
}

export default withRouter(SubmissionsTable);
