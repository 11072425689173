import React from 'react'

import Aside from './components/Aside'
import GeneralSettings from './components/settings/GeneralSettings'
import SecuritySettings from './components/settings/SecuritySettings'
import DomainSettings from './components/settings/DomainSettings'
import EmailSettings from './components/settings/EmailSettings'
import DashboardSettings from './components/settings/DashboardSettings'
import NotificationSettings from './components/settings/NotificationSettings'

import Backend from '../utils/Backend'

const TABS = [
  {
    title:"General Settings",
    subtitle:"Setup Your Website Details",
    icon:"settings",
  },
  {
    title:"Security Settings",
    subtitle:"Password protect your website",
    icon:"lock",
  },
  {
    title:"Dashboard Settings",
    subtitle:"Customise your dashboard",
    icon:"dashboard",
  },
  {
    title:"Notification Settings",
    subtitle:"Customise your alerts",
    icon:"alarm",
  },
]

export default class Settings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        activeTabIndex: 0
    }
  }

  _renderContent(tabIndex){
    switch(tabIndex){
      case 0:   return <GeneralSettings/>
      case 1:   return <SecuritySettings/>
      case 2:   return <DashboardSettings/>
      case 3:   return <NotificationSettings/>
      default:  return null
    }
  }

  render() {
    let {
      activeTabIndex
    } = this.state

    return (

        <div className="kt-portlet">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
              <Aside
                tabs={TABS}
                activeTabIndex={activeTabIndex}
                onClick={(tab, index) => {
                  return this.setState({ activeTabIndex: index })
                }}
              />
              <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                <form className="kt-form" id="kt_form">

                  { this._renderContent(activeTabIndex) }

                </form>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
