import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import FileUpload from "../common/FileUpload";

export default class AddNewFileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
      file: null,
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
    };
  }

  render() {
    let { show, isLoading, file } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title> Add New File</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="col-md-12">
              <div className="form-group">
                <div>
                  <FileUpload
                    file={file}
                    endpoint={`${window.Api.Files}`}
                    onUploaded={(file) => {
                      this.setState({ file, isLoading: false });

                      this.props.onUploaded();

                      this.props.onHide();
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}
