import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

export default class AddProductEngagementCard extends React.Component {
  render() {
    // to do set the props for sizing here
    return (
      <EngagementCard
        { ...this.props }
        title="Add Your First Product"
        renderContent={() => "Your shop is now live. Lets take a minute to add your first product."}
        cta="Add Product"
      />
    )
  }
}
