import React, { Spinner } from 'react'

import Select from './Select'

import { components } from 'react-select';

import Website from '../../../utils/Website'
import AuthManager, { KEY_WEBSITE } from '../../../utils/AuthManager'
import AsyncStorage from '../../../utils/AsyncStorage'

const SingleValue = ({ children, ...props }) => {

  let option = props.options.find(option => option.value === props.data.value)
  let img = option?.data.favicon?.thumbnail || window.General.Branding.Favicon

  return (
    <div className="selected-value row align-items-center">
      <div className="col-auto">
        <a href="javascript:void(0)"  onClick={() => this._goTo("/")}>
          <img alt="Logo" className="logo" src={img}/>
        </a>
      </div>
      <div className="value col pl-0">
        {props.data.label}
      </div>
    </div>
  )
}

export default class WebsiteSelect extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){

  }

  _renderOptions(){
    let {
      value,
    } = this.state

    let websites = AuthManager.websites.sort(function(a, b) {
       return a.slug.localeCompare(b.slug);
    });

    return (
      <>
        {
          websites.map(option => {
            return (
              <option value={option.id}>
                { option.name } aaa
              </option>
            )
          })
        }
      </>
    )
  }

  render(){
    let {
      value,
    } = this.state

    let website = {
      value: AuthManager.currentWebsite.id,
      label: Website.getCleanDomain(AuthManager.currentWebsite)
    }

    let websites = AuthManager.websites.sort(function(a, b) {
       return a.slug.localeCompare(b.slug);
    });

    return (
      <Select
        value={website}
        className="async-select-paginate website-selector"
        classNamePrefix="async-select-paginate"
        name="website"
        placeholder="Website"
        onChange={option => {
          let website = websites.find(site => site.id === option.value)
          console.log("sel", website)
          AsyncStorage.setItem(KEY_WEBSITE, JSON.stringify(website))
          .then(() => {
            window.location.reload()
          })
        }}
        options={websites.map(website =>({
          value: website.id,
          label: Website.getCleanDomain(website),
          data: website
        }))}
        components={{ SingleValue }}
        isSearchable={true}
      />
    )
  }
}

WebsiteSelect.defaultProps = {
  className: "country-select"
}
