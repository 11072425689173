import React from 'react'

import IconLogoPositionLeft from '../pages/components/icons/login/v2/logo-position/Left'
import IconLogoPositionCenter from '../pages/components/icons/login/v2/logo-position/Center'

import IconMainBannerStyleFullHeight from '../pages/components/icons/login/v2/main-banner-style/FullHeight'
import IconMainBannerStyleHalfHeight from '../pages/components/icons/login/v2/main-banner-style/HalfHeight'
import IconMainBannerStyleLeftRight from '../pages/components/icons/login/v2/main-banner-style/LeftRight'

import extractDomain from 'extract-domain';
import General from "./General";

const LOREM_IPSUM = "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies."

export const BUTTON_STYLES = [
  { text: "Circle", css: "circle", value: "rounded" },
  { text: "Square", css: "square",  value: "square"},
  { text: "Rounded", css: "rounded", value: "standard" },
]

export const FONTS = [
  { text: "Modern", css: "montserrat", value: "Montserrat" },
  { text: "Handwritten", css: "sacramento", value: "Sacramento" },
  { text: "Code", css: "roboto-mono", value: "Roboto Mono" },
]

export const LOGO_POSITIONS = [
  { image: <IconLogoPositionLeft className={"w-100 h-auto"} />, text: "Logo Left Aligned", css: "", value: "left" },
  { image: <IconLogoPositionCenter className={"w-100 h-auto"} />, text: "Logo Centre Aligned", css: "", value: "center"}
]

export const BANNER_STYLES = [
  { image: <IconMainBannerStyleFullHeight className={"w-100 h-auto"} />, text: "Full Banner Image", css: "", value: "full" },
  { image: <IconMainBannerStyleHalfHeight className={"w-100 h-auto"} />, text: "Half Banner Image", css: "", value: "half" },
  { image: <IconMainBannerStyleLeftRight className={"w-100 h-auto"} />, text: "Image Left & Text Right", css: "", value: "image_left" }
]

export const BLOCKS = [
  { icon: 'collections_bookmark', title: "Gallery", key: "gallery" },
  { icon: 'contact_page', title: "Contact From", key: "crm" },
  { icon: 'play_circle', title: "Video", key: "video" },
  { icon: 'place', title: "Map", key: "map" },
  { icon: 'handshake', title: "Logo Carousel", key: "partners" },
  { icon: 'star', title: "Testimonials", key: "testimonials" },
  { icon: 'people', title: "People / Team Photos", key: "team" },
]

export const ADD_ONS = [
  { icon: 'contact_page', title: "CRM", key: "crm" },
  { icon: 'question_answer', title: "Live Chat", key: "chat" },
  { icon: 'credit_card', title: "Payment Widget", key: "payment_widget" },
  { icon: 'shopping_cart', title: "eCommerce Shop", key: "shop" },
  { icon: 'calendar_month', title: "Booking System", key: "bookings" },

  { icon: 'newspaper', title: "Blog", key: "blog" },

  { icon: 'analytics', title: "Visitors & Analytics", key: "visitors" },

  { icon: 'smartphone', title: "Mobile App", key: "mobile_app" },
  { icon: 'web', title: "Custom Domain Name", key: "domains" },

  { icon: 'folder', title: "Media Bank", key: "files" },
  { icon: 'people', title: "Team", key: "team" },
  { icon: 'brush', title: "Web Designer", key: "web_designer" },
]

const animation = "fade-up"

export default class SiteBuilder {
  constructor(data) {
    this.data = data
  }

  getBaseDetails(){
    let {
      first_name,
      last_name,
      company_name,
      email,
      phone_number,
      password,
      premium,
      source,
      referrer,
      slug,
      image,
      color,
      font,
      blocks,
      pkg,
      buttonStyle,
      logoPosition,
      bannerStyle
    } = this.data

    font = {
      weight: 900,
      family_name: font,
    }

    let transparentNav = bannerStyle !== "image_left"
    let secondaryLogoColor = transparentNav ? "#ffffff": color
    let secondaryNavBackgroundColor = transparentNav ?  "transparent" : "#ffffff"

    return {
      source,
      referrer,
      name: company_name,
      user: {
        first_name: first_name,
        last_name: "Last Name",
        email: email,
        password: password
      },
      homepage: {
        title: company_name
      },
      primary_color: color,
      menu: {
        nav_items: [
          {
            text: "Contact",
            url: "/#"
          },
        ],
        settings: {
          msc_enabled: true,
          primary_color: "#333333",
          secondary_color: transparentNav ? "#ffffff" : "#333333",
          tertiary_color: "#ffffff",
          quaternary_color: secondaryNavBackgroundColor
        },
        primary_button: {
          title: "Get In Touch",
          url: "/#form",
          settings: {
            primary_color: "#ffffff",
            quaternary_color: null,
            quinary_color: color,
            secondary_color: color,
            senary_color: color,
            tertiary_color: color,
          },
          badge_settings: {}
        },
        secondary_button: null,
        logo_position: logoPosition,
        logo_custom: true,
        logo_size: 22,
        style: "bar",
        primary_logo: {
          font: font,
          icon: "icon-Atom",
          icon_color: color,
          style: "icon_left_text_right",
          text: company_name,
          text_color: color
        },
        secondary_logo: {
          font: font,
          icon: "icon-Atom",
          icon_color: secondaryLogoColor,
          style: "icon_left_text_right",
          text: company_name,
          text_color: secondaryLogoColor,
        }
      },
      footer: {
        fine_print: window.General.Branding.Name,
        title: company_name,
        settings: {
          msc_enabled: true,
          primary_color: "#ffffff",
          secondary_color: "#ffffff",
          senary_color: null,
          tertiary_color: color
        }
      },
      hide_footer: false,
      hide_menu: false,
      h1_font: {
        ...font,
        weight: 600,
      },
      h2_font: {
        ...font,
        weight: 400,
      },
      button_style: buttonStyle,
      subscription:  {
        package: pkg.id,
        add_ons: pkg.add_ons
      }
    }
  }

  getContentBlocks(){
    let blocksData = []

    blocksData =  [
      this.bannerBlock(this.data), // #41: Main Banner and #15 - Full Height Image Left Text Right
      this.featuresBlock(this.data), // #10 - 3 Icon Boxes
      this.aboutUsBlock(this.data), // 13 - Image Left Text Right
      this.ourMissionBlock(this.data), // 14 - Text Left Image Right
    ]

    if(this.blocksInclude("gallery")){ // #24 - Small Slider Gallery
      blocksData = [
        ...blocksData,
        this.galleryBlock(this.data)
      ]
    }

    if(this.blocksInclude("video")){ // #52 - Video Block
      blocksData = [
        ...blocksData,
        this.videoBlock(this.data)
      ]
    }


    if(this.blocksInclude("partners")){
      blocksData = [
        ...blocksData,
        this.partnersBlock(this.data) // #55 - Logo Carousel
      ]
    }


    if(this.blocksInclude("team")){
      blocksData = [
        ...blocksData,
        this.teamBlock(this.data) // #25 - Circle Team
      ]
    }

    if(this.blocksInclude("testimonials")){
      blocksData = [
        ...blocksData,
        this.testimonialsBlock(this.data) // #49 - Testimonials
      ]
    }

    if(this.blocksInclude("crm")){ // #42 - CRM Form
      blocksData = [
        ...blocksData,
        this.formBlock(this.data)
      ]
    }

    if(this.blocksInclude("map")){ // #34 - Map Fullscreen
      blocksData = [
        ...blocksData,
        this.mapBlock(this.data)
      ]
    }

    blocksData = [
      ...blocksData,
      this.abstractBlock(this.data)
    ]

    return blocksData
  }

  bannerBlock(data){
    let { bannerStyle, image, color, company_name, company_description } = data
    let blockId = 41
    let height = "full"
    let title = company_name
    let subtitle = company_description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies."
    let body = null
    let backgroundImage = {
      ...image,
      type: "photo",
    }

    let fontColor = "#ffffff"
    let foregroundImage = null
    let overlay = 6

    if(bannerStyle === "half"){
      height = "half"
    }
    else if(bannerStyle === "image_left"){
      blockId = 15
      body = subtitle
      subtitle = null
      backgroundImage = null
      foregroundImage = {
        ...image,
        type: "photo",
      }
      fontColor = "#333333"
      overlay = null
    }
    let blockData = {
      height,
      title,
      subtitle,
      title_color: fontColor,
      subtitle_color: fontColor,
      body,
      anchor: "banner",
      block_id: blockId,
      gradient: false,
      background_image: backgroundImage,
      foreground_image: foregroundImage,
      primary_button: {
        title: "PRIMARY BUTTON TEXT",
        url: "/#",
        settings: {
          primary_color: "#ffffff",
          secondary_color: color,
          tertiary_color: color,
          msc_enabled: true,
        },
        badge_settings: {

        }
      },
      secondary_button: {
        title: "SECONDARY BUTTON TEXT",
        url: "/#",
        settings: {
          primary_color: null,
          secondary_color: "transparent",
          tertiary_color: null,
          msc_enabled: true,
        },
        badge_settings: {

        }
      },
      link_button: null,
      settings: {
        overlay,
        animation,
        msc_enabled: true,
        primary_color: fontColor,
        quinary_color: null,
        secondary_color: fontColor
      }
    }

    return blockData
  }

  titleBlock(data){
    return {
      anchor: "title",
      title: "Hover To Change This Title",
      subtitle: LOREM_IPSUM,
      title_color: "#000000",
      subtitle_color: "#333333",
      block_id: 11,
      settings: {
        animation,
        primary_color: "#000000",
        secondary_color: "#333333",
        tertiary_color: "#ffffff",
      }
    }
  }

  featuresBlock(data){
    let { company_services } = data
    let title = "Hover To Change This Title"
    let subtitle = LOREM_IPSUM

    let titleOne = "Title 1 Text"
    let subtitleOne = "Hover and click into this text to change it your own text to suit your website."

    let titleTwo = "Title 2 Text"
    let subtitleTwo = "Hover and click into this text to change it your own text to suit your website."

    let titleThree = "Title 3 Text"
    let subtitleThree = "Hover and click into this text to change it your own text to suit your website."

    if(company_services?.services.length == 3){
      let services = company_services?.services
      title = "Our Services"
      subtitle = company_services.highlight

      titleOne = services[0].title
      subtitleOne = services[0].subtitle

      titleTwo = services[1].title
      subtitleTwo = services[1].subtitle

      titleThree = services[2].title
      subtitleThree = services[2].subtitle
    }

    return {
      name: "3 Icon Boxes",
      block_id: 10,
      title: title,
      subtitle: subtitle,
      anchor: "section_10",
      title_color: "#252525",
      subtitle_color: "#666666",
      settings: {
        animation,
        primary_color: "#252525",
        tertiary_color: "#ffffff",
        secondary_color: "#666666",
        quaternary_color: null,
      },
      icon_infos: [
        {
          icon: "icon icon--lg icon-Air-Balloon",
          title: titleOne,
          settings: {

          },
          subtitle: subtitleOne,
          button: null
        },
        {
          icon: "icon icon--lg icon-Speaker-2",
          title: titleTwo,
          settings: {

          },
          subtitle: subtitleTwo,
          button: null
        },
        {
          icon: "icon icon--lg icon-Astronaut",
          title: titleThree,
          settings: {

          },
          subtitle: subtitleThree,
          button: null
        }
      ],
    }
  }

  textBlock(data){
    let title = "Don't Need A Block... Delete It!"
    let body = "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.\nHover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies."
    if(data.company_about_us){
      title = "About Us"
      body = data.company_about_us
    }
    return {
      body,
      title,
      name: "Info & Description",
      title_color: "#ffffff",
      anchor: "section_9",
      block_id: 9,
      variation: "no_sidebar",
      settings: {
        animation,
        primary_color: "#ffffff",
        tertiary_color: data.color,
        secondary_color: "#ffffff",
        animation_duration: 0
      },
      text_infos: [
        {
          title: "Contact",
          settings: {

          },
          subtitle: "James Murphy"
        },
        {
          title: "Phone",
          settings: {

          },
          subtitle: "+353 1 2345678"
        },
        {
          title: "Email",
          settings: {

          },
          subtitle: "james@website.com"
        }
      ],
    }
  }


  aboutUsBlock(data){
    let title = "Don't Need A Block... Delete It!"
    let body = "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.\nHover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies."
    if(data.company_about_us){
      title = "About Us"
      body = data.company_about_us
    }

    let imageUrl = "https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600"
    if(data.similarImages.length > 1){
      imageUrl = data.similarImages[0].original
    }

    let image = {
      path: imageUrl,
      type: "photo",
      banner: imageUrl,
      favicon: imageUrl,
      original: imageUrl,
      thumbnail: imageUrl,
    }

    return {
      body,
      title,
      foreground_image: image,
      name: "About Us",
      title_color: "#ffffff",
      anchor: "section_13",
      block_id: 13,
      settings: {
        animation,
        primary_color: "#ffffff",
        tertiary_color: data.color,
        secondary_color: "#ffffff",
        animation_duration: 0
      },
    }
  }

  ourMissionBlock(data){
    let title = "Don't Need A Block... Delete It!"
    let body = "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.\nHover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies."
    if(data.company_our_mission){
      title = "Our Mission"
      body = data.company_our_mission
    }

    let imageUrl = "https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600"
    if(data.similarImages.length > 1){
      imageUrl = data.similarImages[1].original
    }

    let image = {
      path: imageUrl,
      type: "photo",
      banner: imageUrl,
      favicon: imageUrl,
      original: imageUrl,
      thumbnail: imageUrl,
    }

    return {
      body,
      title,
      foreground_image: image,
      name: "Our Mission",
      title_color: "#ffffff",
      anchor: "section_14",
      block_id: 14,
      settings: {
        animation,
        primary_color: "#ffffff",
        tertiary_color: data.color,
        secondary_color: "#ffffff",
        animation_duration: 0
      },
    }
  }


  galleryBlock(data){


    let imageUrls = [
      "https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600",
      "https://images.unsplash.com/photo-1498654896293-37aacf113fd9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600",
      "https://images.unsplash.com/photo-1508424757105-b6d5ad9329d0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600",
      "https://images.unsplash.com/photo-1485182708500-e8f1f318ba72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600"
    ]

    let variation = "slider"
    if(data.similarImages.length > 5){
      imageUrls = []
      variation = "masonry"
      for(var i = 2; i < data.similarImages.length; i++){
        imageUrls.push(data.similarImages[i].original)
      }
    }

    let images = imageUrls.map(imageUrl => {
      return {
        path: imageUrl,
        type: "photo",
        banner: imageUrl,
        favicon: imageUrl,
        original: imageUrl,
        thumbnail: imageUrl,
      }
    })

    return {
      variation,
      name: "Gallery",
      title: null,
      title_color: "#ffffff",
      subtitle_color: "#ffffff",
      anchor: "gallery",
      block_id: 24,
      settings: {
          animation,
          primary_color: "#ffffff",
          tertiary_color: "#ffffff",
          secondary_color: "#ffffff",
      },
      primary_button: null,
      images: images,
    }
  }

  mapBlock(data){
    return {
      anchor: "form",
      title: null,
      subtitle: null,
      block_id: 34,
      settings: {
        animation,
        primary_color: "#333333",
        secondary_color: "#333333",
        senary_color: data.color,
        tertiary_color: "#ffffff"
      }
    }
  }

  formBlock(data){
    return {
      anchor: "form",
      title: "Get In Touch",
      subtitle: "Please complete the form below and we'll get back to you soon.",
      title_color: "#333333",
      subtitle_color: "#333333",
      block_id: 42,
      settings: {
        animation,
        primary_color: "#333333",
        secondary_color: "#333333",
        senary_color: data.color,
        tertiary_color: "#ffffff"
      }
    }
  }

  teamBlock(data){
    return {
      name: "Team",
      block_id: 25,
      gradient: null,
      title:"Meet Our Team",
      title_color: "#252525",
      subtitle_color: "#808080",
      settings: {
        animation,
        primary_color: "#252525",
        tertiary_color: "#ffffff",
        secondary_color: "#808080",
        padding_top: 0
      },
      image_infos: [
        {
          image: {
            path: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "James Murphy",
          settings: {

          },
          subtitle: "Sales"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Jane Townsend",
          settings: {

          },
          subtitle: "Design"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Bernard Smith",
          settings: {

          },
          subtitle: "Operations"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Jake Butler",
          settings: {

          },
          subtitle: "Product"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Sylvia O Rourke",
          button: null,
          settings: {

          },
          subtitle: "Marketing"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Sarah Johnson",
          button: null,
          settings: {

          },
          subtitle: "Growth"
        }
      ],
    }
  }

  testimonialsBlock(data){
    return {
      name: "Testimonials",
      block_id: 49,
      title:"What Our Customers Say",
      title_color: "#444444",
      subtitle_color: "#444444",
      settings: {
        animation,
        primary_color: "#444444",
        tertiary_color: "#ffffff",
        secondary_color: "#444444",
        quaternary_color: "#3d85c6",
        padding_top: 0
      },
      text_infos: [
        {
          body: "One of the best companies that we have ever worked with.",
          title: "James Murphy",
          settings: {

          },
          subtitle: "CEO (ABC Ltd)"
        },
        {
          body: "One of the best companies that we have ever worked with.",
          title: "Jason Briggs",
          settings: {

          },
          subtitle: "CEO (ABC Ltd)"
        },
        {
          body: "One of the best companies that we have ever worked with.",
          title: "Sarah Johnson",
          settings: {

          },
          subtitle: "CEO (ABC Ltd)"
        }
      ],
    }
  }

  partnersBlock(data){
    return {
      name: "Logo Carousel",
      block_id: 55,
      title:"Our Clients & Partners",
      title_color: "#252525",
      subtitle_color: "#808080",
      settings: {
        animation,
        primary_color: "#252525",
        tertiary_color: "#ffffff",
        secondary_color: "#808080",
        padding_top: 0
      },
      image_infos: [
        {
          image: {
            path: "/companies/1/websites/images/original_image_59a2c103-d01e-419a-b1ba-97f5b3697e54.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_59a2c103-d01e-419a-b1ba-97f5b3697e54.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_59a2c103-d01e-419a-b1ba-97f5b3697e54.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_59a2c103-d01e-419a-b1ba-97f5b3697e54.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_bc7d03f9-88d9-4fe5-8363-dbbb1ebf20a2.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_bc7d03f9-88d9-4fe5-8363-dbbb1ebf20a2.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_bc7d03f9-88d9-4fe5-8363-dbbb1ebf20a2.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_bc7d03f9-88d9-4fe5-8363-dbbb1ebf20a2.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_8597ac83-7c8d-4c44-bfaa-a22744ab3145.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_8597ac83-7c8d-4c44-bfaa-a22744ab3145.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_8597ac83-7c8d-4c44-bfaa-a22744ab3145.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_8597ac83-7c8d-4c44-bfaa-a22744ab3145.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_cab5c388-7706-4d65-a9d6-6bfcaec6e0d5.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_cab5c388-7706-4d65-a9d6-6bfcaec6e0d5.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_cab5c388-7706-4d65-a9d6-6bfcaec6e0d5.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_cab5c388-7706-4d65-a9d6-6bfcaec6e0d5.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_e1300b46-8495-4292-a50e-a4c8adef0c36.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_e1300b46-8495-4292-a50e-a4c8adef0c36.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_e1300b46-8495-4292-a50e-a4c8adef0c36.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_e1300b46-8495-4292-a50e-a4c8adef0c36.png",
          },
          title: "Title goes here",
          settings: {
          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_56f1d764-fad5-4114-87f3-dd6a7648f289.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_56f1d764-fad5-4114-87f3-dd6a7648f289.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_56f1d764-fad5-4114-87f3-dd6a7648f289.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_56f1d764-fad5-4114-87f3-dd6a7648f289.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        }
      ],
    }
  }

  videoBlock(data){
    return {
      title: "Watch Our Video",
      subtitle: "Hover and click into this text to change it your own text to suit your website.",
      title_color: "#333333",
      subtitle_color: "#333333",
      body: "Hover and click into this text to change it your own text to suit your website.",
      block_id: 52,
      background_video: {
        url: "https://www.youtube.com/watch?v=Xn8tufsbSz0"
      },
      settings: {
        animation,
        primary_color: "#333333",
        secondary_color: "#333333",
        senary_color: data.color,
        tertiary_color: "#ffffff"
      }
    }
  }

  abstractBlock(data) {
    let { color } = data

    return {
      height: "half",
      title: "<p>Get In Touch</p>",
      subtitle: null,
      title_color: "#FFFFFF",
      subtitle_color: "#FFFFFF",
      anchor: `cb_${General.uuid()}`,
      block_id: 41,
      gradient: false,
      background_mode: "blob",
      foreground_image: null,
      primary_button: {
        title: "Speak To Us",
        url: "/#",
        settings: {
          primary_color: "#ffffff",
          secondary_color: color,
          tertiary_color: color,
          msc_enabled: true,
        },
        badge_settings: {

        }
      },
      secondary_button: null,
      video_button: null,
      link_button: null,
      settings: {
        overlay: 0,
        msc_enabled: true,
        primary_color: "#ffffff",
        secondary_color: "#FFFFFF",
        quinary_color: null,
      },
      extra: {
        blobs: {
          variation: "02a",
          color
        }
      }
    }
  }

  getProgress(){
    let {
      first_name,
      email,
      phone_number,
      agree,
      source,
      password,
      company_name,
      image,
      color,
      font,
      buttonStyle,
      logoPosition,
      bannerStyle
    } = this.data
    let progress = 30

    let values = [
        color,
        font,
        image,
        buttonStyle,
        logoPosition,
        bannerStyle
    ]

    for(var i =  0; i < values.length; i++){
      let value = values[i]
      if(value){
        progress += 10
      }
    }

    let details = [
      first_name,
      email,
      password,
      company_name,
    ]

    for(var i =  0; i < details.length; i++){
      let detail = details[i]
      if(detail){
        progress += 2
      }
    }

    if(agree){
      progress += 1
    }

    if(source){
      progress += 1
    }

    return progress
  }

  blocksInclude(key){
    let { blocks } = this.data
    return blocks.findIndex(o => o === key) > -1
  }
}
