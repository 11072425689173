import React from "react";

export default class Separator extends React.Component{
  render() {

    let className = "c-separator form-group mt-4 mb-4 row align-items-center text-center "
    if(this.props.css){
      className += this.props.css
    }

    return (
      <div 
        className={className}
      >
    		<div className="col-2 col-sm-3">
          {this.props.hr &&
      			<hr/>
          }
    		</div>
    		<div className="col-8 col-sm-6">
    			<span><strong>{this.props.text}</strong></span>
          {this.props.subtext &&
            <span class="sub-text">{this.props.subtext}</span>
          }
    		</div>
    		<div className="col-2 col-sm-3">
          {this.props.hr &&
            <hr/>
          }
    		</div>
	    </div>
    )
  }
}

Separator.defaultProps = {
  hr: true,
}
