import React from "react";

import ImageEdit from "../common/ImageEdit";

import Backend from "../../../utils/Backend";
import AuthManager from "../../../utils/AuthManager";
import Notify from "../../../utils/Notify";

export default class DomainSettings extends React.Component {
  constructor(props) {
    super(props);

    let website = { ...AuthManager.currentWebsite };
    if (website.url) {
      website.url = this._removeHTTP(website.url);
    }
    this.state = {
      website,
      loading: true,
    };
  }

  componentDidMount() {
    this._setup();
  }

  _removeHTTP(url) {
    return url.replace("http://", "").replace("https://", "");
  }

  _addHTTPS(url) {
    if (url.indexOf("https://") === -1) {
      return "https://" + url;
    }
    return url;
  }

  _setup() {
    Backend.getDNSRecords(this.state.website)
      .then((dns) => {
        this.setState({
          dns,
          loading: false,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _update() {
    let { website } = this.state;

    if (!website.url || website.url === "") {
      Notify.error("Please enter a valid url");
      return;
    }

    this.setState({ loading: true });

    let data = {
      url: this._addHTTPS(website.url),
    };

    Backend.updateWebsite(website, data)
      .then((website) => this._setup())
      .catch((error) => {
        this.setState({
          loading: false,
        });
        Notify.error(error.message);
      });
  }

  _handleChange(e) {
    let website = this.state.website;
    website[e.target.name] = e.target.value;
    this.setState({ website, error: null });
  }

  render() {
    let { dns, website, loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div
        className="kt-wizard-v2__content"
        data-ktwizard-type="step-content"
        data-ktwizard-state="current"
      >
        <div className="kt-heading kt-heading--md">Domain Settings</div>
        <div className="kt-form__section kt-form__section--first">
          <div className="kt-wizard-v2__form">
            <div className="form-group">
              <label>Default</label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={dns.default.host}
                disabled
              />
            </div>

            <br />
            <h4>Custom</h4>
            <span>Use the below to define a custom domain for your site</span>
            <br />
            <br />

            <div className="form-group">
              <label>URL</label>
              <div className="input-group">
                <span className="input-group-addon">https://</span>
                <input
                  type="text"
                  name="url"
                  className="form-control form-control-lg"
                  value={website.url}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
            </div>

            {dns.custom && (
              <div className="form-group">
                <label>CNAME</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  value={dns.custom.data}
                  disabled
                />
                <span>{`Add the above as a CNAME to your DNS records to have '${website.url}' go to your site`}</span>
              </div>
            )}
          </div>
        </div>

        <div className="kt-form__actions pull-right">
          <button
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            disabled={loading}
            onClick={() => this._update()}
          >
            Save Changes
          </button>
        </div>
      </div>
    );
  }
}
