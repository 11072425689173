import React, {Component} from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"


export default class AddressInput extends React.Component {
    constructor(props){
      super(props)
      this.state = {
          search: "",
          value: this._getValue(props.address),
      }
    }

    componentWillReceiveProps(nextProps){
      this.setState({
        ...nextProps,
        value: this._getValue(nextProps.address),
      })
    }

    _getValue(address){
      if(address){
        return address.raw
      }
      return ""
    }

    handleInputChange = e => {
        this.setState({search: e.target.value, value: e.target.value})
    }

    handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        this.setState({
          search: "",
          value: geocodedPrediction.formatted_address
        }, () => {
          this._updateAddress(geocodedPrediction)
        }
      )
    }

    _updateAddress(details){
      let data = {}
      if(details.geometry && details.geometry.location){
        const location = details.geometry.location;
        data.longitude = location.lng();
        data.latitude = location.lat();
      }

      data.raw = details.formatted_address;
      let lines = data.raw.split(", ")
      data.line_1 = lines[0]
      if(lines.length > 1){
        data.line_2 = lines[1]
      }
      if(lines.length > 2){
        data.line_3 = lines[2]
      }

      data.city = {
        state: {
          country: {

          }
        }
      }

      details.address_components.forEach(function(address_component){
        var type = address_component.types[0];
        if(type === "country"){
          data.city.state.country.name = address_component.long_name;
          data.city.state.country.short_code = address_component.short_name;
        }
        if(type === "locality" || type === "postal_town") {
          data.city.name = address_component.long_name;
        }
        else if(type === "administrative_area_level_1") {
          data.city.state.name = address_component.long_name;
        }
        else if(type === "postal_code"){
          data.postal_code = address_component.long_name;
        }
      });
      this.props.onUpdated(data)
    }

    handleNoResult = () => {

    }

    handleStatusUpdate = (status) => {

    }

    render() {
        const {search, value} = this.state
        return (
            <ReactGoogleMapLoader
                params={{
                    key: process.env.REACT_APP_GMAPS_KEY,
                    libraries: "places,geocode",
                }}
                render={googleMaps =>
                    googleMaps && (
                        <ReactGooglePlacesSuggest
                            googleMaps={googleMaps}
                            autocompletionRequest={{
                                input: search,
                                ...this.props.restrictions,
                                // Optional options
                                // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                            }}
                            // Optional props
                            onNoResult={this.handleNoResult}
                            onSelectSuggest={this.handleSelectSuggest}
                            onStatusUpdate={this.handleStatusUpdate}
                            textNoResults="No results" // null or "" if you want to disable the no results item
                        >
                            <input
                                className="form-control"
                                type="text"
                                value={value}
                                placeholder="Search a location"
                                onChange={this.handleInputChange}
                            />
                        </ReactGooglePlacesSuggest>
                    )
                }
            />
        )
    }
}

AddressInput.defaultProps = {
  restrictions: {}
}
