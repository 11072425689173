import React from 'react'

import AuthManager from '../utils/AuthManager'

import BookingEngagementCard from './components/cards/BookingEngagementCard'

import ResourcesTable from "./components/tables/ResourcesTable";


export default class Resources extends React.Component {

  constructor(props) {
    super(props)


    this.state = {
    }
  }

  render() {

    let {
    } = this.state

    return (
      <div className="row">
        <BookingEngagementCard mode="resources"/>
        <ResourcesTable website={AuthManager.currentWebsite}/>
      </div>
    )

  }
}
