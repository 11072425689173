import React from 'react'
import Modal from 'react-bootstrap/Modal'
import slug from 'limax';

import Select from '../common/Select'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from '../../../utils/Notify';

const STATUSES = [
  {
    label: "Live",
    value: true
  },
  {
    label: "Draft (Not Live)",
    value: false
  }
]

const ADD_TO_MENU_OPTIONS = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  }
]

export default class PageAddModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      loading: false,
      error: null,
      data: {
        is_live: true,
        add_to_menu: true,
      }
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _isFormValid(){
    let {
      title,
      slug,
      keywords,
      description,
    } = this.state.data

    let error = null
    if(title == null || title.length === 0){
      error = "Please enter a valid title"
    }
    else if(slug == null || slug.length ===0){
      error = "Please enter a valid Permalink"
    }

    if(error){
      this.setState({ error })
      return false
    }
    return true
  }

  _handleAddPage(){
    if(!this._isFormValid()){
      return
    }
    this._addPage()
  }

  _addPage(){
    let {
      data
    } = this.state

    this.setState({ loading: true })
    Backend.addPage(data)
    .then(page => {
      this.setState({ loading: false })
      this.props.onAdded(page)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message);
    })
  }

  render() {
    let {
      show,
      loading,
      data,
      error
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Page</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <div className="form-group row">
              <span className="error-message">{ error }</span>
            </div>
          }

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="title"
                className="form-control form-control-solid"
                value={ data.title }
                onChange={e => this._handleChange(e)}
                onBlur={() => {
                  if(!data.slug){
                    data.slug = slug(data.title)
                    this.setState({ data })
                  }
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Page Url</label>
            <div className="col-lg-9 my-auto">
              <div className="input-group">
                <span className="input-group-addon">
                  { Website.getDomain(AuthManager.currentWebsite) + "/" }
                </span>
                <input
                  type="text"
                  name="slug"
                  className="form-control form-control-solid"
                  value={ data.slug }
                  onChange={e => this._handleChange(e)}
                  onBlur={() => {
                    data.slug = slug(data.slug)
                    this.setState({ data })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Status</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={STATUSES.find(status => status.value === data.is_live)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                name="is_live"
                placeholder="Please Select An Option"
                onChange={option => {
                  data.is_live = option.value
                  this.setState({ data })
                }}
                options={STATUSES}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Add To Menu</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={ADD_TO_MENU_OPTIONS.find(option => option.value === data.add_to_menu)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                name="add_to_menu"
                placeholder="Please Select An Option"
                onChange={option => {
                  data.add_to_menu = option.value
                  this.setState({ data })
                }}
                options={ADD_TO_MENU_OPTIONS}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9 my-auto">
              <textarea
                name="description"
                className="form-control form-control-solid"
                value={ data.description }
                placeholder="Description goes here..."
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Keywords</label>
            <div className="col-lg-9 my-auto">
              <textarea
                name="keywords"
                className="form-control form-control-solid"
                value={ data.keywords }
                placeholder="Comma, separated, list"
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._handleAddPage()}
          >
            Create Page
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
