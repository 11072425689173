import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import Div from "../layouts/Div"

import FileUpload from "./FileUpload"

import LocationInput from "../common/LocationInput"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"

export default class Person extends React.Component {
  constructor(props){
    super(props)

    let maxDate = moment().subtract('years', 18).format('YYYY-MM-DD')

    this.state = {
      show: props.show,
      loading: false,
      type: props.type,
      maxDate,
      data: Person.getData(props)
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      ...nextProps,
      data: Person.getData(nextProps)
    })
  }

  static getData(props){
    let representative = props ? props.representative : false
    return (props && props.data) ? props.data : {
      address: {},
      relationship: {
        representative,
        director: false,
        executive: false,
        owner: false
      },
      verification: {
        document: {
          front: null,
          back: null,
        },
        additional_document: {
          front: null,
          back: null
        },
      }
    }
  }

  _handleChange(e){
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.props.onUpdated(data)
  }

  _handleChangeRelationship(e){
    let checked = e.target.checked
    let data = this.state.data
    data.relationship[e.target.name] = checked
    this.props.onUpdated(data)
  }

  _renderRelationship(){
    let {
      relationship
    } = this.state.data

    if(this.state.type === "individual"){
      return null
    }

    return (
      <div className="col-md-12">
        <label>Roles / Reponsibilitiees</label>
        <br/>
        <div class="kt-checkbox-list">
          <label class="kt-checkbox kt-checkbox--brand">
            <input
              type="checkbox"
              name="director"
              checked={relationship.director}
              onChange={e => this._handleChangeRelationship(e)}
            />
              Director
            <span></span>
          </label>
        </div>
        <div class="kt-checkbox-list">
          <label class="kt-checkbox kt-checkbox--brand">
            <input
              type="checkbox"
              name="executive"
              checked={relationship.executive}
              onChange={e => this._handleChangeRelationship(e)}
            />
              Executive
            <span></span>
          </label>
        </div>
        <div class="kt-checkbox-list">
          <label class="kt-checkbox kt-checkbox--brand">
            <input
              type="checkbox"
              name="owner"
              checked={relationship.owner}
              onChange={e => this._handleChangeRelationship(e)}
            />
              Owner
            <span></span>
          </label>
        </div>
      </div>
    )
  }

  _renderFileUpload(file, name, callback){

    if(file.statuc === "verified"){
      let color = file.status === "verified" ? "green" : "orange"
      let status = file.status === "verified" ? "Verified" : "Pending"
      return <h6 className="mb-3">{name}: <span style={{ color }}>{ status }</span></h6>
    }

    let {
      data
    } = this.state

    let { front, back } = file

    let status = "Pending Verification"

    return (
      <>
        <br/>
        <h6>{ name }</h6>
        <div className="row mt-1">
          <div className="col-md-6">
            <div className="form-group">
              <label>Front Photo/Scan</label>
              <FileUpload
                fileId={front}
                status={status}
                onUploaded={front => {
                  file.front = front
                  file.front_updated = true
                  callback(file)
                }}
                onRemoved={() => {
                  file.front = null
                  file.front_updated = true
                  callback(file)
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Back Photo/Scan</label>
              <FileUpload
                fileId={back}
                status={status}
                onUploaded={back => {
                  file.back = back
                  file.back_updated = true
                  callback(file)
                }}
                onRemoved={() => {
                  file.back = null
                  file.back_updated = true
                  callback(file)
                }}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  render(){
    let {
      data,
      type,
      maxDate
    } = this.state

    let showAddress = true //type !== "director"
    let showPhone = true //type !== "director"
    let showTitle = type != "individual"

    let dateOfBirth = null
    if(data.dob){
      let { year, month, day } = data.dob
      dateOfBirth = moment([year, month - 1, day]).format('YYYY-MM-DD')
    }


    let address = data.address

    let location = (address && address.line1) ? {
      ...address,
      raw: `${address.line1}, ${address.line2}, ${address.City}, ${address.state}, ${address.country}`,
      address_line_1: address.line1,
      address_line_2: address.line2,
    } : null


    return (
      <div className="col">

      <div className="kt-heading kt-heading--md">
        { this.props.title }
      </div>
      <div className="kt-form__section kt-form__section--first">
        { this.props.canDelete &&
          <div className="my-auto">
            <a
              className="btn btn-label-danger btn-outline"
              onClick={() => this.props.onDeletePressed()}
            >
              Delete
            </a>
          </div>
        }

        <div class="row">

          <div className="col-md-6">
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                name="first_name"
                className="form-control"
                value={data.first_name}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                name="last_name"
                className="form-control"
                value={data.last_name}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                name="email"
                className="form-control"
                value={data.email}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          { showPhone &&
            <div className="col-md-6">
              <div className="form-group">
                <label>Phone</label>
                <PhoneInput
                  inputClass="form-control w-100"
                  country={this.props.defaultCountry}
                  value={data.phone}
                  onChange={(value, phoneData) => {
                    data.phone = "+"+value
                    this.props.onUpdated(data)
                  }}
                  inputProps={{ autocomplete: 'off' }}
                />
              </div>
            </div>
          }

          { showTitle &&
            <div className="col-md-6">
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  value={data.relationship.title}
                  onChange={e => {
                    data.relationship.title = e.target.value
                    this.props.onUpdated(data)
                  }}
                />
              </div>
            </div>
          }

          <div className={showTitle ? "col-md-6" : "col-md-12"}>
            <div className="form-group">
              <label>Date Of Birth</label>
              <Flatpickr
                  className="form-control"
                  value={dateOfBirth}
                  options={{
                    maxDate,
                    enableTime: false,
                    noCalendar: false,
                    dateFormat: "Y-m-d",
                  }}
                  onChange={dates => {
                    let date = moment(dates[0])
                    let dob = {
                      day: parseInt(date.format("DD")),
                      month: parseInt(date.format("MM")),
                      year: parseInt(date.format("YYYY")),
                    }
                    data.dob = dob
                    this.props.onUpdated(data)
                  }}
                />
              </div>
            </div>

          { showAddress &&
            <div className="col-md-12">
              <div className="form-group">
                <LocationInput
                  className="form-control"
                  allowManualInput={true}
                  placeholder="Home Address"
                  location={location}
                  onUpdated={address => {
                    address = {
                      line1: address.address_line_1,
                      line2: address.address_line_2,
                      city: address.city,
                      state: address.state,
                      country: address.country_short,
                      country_short: address.country_short,
                      postal_code: address.postal_code
                    }
                    data.address = address
                    this.props.onUpdated(data)
                  }}
                />
              </div>
            </div>
          }

          { this._renderRelationship() }

        </div>

        <br/>

        { this._renderFileUpload(
            data.verification.document,
            "ID Verification (Passport or Drivers License)",
            file => {
              data.verification.document = file
              this.props.onUpdated(data)
            }
          )
        }

        { this._renderFileUpload(
            data.verification.additional_document,
            "Address Verification (Utility Bill)",
            file => {
              data.verification.additional_document = file
              this.props.onUpdated(data)
            }
          )
        }

      </div>
    </div>
    )
  }
}

Person.defaultProps = {
  type: "individual",
  defaultCountry: 'ie'
}
