import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

export default class ActivateShopEngagementCard extends React.Component {
  render(){
    // to do set the props for sizing here
    return (
      <EngagementCard
        { ...this.props }
        title="Activate Your Shop"
        renderContent={() => "Enter a few small details, and adjust your shop settings."}
        cta="Activate Shop"
      />
    )
  }
}
