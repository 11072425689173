import React from 'react'

import FormsTable from "./components/tables/FormsTable";


export default class Forms extends React.Component {

  constructor(props) {
    super(props)


    this.state = {
    }
  }

  render() {

    let {
      formId,
    } = this.state

    return (
      <div className="row">
        <FormsTable/>
      </div>
    )

  }
}
