import React from 'react'

import AuthManager from '../utils/AuthManager'

import RegistrationsTable from "./components/tables/RegistrationsTable";

export default class Submissions extends React.Component {

  constructor(props) {
    super(props)

    let formId = this.props.match.params.form_id

    this.state = {
      formId
    }
  }

  render() {

    let {
      formId,
    } = this.state

    return (
      <div className="row">
        <RegistrationsTable/>
      </div>
    )

  }
}
