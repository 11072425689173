import React from 'react';

const FullHeight = ({ className }) => {
    return (
        <svg className={className} width="648" height="379" viewBox="0 0 648 379" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2316_598)">
                <rect width="648" height="379" rx="20" fill="#404040" />
                <circle cx="345.5" cy="94.5" r="29.5" fill="#19E68A" />
                <g opacity="0.59">
                    <path d="M594.751 232.917L561.034 273.166C549.929 286.421 559.354 306.61 576.646 306.61H739.71C755.357 306.61 765.159 289.699 757.382 276.121L718.94 209.013C712.027 196.945 695.32 195.203 686.065 205.585L659.941 234.892C652.735 242.976 640.462 244.007 632.009 237.238L623.092 230.098C614.45 223.177 601.861 224.429 594.751 232.917Z" fill="#818181" />
                    <circle opacity="0.18" cx="24.5" cy="36.5" r="155.5" fill="#19E68A" />
                    <circle cx="24.2067" cy="37.1199" r="60.0403" fill="#19E68A" />
                </g>
                <path d="M225 202H422.5" stroke="white" stroke-width="15" stroke-linecap="round" />
                <path d="M164 236H297" stroke="#818181" stroke-width="10" stroke-linecap="round" />
                <path d="M421 236H477" stroke="#818181" stroke-width="10" stroke-linecap="round" />
                <path d="M327 236H391" stroke="#818181" stroke-width="10" stroke-linecap="round" />
                <rect x="168" y="270" width="145" height="46" rx="10" fill="#19E68A" />
                <path d="M209 292.5H271" stroke="white" stroke-width="10" stroke-linecap="round" />
                <rect x="329" y="270" width="145" height="46" rx="10" fill="#767676" />
                <path d="M370 292.5H432" stroke="white" stroke-width="10" stroke-linecap="round" />
                <path d="M312.6 79.6303C315.85 77.3853 320.15 77.3853 323.4 79.6303L335.947 88.2981L348.068 97.5529C351.207 99.9498 352.536 104.039 351.405 107.824L347.039 122.435L341.983 136.823C340.673 140.549 337.194 143.077 333.246 143.17L318 143.533L302.754 143.17C298.806 143.077 295.327 140.549 294.017 136.823L288.961 122.435L284.595 107.824C283.464 104.039 284.793 99.9498 287.932 97.5529L300.053 88.2981L312.6 79.6303Z" stroke="white" stroke-width="13" />
            </g>
            <defs>
                <clipPath id="clip0_2316_598">
                    <rect width="648" height="379" rx="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FullHeight;