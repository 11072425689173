import React from "react"

import ImageEdit from "../common/ImageEdit"

import Div from "../layouts/Div"
import SmartList from "../common/SmartList"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import ProductVariantGalleryModal from "../modal/ProductVariantGalleryModal";
import General from "../../../utils/General";
import Product from "../../../utils/Product";
import ExplainerAlert from "../alert/ExplainerAlert";

export default class Options extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      data: props.data,
      options: props.data.options.map(optionName => {return {name:optionName, values: []}}),
    }
  }

  componentDidMount() {
    this._setOptions()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => this._setOptions())
  }

  _setOptions(){
    let {
      data,
      options,
    } = this.state

    data.variants.map(variant => {
      variant.options.map((option, index) => {
        options[index].values.push({name: option})
      })
    })

    options.map(productOption => {
      productOption.values = productOption.values.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.name === value.name
          ))
      )
    })

    this.setState({data, options})
  }

  _updateVariants(productOptions){
    let {
      data
    } = this.state

    let optionNames = productOptions.map(variation => variation.name)
    let options = productOptions.map(variation => variation.values)
    options = options.map(optionArray => {
      return optionArray.map(option => option.name)
    })
    data.options = optionNames
    data.variants = Product.getOptionsCartesian(options, data)
    this.setState({ data })
  }

  _renderItem(item, index){
    let {
      data,
      options
    } = this.state

    return (
      <>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Option Name</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="variant_question"
              className="form-control form-control-solid"
              value={ item.name }
              onChange={e => {
                options[index].name = e.target.value
                this._updateVariants(options)
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Option Values</label>
          <div className="col-lg-9 my-auto">
            <SmartList
              title="Variants"
              items={options[index].values}
              itemName="Option Value"
              value={item => item.name}
              disabled={item => false}
              optionClassName={''}
              onChange={(value, item, optionIndex) => {
                options[index].values[optionIndex].name = value
                this._updateVariants(options)
              }}
              onUpdated={(values) => {
                if(values.length > 0) {
                  options[index].values = values
                }else {
                  options.splice(index, 1)
                }
                this._updateVariants(options)
              }}
              onAddItemPressed={() => {
                options[index].values.push({name: ""})
                this._updateVariants(options)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  render() {
    let {
      data,
      options,
      loading,
      showGallery,
      selectedIndex
    } = this.state

    return (
      <>
        <SmartList
          title="Variants"
          minimumItems={3}
          items={options}
          itemName="Option"
          value={item => item.name}
          disabled={item => false}
          renderItem={(item, index) => {
            return this._renderItem(item, index)
          }}
          onUpdated={options => {
            this._updateVariants(options)
          }}
          onAddItemPressed={() => {
            options.push({name: "Option", values: [{name: "Option Name"}]})
            this._updateVariants(options)
          }}
        />
        <div className="separator separator-dashed separator-border-4 mb-5 mt-5"/>
        <h4 style={{marginBottom: 33}}>Variants</h4>
        <ExplainerAlert
          text="These are all the different combinations of the options you've selected for your product. Use the table below to set the price/stock levels of all the variants you intend to sell."
        />
        <div className="dataTables_wrapper">
          <table className="table table-striped- table-hover dataTable">
            <thead>
            <tr>
              <th>Images</th>
              <th>Variant</th>
              <th>Price</th>
              <th>Stock</th>
              <th>&nbsp;</th>
            </tr>
            </thead>

            <tbody>
            {
              data.variants.map((variant, index) => {
                return (
                  <tr>
                    <td>
                      <div
                        className="col-auto"
                        style={{cursor: 'pointer'}}
                        onClick={e => {
                          this.setState({showGallery: true, selectedIndex: index})
                        }}
                      >
                        {
                          variant.images?.length > 0 ?
                            <>
                              <img className="img-v1" src={variant.images[0].thumbnail}/>
                            </>
                            :
                            <>
                              <div className="image-input-wrapper">
                                <i className="fa far fa-plus icon-3x opacity-50 bg-secondary p-20 rounded-xl"></i>
                              </div>
                            </>
                        }
                      </div>
                    </td>
                    <td>{variant.options.join(', ')}</td>
                    <td>
                      <input
                        type="number"
                        className="form-control form-control-solid"
                        value={ variant.price / 100 }
                        onChange={e => {
                          data.variants[index].price = Math.round(parseFloat(e.target.value) * 100)
                          this.setState({data})
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control form-control-solid"
                        value={ variant.stock }
                        onChange={e => {
                          data.variants[index].stock = e.target.value
                          this.setState({data})
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-md-12">
            <a
              href="javascript:;"
              className="btn btn-bold btn-sm btn-label-success float-right"
              onClick={() => {
                this.props.onUpdated(data)
              }}
            >
              Done
            </a>
          </div>
        </div>

        {
          showGallery &&
          <ProductVariantGalleryModal
            show={showGallery}
            variant={data.variants[selectedIndex]}
            onHide={() => this.setState({showGallery: false, selectedVariant: null})}
            onUpdated={images => {
              data.variants[selectedIndex].images = images
              this.setState({data, showGallery: false, selectedVariant: null})
            }}
          />
        }
      </>

    )
  }
}
