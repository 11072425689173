import React from 'react'
import Modal from 'react-bootstrap/Modal'
import slug from 'limax';

import Select from '../common/Select'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from '../../../utils/Notify';

const STATUSES = [
  {
    label: "Live",
    value: true
  },
  {
    label: "Draft (Not Live)",
    value: false
  }
]

export default class PageEditModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      page: props.page,
      loading: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let page = this.state.page
    page[e.target.name] = e.target.value
    this.setState({ page })
  }

  _updatePage(){
    let {
      page
    } = this.state

    this.setState({ loading: true })
    Backend.updatePage(page)
    .then(page => {
      this.setState({ loading: false })
      this.props.onUpdated(page)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message);
    })
  }

  render() {
    let {
      show,
      loading,
      page,
    } = this.state

    if(page == null){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Page Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="title"
                className="form-control form-control-solid"
                value={ page.title }
                disabled={ page.is_homepage }
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          { !page.is_homepage &&
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Page Url</label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <span className="input-group-addon">
                    { Website.getDomain(AuthManager.currentWebsite) + "/" }
                  </span>
                  <input
                    type="text"
                    name="slug"
                    className="form-control form-control-solid"
                    value={ page.slug }
                    disabled={ page.is_homepage }
                    onChange={e => this._handleChange(e)}
                    onBlur={() => {
                      page.slug = slug(page.slug)
                      this.setState({ page })
                    }}
                  />
                </div>
              </div>
            </div>
          }

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Status</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={STATUSES.find(status => status.value === page.is_live)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                name="is_live"
                placeholder="Please Select An Option"
                onChange={option => {
                  page.is_live = option.value
                  this.setState({ page })
                }}
                options={STATUSES}
              />
              </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9 my-auto">
              <textarea
                name="description"
                className="form-control form-control-solid"
                value={ page.description }
                placeholder="Description goes here..."
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Keywords</label>
            <div className="col-lg-9 my-auto">
              <textarea
                name="keywords"
                className="form-control form-control-solid"
                value={ page.keywords }
                placeholder="Comma separated list."
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._updatePage()}
          >
            Save Changes
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
