import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import ActivateShopBanner from './components/product/ActivateShopBanner'

import AddProductEngagementCard from './components/cards/AddProductEngagementCard'

import ProductsTable from './components/tables/ProductsTable'

import Alert from './components/alert/Alert'

export default class Products extends React.Component {
  constructor(props) {
    super(props)

    let productAdded = window.location.href.indexOf("product_added=true") > -1

    this.state = {
      productAdded,
      loading: true,
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    Backend.getShop(AuthManager.currentWebsite)
    .then(shop => {
      this.setState({ shop, loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
      //this.setState({ shop, loading: false })
    })
  }

  _renderAddProduct(){
    let {
      showCreateModel
    } = this.state

    return (
      <div className="row">
        <AddProductEngagementCard
          onCtaPressed={() => this.props.history.push(`/products/add`)}
        />
      </div>
    )
  }

  render() {
    let {
      shop,
      loading,
      productAdded,
    } = this.state

    if(loading){
      return null
    }

    if(!shop || shop.products_no == 0){
      return this._renderAddProduct()
    }

    return (
      <>
        <div className="row">
        { productAdded &&
          <div className="col-12">
            <Alert
              text="Your product was successfully added and is available for purchase!"
            />
          </div>
        }
          <ProductsTable shop={shop}/>
        </div>
        <ActivateShopBanner show={!AuthManager.currentUser.company.payments_connected}/>
      </>
    )
  }
}
