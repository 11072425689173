import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/requestResetPassword"
window.Api.User.ResetPassword         = window.Api.User.Base + "/resetPassword"
window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"

window.Api.Register = window.Api.Base + "/companies/members"


window.Api.Submissions      = window.Api.Base + "/submissions"
window.Api.Invites          = window.Api.Base + "/invites"
window.Api.Staff            = window.Api.Base + "/companies/members/staff"
window.Api.Websites         = window.Api.Base + "/websites"
window.Api.Forms            = window.Api.Base + "/forms"
window.Api.Stats            = window.Api.Base + "/stats"
window.Api.Pages            = window.Api.Base + "/pages"
window.Api.Images           = window.Api.Base + "/images"
window.Api.DNSRecords       = window.Api.Base + "/dnsrecords"
window.Api.MXRecords        = window.Api.Base + "/mxrecords"
window.Api.Shops            = window.Api.Base + "/shops"
window.Api.Connect          = window.Api.Base + "/connect"
window.Api.Tags             = window.Api.Base + "/tags"
window.Api.Products         = window.Api.Base + "/products"
window.Api.Sales            = window.Api.Base + "/sales"
window.Api.Connect          = window.Api.Base + "/connect"
window.Api.ConnectFiles     = window.Api.Base + "/connect-files"
window.Api.BankAccounts     = window.Api.Base + "/bank-accounts"
window.Api.Deliveries       = window.Api.Base + "/deliveries"
window.Api.Collections      = window.Api.Base + "/collections"
window.Api.Domains          = window.Api.Base + "/domains"
window.Api.Coupons          = window.Api.Base + "/coupons"
window.Api.Hashes           = window.Api.Base + "/hashes"
window.Api.Contacts         = window.Api.Base + "/contacts"
window.Api.Notes            = window.Api.Base + "/notes"
window.Api.Articles         = window.Api.Base + "/articles"
window.Api.CRM              = window.Api.Base + "/crm"
window.Api.SubmissionStatus = window.Api.Base + "/submission-statuses"
window.Api.Files            = window.Api.Base + "/files"
window.Api.Conversations    = window.Api.Base + "/conversations"
window.Api.Messages         = window.Api.Base + "/messages"
window.Api.Visitors         = window.Api.Base + "/visitors"
window.Api.PageViews        = window.Api.Base + "/page-views"


window.Api.Subscriptions    = window.Api.Base + "/subscriptions"
window.Api.Packages         = window.Api.Base + "/packages"
window.Api.Images           = window.Api.Base + "/images"
window.Api.ImagesSearch     = window.Api.Base + "/images-search"
window.Api.Branding         = window.Api.Base + "/branding"
window.Api.Vouchers         = window.Api.Base + "/vouchers"
window.Api.Sites            = window.Api.Base + "/sites"
window.Api.AI               = window.Api.Base + "/ai"

window.Api.Resources        = window.Api.Base + "/resources"
window.Api.Services         = window.Api.Base + "/services"
window.Api.Bookings         = window.Api.Base + "/bookings"

window.Api.ProductCategories  = window.Api.Base + "/product-categories"
window.Api.ArticleCategories  = window.Api.Base + "/article-categories"

window.Api.Leads            = window.Api.Base + "/leads"

window.Api.WebsiteNakedDomain = env.REACT_APP_WEBSITE_NAKED_DOMAIN

window.Api.StripePubishableKey = env.REACT_APP_STRIPE_PUBLISHABLE_KEY
