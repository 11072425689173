import React from "react"

import { withRouter } from "react-router-dom";

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import ServiceActionsCell from "./cells/ServiceActionsCell"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Website from '../../../utils/Website'

import moment from 'moment'

class ServicesTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      website: props.website
    }

    this.table = React.createRef()
  }

  componentDidMount(){

  }


  _getColumns() {
    let {
      shop
    } = this.state

    return [
      {
        Header: 'Service Name',
        accessor: 'name',
        width: 300,
        Cell: rowInfo => {
          let service = rowInfo.original
          return (
            <div class="d-flex align-items-center">
            { service.image &&
              <div class="symbol symbol-50 flex-shrink-0">
                  <img class="service-thumbnail" src={service.image.thumbnail} alt="photo"/>
              </div>
            }
              <div class={service.image ? "ml-3" : ""}>
                  <span>{service.name}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Date Added',
        id: 'created_at',
        accessor: service => moment(service.created_at).format('DD/MM/YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          return (
            <ServiceActionsCell
              service={rowInfo.original}
              onEditPressed={service => this.props.history.push(`/services/${service.id}`)}
            />
          )
        }
      }
    ]
  }


  render() {
    let {
      website,
      service,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Services}
          params={{
            website_id: website.id
          }}
          title="Services"
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          renderToolbar={() => {
            return (
              <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">
                  <button
                    type="button"
                    class="btn btn-brand btn-icon-sm"
                    onClick={() => this.props.history.push(`/services/add`)}
                  >
                    <i class="flaticon2-plus lh-1"></i>
                    Add Service
                  </button>
                </div>
              </div>
            )
          }}
        />
      </>
    )
  }
}

export default withRouter(ServicesTable);
