import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ReactTooltip from "react-tooltip";

import moment from 'moment'

import Switch from "react-switch";

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import ExplainerAlert from "./components/alert/ExplainerAlert"

import ConfirmModal from "./components/modal/ConfirmModal"

import AddressInput from "./components/common/AddressInput"

import Gallery from "./components/common/Gallery"
import ImageEdit from "./components/common/ImageEdit"
import TextEditor from "./components/common/TextEditor"
import Tags from "./components/common/Tags"

import AsyncSelect from './components/common/AsyncSelect'

import Div from "./components/layouts/Div"

import ProductsSelect from './components/product/Select'
import ProductCategory from './components/product/Category'
import ProductInfos from './components/product/Infos'
import ProductSection from './components/product/Section'

import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Website from "../utils/Website"
import Notify from "../utils/Notify"
import Currency from "../utils/Currency"
import Options from "./components/product/Options";

export default class Product extends React.Component {
  constructor(props){
    super(props)

    let productSlug = this.props.match.params.product_slug

    this.state = {
      productSlug,
      loading: true,
      showActiveShopModal: false,
    }
  }

  componentDidMount(){
    let {
      productSlug
    } = this.state

    Backend.getShop(AuthManager.currentWebsite)
    .then(shop => {
      this.setState({ shop })
      if(!productSlug || productSlug === "add"){
        this.setState({
          ...this._getState(null),
          loading: false,
        })
        return
      }
      return this._load()
    })
  }

  _getState(product){

    return {
      editMode: product != null,
      data: product || {
        active: true,
        shop: AuthManager.currentWebsite.id,
        price: null,
        sale_price: null,
        shipping_price: 0,
        tags: [],
        stock: 0,
        images: [],
        variants: [],
        variations: [],
        options: [],
        related_products: [],
        categories: [],
        infos: []
      },
      showShippingPrice: product ? product.shipping_price > 0 : false
    }
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _load(){
    let {
      productSlug
    } = this.state

    Backend.getProductBySlug(productSlug, AuthManager.currentWebsite.id)
    .then(product => {
      this.setState({
        ...this._getState(product),
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _isFormValid(){
    let {
      data,
      shop,
    } = this.state

    let {
      name,
      description,
      categories,
      on_sale,
      price,
      sale_price,
      stock,
      infoes
    } = data

    let error = null

    if(data.name == null || data.name.length == 0){
      error = "Please enter a valid title"
    }
    else if(!data.price || isNaN(+data.price) || +data.price < 1){
      error = `Price must be at least 1`
    }
    else if(data.on_sale && (!data.sale_price || isNaN(+data.sale_price) || +data.sale_price < 1)){
      error = `Sale price must be at least 1`
    }
    else if(data.description == null || data.description.length == 0){
      error = "Please enter a valid description"
    }
    else if(data.categories.length == 0){
      error = "Please select/create at least one category"
    }
    else if(data.featured_image == null){
      error = "Please select a main image"
    }
    else if(data.variants.length > 0 && (data.options == null || data.options.length == 0)){
      error = "Please enter a valid product option"
    }
    else if(data.variants.length == 1){
      error = "Please enter at least 2 variants"
    }
    else if(shop.stock_enabled && (isNaN(+data.stock) || +data.stock < 0)){
      error = `Stock must be at least 0`
    }

    if(data.infos){
      for(var i = 0; i < data.infos.length; i++){
        let info = data.infos[i]
        if(info.title == null || info.title.length == 0){
          error = `Product Info #${i + 1} must have a title`
        }
        else if(info.text == null || info.text.length == 0){
          error = `Product Info #${i + 1} must have a description`
        }

        if(error){
          break
        }
      }
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleCreateProduct(){
    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })

    this._handleProduct()
    .then(product => {
      if(!this.state.editMode){
        this.props.history.push("/products?product_added=true")
        Notify.success("Your Product has been added")
      }
      else{
        Notify.success("Your Product has been updated")
      }
      this.setState({
        loading: false,
        ...this._getState(product),
        showActiveShopModal: !product.payments_connected,
      })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _handleProduct(){
    let {
      data,
      editMode
    } = this.state

    if(editMode){
      return Backend.editProduct(data)
    }
    else{
      return Backend.createProduct(data)
    }
  }

  _renderSection(title, content, collapsed=false, collapsible=false, subtitle=null){

    return (
      <ProductSection
        title={title}
        subtitle={subtitle}
        content={content}
        collapsed={collapsed}
        collapsible={collapsible}
      />
    )

  }

  _renderForm(){
    let {
      data,
      shop,
      showShippingPrice
    } = this.state

    return (
      <>
        { this._renderSection("Product Details", (
            <>
              <div className="form-group row align-items-center">
                <label className="col-lg-3 col-form-label">Active</label>
                <div className="col-auto">
                  <Switch
                    onChange={checked => {
                      data.active = checked
                      this.setState({ data })
                    }}
                    checked={data.active }
                    aria-labelledby="neat-label"
                    onColor="#333333"
                    offColor="#F3F6F9"
                    checkedIcon={null}
                    uncheckedIcon={null}
                    className="c-switch"
                    />
                </div>
                <div className="col-auto">
                  Product is visible / purchasable
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Product Title</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ data.name }
                    onChange={e => this._handleChange(e)}
                  />
                </div>
              </div>


              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Product Price</label>
                <div className="col-lg-9 my-auto">
                  <div className="input-group">
                    <span className="input-group-addon">
                      { Currency.getSymbol(shop.currency) }
                    </span>
                    <input
                      type="number"
                      className="form-control form-control-solid"
                      value={ data.price / 100 }
                      onChange={e => {
                        data.price = Math.round(parseFloat(e.target.value) * 100)
                        this.setState({ data })
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">On Sale</label>
                <div className="col-lg-9 my-auto">
                  <Switch
                    className="react-switch"
                    onChange={checked => {
                      data.on_sale = checked
                      this.setState({ data })
                    }}
                    checked={data.on_sale }
                    aria-labelledby="neat-label"
                    onColor="#333333"
                    offColor="#F3F6F9"
                    checkedIcon={null}
                    uncheckedIcon={null}
                    />
                </div>
              </div>

              { data.on_sale &&
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">New Sale Price</label>
                  <div className="col-lg-9 my-auto">
                    <div className="input-group">
                      <span className="input-group-addon">
                        { Currency.getSymbol(shop.currency) }
                      </span>
                      <input
                        type="number"
                        className="form-control form-control-solid"
                        value={ data.sale_price / 100 }
                        onChange={e => {
                          data.sale_price = Math.round(parseFloat(e.target.value) * 100)
                          this.setState({ data })
                        }}
                      />
                    </div>
                  </div>
                </div>
              }

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Product Description</label>
                <div className="col-lg-9 my-auto">
                  <textarea
                    value={ data.description }
                    name="description"
                    rows={6}
                    className="form-control form-control-solid"
                    placeholder="Product description goes here..."
                    onChange={e => this._handleChange(e)}
                  />
                </div>
              </div>

              <div
                className="form-group row"
              >
                <label className="col-lg-3 col-form-label">Product Categories</label>
                <div className="col-lg-9 my-auto product-categories">
                  <span>Tag and organise your products into categories</span>
                  <div className="mb-5"/>
                  <ProductCategory
                    selectedCategories={data.categories}
                    onUpdated={category => {
                      data.category = category
                      this.setState({ data })
                    }}
                  />
                  <div/>
                </div>
              </div>
            </>
          ))
        }
        { shop.shipping_fees_type === "product" && this._renderSection("Shipping", (
            <>
              <ExplainerAlert
                text="Do you want to charge extra shipping for this product?"
              />
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Charge Shipping</label>
                <div className="col-lg-9 my-auto">
                  <Switch
                    className="react-switch"
                    onChange={checked => {
                      showShippingPrice = checked
                      data.shipping_price = showShippingPrice ? data.shipping_price : 0
                      this.setState({ showShippingPrice, data })
                    }}
                    checked={showShippingPrice}
                    aria-labelledby="neat-label"
                    onColor="#333333"
                    offColor="#F3F6F9"
                    checkedIcon={null}
                    uncheckedIcon={null}
                    />
                </div>
              </div>

              { showShippingPrice &&
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Shipping Cost</label>
                <div className="col-lg-9 my-auto">
                  <div className="input-group">
                    <span className="input-group-addon">
                      { Currency.getSymbol(shop.currency) }
                    </span>
                    <input
                      type="number"
                      name="shipping_price"
                      className="form-control form-control-solid"
                      value={ data.shipping_price / 100 }
                      onChange={e => {
                        data.shipping_price = Math.round(parseFloat(e.target.value) * 100)
                        this.setState({ data })
                      }}
                    />
                  </div>
                  <span>Add additional shipping cost on top of the product price</span>
                </div>
              </div>
              }
            </>
          ))
        }
        { shop.stock_enabled && this._renderSection("Stock", (
            <>
              <ExplainerAlert
                text="What are the stock levels for this product?"
              />
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Stock</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="number"
                    step="1"
                    className="form-control form-control-solid"
                    value={ data.stock }
                    onChange={e => {
                      data.stock = Math.round(e.target.value)
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>
            </>
          ))
        }
        { this._renderSection("Media", (
            <>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Main Image</label>
                <div className="col-lg-9 my-auto">
                  <ImageEdit
                    image={data.featured_image}
                    type="photo"
                    cropImage={true}
                    aspectRatio={1.52}
                    onUpdated={image => {
                      data.featured_image = image
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Additional Images</label>
                <div className="col-lg-9 my-auto">
                  <Gallery
                    images={data.images}
                    cropImage={true}
                    aspectRatio={1}
                    maxImages={7}
                    onUpdated={images => {
                      data.images = images
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>
            </>
          ))
        }
        { this._renderSection("Related Products", (
            <>
              <ExplainerAlert
                text="When your customers view this product, you have the option to promote other linked/related products at the bottom of the page too."
              />
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Related Products</label>
                <div className="col-lg-9 my-auto">
                  <ProductsSelect
                    product={data}
                    products={data.related_products}
                    onUpdated={related_products => {
                      data.related_products = related_products
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>
            </>
          ), true, true)
        }
        { this._renderSection("Product Options & Variants", (
          <>
            <ExplainerAlert
              text="Let your customers choose from different options and variants of your product (e.g - Size, Colour, etc)"
            />
            <Options
              data={data}
              onUpdated={data => {
                this.setState({ data })
              }}
            />
          </>
        ), true, true)
        }
      { this._renderSection("Additional Info Tabs", (
          <>
            <ExplainerAlert
              text="Add different information tabs for weight, size, notes, etc"
            />
            <ProductInfos
              infos={data.infos}
              onUpdated={infos => {
                data.infos = infos
                this.setState({ data })
              }}
            />
          </>
        ), true, true)
      }
      { this._renderSection("SEO", (
          <>
            <div
              className="form-group row"
            >
              <label className="col-lg-3 col-form-label">Keywords</label>
              <div className="col-lg-9 my-auto">
                <Tags
                  tags={data.tags}
                  subtitle="Add commas to seperate keywords"
                  onUpdated={tags => {
                    data.tags = tags
                    this.setState({ data })
                  }}
                />
              </div>
            </div>
          </>
        ), true, true)
      }
      </>
    )
  }

  render() {
    let {
      show,
      data,
      error,
      shop,
      loading,
      editMode,
      showActiveShopModal
    } = this.state

    if(!data){
      return null
    }

    let title = editMode ? "Update Product" : "Add Product"
    let saveButtonTitle = editMode ? "Update Product" : "Add Product"

    return (
      <div className="row">
				<Div
          className="col-lg-8"
          disabled={loading}
        >

          { this._renderForm() }


          <div className="kt-portlet__foot">
            <div className="kt-form__actions pull-right">

              <button
                type="button"
                className="btn btn-primary ml-3"
                disabled={loading}
                onClick={() => this._handleCreateProduct()}
              >
                { saveButtonTitle }
              </button>
            </div>
          </div>
        </Div>
        <ConfirmModal
          show={showActiveShopModal}
          title={`Activate Shop!`}
          message="Now that you've added a product, activate your shop so people can buy it!"
          loading={false}
          confirmButtonText="Activate Shop"
          onCancel={() => this.props.history.push("/products")}
          onConfirm={() => this.props.history.push("/shop")}
        />
        <ReactTooltip effect="solid"/>
      </div>
    )
  }
}
