import React from "react"

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import InvitesTable from "./InvitesTable"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Submission from '../../../utils/Submission'

import moment from 'moment'


const EMAILS = [
  {
    created_at: new Date(),
    name: "Invitation",
    sent_at: new Date(),
    sends: 100,
    opens: 30,
    visits: 5
  },
  {
    created_at: new Date(),
    name: "Re Targetting",
    sent_at: new Date(),
    sends: 95,
    opens: 35,
    visits: 20
  }
]

export default class EmailsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: true,
    }

  }

  _getColumns() {
    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: email => moment(email.created_at).format('DD/MM/YYYY')
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Sent At',
        id: 'sent_at',
        accessor: email => moment(email.sent_at).format('DD/MM/YYYY')
      },
      {
        Header: 'Sends',
        accessor: 'sends',
      },
      {
        Header: 'Opens',
        accessor: 'opens',
      },
      {
        Header: 'Visits',
        accessor: 'visits'
      },
    ]
  }

  _renderEmailData(){
    return <InvitesTable/>
  }


  render() {
    let {
      loading,
    } = this.state


    return (
      <BaseTable
        endpoint={""}
        mockData={() => EMAILS}
        title="Emails"
        columns={this._getColumns()}
        SubComponent={row => this._renderEmailData(row.original)}
      />
    )
  }
}
