import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"

export default class ImportGuestsModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onFileChange(e, key) {
    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0]
    this.setState({
      file,
      error: null
    })
  }

  _onConfirmPressed(){
    let {
      file
    } = this.state

    if(!file){
      this.setState({ error: "Please selet a file" })
      return null
    }

    this.setState({ loading: true })
    Backend.uploadInvites(file)
    .then(() => {
      this.props.onInvitesUploaded()
      this.setState({
        loading: false
      })
    })
    .catch(error => [
      this.setState({
        error: error.message,
        loading: false
      })
    ])

  }

  render() {
    let {
      email,
      first_name,
      last_name,
      error,
      loading,
      show
     } = this.state

     if(!show){
       return null
     }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Guests</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <>
              <span className="error-message">{ error }</span>
              <br/><br/>
            </>
          }

          <div className="form-group mt-3">
            <label>CSV</label>
            <input
              type="file"
              accept=".csv"
              class="form-control"
              onChange={e => this._onFileChange(e, "file")}
            />
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._onConfirmPressed()}
          >
            Add
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
