import React from 'react'
import Modal from 'react-bootstrap/Modal'

import {loadStripe} from '@stripe/stripe-js';
import {IbanElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import Div from "../layouts/Div"

import Select from '../common/Select'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

const ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
}

const CURRENCIES = [
  {
    label: "Euro",
    value: "eur"
  },
  {
    label: "Pound Sterling",
    value: "gbp"
  },
  {
    label: "US Dollar",
    value: "USD"
  }
]

const COUNTRIES = [
  {
    label: 'United Kingdom',
    value: 'GB'
  },
  {
    label: 'United States',
    value: 'US'
  }
]

const ACCOUNT_HOLDER_TYPES = [
  {
    label: "Individual / Personal",
    value: "individual"
  },
  {
    label: "Company",
    value: "company"
  }
]

export default class BankAccountModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      bankAccount:  {
        currency: "eur",
        account_holder_type: "individual"
      },
      title: props.title,
      loading: false,
    }
    this.stripePromise = loadStripe(window.Api.StripePubishableKey, { stripeAccount: props.stripeAccountId });
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let bankAccount = this.state.bankAccount
    bankAccount[e.target.name] = e.target.value
    this.setState({ bankAccount, error: null })
  }

  _isFormValid(){
    let {
      currency,
      country,
      sort_code,
      account_number,
      account_holder_type,
      account_holder_name,
    } = this.state.bankAccount

    let error = null

    if(!account_holder_name || account_holder_name.length === 0){
      error = "Please enter an 'Account Holder Name'"
    }

    if(!this._useIbanInput()){
      if(!account_number || account_number.length === 0){
        error = "Please enter an 'Account Number'"
      }
      else if(!account_number || account_number.length === 0){
        error = "Please enter an 'Account Number'"
      }
      else if(!sort_code || sort_code.length === 0){
        let name = country === "US" ? "Routing Number" : "Sort Code"
        error = `Please enter a '${name}'`
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _useIbanInput(){
    let {
      bankAccount
    } = this.state

    return bankAccount.currency === "eur"
  }

  _getBankToken(){
    let {
      bankAccount
    } = this.state

    if(this._useIbanInput()){
      const ibanElement = this.elements.getElement(IbanElement)

      return this.stripe.createToken(ibanElement, {
         currency: bankAccount.currency,
         account_holder_name: bankAccount.account_holder_name,
         account_holder_type: bankAccount.account_holder_type,
      })
    }
    else{
      return this.stripe.createToken('bank_account', {
          country: bankAccount.country,
          currency: bankAccount.currency,
          account_holder_type: bankAccount.indivvidual,
          account_number: bankAccount.account_number,
          account_holder_name: bankAccount.account_holder_name,
          routing_number: bankAccount.sort_code
        },
      )
    }
  }

  _hanndleCreeateBankAccount(){
    if(!this._isFormValid()){
      return
    }

    this._createBankAccount()
  }

  _createBankAccount(){

    this.setState({ loading: true })
    this._getBankToken()
    .then(({ error, token }) => {
      if(error){
        throw error
      }
      return Backend.saveBankAccount({ source: token.id })
    })
    .then(bankAccount => {
      this.props.onAccountUpdated(bankAccount)
      this.setState({
        loading: false,
        bankAccount: {
          account_holder_type: "individual"
        }
      })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      loading,
      bankAccount,
    } = this.state

    let sortCodeName = bankAccount.country === "US" ? "Routing Number" : "Sort Code"

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        dialogClassName="w-70"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bank Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Div disabled={loading}>
            <Elements stripe={this.stripePromise}>
              <ElementsConsumer>
                {({stripe, elements}) => {
                  this.stripe = stripe
                  this.elements = elements
                  return (
                    <>
                      <div className="form-group row">
                        <label className="col-lg-5 col-form-label">Account Holder Name:</label>
                        <div className="col-lg-7 my-auto">
                          <input
                            type="text"
                            name="account_holder_name"
                            className="form-control form-control-solid"
                            im-insert="true"
                            value={bankAccount.account_holder_name}
                            onChange={e => this._handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-5 col-form-label">Account Type:</label>
                        <div className="col-lg-7 my-auto">
                          <Select
                            value={ACCOUNT_HOLDER_TYPES.find(accountHolderType => accountHolderType.value === bankAccount.account_holder_type)}
                            className="async-select-paginate"
                            classNamePrefix="async-select-paginate"
                            name="account_holder_type"
                            placeholder="Please Select An Option"
                            onChange={option => {
                              bankAccount.account_holder_type = option.value
                              this.setState({ bankAccount })
                            }}
                            options={ACCOUNT_HOLDER_TYPES}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-5 col-form-label">Account Currency:</label>
                        <div className="col-lg-7 my-auto">
                          <Select
                            value={CURRENCIES.find(currency => currency.value === bankAccount.currency)}
                            className="async-select-paginate"
                            classNamePrefix="async-select-paginate"
                            name="currency"
                            placeholder="Please Select An Option"
                            onChange={option => {
                              bankAccount.currency = option.value
                              this.setState({ bankAccount })
                            }}
                            options={CURRENCIES}
                          />
                        </div>
                      </div>

                      { !this._useIbanInput() &&
                        <>
                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">Account Number:</label>
                            <div className="col-lg-7 my-auto">
                              <input
                                type="text"
                                name="account_number"
                                className="form-control form-control-solid"
                                im-insert="true"
                                value={bankAccount.account_number}
                                onChange={e => this._handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">Country:</label>
                            <div className="col-lg-7 my-auto">
                              <Select
                                value={COUNTRIES.find(country => country.value === bankAccount.country)}
                                className="async-select-paginate"
                                classNamePrefix="async-select-paginate"
                                name="country"
                                placeholder="Please Select An Option"
                                onChange={option => {
                                  bankAccount.country = option.value
                                  this.setState({ bankAccount })
                                }}
                                options={COUNTRIES}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">{sortCodeName}:</label>
                            <div className="col-lg-7 my-auto">
                              <input
                                type="text"
                                name="sort_code"
                                className="form-control form-control-solid"
                                im-insert="true"
                                value={bankAccount.sortCode}
                                onChange={e => this._handleChange(e)}
                              />
                            </div>
                          </div>
                        </>
                      }


                      { this._useIbanInput() &&
                        <div className="form-group row">
                          <label className="col-lg-5 col-form-label">IBAN:</label>
                          <div className="col-lg-7 my-auto">
                            <IbanElement
                              id="e"
                              options={ELEMENT_OPTIONS}
                              className="form-control form-control-solid"
                              stripe={stripe}
                            />
                          </div>
                        </div>
                      }
                    </>
                  )
                }}
              </ElementsConsumer>
            </Elements>
          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._hanndleCreeateBankAccount()}
          >
            Save
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

BankAccountModal.defaultProps = {
    title: "Add Bank Account"
}
