import React from 'react'

import AuthManager from '../utils/AuthManager'

import BookingEngagementCard from './components/cards/BookingEngagementCard'

import ServicesTable from "./components/tables/ServicesTable";

export default class Services extends React.Component {

  constructor(props) {
    super(props)


    this.state = {
    }
  }

  render() {

    let {
    } = this.state

    return (
      <div className="row">
        <BookingEngagementCard mode="services"/>
        <ServicesTable website={AuthManager.currentWebsite}/>
      </div>
    )

  }
}
