import React from 'react'
import {Modal} from 'react-bootstrap'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

const DEFAULT_CROPPER_OPTIONS = {
  aspectRatio: 1,
  autoCropArea: 1,
  rotatable: false,
  movable: true,
  scalable: false,
  zoomable: true,
  zoomOnTouch: true,
  zoomOnWheel: true,
}

export default class CropImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }
  }

  cropImage(url, type, name, options) {

    options = {
      ...DEFAULT_CROPPER_OPTIONS,
      ...options
    }

    this.setState({
      url,
      type,
      name,
      showModal: true,
    }, () => {
      this.cropper = new Cropper(document.getElementById('crop-image'), options)
    })
  }

  _crop() {
    let {
      type,
      name,
    } = this.state

    this.cropper.getCroppedCanvas({
      fillColor: '#fff',
      imageSmoothingQuality: 'high'
    })
    .toBlob((blob) => {
      var image = new File(
        [blob],
        name,
        {
          type,
          lastModified: new Date()
        }
      )

      this.setState({
        showModal: false,
      }, () => this.props.onCropped(image))

    }, type)
  }


  render() {
    return (
      <Modal
        show={this.state.showModal}
        size="xl"
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop='static'
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Crop image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="" style={{maxHeight: '70vh'}}>
            <img
              id="crop-image"
              className="image-to-crop"
              src={this.state.url}
              style={{
                height: '100%',
                width: 'auto',
                maxWidth: '100%'
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <div className="controls">
              <button
                type="button"
                className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                onClick={() => this._crop()}
              >
                Save
              </button>

            </div>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}
