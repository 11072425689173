import React, { Component } from "react";

import FilesTable from "./components/tables/FilesTable";

export class Files extends Component {
  render() {
    return (
      <div className="row">
        <FilesTable title="Files" />
      </div>
    );
  }
}

export default Files;
