import React from "react"

import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'
import Notify from "../../../utils/Notify"

export default class FileUpload extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      fileId: props.fileId,
      name: props.name,
      status: props.status,
      params: props.params,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onFileChange(e) {
    let {
      endpoint,
      params
    } = this.state

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0]
    if(file == null){
      return
    }
    this.setState({ loading: true })
    Backend.uploadFile(file, window.Api.ConnectFiles, params)
    .then(response => {
      this.setState({
        fileId: response.id,
        loading: false
      })
      this.props.onUploaded(response.id)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message);
    })

  }

  _renderFileInput(){
    let {
      fileId,
      name,
      status,
      loading
    } = this.state

    if(fileId == null){
      return (
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            accept=".pdf,.png,.jpg"
            onChange={e => this._onFileChange(e)}
          />
          <label class="custom-file-label">Choose file</label>
        </div>
      )
    }

    return (
        <>
          <div className="my-auto">
            { status }
          </div>
          <a
            href="javascript:;"
            className="btn btn-sm btn-label-danger btn-bold ml-auto"
            onClick={() => {
              this.setState({ fileId: null })
              this.props.onRemoved()
            }}
          >
              <i className="la la-trash-o align-select-centers"
                style={{
                  marginRight: 0,
                  color: 'white'
                }}
              />
          </a>
        </>
    )
  }

  render() {

    let {
      fileId,
      name,
      loading
    } = this.state

    let label = fileId ? name : "Choose file"
    return (
			<Div disabled={this.state.loading}>
        <div class="input-group">
          { this._renderFileInput() }
        </div>
			</Div>
    )
  }
}
