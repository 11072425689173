import React from 'react'

import ActivateShopForm from "../components/product/ActivateShopForm"

import Div from "../components/layouts/Div"

import Backend from "../../utils/Backend"
import Notify from "../../utils/Notify"

const CURRENCIES = [
  {
    label: "Euro",
    value: "EUR"
  },
  {
    label: "Pound Sterling",
    value: "GBP"
  },
  {
    label: "US Dollar",
    value: "USD"
  }
]

export default class Settings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      shop: props.shop
    }

    this.activateShopForm = React.createRef()
  }

  componentDidMount(){
    Backend.getPaymentForm()
    .then(paymentForm => {
      this.setState({ paymentForm })
    })
  }

  _handleUpdateShop(){

    this.setState({ loading: true })

    this.activateShopForm.current.save()
    .then(() => {
      this.setState({ loading: false })
      Notify.success("Payment Settings Updated")
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render(){
    let {
      shop,
      paymentForm,
      loading,
    } = this.state

    if(!paymentForm){
      return <p>Loading...</p>
    }

    return (
      <ActivateShopForm
        ref={this.activateShopForm}
        shop={shop}
        mode="payment"
        paymentForm={paymentForm}
        containerClassName=""
        card={false}
        title="Payment Settings"
        confirmTitle="Update"
        onConfirmPressed={() => this._handleUpdateShop()}
      />
    )
  }
}
