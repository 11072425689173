import React, {Component} from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

import CountrySelect from "./CountrySelect"

export default class LocationInput extends React.Component {
    constructor(props){
      super(props)
      this.state = {
          search: "",
          value: this._getValue(props.location),
          manualInput: this.props.manualInput,
          data: {}
      }

      this.googlePlacesSuggest = React.createRef()
    }

    componentWillReceiveProps(nextProps){
      this.setState({
        ...nextProps,
        value: this._getValue(nextProps.location),
        manualInput: this.state.manualInput
      })
    }

    _getValue(location){
      return location ? location.raw : ""
    }

    handleInputChange = e => {
        this.setState({search: e.target.value, value: e.target.value})
    }

    handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        this.setState({
          search: "",
          value: geocodedPrediction.formatted_address
        }, () => {
          this._updateLocation(geocodedPrediction)
        }
      )
    }

    _handleManualInputChange(e){
      let {
        data
      } = this.state

      data[e.target.name] = e.target.value

      data.raw = this._getRaw(data)
      data.longitude = 0
      data.latitude = 0

      this.setState({ data })
      this.props.onUpdated(data)
    }

    _getRaw(data){
      let raw = ""

      let orderedKeys = [
        "address_line_1",
        "address_line_2",
        "address_line_3",
        "city",
        "state",
        "country"
      ]

      for(var i = 0; i < orderedKeys.length; i++){
        let key = orderedKeys[i]
        let value = data[key]

        if(value){
          if(i > 0 && i < orderedKeys.length){
            raw += ", "
          }
          raw += value
        }
      }

      return raw
    }

    _updateLocation(details){
      let data = {}
      if(details.geometry && details.geometry.location){
        const location = details.geometry.location;
        data["longitude"] = location.lng();
        data["latitude"] = location.lat();
      }

      data["raw"] = details.formatted_address;
      let lines = data.raw.split(", ")
      data["address_line_1"] = lines[0]
      if(lines.length > 1){
        data["address_line_2"] = lines[1]
      }
      if(lines.length > 2){
        data["address_line_3"] = lines[2]
      }

      details.address_components.forEach(function(address_component){
          var type = address_component.types[0];
          if(type === "country"){
            data["country"] = address_component.long_name;
            data["country_short"] = address_component.short_name;
          }
          if(type === "locality" || type === "postal_town") {
              data["city"] = address_component.long_name;
          }
          else if(type === "administrative_area_level_1") {
              data["state"] = address_component.long_name;
          }
          else if(type === "postal_code"){
            data["postal_code"] = address_component.long_name;
          }
      });
      this.props.onUpdated(data)
    }

    handleNoResult = () => {

    }

    handleStatusUpdate = (status) => {

    }

    _renderInput(){
      if(this.props.showIcon){
        return (
          <div class="input-icon">
            <i class="material-icons">
              place
            </i>
              { this._renderInputField() }
          </div>
        )
      }

      return this._renderInputField()
    }

    _renderInputField(){
      const {search, value} = this.state
      return (

      <div class="form-group row mb-0">
        <label className="col-lg-3 col-form-label">{this.props.placeholder}</label>
        <div className="col-lg-9">
          <input
              type="text"
              value={value}
              className={this.props.className}
              autocomplete="false"
              onChange={this.handleInputChange}
          />
        </div>
        </div>
      )
    }

    _renderManualInputFields(){
      return (
        <>
          {this._renderManualInputField("address_line_1", 'place', 'Address Line 1')}
          {this._renderManualInputField("address_line_2", 'place', 'Address Line 2')}
          {this._renderManualInputField("city", 'location_city', 'City / Town')}

          {this._renderManualInputField("state", 'pin_drop', 'State / Province')}

          {this._renderManualInputField("postal_code", 'pin_drop', 'Post Code')}

          {this._renderManualCountrySelectField('pin_drop', 'Country')}

        </>
      )
    }

    _renderManualInputField(name, icon, placeholder){
      let {
        data
      } = this.state


      let fieldPlaceholder = placeholder
      if(!this.props.useIcon){
        fieldPlaceholder = ""
      }

      let field = (
        <input
            type="text"
            name={name}
            className={this.props.className}
            value={data[name]}
            placeholder={fieldPlaceholder}
            onChange={e => this._handleManualInputChange(e)}
        />
      )

      if(this.props.useIcon){
        return (
          <div class="form-group">
            <div class="input-icon">
              <i class="material-icons">
                { icon }
              </i>
              { field }
            </div>
          </div>
        )
      }

      return (
        <div class="form-group row">
          <label className="col-lg-3 col-form-label">{placeholder}</label>
          <div className="col-lg-9">
            { field }
          </div>
        </div>
      )
    }

    _renderManualCountrySelectField(icon, placeholder){
      let {
        data
      } = this.state

      let field = (
        <CountrySelect
          className={this.props.className}
          onSelected={country => {
            data.country = country.label
            data.country_short = country.value
            data.raw = this._getRaw(data)

            this.setState({ data })
            this.props.onUpdated(data)
          }}
        />
      )

      if(this.props.useIcon){
        return (
          <div class="form-group row">
            <div class="input-icon">
              <i class="material-icons">
                { icon }
              </i>
              { field }
            </div>
          </div>
        )
      }

      return (
        <div class="form-group row">
          <label className="col-lg-3 col-form-label">{placeholder}</label>
          <div className="col-lg-9">
            { field }
          </div>
        </div>
      )
    }

    render() {
        const {search, value, manualInput} = this.state

        if(manualInput){
          return this._renderManualInputFields()
        }

        return (
          <div class="form-group row">
            <label className="col-lg-3 col-form-label">{this.props.placeholder}</label>
            <div className="col-lg-9">
              <ReactGoogleMapLoader
                  params={{
                      key: process.env.REACT_APP_GMAPS_KEY,
                      libraries: "places,geocode",
                  }}
                  render={googleMaps =>
                      googleMaps && (
                          <ReactGooglePlacesSuggest
                              ref={this.googlePlacesSuggest}
                              googleMaps={googleMaps}
                              autocompletionRequest={{
                                  input: search,
                                  // Optional options
                                  // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                              }}
                              // Optional props
                              onNoResult={this.handleNoResult}
                              onSelectSuggest={this.handleSelectSuggest}
                              onStatusUpdate={this.handleStatusUpdate}
                              textNoResults="No resultst" // null or "" if you want to disable the no results item
                              customContainerRender={items => {
                                return (
                                  <div className="google-suggestion-list">
                                    { items.map(item => {
                                        return (
                                          <p
                                            className="google-suggestion-item"
                                            onClick={() => {
                                              this.googlePlacesSuggest.current.handleSelectPrediction(item)
                                            }}
                                          >
                                            {item.description}
                                          </p>
                                        )
                                      })
                                    }
                                    { this.props.allowManualInput &&
                                      <p
                                        className="google-suggestion-item google-suggestion-item-manual"
                                        onClick={() => this.setState({ manualInput: true })}
                                      >
                                        ENTER MY ADDRESS MANUALLY
                                      </p>
                                    }
                                  </div>
                                )
                              }}
                          >
                            <input
                                type="text"
                                value={value}
                                className={this.props.className}
                                autocomplete="false"
                                onChange={this.handleInputChange}
                            />
                          </ReactGooglePlacesSuggest>
                      )
                  }
              />
            </div>
          </div>
        )
    }
}

LocationInput.defaultProps = {
  showIcon: false,
  manualInput: false,
  allowManualInput: false,
  placeholder: "Type your address"
}
