import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

export default class CreateArticleCategoryModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      data: {
        name: ""
      }
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _isFormValid(){
    let {
      name
    } = this.state.data

    let error = null
    if(name == null || name.length === 0){
      error = "Please enter a valid name"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _handleAddArticleCategory(){
    if(!this._isFormValid()){
      return
    }
    this._addArticleCategory()
  }

  _addArticleCategory(){
    let {
      data
    } = this.state

    this.setState({ loading: true })
    Backend.addArticleCategory(data)
    .then(articleCategory => {
      data.name = ""
      this.setState({ data, loading: false })
      this.props.onAdded(articleCategory)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message);
    })
  }

  render() {
    let {
      show,
      loading,
      data,
      error
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Article Category</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Category Name</label>
            <div className="col-lg-8 my-auto">
              <input
                type="text"
                name="name"
                className="form-control form-control-solid"
                value={ data.name }
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._handleAddArticleCategory()}
          >
            Add Category
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
