import moment from "moment"

import Currency from "./Currency"

export default class Submission {
  static getAnswerValue(answer, submission){
    if(answer.option_selected){
      return answer.option_selected.text
    }
    else if(answer.options_selected.length > 0){
      return answer.options_selected.map(option => option.text).join(', ')
    }
    else if(answer.bool != null){
      return answer.bool ? "Yes" : "No"
    }
    else if(answer.value_from){
      return `${answer.value_from} - ${answer.value_to}`
    }
    else if(answer.file){
      return answer.file.url
    }
    else if(answer.image){
      return answer.image.original
    }
    else if(answer.field.type == "price"){
      let { sale } = submission
      if(!sale){
        return null
      }
      let label = Currency.format(sale.amount, sale.currency)
      return label
    }
    else if(answer.field.type == "booking"){
      let { booking } = submission
      if(!booking){
        return "-"
      }
      let datetime = moment(booking.starts_at).format("dddd, MMMM Do YYYY, h:mm a")
      let text = `${booking.service_resource.service.name} - ${datetime}`
      return text
    }
    return answer.value
  }
}
