import React from "react";

import moment from "moment";

import BaseTable from "./BaseTable";

import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

import AddNewFileModal from "../modal/AddNewFileModal";
import CopyToClipboard from "react-copy-to-clipboard";

import ConfirmModal from "../modal/ConfirmModal";

export default class FilesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _removeFile(fileId) {
    this.setState({ isLoading: true });

    Backend.removeFile(fileId)
      .then(() => {
        this.setState({
          showConfirmModal: false,
          isLoading: false,
        });
        Notify.success("File has been removed successfully.");
        this.table.current.refresh();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _getColumns() {
    let columns = [
      {
        Header: "Created",
        id: "created_at",
        width: 100,
        accessor: (file) => moment(file.created_at).format("DD/MM/YYYY"),
      },
      {
        Header: "Name",
        id: "name",
        width: 320,
        Cell: (rowInfo) => {
          let file = rowInfo.original;

          let fileName = file?.name;

          return (
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name">{fileName}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "URL",
        id: "url",
        sortable: false,
        Cell: (rowInfo) => {
          let file = rowInfo.original;

          let fileUrl = file.url;

          return <span>{fileUrl ? fileUrl : "-"}</span>;
        },
      },

      {
        Header: "Actions",
        id: "id",
        width: 130,
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let file = rowInfo.original;

          return (
            <>
              <span>
                <a
                  href="javascript:;"
                  onClick={() => window.open(file.url)}
                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  title="Download"
                >
                  <i className="la la-download" />
                </a>
                <span className="ml-1">
                  <CopyToClipboard
                    text={file.url}
                    onCopy={() => {
                      Notify.success("Copied to clipboard");
                    }}
                  >
                    <a
                      href="javascript:;"
                      className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      title="Copy"
                    >
                      <i className="la la-copy" />
                    </a>
                  </CopyToClipboard>
                </span>
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ file, showConfirmModal: true });
                  }}
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  title="Delete"
                >
                  <i className="la la-trash" />
                </a>
              </span>
            </>
          );
        },
      },
    ];

    return columns;
  }

  render() {
    let { showAddNewFileModal, showConfirmModal, file } = this.state;

    const columns = this._getColumns();

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Files}
          params={{
            website_id: AuthManager.currentWebsite.id,
          }}
          title={this.props.title}
          showSearch={false}
          noDataMessage={"No files found"}
          columns={columns}
          exportButtonsEnabled={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          searchFilterName="active"
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: column.Header === "Actions" ? 0 : 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
          renderToolbar={() => {
            return (
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-brand btn-icon-sm"
                    onClick={() => {
                      this.setState({ showAddNewFileModal: true });
                    }}
                  >
                    <i className="flaticon2-plus" />
                    Add New File
                  </button>
                </div>
              </div>
            );
          }}
        />
        {showAddNewFileModal && (
          <AddNewFileModal
            show={showAddNewFileModal}
            onHide={() => {
              this.setState({ showAddNewFileModal: false });
            }}
            onUploaded={() => this.table.current.refresh()}
          />
        )}

        <ConfirmModal
          show={showConfirmModal}
          message="This action cannot be undone"
          confirmButtonText="Delete"
          buttonClassName="danger"
          onConfirm={() => this._removeFile(file.id)}
          onCancel={() => {
            this.setState({
              showConfirmModal: false,
            });
          }}
        />
      </>
    );
  }
}

FilesTable.defaultProps = {
  title: "",
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
};
