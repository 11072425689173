
export default class Currency {
  static format(value, currencyCode="eur") {
    let dollarValue = value / 100
  	let symbol = Currency.getSymbol(currencyCode)
    let price = symbol + dollarValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return price.replace(".00", "")
  }

  static getSymbol(currencyCode){
    switch (currencyCode.toLowerCase()) {
      case 'eur': return '€'
      case 'gbp': return '£'
      case 'usd':
      case 'cad': return '$'
      default:    return ''
    }
  }
}
