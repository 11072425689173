import React from 'react'

import Div from "../components/layouts/Div"

import BankAccountModal from "../components/modal/BankAccountModal"

import Backend from "../../utils/Backend"
import Notify from "../../utils/Notify"

export default class BankDetails extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      createMode: false,
      bankAccount: null,
      stripeAccountId: props.stripeAccountId,
      showBankAccountModal: false
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    this.setState({ loadingAccount: true })
    Backend.getBankAccount()
    .then(bankAccount => {
      this.setState({
        bankAccount,
        loading: false,
      })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  _renderReadOnlyBankDetailsSection(){
    let { bankAccount } = this.state
    return (
      <>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Account Holder Name:</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              className="form-control"
              im-insert="true"
              value={bankAccount.account_holder_name}
              disabled
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Account Number:</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              className="form-control form-control-solid"
              im-insert="true"
              value={`**** **** ${bankAccount.last4}`}
              disabled
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Bank:</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              className="form-control form-control-solid"
              im-insert="true"
              value={bankAccount.bank_name}
              disabled
            />
          </div>
        </div>
      </>
    )
  }

  _renderContent(){
    let { bankAccount } = this.state

    if(bankAccount){
      return this._renderReadOnlyBankDetailsSection()
    }

    return (
      <div>
        <p>
          Add a Bank Account in order to enable payouts/payments.
        </p>
      </div>
    )
  }


  render(){
    let {
      loading,
      bankAccount,
      stripeAccountId,
      showBankAccountModal
    } = this.state

    let buttonTitle = bankAccount ? "Update Bank Account" : "Add Bank Account"

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
          spinner={true}
        >
          <div className="kt-heading kt-heading--md">
            Bank Details
          </div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">
              { this._renderContent() }
            </div>
          </div>
        </Div>
        <div className="kt-form__actions">
          <a
            href="#"
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            onClick={e => this.setState({ showBankAccountModal: true })}>
              { buttonTitle }
            </a>
        </div>
        <BankAccountModal
          show={showBankAccountModal}
          stripeAccountId={stripeAccountId}
          onCancel={() => this.setState({ showBankAccountModal: false})}
          onAccountUpdated={bankAccount => {
            this.setState({
              bankAccount,
              showBankAccountModal: false
            })
            Notify.success("Bank Account Saved")
          }}
        />
      </>
    )
  }
}
