import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"

export default class SuccessModal extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      type,
      title,
      message,
      loading,
      buttonClassName,
      confirmButtonText,
    } = this.props

    return (
      <Modal
        show={show}
        onHide={() => {
          if(!loading){
            this.props.onHide()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <p>{ message }</p>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className={`btn btn-${buttonClassName}`}
            disabled={loading}
            onClick={() => this.props.onConfirm()}
          >
            { confirmButtonText }
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

SuccessModal.defaultProps = {
    title: 'Are you sure?',
    message: null,
    loading: false,
    buttonClassName: 'primary',
    confirmButtonText: 'Confirm',
    cancelButtonText: "Cancel"
}
