import moment from "moment";

import React, { Component } from "react";

import { GiftedChat, Bubble } from "react-gifted-chat";

import TextareaAutosize from 'react-autosize-textarea';

import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";
import Event from "../../../utils/Event";
import Backend from "../../../utils/Backend";

import LazyLoader from "../common/LazyLoader";

import VisitorForm from "../visitor/VisitorForm";

import PageViewsList from "../PageViewsList";

import sendIcon from "../../../assets/media/icons/png/send-icon.png";

export class Conversation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitor: props.visitor,
      conversation: props.conversation,
      messages: [],
      text: "",
      open: false,
      isFormOpen: false,
      isChatOpen: !props.isVisitorsPage,
      isVisitorsPage: props.isVisitorsPage,
    };

    this.lazyLoader = React.createRef();
    this.messagesEnd = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
      isChatOpen: !nextProps.isVisitorsPage,
      isFormOpen: false,
    });
  }

  componentDidMount() {
    this.handleMessage = this._handleMessage.bind(this);

    Event.on("MESSAGE", this.handleMessage);
  }

  componentWillUnmount() {
    Event.off("MESSAGE", this.handleMessage);
  }

  _handleMessage(data) {
    let { visitor, conversation } = this.state;

    const matchingVisitorToken =
      data.visitor_token && data.visitor_token == visitor?.token;
    const matchingConversationToken =
      data.conversation && data.conversation == conversation?.id;
    if (matchingVisitorToken || matchingConversationToken) {
      this._onMessage(data);
    } else {
      Event.emit("SHOW_MESSAGE", data);
    }
  }

  _onMessage(data) {
    if (data.sender.user?.id === AuthManager.currentUser.user.id) {
      return;
    }

    data.user = true;
    let { messages } = this.state;
    messages.unshift(data);
    this.setState(
      {
        messages,
        open: true,
      },
      () => this._scrollToBottom()
    );
  }

  _parseMessages(messages) {
    return messages.map((message) => {
      let senderId =
        message.sender.user?.id || `v_${message.sender.visitor.id}`;

      return {
        _id: message.id,
        text: message.text,
        createdAt: message.created_at,
        user: {
          _id: senderId,
          isVisitor: message.sender?.user != null,
          messageName:
            message.sender.user?.first_name ||
            message.sender.visitor?.first_name,
          name:
            message.sender.user?.first_name ||
            `${
              message.sender.visitor?.first_name || message.sender.visitor.id
            }`,
          avatar: null,
        },
      };
    });
  }

  _scrollToBottom() {
    if(this.messagesEnd && this.messagesEnd.current){
      this.messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  _sendMessage() {
    let { text, messages, visitor, conversation } = this.state;

    let data = {
      text,
    };

    if (conversation) {
      data.conversation = conversation.id;
    }

    if (visitor?.token) {
      data.visitor_token = visitor.token;
    }

    Backend.sendMessage(data);

    messages.unshift({
      text: data.text,
      id: General.uuid(),
      sender: AuthManager.currentUser,
      createdAt: moment().toDate(),
    });
    this.setState(
      {
        messages,
        text: "",
      },
      () => this._scrollToBottom()
    );
  }

  _renderRightButton(title, status, onClickCallBack) {
    return (
      <div className="kt-chat__icon">
        <button
          href="javascript:;"
          className={`btn btn-${status} btn-md btn-upper btn-bold kt-chat__reply`}
          onClick={() => onClickCallBack()}
        >
          <span className={`text-white font-weight-bold`}>{title}</span>
        </button>
      </div>
    );
  }

  _renderProfileRightButton() {
    return this._renderRightButton("Visitor Info", "info", () => {
      this.setState({ isFormOpen: true });
    });
  }

  _renderChatRightButton() {
    return this._renderRightButton("Live Chat", "success", () => {
      this.setState({ isChatOpen: true });
    });
  }

  _renderCloseRightButton() {
    return this._renderRightButton("Close", "brand", () => {
      this.setState({
        isChatOpen: !this.state.isVisitorsPage,
        isFormOpen: false,
      });
    });
  }

  _renderBubble(props) {
    let currentMessageDate = moment(props.currentMessage.createdAt).fromNow();

    let sender = props.currentMessage.user.name.includes(
      props.currentMessage.user.messageName
    )
      ? props.currentMessage.user.name
      : `Visitor #${props.currentMessage.user.name}`;

    let isCurrentUser =
      AuthManager.currentUser.user.id == props.currentMessage.user._id;

    let position = isCurrentUser ? "kt-chat__message--right" : "";
    let position2 = isCurrentUser ? "justify-content-end" : "";

    let renderSender =
      props.previousMessage?.user?._id != props.currentMessage.user._id;

    let linkStyles = {
      color: "#6c7293 !important",
      textDecorationLine: "none !important",
    };

    return (
      <>
        <div className={`kt-chat__message mb-0 ${position}`}>
          {renderSender && (
            <div
              className={`kt-chat__user d-flex align-items-center ${position2}`}
            >
              {isCurrentUser ? (
                <>
                  <span className="kt-chat__datetime">
                    {currentMessageDate}
                  </span>
                  <span className="kt-chat__username">{sender}</span>
                </>
              ) : (
                <>
                  <span className="kt-chat__username">{sender}</span>
                  <span className="kt-chat__datetime">
                    {currentMessageDate}
                  </span>
                </>
              )}
            </div>
          )}
          <Bubble
            {...props}
            linkStyle={{
              left: linkStyles,
              right: linkStyles,
            }}
            textStyle={{
              left: {
                color: "#6c7293",
                fontSize: "14px",
                fontFamily: "Poppins,Helvetica,sans-serif",
                fontWeight: "500",
                wordBreak: "break-word",
                marginTop: "0",
                marginRight: "0",
                marginBottom: "0",
                marginLeft: "0",
              },
              right: {
                color: "#6c7293",
                fontSize: "14px",
                fontFamily: "Poppins,Helvetica,sans-serif",
                fontWeight: "500",
                wordBreak: "break-word",
                marginTop: "0",
                marginRight: "0",
                marginBottom: "0",
                marginLeft: "0",
              },
            }}
            wrapperStyle={{
              left: {
                backgroundColor: "rgba(10,187,135,.1)",
                display: "inline-block",
                paddingTop: "10px",
                paddingRight: "45px",
                paddingBottom: "10px",
                paddingLeft: "20px",
                borderRadius: "4px",
                marginTop: "0",
                marginRight: "8px",
                marginBottom: "0",
                marginLeft: "0",
              },
              right: {
                backgroundColor: "rgba(93, 120, 255, 0.1)",
                display: "inline-block",
                paddingTop: "10px",
                paddingRight: "20px",
                paddingBottom: "10px",
                paddingLeft: "45px",
                borderRadius: "4px",
                marginTop: "0",
                marginRight: "0",
                marginBottom: "0",
                marginLeft: "8px",
              },
            }}
          />
        </div>
      </>
    );
  }

  _renderFooter(props) {
    return (
      <>
        <div />
      </>
    );
  }

  _renderTime(props) {
    return null;
  }

  _renderDay(props) {
    let currentMessageDate = moment(props.currentMessage.createdAt);

    let previousMessageDate = props.previousMessage?.createdAt
      ? moment(props.previousMessage?.createdAt)
      : null;

    if (
      previousMessageDate &&
      currentMessageDate.isSame(previousMessageDate, "date")
    ) {
      return null;
    }

    let formattedDate = currentMessageDate.format("DD MMM YYYY");

    if (currentMessageDate.isSame(moment(), "date")) {
      formattedDate = "Today";
    }

    return <div className="m-auto">{formattedDate}</div>;
  }

  _renderInputToolbar(props) {
    let { text } = this.state;

    return (
      <div className="kt-portlet__foot chat-input">
        <div className="kt-chat__input row">
          <div className="kt-chat__editor col">
            <TextareaAutosize
              type="text"
              placeholder="Type a message"
              value={text}
              onChange={(e) => this.setState({ text: e.target.value })}
             />
          </div>

          <div className="send">
            <button
              type="button"
              className="send-icon"
              disabled={!text.trim()}
              style={{
                opacity: !text.trim() ? 0.4 : 0.7,
              }}
              onClick={() => this._sendMessage()}
            >
              <img src={sendIcon} alt="Send" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  _renderGiftedChat() {
    let { messages, visitor, conversation } = this.state;

    let visitorToken = visitor?.token;

    let endpoint = window.Api.Messages;

    if (visitorToken) {
      endpoint += `?visitor_token=${visitorToken}`;
    } else {
      endpoint += `?with_admin=true&conversation_id=${conversation.id}`;
    }

    return (
      <div className={`kt-chat__messages`}>
        <LazyLoader
          ref={this.lazyLoader}
          headers={AuthManager.getHeaders()}
          endpoint={endpoint}
          onItemsUpdated={(messages) => {
            this.setState({ messages, isInitialLoading: false });
          }}
        >
          <GiftedChat
            messages={this._parseMessages(messages)}
            onSend={() => this._sendMessage()}
            user={{
              _id: AuthManager.currentUser.user.id,
            }}
            renderAvatarOnTop={true}
            renderAvatar={(props) => this._renderAvatar(props)}
            renderBubble={(props) => this._renderBubble(props)}
            renderFooter={(props) => this._renderFooter(props)}
            renderTime={(props) => this._renderTime(props)}
            renderDay={(props) => this._renderDay(props)}
            renderInputToolbar={(props) => this._renderInputToolbar(props)}
            loadEarlier={this.lazyLoader.current?.hasMore()}
            onLoadEarlier={() => {
              if (this.lazyLoader.current) {
                this.lazyLoader.current._loadMore();
              }
            }}
          />
        </LazyLoader>

        <div ref={this.messagesEnd} />
      </div>
    );
  }

  _renderHeader(title, status, rightButtons = null) {
    return (
      <div className="kt-portlet__head">
        <div className="kt-chat__head">
          <div className="kt-chat__left">
            <div className="kt-chat__label">
              <a href="javascript:;" className="kt-chat__title">
                {title}
              </a>
              <span className="kt-chat__status mb-1">
                <span className="text-muted">{status}</span>
              </span>
            </div>
          </div>

          <div className="kt-chat__center"></div>

          <div className="kt-chat__right">
            {rightButtons && (
              <div className="row">
                <div className="col-4" />
                {rightButtons}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  _renderBody(content) {
    return (
      <div className="kt-portlet__body">
        <div className="kt-scroll kt-scroll--pull" data-mobile-height="300">
          {content}
        </div>
      </div>
    );
  }

  _renderAdminChat() {
    let { conversation } = this.state;

    if (!conversation || !conversation.with_admin) {
      return null;
    }

    return (
      <>
        {this._renderHeader("Florencia")}
        {this._renderBody(this._renderGiftedChat())}
      </>
    );
  }

  _shouldRenderCloseRightButton() {
    let { isFormOpen, isChatOpen, isVisitorsPage } = this.state;

    if (isFormOpen) {
      return true;
    }

    if (isChatOpen && isVisitorsPage) {
      return true;
    }

    return false;
  }

  render() {
    let { messages, visitor, isFormOpen, isChatOpen, isVisitorsPage } =
      this.state;

    if (!visitor) {
      return this._renderAdminChat();
    }

    let title = visitor?.first_name || `Visitor: #${visitor?.id}`;
    let rightButtons = (
      <>
        {!this._shouldRenderCloseRightButton() && (
          <>
            {isVisitorsPage && (
              <div className="col p-1">{this._renderChatRightButton()}</div>
            )}

            <div className="col p-1">{this._renderProfileRightButton()}</div>
          </>
        )}

        {this._shouldRenderCloseRightButton() && (
          <div className="col p-1">{this._renderCloseRightButton()}</div>
        )}
      </>
    );

    let body = null;
    if (isFormOpen) {
      body = (
        <VisitorForm
          visitor={visitor}
          onUpdated={(visitor) => {
            messages = messages.map((message) => {
              if (message.sender.visitor?.id == visitor.id) {
                message.sender.visitor = visitor;
              }

              return message;
            });

            this.setState({ visitor, messages, isFormOpen: false });

            this.props.onVisitorUpdated(visitor);
          }}
          onCancel={() =>
            this.setState({
              isFormOpen: false,
            })
          }
        />
      );
    } else if (isChatOpen) {
      body = this._renderGiftedChat();
    } else if (isVisitorsPage) body = <PageViewsList visitor={visitor} />;

    return (
      <>
        {this._renderHeader(title, visitor.status, rightButtons)}
        {this._renderBody(body)}
      </>
    );
  }
}

export default Conversation;
