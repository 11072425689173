import React from "react"

import ImageEdit from "../common/ImageEdit"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

export default class DomainSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      website: { ...AuthManager.currentWebsite }
    }
  }

  componentDidMount(){
    this._setup()
  }


  _setup(){
    Backend.getMXRecords(this.state.website)
    .then(mxRecords => {
      this.setState({
        mxRecords,
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message);
    })
  }

  _renderContent(mxRecords){
    if(mxRecords == null){
      return (
        <p>Add a custom domain in 'Domain Settings' to be able to verify your email</p>
      )
    }

    return (
      <>
        <span>Add the below to your DNS records to have emails sent from your site come from your custom domain</span>
        <br/><br/>
        <div className='row'>
          <div className='col-md-2'>
            <h5>TYPE</h5>
          </div>
          <div className='col-md-5'>
            <h5>HOST</h5>
          </div>
          <div className='col-md-5'>
            <h5>POINTS TO</h5>
          </div>
        </div>
        { mxRecords.map(mxRecord => {
            return (
              <div className='row'>
                <div className='col-md-2'>
                  <div className="form-control-lg pl-0">{ mxRecord.type.toUpperCase() }</div>
                </div>
                <div className='col-md-5'>
                  <div className="form-control-lg pl-0">{ mxRecord.host }</div>
                </div>
                <div className='col-md-5'>
                  <div className="form-control-lg pl-0">{ mxRecord.data }</div>
                </div>
              </div>
            )}
          )
        }
      </>
    )
  }

  render() {
    let {
      mxRecords,
      website,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
        <div className="kt-heading kt-heading--md">Email Settings</div>
        <div className="kt-form__section kt-form__section--first">
          <div className="kt-wizard-v2__form">

            { this._renderContent(mxRecords) }

          </div>
        </div>
      </div>
    )
  }
}
