import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ReactTooltip from "react-tooltip";

import moment from 'moment'

import Switch from "react-switch";

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import ExplainerAlert from "./components/alert/ExplainerAlert"

import ConfirmModal from "./components/modal/ConfirmModal"

import AddressInput from "./components/common/AddressInput"

import Gallery from "./components/common/Gallery"
import ImageEdit from "./components/common/ImageEdit"
import TextEditor from "./components/common/TextEditor"
import Tags from "./components/common/Tags"

import AsyncSelect from './components/common/AsyncSelect'

import Div from "./components/layouts/Div"

import ArticleCategory from './components/article/Category'
import ArticleSection from './components/product/Section'

import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Website from "../utils/Website"
import Notify from "../utils/Notify"
import Currency from "../utils/Currency"

export default class Article extends React.Component {
  constructor(props){
    super(props)

    let articleSlug = this.props.match.params.article_slug

    this.state = {
      articleSlug,
      loading: true,
    }
  }

  componentDidMount(){
    let {
      articleSlug
    } = this.state

    if(articleSlug){
      this._load()
    }
    else{
      this.setState({
        ...this._getState(null),
        loading: false
      })
    }
  }

  _getState(article){

    return {
      editMode: article != null,
      data: article || {
        active: true,
        status: "active",
        website: AuthManager.currentWebsite.id,
        tags: [],
        categories: [],
        published_at: moment().toDate(),
        gallery: {
          title: "",
          subtitle: "",
          images: []
        }
      },
    }
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _load(){
    let {
      articleSlug
    } = this.state

    Backend.getArticleBySlug(articleSlug, AuthManager.currentWebsite.id)
    .then(article => {
      this.setState({
        ...this._getState(article),
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _isFormValid(){
    let { data } = this.state

    let {
      banner,
      title,
      categories,
      body,
      thumbnail,
      gallery
    } = data

    let error = null
    let isValid = true


    if(!title || title == ""){
      error = "Please enter a title"
    }

    else if(!body || body == ""){
      error = "Please enter a body"
    }

    else if(categories.length == 0){
      error = "Please select a category"
    }

    else if(!banner){
      error = "Please select a banner image"
    }

    else if(!thumbnail){
      error = "Please select a thumbnail image"
    }

    if(error){
      isValid = false
      Notify.error(error)
    }

    return isValid
  }

    _updateArticle(){
      let {
        data
      } = this.state

      if(!this._isFormValid()){
        return
      }

      this.setState({ loading: true })
      Backend.updateArticle(data)
      .then(article => {
        this.setState({ loading: false })
        Notify.success("Article updated!")
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
    }

    _saveArticle(){
      let {
        data
      } = this.state

      if(!this._isFormValid()){
        return
      }

      this.setState({ loading: true })
      Backend.saveArticle(data)
      .then(article => {
        this.setState({ loading: false })
        AuthManager.currentWebsite.articles_no += 1
        this.props.history.push("/articles?article_added=true")
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
    }

  _handleArticle(){
    let {
      data,
      editMode
    } = this.state

    if(editMode){
      return Backend.editArticle(data)
    }
    else{
      return Backend.createArticle(data)
    }
  }

  _renderSection(title, content, collapsed=false, collapsible=false, subtitle=null){

    return (
      <ArticleSection
        title={title}
        subtitle={subtitle}
        content={content}
        collapsed={collapsed}
        collapsible={collapsible}
      />
    )

  }

  _renderForm(){
    let {
      data,
      shop,
      showShippingPrice
    } = this.state

    return (
      <>
        { this._renderSection("Article Details", (
            <>
              <div className="form-group row align-items-center">
                <label className="col-lg-3 col-form-label">Active</label>
                <div className="col-auto">
                  <Switch
                    onChange={checked => {
                      data.status = checked ? "active" : "draft"
                      this.setState({ data })
                    }}
                    checked={data.status === "active"}
                    aria-labelledby="neat-label"
                    onColor="#333333"
                    offColor="#F3F6F9"
                    checkedIcon={null}
                    uncheckedIcon={null}
                    className="c-switch"
                    />
                </div>
                <div className="col-auto">
                  Article is visible
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Article Title</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="title"
                    className="form-control form-control-solid"
                    value={ data.title }
                    onChange={e => this._handleChange(e)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Article Body</label>
                <div className="col-lg-9 my-auto">
                  <TextEditor
                    text={ data.body }
                    className="form-control form-control-lg"
                    placeholder="Article body goes here..."
                    onUpdated={text => {
                      data.body = text
                      this.setState({ data, error: null})
                    }}
                  />
                </div>
              </div>

              <div
                className="form-group row"
              >
                <label className="col-lg-3 col-form-label">Article Categories</label>
                <div className="col-lg-9 my-auto article-categories col-form-label">
                  <span>Tag and organise your articles into categories</span>
                  <div className="mb-4"/>
                  <ArticleCategory
                    selectedCategories={data.categories}
                    onUpdated={categories => {
                      data.categories = categories
                      this.setState({ data })
                    }}
                  />
                  <div/>
                </div>
              </div>

              <div className="form-group row align-items-center">
                <label className="col-lg-3 col-form-label">Featured</label>
                <div className="col-auto">
                  <Switch
                    onChange={checked => {
                      data.featured = checked
                      this.setState({ data })
                    }}
                    checked={data.featured}
                    aria-labelledby="neat-label"
                    onColor="#333333"
                    offColor="#F3F6F9"
                    checkedIcon={null}
                    uncheckedIcon={null}
                    className="c-switch"
                    />
                </div>
                <div className="col-auto">
                  Article is featured on blog page
                </div>
              </div>

              <div className="form-group row align-items-center">
                <label className="col-lg-3 col-form-label">Published At</label>
                <div className="col-auto">
                <Flatpickr
                    className="form-control"
                    value={data.published_at}
                    options={{
                      enableTime: false,
                      noCalendar: false,
                      dateFormat: "d/m/Y",
                    }}
                    onChange={dates => {
                      data.published_at = dates[0]
                      this.setState({data})
                    }}
                  />
                </div>
              </div>
            </>
          ))
        }
        { this._renderSection("Media", (
            <>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Banner Image</label>
                <div className="col-lg-9 my-auto">
                  <ImageEdit
                    image={data.banner}
                    type="photo"
                    imagePicker={true}
                    cropImage={true}
                    aspectRatio={3.2}
                    onUpdated={image => {
                      data.banner = image
                      if(data.thumbnail == null){
                        data.thumbnail = image
                      }
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Thumbnail Image</label>
                <div className="col-lg-9 my-auto">
                  <ImageEdit
                    image={data.thumbnail}
                    type="photo"
                    imagePicker={true}
                    cropImage={true}
                    aspectRatio={1}
                    onUpdated={image => {
                      data.thumbnail = image
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Additional Images</label>
                <div className="col-lg-9 my-auto">
                  <Gallery
                    images={data.gallery.images}
                    imagePicker={true}
                    cropImage={true}
                    aspectRatio={1}
                    maxImages={12}
                    onUpdated={images => {
                      data.gallery.images = images
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>
            </>
          ))
        }

      { this._renderSection("Tags", (
          <>
            <ExplainerAlert
              text="Add tags to link this article to others in the related articles section"
            />
            <div
              className="form-group row"
            >
              <label className="col-lg-3 col-form-label">Tags</label>
              <div className="col-lg-9 my-auto">
                <Tags
                  tags={data.tags}
                  subtitle="Add commas to seperate tags"
                  onUpdated={tags => {
                    data.tags = tags
                    this.setState({ data })
                  }}
                />
              </div>
            </div>
          </>
        ), true, true)
      }
      </>
    )
  }

  render() {
    let {
      show,
      data,
      error,
      shop,
      loading,
      editMode,
      showActiveShopModal
    } = this.state

    if(!data){
      return null
    }

    let title = editMode ? "Update Article" : "Add Article"

    return (
      <div className="row">
				<Div
          className="col-lg-8"
          disabled={loading}
        >

          { this._renderForm() }

          <div className="kt-portlet__foot">
            <div className="kt-form__actions pull-right">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.props.history.push("/articles")}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary ml-3"
                disabled={loading}
                onClick={() => {
                  if(editMode){
                    this._updateArticle()
                  }else{
                    this._saveArticle()
                  }
                }}
              >
                Create Article
              </button>
            </div>
          </div>
        </Div>
      </div>
    )
  }
}
