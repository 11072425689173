import React from 'react'

import Div from "../components/layouts/Div"

import BankAccountModal from "../components/modal/BankAccountModal"

import Backend from "../../utils/Backend"
import Notify from "../../utils/Notify"

export default class BoipaDetails extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount(){
    this._load()
  }

  isFormValid(){
    let {
      data,
    } = this.state

    let {
      merch_id,
      brand_id,
      password
    } = data.terminals?.default || {}

    let error = null

    if(!merch_id){
      error = "Please enter a Merchant ID"
    }
    else if(!brand_id){
      error = "Please enter a Brand ID"
    }
    else if(!password){
      error = "Please enter a Password"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }


  _load(){
    this.setState({ loadingAccount: true })
    Backend.getConnectData()
    .then(data => {
      this.setState({
        data,
        loading: false,
      })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  save(){
    let { data } = this.state

    if(!this.isFormValid()){
      return false
    }

    this.setState({ loading: true })
    Backend.updateConnectData(data)
    .then(data => {
      this.setState({ data, loading: false })
      Notify.success("Settings Updated");
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _renderKYC(){
    let { data } = this.state

    let terminals = data.terminals || {}
    data.terminals = terminals

    let defaultTerminal = terminals.default || {}
    data.terminals.default = defaultTerminal

    return (
      <>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Merchant ID</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="merch_id"
              className="form-control"
              defaultValue={ defaultTerminal.merch_id }
              onChange={e => {
                defaultTerminal.merch_id = e.target.value || null
                data.terminals.default = defaultTerminal
                this.setState({ data })
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Brand ID</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="brand_id"
              className="form-control"
              defaultValue={ defaultTerminal.brand_id }
              onChange={e => {
                defaultTerminal.brand_id = e.target.value || null
                data.terminals.default = defaultTerminal
                this.setState({ data })
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Password</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="password"
              className="form-control"
              defaultValue={ defaultTerminal.password }
              onChange={e => {
                defaultTerminal.password = e.target.value || null
                data.terminals.default = defaultTerminal
                this.setState({ data })
              }}
            />
          </div>
        </div>
      </>
    )
  }

  render(){
    let {
      loading,
      data,
    } = this.state

    if(!data){
      return null
    }

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
          spinner={true}
        >
          <div className="kt-heading kt-heading--md">
            Bank Details
          </div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              { this._renderKYC() }

            </div>
          </div>
        </Div>
        <div className="kt-form__actions pull-right">
            <button
              className="btn btn-brand btn-mdbtn-tall btn-wide kt-font-bold kt-font-transform-u"
              onClick={e => {
                e.preventDefault()
                this.save()
              }}
            >
              Save
            </button>
        </div>
      </>
    )
  }
}
