import React from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";

import LazyLoadingList from "./components/common/LazyLoadingList";

import Backend from "../utils/Backend";
import AuthManager from "../utils/AuthManager";
import Website from "../utils/Website";
import Notify from "../utils/Notify";

import PageEditModal from "./components/modal/PageEditModal";
import PageAddModal from "./components/modal/PageAddModal";
import PageDeleteModal from "./components/modal/PageDeleteModal";

import Alert from "./components/alert/Alert";

export default class Pages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.list = React.createRef();
  }

  _handlePageClicked(page) {
    this.setState({ loadingHash: true });
    let website = AuthManager.currentWebsite;

    let url = Website.getSiteUrl(website);
    if (page.slug !== "index") {
      url += "/" + page.slug;
    }
    url += "?edit=true";

    let editWebsiteTab = window.open();
    Backend.createLoginHash()
      .then((hash) => {
        editWebsiteTab.location.href = url + "&h=" + hash.value
        this.setState({ loadingHash: false });
      })
      .catch((error) => {
        window.open(url);
        this.setState({ loadingHash: false });
      });
  }

  _renderPage(page, index) {
    let status = page.is_live ? "Published" : "Inactive";
    let label = page.is_live ? "success" : "info";

    let shareToken = page.share_token;

    let website = AuthManager.currentWebsite;

    let shareTokenUrl = Website.getSiteUrl(website);
    if (page.slug !== "index") {
      shareTokenUrl += "/" + page.slug;
    }

    if (shareToken) {
      shareTokenUrl += "?st=" + shareToken;
    }

    let viewsNo = page.views_no;
    if (page.is_homepage) {
      viewsNo += 1;
    }

    return (
      <div className="col-md-3">
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head kt-portlet__head--noborder">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title"></h3>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit-y kt-margin-b-40">
            <div className="kt-widget kt-widget--user-profile-4">
              <div className="kt-widget__head">
                <div className="kt-widget__content">
                  <div className="kt-widget__section">
                    <a className="kt-widget__username page-name">
                      {page.title}
                    </a>
                    <div className="kt-widget__button">
                      <span className={`btn btn-label-${label} btn-sm`}>
                        {status}
                      </span>
                    </div>
                    <div className="kt-widget__stats">
                      <div className="kt-widget__stat">
                        <span className="kt-widget__label">Page Views:</span>
                        <a href="#" className="kt-widget__data">
                          {viewsNo}
                        </a>
                      </div>
                    </div>
                    <div className="kt-widget__action">
                      <a
                        href="#"
                        className="btn btn-outline-brand btn-elevate btn-circle btn-icon"
                        style={{ margin: 0, marginRight: "1em" }}
                        onClick={() => this._handlePageClicked(page)}
                      >
                        <i className="la la-pencil"></i>
                      </a>
                      <a
                        href="#"
                        className="btn btn-outline-brand btn-elevate btn-circle btn-icon"
                        data-toggle="modal"
                        data-target="#edit-page"
                        style={{ margin: 0, marginRight: "1em" }}
                        onClick={() =>
                          this.setState({
                            showPageEditModal: true,
                            selectedPage: page,
                            selectedPageIndex: index,
                          })
                        }
                      >
                        <i className="la la-cog"></i>
                      </a>
                      {!page.is_live && (
                        <CopyToClipboard
                          text={shareTokenUrl}
                          onCopy={() =>
                            Notify.success("Preview Url Copied To Clipboard")
                          }
                        >
                          <a
                            href="javascript:void"
                            className="btn btn-outline-info btn-elevate btn-circle btn-icon"
                            style={{ margin: 0, marginRight: "1em" }}
                          >
                            <i className="la la-link"></i>
                          </a>
                        </CopyToClipboard>
                      )}
                      {!page.is_homepage && (
                        <a
                          href="#"
                          className="btn btn-outline-danger btn-elevate btn-circle btn-icon"
                          onClick={() =>
                            this.setState({
                              showPageDeleteModal: true,
                              selectedPage: page,
                              selectedPageIndex: index,
                            })
                          }
                        >
                          <i className="la la-trash"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let {
      selectedPage,
      selectedPageIndex,
      showPageEditModal,
      showPageDeleteModal,
      showPageAddModal,
      pageAdded,
    } = this.state;

    selectedPage = selectedPage ? { ...selectedPage } : null;

    return (
      <>
        <div className="row">
          {pageAdded && (
            <div className="col-12">
              <Alert
                text="Your page was successfully added!"
                cta="Edit"
                onClick={() => {
                  this._handlePageClicked(pageAdded);
                  this.setState({ pageAdded: null });
                }}
                closable={false}
              />
            </div>
          )}
        </div>

        <div className="row">
          <LazyLoadingList
            ref={this.list}
            endpoint={`${window.Api.Pages}?website_id=${AuthManager.currentWebsite.id}&order_by=-is_homepage&page_size=40`}
            onItemUpdated={(pages) => {
              this.setState({ pages, isInitialLoading: false });
            }}
            renderItem={(page, index) => this._renderPage(page, index)}
          />

          <div className="col-md-3">
            <a
              href="#"
              onClick={() => this.setState({ showPageAddModal: true })}
            >
              <div className="kt-portlet kt-portlet--height-fluid add-page-two">
                <div className="kt-portlet__head kt-portlet__head--noborder">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title"></h3>
                  </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit-y kt-margin-b-40">
                  <div className="kt-widget kt-widget--user-profile-4">
                    <div className="kt-widget__head">
                      <div className="kt-widget__content">
                        <div className="kt-widget__section">
                          <a className="kt-widget__username page-name">
                            <i className="la la-plus"></i>
                            <br />
                            Create Page
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <PageEditModal
          page={selectedPage}
          show={showPageEditModal}
          onHide={() => this.setState({ showPageEditModal: false })}
          onUpdated={(page) => {
            this.list.current.refresh();
            this.setState({
              showPageEditModal: false,
              selectedPage: null,
              selectedPageIndex: null,
            });
          }}
        />

        <PageAddModal
          show={showPageAddModal}
          onHide={() => this.setState({ showPageAddModal: false })}
          onAdded={(page) => {
            this.list.current.refresh();
            this.setState({
              pageAdded: page,
              showPageAddModal: false,
            });
          }}
        />

        <PageDeleteModal
          show={showPageDeleteModal}
          page={selectedPage}
          onCancel={() => this.setState({ showPageDeleteModal: false })}
          onDeleted={() => {
            this.list.current.refresh();
            this.setState({
              showPageDeleteModal: false,
            });
          }}
        />
      </>
    );
  }
}
