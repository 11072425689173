import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Select from '../common/Select'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

const ROLES = [
  {
    label: "Manager",
    value: "admin"
  },
  {
    label: "Staff",
    value: "staff"
  },
]

export default class CompanyMemberEditModal extends React.Component {
  constructor(props){
    super(props)
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      companyMember: props.companyMember ? JSON.parse(JSON.stringify(props.companyMember)) : null,
      loading: false,
    }
  }

  _handleChange(e) {
    let companyMember = this.state.companyMember
    companyMember[e.target.name] = e.target.value
    this.setState({ companyMember })
  }

  _handleChangeUser(e) {
    let companyMember = this.state.companyMember
    companyMember["user"][e.target.name] = e.target.value
    this.setState({ companyMember })
  }

  _updateCompanyMember(){
    let {
      companyMember
    } = this.state

    if(!companyMember.user.password){
      delete companyMember.user.password
    }
    else if(companyMember.user.password.length < 6){
      Notify.error("Password must be 6 or more characters")
      return
    }

    this.setState({ loading: true })
    Backend.updateCompanyMember(companyMember)
    .then(companyMember => {
      this.setState({ loading: false })
      Notify.success("User updated successfully")
      this.props.onUpdated(companyMember)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      loading,
      companyMember,
    } = this.state

    if(companyMember == null){
      return null
    }

    let isAdmin = AuthManager.currentUser.role === "admin"
    let isCurrentUser = companyMember.user.id === AuthManager.currentUser.user.id
    let canEditRole = isAdmin && !isCurrentUser

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">First Name</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="first_name"
                className="form-control form-control-solid"
                value={ companyMember.user.first_name }
                onChange={e => this._handleChangeUser(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Last Name</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="last_name"
                className="form-control form-control-solid"
                value={ companyMember.user.last_name }
                onChange={e => this._handleChangeUser(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Email</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="email"
                className="form-control form-control-solid"
                value={ companyMember.user.email }
                onChange={e => this._handleChangeUser(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Password</label>
            <div className="col-lg-9 my-auto">
              <input
                type="password"
                name="password"
                className="form-control form-control-solid"
                onChange={e => this._handleChangeUser(e)}
              />
              <span>Entering a password will change the user's current password</span>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Role</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={ROLES.find(role => role.value === companyMember.role)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                name="role"
                placeholder="Please Select An Option"
                onChange={option => {
                  companyMember.role = option.value
                  this.setState({ companyMember })
                }}
                options={ROLES}
                isDisabled={!canEditRole}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._updateCompanyMember()}
          >
            Save Changes
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
