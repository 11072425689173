import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

export default class AddArticleEngagementCard extends React.Component {
  render() {
    // to do set the props for sizing here
    return (
      <EngagementCard
        { ...this.props }
        title="Add Your First Article"
        renderContent={() => "Your blog awaits, add an article to get started!"}
        cta="Add Article"
      />
    )
  }
}
