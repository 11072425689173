import React from "react"

import BaseTable from "./BaseTable"

import Select from 'react-select';

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Submission from '../../../utils/Submission'

import moment from 'moment'

const INVITE_STATUS_ALL             = "all_statuses"
const INVITE_STATUS_PENDING         = "pending"
const INVITE_STATUS_SENT            = "sent"
const INVITE_STATUS_DELIVERED       = "delivered"
const INVITE_STATUS_BOUNCED         = "bounced"
const INVITE_STATUS_DROPPED         = "dropped"
const INVITE_STATUS_MARKED_AS_SPAM  = "marked_as_spam"
const INVITE_STATUS_OPENED          = "opened"
const INVITE_STATUS_VISITED         = "visited"
const INVITE_STATUS_REGISTERED      = "registered"
const INVITE_STATUS_ATTENDED        = "attended"

const INVITE_STATUSES_LIST = [
    INVITE_STATUS_ALL,
    INVITE_STATUS_PENDING,
    INVITE_STATUS_SENT,
    INVITE_STATUS_DELIVERED,
    INVITE_STATUS_BOUNCED,
    INVITE_STATUS_DROPPED,
    INVITE_STATUS_MARKED_AS_SPAM,
    INVITE_STATUS_OPENED,
    INVITE_STATUS_VISITED,
    INVITE_STATUS_REGISTERED,
    INVITE_STATUS_ATTENDED
]

export default class InvitesTable extends React.Component {

  constructor(props){
    super(props)

    let statusFilterOptions = INVITE_STATUSES_LIST.map(status => {
      return {
        value: status,
        label: General.snakeCaseToTitleCase(status)
      }
    })

    this.state = {
      statusFilterOptions,
      selectedStatusFilter: statusFilterOptions[0],
      endpoint: window.Api.Invites + "?website_id=" + AuthManager.currentWebsite.id,
    }

    this.invitesTable = React.createRef();
  }

  _renderHeaderRightContent(){
    return this._renderStatusFilter()
  }

  _renderStatusFilter(){
    let {
      selectedStatusFilter,
      statusFilterOptions,
    } = this.state

    return (
      <div className="select-status-filter pull-right">
        <label>{}</label>
        <Select
          value={selectedStatusFilter}
          onChange={selectedStatusFilter => {
            this.setState({
              selectedStatusFilter
            }, () => this.invitesTable.current.refresh())
          }}
          options={statusFilterOptions}
        />
      </div>
    )
  }

  _renderInviteData(invite){
    return (
      <div style={{ background: "#f4f5fb" }}>
        <div style={{ margin: 20, padding: 20, background: "#FFF" }}>
          <h5>Registration Details</h5>
          <hr/>
          { this._renderSubmissionData(invite) }
          <h5 style={{ marginTop: 40}}>Invite Details</h5>
          <hr/>
          { this._renderInviteDetails(invite) }
        </div>
      </div>
    )
  }

  _renderInviteDetails(invite){
    let details = []

    if(invite.sent_at){
      details.push({
        title: "Sent At",
        value: moment(invite.sent_at).format('llll')
      })
    }
    if(invite.opened_at){
      details.push({
        title: "Opened At",
        value: moment(invite.opened_at).format('llll')
      })
    }
    if(invite.clicked_at){
      details.push({
        title: "Visited At",
        value: moment(invite.clicked_at).format('llll')
      })
    }
    if(invite.submission){
      details.push({
        title: "Registered At",
        value: moment(invite.submission.created_at).format('llll')
      })
    }
    if(invite.attended_at){
      details.push({
        title: "Attended At",
        value: moment(invite.attended_at).format('llll')
      })
    }

    if(details.length == 0){
      return (
        <div>
          { invite.first_name }'s invite has not yet been sent.
        </div>
      )
    }
    return details.map(detail => {
      return this._renderDetail(detail.title, detail.value)
    })
  }

  _renderSubmissionData(invite){
    if(!invite.submission){
      return (
        <div>
          { invite.first_name } has not yet registered, once he/she has their registration info will appear here.
        </div>
      )
    }

    return this._renderAnswers(invite.submission)
  }

  _renderAnswers(submission){
    return submission.answers.map(answer => {
      return this._renderDetail(answer.field.title, Submission.getAnswerValue(answer, submission))
    })
  }

  _renderDetail(title, value){
    return (
      <p style={{ margin: 0, marginTop: 3 }}>
        <span class="rt-resizable-header-content">{title}:  </span>
        { value }
      </p>
    )
  }

  _getColumns() {
    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: invite => moment(invite.created_at).format('DD/MM/YYYY')
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Status',
        id: "status",
        Cell: rowInfo => {

          let status = rowInfo.original.status

          let statuClassName = ""
          switch(status){
            case "pending":         statuClassName = "dark";          break;
            case "sent":            statuClassName = "info";          break;
            case "delivered":       statuClassName = "info";          break;
            case "bounced":         statuClassName = "danger";        break;
            case "dropped":         statuClassName = "danger";        break;
            case "marked_as_spam":  statuClassName = "danger";        break;
            case "opened":          statuClassName = "warning";       break;
            case "visited":         statuClassName = "warning";       break;
            case "registered":      statuClassName = "success";       break;
            case "attended":        statuClassName = "success";       break;
            default: break;
          }
          status = status.replace('marked_as_spam', 'spam')
          let title = General.snakeCaseToTitleCase(status)
          return (
            <span
              className={ `kt-badge kt-badge--${statuClassName}  kt-badge--inline kt-badge--pill`}
            >
              { title }
            </span>
          )
        }
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
    ]
  }

  render() {
    let {
      endpoint,
      selectedStatusFilter
    } = this.state
    const columns = this._getColumns()

    let params = {}
    if(selectedStatusFilter.value != INVITE_STATUS_ALL){
      params.status = selectedStatusFilter.value
    }

    return (
      <div>
        <BaseTable
          ref={this.invitesTable}
          endpoint={endpoint}
          title="Guests"
          columns={columns}
          params={params}
          SubComponent={row => this._renderInviteData(row.original)}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderHeaderRightContent={() => this._renderHeaderRightContent()}
        />
      </div>
    )
  }
}
