import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

export default class CollectedModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      sale: this.props.sale,
      loading: false
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _collected(){
    let {
      sale,
    } = this.state

    let data = {
      ...sale.collection,
      status: "complete"
    }

    this.setState({ loading: true })
    Backend.collected(data)
    .then(() => {
      this.setState({ loading: false })
      this.props.onCollected()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      data,
      loading
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {
          if(!loading){
            this.props.onCancel()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure?</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <p>This collection will be marked as complete</p>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            disabled={loading}
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-danger`}
            disabled={loading}
            onClick={() => this._collected()}
          >
            Mark As Complete
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
