import React from "react"

import Div from "../layouts/Div"

import ImageEdit from "../common/ImageEdit"

import Alert from "../alert/Alert"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

export default class ExistingDomainSettings extends React.Component {
  constructor(props){
    super(props)

    let website = { ...AuthManager.currentWebsite }
    if(website.url){
      website.url = this._removeHTTP(website.url)
    }
    this.state = {
      website,
      loading: true,
      updating: false,
      mode: "transfer",
    }
  }

  componentDidMount(){
    this._setup()
  }

  _removeHTTP(url){
    return url.replace("http://", "").replace("https://", "")
  }

  _addHTTPS(url){
    if(url.indexOf("https://") === -1){
      return "https://"+url
    }
    return url
  }

  _setup(){
    Backend.getDNSRecords(this.state.website)
    .then(dns => {
      this.setState({
        dns,
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message);
    })
  }

  _update(){
    let {
      website
    } = this.state

    this.setState({ updating: true })

    let data = {
      url: this._addHTTPS(website.url)
    }

    Backend.updateWebsite(website, data)
    .then(website => {
      AuthManager.currentWebsite.url = website.url
      this.props.onUpdatedExternalDomain(website.url)
    })
    .catch(error => {
      this.setState({
        updating: false
      })
      Notify.error(error.message)
    })
  }

  _transfer(){
    let {
      website
    } = this.state

    this.setState({ updating: true })

    Backend.transferDomain(website.url)
    .then(domain => {

      this.props.onTransferredDomain(domain)
    })
    .catch(error => {
      this.setState({
        updating: false
      })
      Notify.error(error.message)
    })
  }

  _handleChange(e) {
    let website = this.state.website
    website[e.target.name] = e.target.value
    this.setState({ website, error: null })
  }

  render() {
    let {
      dns,
      mode,
      website,
      loading,
      updating
    } = this.state

    if(loading){
      return null
    }

    let domainTitle = mode === "custom" ? `Subdomain For ${window.General.Branding.Name} Site` : "Domain"

    return (
      <Div disabled={updating} className="use-existing-domain">
        <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
          <div className="kt-heading kt-heading--md">Use Existing Domain</div>
          <div className="kt-form__section kt-form__section--first">

            <div className="kt-wizard-v2__form">

              <div className="form-group row mt-5">
                <label className="col-lg-4 col-form-label">{ domainTitle }:</label>
                <div className="col-lg-8 my-auto">
                  <div className="input-group">
                    <span className="input-group-addon">
                      https://
                    </span>
                    <input
                      type="text"
                      name="url"
                      className="form-control form-control-solid"
                      value={ website.url }
                      onChange={e => this._handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              { dns.custom &&
                <>
                  <Alert
                    closabled={false}
                  />
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">CNAME</label>
                    <div className="col-lg-8 my-auto">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        value={ dns.custom.data }
                        disabled
                      />
                      <span>{`Add the above as a CNAME to your DNS records to have '${website.url}' go to your site`}</span>
                    </div>
                  </div>
                </>
              }

            </div>
          </div>
        </div>
          <div className="kt-form__actions pull-right">
            { mode &&
              <button
                className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                disabled={updating}
                onClick={() => {

                  if(!website.url || website.url === ""){
                    Notify.error("Please enter a valid url")
                    return
                  }

                  if(mode === "custom"){
                    this._update()
                  }
                  else{
                    this._transfer()
                  }

                }}
              >
                Save Changes
              </button>
            }
          </div>
      </Div>
    )
  }
}
