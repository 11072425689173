import React from 'react'
import { withRouter } from 'react-router-dom'

import { Portal } from 'react-portal';

class ActivateShop extends React.Component {
  render() {
    if(!this.props.show){
      return null
    }

    return (
      <Portal node={document && document.getElementById("kt-content-top")}>
        <div
          className="activate-shop-banner"
          onClick={() => this.props.history.push("/connect")}
        >
          Activate Shop!
        </div>
      </Portal>
    )
  }
}

export default withRouter(ActivateShop)
