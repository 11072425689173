import React from 'react'

import moment from 'moment'

import TextField from '@material-ui/core/TextField'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'

import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import FormFieldModal from '../modal/FormFieldModal'

import { FormHelperText } from "@material-ui/core";

import FileUpload from '../common/FileUpload'

import Switch from 'react-switch'

import Currency from '../../../utils/Currency'

export default class FormAnswer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      answer: props.answer,
      formField: props.formField,
      viewOnly: props.viewOnly
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _isChecked(option, answer) {
    var index = answer.options_selected?.findIndex(
      (item) => item.id === option.id
    )
    if (index === -1) {
      return false
    } else {
      return true
    }
  }

  _updateSelected(option, answer) {
    var index = answer.options_selected.findIndex(
      (item) => item.id === option.id
    )
    if (index === -1) {
      answer.options_selected.push(option)
    } else {
      answer.options_selected.splice(index, 1)
    }
    this.props.onChange(answer)
  }

  _renderDateTimeFields(type, formField) {
    let { answer, viewOnly } = this.state
    if (type === 'date') {
      return (
        <DatePicker
          error={answer.error}
          helperText={answer.error ? "This field is required." : ""}
          fullWidth
          inputVariant="outlined"
          label={formField.title}
          emptyLabel={''}
          value={answer.value ? moment(answer.value).format("YYYY-MM-DD") : null}
          disableToolbar
          disabled={viewOnly}
          format={'YYYY-MM-DD'}
          onChange={(date) => {
            answer.value = date.format('YYYY-MM-DD')
            this.props.onChange(answer)
          }}
        />
      )
    } else if (type === 'time') {
      return (
        <TimePicker
          error={answer.error}
          helperText={answer.error ? "This field is required." : ""}
          fullWidth
          inputVariant="outlined"
          label={formField.title}
          emptyLabel={''}
          value={answer.value || null}
          disableToolbar
          disabled={viewOnly}
          format={'hh:mm'}
          onChange={(date) => {
            answer.value = date.format('hh:mm')
            this.props.onChange(answer)
          }}
        />
      )
    } else if (type === 'datetime') {
      return (
        <DateTimePicker
          error={answer.error}
          helperText={answer.error ? "This field is required." : ""}
          fullWidth
          inputVariant="outlined"
          label={formField.title}
          emptyLabel={''}
          value={answer.value || null}
          disableToolbar
          disabled={viewOnly}
          format={'YYYY-MM-DD hh:mm'}
          onChange={(date) => {
            answer.value = date.format('YYYY-MM-DD hh:mm')
            this.props.onChange(answer)
          }}
        />
      )
    }
  }

  _renderField() {
    let { formField, answer, viewOnly } = this.state
    let type = formField.type

    if (type === 'single_select' && formField.alignment === 'horizontal') {
      return (
        <FormControl
          component="fieldset"
          fullWidth
          error={answer.error}
        >
          <FormLabel component="legend">{formField.title}</FormLabel>
          <RadioGroup>
            {formField.options.map((option) => (
              <FormControlLabel
                value={option.text}
                control={
                  <Radio
                    disabled={viewOnly}
                    checked={answer.option_selected?.id == option.id}
                    onChange={(event) => {
                      answer.option_selected = option
                      this.props.onChange(answer)
                    }}
                  />
                }
                label={option.text}
              />
            ))}
          </RadioGroup>
          {
            answer.error &&
            <FormHelperText>This field is required.</FormHelperText>
          }
        </FormControl>
      )
    } else if (type === 'multi_select') {
      return (
        <FormControl
          row
          component="fieldset"
          fullWidth
          error={answer.error}
        >
          <FormLabel component="legend">{formField.title}</FormLabel>
          {formField.options.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={viewOnly}
                  checked={this._isChecked(option, answer)}
                  onChange={() => this._updateSelected(option, answer)}
                />
              }
              label={option.text}
            />
          ))}
          {
            answer.error &&
            <FormHelperText>This field is required.</FormHelperText>
          }
        </FormControl>
      )
    } else if (type === 'single_select' && formField.alignment === 'vertical') {
      return (
        <FormControl
          fullWidth
          variant={'outlined'}
          error={answer.error}
        >
          <InputLabel id={formField.id}>{formField.title}</InputLabel>
          <Select
            label={formField.title}
            labelId={formField.id}
            disabled={viewOnly}
            value={answer.option_selected?.id}
            onChange={(event) => {
              answer.option_selected = formField.options.find(
                (item) => item.id === event.target.value
              )
              this.props.onChange(answer)
            }}
          >
            {formField.options.map((option) => (
              <MenuItem value={option.id}>{option.text}</MenuItem>
            ))}
          </Select>
          {
            answer.error &&
            <FormHelperText>This field is required.</FormHelperText>
          }
        </FormControl>
      )
    } else if (type === 'date' || type === 'datetime' || type === 'time') {
      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {this._renderDateTimeFields(type, formField)}
        </MuiPickersUtilsProvider>
      )
    } else if (type === 'boolean') {
      let disabled = formField.is_required && !!answer.id
      let errorMessage = "This field is required."

      if(formField.error_message){
        errorMessage = formField.error_message
      }
      return (
        <>
          <label className="col-lg-12 col-form-label">{formField.title}</label>
          <Switch
            onChange={(checked) => {
              answer.bool = checked
              this.props.onChange(answer)
            }}
            checked={answer.bool}
            aria-labelledby="neat-label"
            onColor="#333333"
            offColor="#d9d9d9"
            checkedIcon={null}
            uncheckedIcon={null}
            className="c-switch"
            disabled={disabled || viewOnly}
          />
          {
            answer.error &&
            <FormHelperText>{errorMessage}</FormHelperText>
          }
        </>
      )
    } else if (type === 'file' || type === "image") {
      return (
        <div className={"validate-required"}>
          <FileUpload
            file={answer.image || answer.file }
            endpoint={type === "image" ? window.Api.Images : window.Api.Files}
            accept={type === "image" ? "image/*" : "*/*"}
            params={{
              type: "photo",
            }}
            disabled={viewOnly}
            onUploading={() => {
              this.setState({loading: true})
            }}
            onUploaded={file => {
              answer.error = false
              if(type === "image"){
                answer.image = file
              }
              else{
                answer.file = file
              }
              this.props.onChange(answer)
            }}
            onRemoved={() => {
              answer.error = false
              answer.image = null
              answer.file = null
              this.props.onChange(answer)
            }}
          />

          {
            answer.error &&
            <FormHelperText>This field is required.</FormHelperText>
          }
        </div>
      )
    } else if(type == "price"){
      let { sale } = this.props.submission
      if(!sale){
        return null
      }
      let symbol = Currency.getSymbol(sale.currency)
      let label = Currency.format(sale.amount, sale.currency)
      return (
        <div class="input-group mb-3 f-price">
          <span class="input-group-text">{symbol}</span>
          <input
            class="form-control"
            type="number"
            placeholder="0.00"
            disabled={true}
            value={sale.amount / 100.0}
          />
        </div>
      )
    } else if(type == "booking"){
      let { booking } = this.props.submission
      if(!booking){
        return null
      }
      let datetime = moment(booking.starts_at).format("dddd, MMMM Do YYYY")
      let text = `${booking.service_resource.service.name} - ${datetime}`

      return (
        <TextField
          label={formField.title}
          fullWidth
          variant={'outlined'}
          value={text}
          disabled={true}
        />
      )
    } else {
      let errorMessage = "This field is required."

      if(type === "phone" || type === "contact_phone"){
        errorMessage = "Please enter a valid phone number."
      }

      if(type === "email" || type === "contact_email"){
        errorMessage = "Please enter a valid email address."
      }
      return (
        <TextField
          error={answer.error}
          helperText={answer.error ? errorMessage : ""}
          label={formField.title}
          fullWidth
          variant={'outlined'}
          multiline={type === 'textarea'}
          rows={type === 'textarea' ? 4 : 1}
          value={answer.value}
          disabled={viewOnly}
          onChange={(event) => {
            answer.value = event.target.value
            this.props.onChange(answer)
          }}
        />
      )
    }
  }

  render() {
    return <>{this._renderField()}</>
  }
}
