import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import ActivateShopBanner from './components/product/ActivateShopBanner'

import AddArticleEngagementCard from './components/cards/AddArticleEngagementCard'

import ArticlesTable from './components/tables/ArticlesTable'

import Alert from './components/alert/Alert'

export default class Articles extends React.Component {
  constructor(props) {
    super(props)

    let articleAdded = window.location.href.indexOf("article_added=true") > -1

    this.state = {
      articleAdded,
    }
  }

  _renderAddArticle(){
    return (
      <div className="row">
        <AddArticleEngagementCard
          onCtaPressed={() => this.props.history.push(`/articles/add`)}
        />
      </div>
    )
  }

  render() {
    let {
      shop,
      articleAdded,
    } = this.state

    if(AuthManager.currentWebsite.articles_no === 0){
      return this._renderAddArticle()
    }

    return (
      <>
        <div className="row">
        { articleAdded &&
          <div className="col-12">
            <Alert
              text="Your article was successfully created"
            />
          </div>
        }
          <ArticlesTable website={AuthManager.currentWebsite}/>
        </div>
      </>
    )
  }
}
