import React from 'react'

import LazyLoadingList from './components/common/LazyLoadingList'

import Backend from '../utils/Backend'
import General from '../utils/General'
import AuthManager from '../utils/AuthManager'

import CompanyMemberEditModal from "./components/modal/CompanyMemberEditModal"
import CompanyMemberAddModal from "./components/modal/CompanyMemberAddModal"
import CompanyMemberDeleteModal from "./components/modal/CompanyMemberDeleteModal"

export default class Team extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
    this.list = React.createRef()
  }

  _renderAddButtons(){
    if(AuthManager.currentUser.role !== "admin"){
      return null
    }

    return (
      <div className="col-md-3">
        <a
          href="#"
          onClick={() => this.setState({ showCompanyMemberAddModal: true })}
        >
          <div className="kt-portlet kt-portlet--height-fluid add-page-two">
            <div className="kt-portlet__head kt-portlet__head--noborder">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">

                </h3>
              </div>
            </div>
            <div className="kt-portlet__body kt-portlet__body--fit-y kt-margin-b-40">
              <div className="kt-widget kt-widget--user-profile-4">
                <div className="kt-widget__head">
                  <div className="kt-widget__content">
                    <div className="kt-widget__section">
                      <a
                        className="kt-widget__username page-name"
                      >
                        <i className="la la-plus"></i><br/>
                        Add Member
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    )
  }

  _renderCompanyMember(companyMember, index){
    let role = General.snakeCaseToTitleCase(companyMember.role)
    let name = companyMember.user.first_name + " " + companyMember.user.last_name
    let initials = General.getInitials(name)

    let isCurrentUser = companyMember.user.id === AuthManager.currentUser.user.id
    let isAdmin = AuthManager.currentUser.role === 'admin'

    let canEdit = isCurrentUser || isAdmin
    let canDelete = !isCurrentUser && isAdmin

    let buttonClassName = "info"
    if(companyMember.role === "planner"){
      buttonClassName = "warning"
    }
    else if(companyMember.role === "admin"){
      buttonClassName = "danger"
    }

    return (
      <div className="col-md-3">
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head kt-portlet__head--noborder">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">

              </h3>
            </div>
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit-y kt-margin-b-40">
            <div className="kt-widget kt-widget--user-profile-4">
              <div className="kt-widget__head">
                <div className="kt-widget__media">
                  { this._renderAvatar(companyMember, buttonClassName, initials) }
                </div>
                <div className="kt-widget__content">
                  <div className="kt-widget__section">
                    <a href="#" className="kt-widget__username">
                      { name }
                    </a>

                    <div className="kt-widget__button">
                      <span className={`btn btn-label-${buttonClassName} btn-sm`}>{ role }</span>
                    </div>
                    <div className="kt-widget__action">
                      { canEdit &&
                        <a
                          href="#"
                          className="btn btn-outline-brand btn-elevate btn-circle btn-icon"
                          style={{ margin: 0 }}
                          onClick={() => this.setState({
                              showCompanyMemberEditModal: true,
                              selectedCompanyMember: companyMember,
                              selectedCompanyMemberIndex: index
                            })
                          }
                        >
                          <i className="la la-cog"></i>
                        </a>
                      }
                      { canDelete &&
                        <a
                          href="#"
                          className="btn btn-outline-danger btn-elevate btn-circle btn-icon"
                          style={{ margin: 0, marginLeft: '1em' }}
                          onClick={() => this.setState({
                              showCompanyMemberDeleteModal: true,
                              selectedCompanyMember: companyMember,
                              selectedCompanyMemberIndex: index
                            })
                          }
                        >
                          <i className="la la-trash"></i>
                        </a>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderAvatar(companyMember, label, initials){
    if(companyMember.user.avatar){
      return (
        <img className="kt-widget__img" src={ companyMember.user.avatar } alt="image"/>
      )
    }
    return (
      <div className={ `kt-widget__pic kt-widget__pic--${label} kt-font-${label} kt-font-boldest` }>
        { initials }
      </div>
    )
  }

  render() {
    let {
      selectedCompanyMember,
      showCompanyMemberEditModal,
      showCompanyMemberAddModal,
      showCompanyMemberDeleteModal,
      selectedCompanyMemberIndex
    } = this.state

    selectedCompanyMember = selectedCompanyMember ? JSON.parse(JSON.stringify(selectedCompanyMember)) : null

    return (
      <>
				<div className="row">

          <LazyLoadingList
            ref={this.list}
            endpoint={`${window.Api.Staff}`}
            renderItem={(companyMember, index) => this._renderCompanyMember(companyMember, index)}
          />

					{ this._renderAddButtons() }

          <CompanyMemberEditModal
            companyMember={selectedCompanyMember}
            show={showCompanyMemberEditModal}
            onHide={() => this.setState({ showCompanyMemberEditModal: false })}
            onUpdated={companyMember => {
              this.list.current.refresh()
              this.setState({
                showCompanyMemberEditModal: false,
                selectedCompanyMember: null,
                selectedCompanyMemberIndex: null
              })
            }}
          />

          <CompanyMemberAddModal
            show={showCompanyMemberAddModal}
            onHide={() => this.setState({ showCompanyMemberAddModal: false })}
            onAdded={page => {
              this.list.current.refresh()
              this.setState({
                showCompanyMemberAddModal: false
              })
            }}
          />

          <CompanyMemberDeleteModal
            show={showCompanyMemberDeleteModal}
            companyMember={ selectedCompanyMember }
            onCancel={() => this.setState({ showCompanyMemberDeleteModal: false })}
            onDeleted={() => {
              this.list.current.refresh()
              this.setState({
                showCompanyMemberDeleteModal: false
              })
            }}
          />

				</div>
      </>
    )
  }
}
