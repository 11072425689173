import React from "react";
import General from "../utils/General";
import Form from "./components/form-builder/Form";
import AsyncSelect from "./components/AsyncSelect";
import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

const ENGAGE_CARD = require('../assets/media/svg/illustrations/copy.svg')

export default class LeadForm extends React.Component  {

  constructor(props){
    super(props)

    let formId = this.props.match.params.form_id

    this.state = {
      formId
    }
  }

  componentDidMount() {
    this._handleGetForm()
    .then(form => {
      this.setState({
        selectedForm: form
      })
    }).catch(error => {
      this.setState({loading: false})
      Notify.error(error.message)
    })
  }

  _handleGetForm(){
    let { formId } = this.state

    let paymentForm = window.location.pathname.indexOf("payment-form") > -1
    let bookingForm = window.location.pathname.indexOf("booking-form") > -1

    if(formId){
      return Backend.getForm(formId)
    }
    else if(paymentForm){
      return Backend.getPaymentForm()
    }
    else if(bookingForm){
      return Backend.getBookingForm()
    }
    else{
      return Backend.getCRMForm()
    }
  }

  render() {
    let {
      selectedForm
    } = this.state

    if(!selectedForm){
      return null
    }

    let isCRM = selectedForm.type === "crm"
    let title = isCRM ? "CRM Contact Form Builder" : "Form Builder"
    let subtitle = isCRM ? "Edit your contact form to suit your needs" : "Edit your form to suit your needs"

    return (
      <>

        {/* begin::Engage Card */}
        <div className="metr_v702 form-builder-engagecard">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body d-flex p-0">
              <div className="flex-grow-1 py-10 py-md-14 px-10 px-md-20 pr-lg-0 card-rounded flex-grow-1 bgi-no-mobile bgi-no-repeat bgi-position-y-bottom bgi-position-x-right bgi-size-h-100"
                style={{
                  backgroundImage:`url(${ENGAGE_CARD})`,
                  backgroundColor: 'rgb(27, 40, 63)',
                }}
              >
                <h1 className="font-weight-bolder text-white mb-0">{title}</h1>
                <div className="text-muted font-size-h5 mb-8">{subtitle}</div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Engage Card */}

        <div className="row metr_v702 form-builder">
          <Form
            form={selectedForm}
          />
        </div>

      </>
    )

  }

}
