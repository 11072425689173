import React from 'react'

import Switch from "react-switch";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import BookingEngagementCard from './components/cards/BookingEngagementCard'

import BookingsCalendar from './components/BookingsCalendar'

import SubmissionsTable from "./components/tables/SubmissionsTable";

import AuthManager from '../utils/AuthManager'


export default class Contacts extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      tableView: false,
    }
  }

  render() {

    let {
      tableView,
    } = this.state

    return (
      <div className="text-center c-tabs">
        <Tabs
          defaultActiveKey="kanban"
          id="noanim-tab-example"
          variant="pills"
        >
          <Tab eventKey="kanban" title="Calendar View">
            <div className="text-left">
              <BookingEngagementCard mode="bookings"/>
              <BookingsCalendar/>
            </div>
          </Tab>
          <Tab eventKey="table" title="Table View">
            <div className="row text-left px-25 mt-25px">
              <SubmissionsTable bookings={true}/>
            </div>
          </Tab>
        </Tabs>
      </div>
    )

  }
}
