import React from 'react';

export default class FormDropzone extends React.Component  {
  constructor(props){
    super(props)
    this.state = {
      position: props.position,
      rowIndex: props.rowIndex,
      placeIndex: props.placeIndex,
      showDropzones: props.showDropzones,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render(){
    let{
      position,
      rowIndex,
      placeIndex,
      showDropzones,
    } = this.state

    let showDropzoneClass = "itemdropzone show"
    let hideDropzoneClass = "itemdropzone"

    if(placeIndex !== undefined){
      showDropzoneClass = "col-1 p-0 itemdropzone sides show"
      hideDropzoneClass = "col-auto p-0 itemdropzone sides"
    }

    return (
      <div
        className={showDropzones ? showDropzoneClass : hideDropzoneClass}
        onDragOver={e => {
          e.preventDefault()
          e.dataTransfer.dropEffect = "copy"
        }}
        onDragEnter={e => {
          e.target.style.background = "#95caff";
        }}
        onDragLeave={e => {
          e.target.style.background = "";
        }}
        onDrop={e => {
          e.target.style.background = "";
          e.preventDefault()
          this.props.onAddField(JSON.parse(e.dataTransfer.getData("text/plain")), rowIndex, placeIndex, position)
        }}
      />
    )

  }
}