import React from 'react';

import FormField from "./FormField";
import FormDropzone from "./FormDropzone";

export default class FormRow extends React.Component  {
  constructor(props){
    super(props)
    this.state = {
      form: props.form,
      formRow: props.formRow,
      rowIndex: props.rowIndex,
      showDropzones: props.showDropzones,
      selectedFormFields: props.selectedFormFields,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderItem(index){
    let {
      form,
      formRow,
      rowIndex,
    } = this.state

    return (
      <FormField
        form={form}
        formField={formRow[index]}
        index={index}
        rowIndex={rowIndex}
        onShowDropzones={show => this.props.onShowDropzones(show)}
        onUpdate={formField => {
          this.props.onUpdate(rowIndex, index, formField)
        }}
        onRemoveItem={(rowIndex, index) => {
          this.props.onRemoveItem(rowIndex, index)
        }}
      />
    )
  }

  _renderInputItems(){
    let {
      form,
      formRow,
      rowIndex,
      showDropzones,
    } = this.state

    let numberOfInputs = formRow.length

    if (numberOfInputs === 2){
      return (
        <>
          <div className="col-auto p-0 itemdropzone sides"></div>
          <div className={`col pl-1`}>
            {this._renderItem(0)}
          </div>
          <div className={`col pr-1`}>
            {this._renderItem(1)}
          </div>
          <div className="col-auto p-0 itemdropzone sides"></div>
        </>
      )
    }
    return(
      <>
        <FormDropzone
          position={"column"}
          rowIndex={rowIndex}
          placeIndex={0}
          showDropzones={showDropzones}
          onAddField={(formFieldObject, destinationIndex, placeIndex, position) => {
            this.props.onAddField(formFieldObject, destinationIndex, placeIndex, position)
          }}
        />

        <div className={`col px-1`}>
          {this._renderItem(0)}
        </div>

        <FormDropzone
          position={"column"}
          rowIndex={rowIndex}
          placeIndex={1}
          showDropzones={showDropzones}
          onAddField={(formFieldObject, destinationIndex, placeIndex, position) => {
            this.props.onAddField(formFieldObject, destinationIndex, placeIndex, position)
          }}
        />

      </>
    )
  }

  render(){
    let{
      rowIndex,
      showDropzones,
      selectedFormFields,
    } = this.state

    return (
      <>
        <FormDropzone
          position={"row"}
          rowIndex={rowIndex}
          placeIndex={undefined}
          showDropzones={showDropzones}
          onAddField={(formFieldObject, destinationIndex, placeIndex, position) => {
            this.props.onAddField(formFieldObject, destinationIndex, placeIndex, position)
          }}
        />

        <div className={"row"}>
          {this._renderInputItems()}
        </div>

        {
          selectedFormFields.length === rowIndex + 1 &&
          <>
            <FormDropzone
              position={"row"}
              rowIndex={rowIndex+1}
              placeIndex={undefined}
              showDropzones={showDropzones}
              onAddField={(formFieldObject, destinationIndex, placeIndex, position) => {
                this.props.onAddField(formFieldObject, destinationIndex, placeIndex, position)
              }}
            />
          </>
        }

      </>
    )

  }
}