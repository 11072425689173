import extractDomain from 'extract-domain';

export default class Website {
  static getDomain(website){
    let url = Website.getCustomDomain(website) || Website.getNativeDomain(website)
    return url
  }

  static getCustomDomain(website){
    if(!website.url){
      return null
    }

    if(website.url.startsWith("http://") || website.url.startsWith("https://")){
      return website.url
    }
    return "http://" + website.url
  }

  static getNativeDomain(website){
    return "https://" + website.slug + "." + window.General.WebsiteNakedDomain
  }

  static getCleanDomain(website){
    let url = Website.getDomain(website)
    url = url.replace("https://", "").replace("http://", "")
    return url
  }

  static getSiteUrl(website){

    if(website.domain && website.domain.dns && website.domain.ssl){
      return `https://${website.domain.name}`
    }

    if(website.url){
      let url = website.url
      if(url.indexOf("http://") === -1 && url.indexOf("https://") === -1){
        url = "https://" + website.url
      }
      return url
    }

    let domain = extractDomain(window.location.hostname);

    if (window.location.hostname.indexOf("localhost") === -1) {
      return `https://${website.slug}.${domain}`
    } else {
      return `https://${website.slug}.${window.General.WebsiteNakedDomain}`
    }
  }
}
