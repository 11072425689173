import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.General = {};

window.General.WebsiteNakedDomain = env.REACT_APP_WEBSITE_NAKED_DOMAIN

window.General.Branding = {}

window.General.Branding.Name = "XYZ"
window.General.Branding.Logo = require('../assets/media/logos/logo-dark.png')
window.General.Branding.Favicon = '/favicon.png'
window.General.Branding.Domain = window.General.WebsiteNakedDomain
window.General.Branding.CrmName = "Leads"

window.General.CrmPremium = false
