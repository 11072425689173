import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from "../layouts/Div"

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"
import Currency from "../../../utils/Currency";

let stripePromise = null

let CARD_ELEMENT_STYLE = {
  base: {
    iconColor: '#666666',
    color: '#fff',
    fontSize: '16px',
    '::placeholder': {
      color: '#87BBFD',
    },
  },
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  },
}

export default class PurchaseDomainModal extends React.Component {
  constructor(props){
    super(props)
    const user = AuthManager.currentUser.user

    this.state = {
      loading: false,
      cardHolderName: user.first_name + " " + user.last_name,
      cardHolderEmail: user.email
    }
    stripePromise = loadStripe(window.Api.StripePubishableKey)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleBuyDomain(){
    let {
      domain,
      paymentIntentId
    } = this.state

    let price = domain.prices[0].price
    if(domain.is_premium){
      price = domain.premium_price
    }

    let data = {
      name: domain.name,
      expected_price: price,
      years: 1,
      website: AuthManager.currentWebsite.id,
      type: "purchase"
    }

    this.setState({ loading: true })
    this._getPaymentMethod()
    .then(paymentMethod => {
      data.payment_details = {
        payment_method_id: paymentMethod.id
      }
      if(paymentIntentId){
        data.payment_details.payment_intent_id = paymentIntentId
      }
      return Backend.purchaseDomain(data)
    })
    .then(response => {
      if(response.requires_action){
        this._validatePayment(response)
        return
      }

      this.props.onPurchasedDomain(response)
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  async _getPaymentMethod(){
    if(!this.stripe || !this.elements){
      throw { message: "Unexpected error, please try again" }
    }

    const cardElement = this.elements.getElement(CardElement)

    const user = AuthManager.currentUser.user
    return this.stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: this.state.cardHolderName,
        email: this.state.cardHolderEmail
      },
    })
    .then(({paymentMethod, error}) => {
      if(paymentMethod == null){
        throw { message: "Please check your card details and try again later" }
      }
      return paymentMethod
    });
  }

  _validatePayment(response){
    this.stripe.confirmCardPayment(response.client_secret)
    .then(result => {
      if(result.error){
        Notify.error(result.error.message)
        this.setState({ loading: false })
        return
      }

      this.setState({
        paymentIntentId: result.paymentIntent.id
      }, () => this._handleBuyDomain())
    })
  }

  _renderCardInput(){
    let {
      cardHolderName,
      cardHolderEmail
    } = this.state
    return (
      <>
        <div className="form-group row">
          <label className="col-lg-4 col-form-label">Card Holder Name:</label>
          <div className="col-lg-8 my-auto">
           <input
              className="form-control form-control-solid"
              value={cardHolderName}
              onChange={e => this.setState({ cardHolderName: e.target.value })}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-4 col-form-label">Card Holder Email:</label>
          <div className="col-lg-8 my-auto">
           <input
              className="form-control form-control-solid"
              value={cardHolderEmail}
              onChange={e => this.setState({ cardHolderEmail: e.target.value })}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-4 col-form-label my-auto">Card Number:</label>
          <div className="col-lg-8 my-auto">
            <div class="card-input-container my-auto" style={{ paddingTop: 12 }}>
              { stripePromise &&
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({stripe, elements}) => {
                      this.stripe = stripe
                      this.elements = elements

                      return (
                        <CardElement
                          stripe={this.stripe}
                          options={{
                            style: {
                              base: {
                                iconColor: '#000',
                                marginTop: '20px',
                                fontSize: '1rem',
                                color: '#333',
                                '::placeholder': {
                                  color: '#c5c5c5',
                                  iconColor: "red",
                                },
                              },
                            },
                          }}
                          className="form-control form-control-solid"
                        />
                      )
                    }}
                  </ElementsConsumer>
                </Elements>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    let {
      show,
      domain,
      loading,
    } = this.state

    if(domain == null){
      return null
    }


    let price = domain.prices[0].price
    if(domain.is_premium){
      price = domain.premium_price
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if(!loading){
            this.props.onCancel()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Purchase Domain</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Div disabled={loading} spinner={true}>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">Domain:</label>
              <div className="col-lg-8 my-auto">
               <input
                disabled
                className="form-control form-control-solid"
                value={ domain.name }
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">Duration:</label>
              <div className="col-lg-8 my-auto">
               <input
                disabled
                className="form-control form-control-solid"
                value="1 Year (Recurring)"
                />
              </div>
            </div>
            { this._renderCardInput() }
          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            disabled={loading}
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._handleBuyDomain()}
          >
            Buy {Currency.format(price, "eur")} / yr
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

PurchaseDomainModal.defaultProps = {
}
