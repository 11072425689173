import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import LeftMenu, { MENU_ITEMS } from "./components/LeftMenu";
import MobileHeader from "./components/MobileHeader";
import Footer from "./components/Footer";
import QuickPanel from "./components/QuickPanel";
import Header from "./components/Header";
import SubHeader from "./components/SubHeader";

import SubscriptionAlert from "./components/alert/SubscriptionAlert";
import PropagationAlert from "./components/alert/PropagationAlert";
import TransferDomainAlert from "./components/alert/TransferDomainAlert";

import Dashboard from "./Dashboard";
import Pages from "./Pages";
import Emails from "./Emails";
import Team from "./Team";
import Products from "./Products";
import Product from "./Product";
import Articles from "./Articles";
import Article from "./Article";
import Sales from "./Sales";
import Payments from "./Payments";
import Subscription from './Subscription'
import Settings from "./Settings";
import ShopSettings from "./ShopSettings";
import DomainSettings from "./DomainSettings";
import Activate from "./shop/Activate";
import Invites from "./Invites";
import Contacts from "./Contacts";
import MobileCRM from "./MobileCRM";
import ContactSettings from "./ContactSettings";
import Coupons from "./Coupons";
import Files from "./Files";
import Conversations from "./Conversations";
import ConversationSettings from "./ConversationSettings";
import Visitors from "./Visitors";
import AdminChat from "./AdminChat";
import SubscriptionV2 from "./SubscriptionV2";
import Submissions from "./Submissions";
import Forms from "./Forms";

import Resources from "./Resources";
import Resource from "./Resource";
import Services from "./Services";
import Service from "./Service";
import Bookings from "./Bookings";

import SubscriptionAlertModal from "./components/modal/SubscriptionAlertModal";
import SubscriptionActivateModal from "./components/modal/SubscriptionActivateModal";

import Form from "./Form";

import AuthManager from "../utils/AuthManager";
import Notify from "../utils/Notify";
import Event from "../utils/Event";
import General from "../utils/General";


let SUBSCRIPTION_PAGES = ["product", "shop", "domain", "subscribe"];

export default class App extends React.Component {
  componentDidMount() {
    this._setupWebsocket()
    Event.on('SHOW_MESSAGE', (data) => {
      this._showMessageNotification(data)
    })
  }

  _showMessageNotification(data){
    if(!AuthManager.currentUser.notify_message){
      return
    }

    Notify.message(data, () => {
      const url = data.visitor_token ? `/chats?chat_id=${data.conversation}` : "/chats/admin"
      window.location = url
    })
  }

  _setupWebsocket(attemptNo=1){
    let baseUrl = window.Api.Base;
    let protocol = baseUrl.indexOf("https://") > -1 ? "wss://" : "ws://";
    baseUrl = baseUrl.replace("https://", "").replace("http://", "");

    let url = protocol + baseUrl + "/ws/notifications";

    this.chatSocket = new WebSocket(url, [`Bearer${AuthManager.accessToken}`]);

    this.chatSocket.onopen = (e) => {
      this.setState({ loading: false });
    };

    this.chatSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);

      if(data.sender && data.sender.user?.id != AuthManager.currentUser.user.id){
        let url = window.location.href
        if(url.indexOf("settings") == -1 && (url.indexOf("/chat") > 0 || url.indexOf("/visitors") > 0)){
          Event.emit('MESSAGE', data)
        }
        else{
          this._showMessageNotification(data)
        }
      }
    };

    this.chatSocket.onerror = (e) => {
      console.log("ERROR", e);
    };

    this.chatSocket.onclose = (e) => {
      setTimeout(() => {
        console.log("Reattempting connection to websocket", e);
        this._setupWebsocket(attemptNo + 1)
      }, 3000 * attemptNo)
      console.error("Chat socket closed unexpectedly, will re attempt to connect");
    };
  }

  _getAddOn() {
    let subscription = AuthManager.currentUser.company.subscription;

    let url = window.location.pathname;

    let menuItem = MENU_ITEMS.find(menuItem => {
      return menuItem.url === url
    })


    if(!menuItem || !menuItem.addOnKey){
      return false
    }

    let addOns = subscription.add_ons
    return addOns[menuItem.addOnKey]
  }

  _getShowSubscriptionActivateModal(){
    let path = window.location.pathname;
    let subscription = AuthManager.currentUser.company.subscription;
    let forcedPaymentFlows = [
      "sign_up",
      "post_sign_up"
    ]

    if(subscription.active){
      return false
    }

    if(path == "/subscriptionv1" || path == "/add-ons"){
      return false
    }

    const paymentFlow = subscription.payment_flow

    if(forcedPaymentFlows.indexOf(subscription.payment_flow) > -1){
      return true
    }

    if(subscription.payment_flow_triggered){
      return true
    }

    let addOn = this._getAddOn()
    if(paymentFlow == "add_on" && addOn && !addOn.enabled){
      return true
    }

    if(General.getQueryParam("subscribe")){
      return true
    }


    return false
  }

  _getShowSubscriptionAlert(){
    let path = window.location.pathname;
    let subscription = AuthManager.currentUser.company.subscription;

    if(subscription.active || path == "/subscriptionv1" || path == "/add-ons"){
      return false
    }

    const paymentFlow = subscription.payment_flow

    let addOn = this._getAddOn()
    if(paymentFlow == "add_on" && addOn && !addOn.enabled){
      return true
    }

    return false
  }

  _renderMobileCRM() {
    return <MobileCRM />;
  }

  render() {
    if (window.location.href.indexOf("/leads-mobile") > -1) {
      return this._renderMobileCRM();
    }

    let addOn = this._getAddOn()
    let subscription = AuthManager.currentUser.company.subscription;

    let showSubscriptionAlertModal = this._getShowSubscriptionAlert()
    let showSubscriptionActivateModal = this._getShowSubscriptionActivateModal()

    return (
      <>
        <MobileHeader />

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <LeftMenu />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />

              <div id="kt-content-top" />

              {/* begin:: Content */}
              <div
                className={
                  window.location.pathname == "/leads" ||
                  window.location.pathname == "/leads-mobile"
                    ? "kt-content  kt-grid__item kt-grid__item--fluid pt-0 pb-1"
                    : "kt-content  kt-grid__item kt-grid__item--fluid"
                }
                id="kt_content"
              >
                <div
                  className={
                    window.location.pathname == "/leads" ||
                    window.location.pathname == "/leads-mobile"
                      ? " "
                      : "kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid"
                  }
                >
                  <div
                    className={
                      window.location.pathname == "/leads" ||
                      window.location.pathname == "/leads-mobile"
                        ? "kt-container "
                        : ""
                    }
                  >
                    <PropagationAlert />
                    <TransferDomainAlert />
                    <SubscriptionAlert />
                  </div>

                  <Switch>
                    {/* }<Route exact path="/" component={Overview} /> */}
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/guests" component={Invites} />
                    <Route exact path="/emails" component={Emails} />
                    <Route exact path="/pages" component={Pages} />
                    <Route exact path="/team" component={Team} />

                    <Route exact path="/subscriptionv1" component={Subscription} />
                    <Route exact path="/add-ons" component={SubscriptionV2} />

                    <Route exact path="/sales" component={Sales} />

                    <Route exact path="/payments" component={Payments} />

                    <Route exact path="/products" component={Products} />
                    <Route exact path="/products/add" component={Product} />
                    <Route
                      exact
                      path="/products/:product_slug"
                      component={Product}
                    />

                    <Route exact path="/coupons" component={Coupons} />

                    <Route exact path="/settings" component={Settings} />
                    <Route
                      exact path="/shop/activate"
                      render={props => (
                        <Activate
                          {...props}
                          mode="shop"
                        />
                      )}
                    />
                    <Route
                      exact path="/payment/activate"
                      render={props => (
                        <Activate
                          {...props}
                          mode="payment"
                        />
                      )}
                    />
                    <Route path="/shop" component={ShopSettings} />
                    <Route
                      path="/payment"
                      render={props => (
                        <ShopSettings
                          {...props}
                          key="payment"
                          mode="payment"
                        />
                      )}
                    />

                    <Route exact path="/leads" component={Contacts} />
                    <Route
                      exact
                      path="/lead-form"
                      render={props => (
                        <Form
                          {...props}
                          key="lead-form"
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/payment-form"
                      render={props => (
                        <Form
                          {...props}
                          key="payment-form"
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/booking-form"
                      render={props => (
                        <Form
                          {...props}
                          key="booking-form"
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/leads/settings"
                      component={ContactSettings}
                    />

                    <Route exact path="/articles" component={Articles} />
                    <Route exact path="/articles/add" component={Article} />
                    <Route
                      exact
                      path="/articles/:article_slug"
                      component={Article}
                    />

                    <Route exact path="/files" component={Files} />

                    <Route exact path="/chats" component={Conversations} />

                    <Route exact path="/chats/admin" component={AdminChat} />

                    <Route exact path="/visitors" component={Visitors} />

                    <Route exact path="/chats/settings" component={ConversationSettings} />

                    <Route path="/domain" component={DomainSettings} />

                    <Route exact path="/submissions/:form_id" component={Submissions}/>
                    <Route exact path="/submissions" component={Submissions}/>

                    <Route exact path="/forms" component={Forms}/>

                    <Route exact path="/forms/:form_id" component={Form} />

                    <Route exact path="/bookings" component={Bookings} />

                    <Route exact path="/resources" component={Resources} />
                    <Route exact path="/resources/add" component={Resource} />
                    <Route
                      exact
                      path="/resources/:resource_id"
                      component={Resource}
                    />

                    <Route exact path="/services" component={Services} />
                    <Route exact path="/services/add" component={Service} />
                    <Route
                      exact
                      path="/services/:service_id"
                      component={Service}
                    />

                    <Redirect to="/404" />
                  </Switch>
                </div>

                {/*  end:: Content  */}
              </div>
            </div>
          </div>
          {/*  end:: Page  */}
          {/*  begin::Quick Panel  */}
          <QuickPanel />
          {!subscription.active &&
            <SubscriptionActivateModal
              show={showSubscriptionActivateModal}
              subscription={subscription}
              onSuccess={() => {
                let location = window.location;
                let url =
                  location.protocol + "//" + location.host + location.pathname;
                url += "?subscribed=true";
                window.location.href = url;
              }}
            />
          }
          {subscription.active &&
            <SubscriptionAlertModal
              addOn={addOn}
              show={showSubscriptionAlertModal}
              onCancel={() => this.props.history.push("/")}
              onConfirm={() => this.props.history.push("/add-ons")}
            />
          }
          {/*  end::Quick Panel  */}
          {/*  begin::Scrolltop  */}
          <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="fa fa-arrow-up" />
          </div>
        </div>
      </>
    );
  }
}
