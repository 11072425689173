import React from 'react';
import logo from './logo.svg';
import './App.css';

import { Helmet } from "react-helmet";

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ReactTableDefaults } from 'react-table'
import { toast } from 'react-toastify';

import Api from './constants/Api'
import General from './constants/General'

import Backend from './utils/Backend'

import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'

import Pagination from './pages/components/tables/Pagination'

import './assets/css/metronic-7.0.2/pages/login/login-1.css'
import './assets/css/metronic-7.0.5/kanban/kanban.css'
import './assets/css/metronic-7.0.5/label/label.css'
import './assets/plugins/global/plugins.bundle.css'
import './assets/css/style.bundle.css'
import './assets/css/skins/header/base/light.css'
import './assets/css/skins/header/menu/light.css'
import './assets/css/custom.css'
import './assets/css/skins/brand/light.css'
import './assets/css/skins/aside/light.css'
import './assets/css/wizard-2.css'
import './assets/plugins/custom/datatables/datatables.bundle.css'
import './assets/css/style.bundle-7.0.2.css'
import './assets/sass/app.scss'

import Login from './pages/Login'
import Create from './pages/Create'
import Partnership from './pages/Partnership'
import NotFound from './pages/shared/NotFound'

import $ from 'jquery'
Object.assign(ReactTableDefaults, {
  minRows: 9,
  PaginationComponent: Pagination
})

toast.configure({
  limit: 3
})

export default class App extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    let scripts = []


    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/js/custom.js')
    )
    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL + '/assets/plugins/global/plugins.bundle.js'
      )
    )
    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL + '/assets/js/scripts.bundle.js'
      )
    )
    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL + '/assets/plugins/custom/datatables/datatables.bundle.js'
      )
    )
    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/js/dashboard.js')
    )

    setTimeout(() => {
      this._addScripts(scripts)
    }, 3000)

    Backend.getBranding()
    .then(branding => {
      window.General.Branding.Name = branding.platform_name

      if(branding.logo){
        window.General.Branding.Logo = branding.logo?.original
      }

      if(branding.favicon){
        window.General.Branding.Favicon = branding.favicon?.thumbnail
      }

      window.General.Branding.Domain = branding.domain
      window.General.Branding.CrmName = branding.crm_name

      window.General.CrmPremium = branding.crm_premium
      window.General.WebsiteNakedDomain = branding.domain
      this.setState({ loading: false })
    })
    .catch(() => {
      this.setState({ loading: false })
    })
  }

  _getScript(path) {
    const script = document.createElement('script')

    script.src = path

    return script
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i]
      document.body.appendChild(script)
      await this._sleep(500)
    }
  }

  _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  render() {
    let {
      loading
    } = this.state

    if(loading){
      return null
    }

    const app = require('./pages/App').default

    return (
      <>
        <Helmet>
            <title>{ window.General.Branding.Name + " | Dashboard" }</title>
            <link rel="icon" href={ window.General.Branding.Favicon }/>
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route exact path="/account" component={Login} />
            <Route exact path="/create" component={Create} />
            <Route exact path="/create/:pkg_slug" component={Create} />
            <Route exact path="/quote-builder" component={Partnership} />
            <AuthenticatedRoute path="/" component={app} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </>
    )
  }
}
