import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import Div from "../layouts/Div"

import Select from '../common/Select';
import LocationInput from "../common/LocationInput"

import ExplainerAlert from '../alert/ExplainerAlert';

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Currency from "../../../utils/Currency"
import Notify from "../../../utils/Notify"

const CURRENCIES = [
  {
    label: "Euro",
    value: "EUR"
  },
  {
    label: "Pound Sterling",
    value: "GBP"
  },
  {
    label: "US Dollar",
    value: "USD"
  }
]

const SHIPPING_OPTIONS = [
  {
    label: "I Am Selling Services (No Shipping Required)",
    value: false
  },
  {
    label: "I Am Selling Physical Goods (Shipping Required)",
    value: true
  }
]

const SHIPPING_METHODS = [
  {
    label: "Delivery Only",
    value: "delivery"
  },
  {
    label: "Collection Only",
    value: "collection"
  },
  {
    label: "Both",
    value: "delivery_and_collection"
  }
]

const SHIPPING_FEES_OPTIONS = [
  {
    label: "I Do Not Need To Add Additional Shipping Costs To Orders",
    value: 'none'
  },
  {
    label: "I Will Add Additional Shipping Cost To Each Product",
    value: 'product'
  },
  {
    label: "I Will Add A Flat Shipping Cost To Cover Any Order",
    value: 'flat'
  }
]

const SHIPPING_FREE_THRESHOLD_OPTIONS = [
  {
    label: "Offer Free Delivery Over A Set Price",
    value: true
  },
  {
    label: "No",
    value: null
  }
]

const SHOW_PAYMENT_WIDGET_OPTIONS = [
  {
    label: "Yes, I Want To Display The Payment Widget",
    value: true
  },
  {
    label: "No, I Don't Want To Display The Payment Widget",
    value: false
  },
]

const PAYMENT_OPTIONS = [
  {
    label: "Fixed Price For All Visitors",
    value: "fixed"
  },
  {
    label: "Variable Price Decided By Visitor",
    value: "variable"
  },
]

const SHOW_CART_OPTIONS = [
  {
    label: "Yes, I Want To Display The Cart",
    value: true
  },
  {
    label: "No, I Don't Want To Display The Cart",
    value: false
  },
]



export default class ActivateShopForm extends React.Component {
  constructor(props){
    super(props)

    let maxDate = moment().subtract('years', 18).format('YYYY-MM-DD')

    this.state = {
      maxDate,
      mode: props.mode,
      show: props.show,
      loading: false,
      title: props.title,
      confirmButtonText: props.confirmButtonText,
      paymentForm: props.paymentForm,
      data: this._getInitialConnectData(props)
    }
  }

  _getInitialConnectData(props){
    if(props.shop){
      return { ...props.shop }
    }

    let data = {
        currency: CURRENCIES[0].value,
        shipping_enabled: props.mode === "payment" ? false : null,
        shipping_methods: null,
        shipping_fees_type: props.mode === "payment" ? 'none' : null,
        shipping_free_threshold: "null",
        flat_shipping_fee: 0,
        location: null,
        hide_payment_wiget: false,
        hide_cart: false,
    }
    let user = AuthManager.currentUser.user

    let processorData = {}
    if(window.General.PlatformPaymentMethod.processor == "boipa"){
      processorData = {
        merch_id: null,
        brand_id: null,
        password: null
      }
    }
    else{
      processorData = {
        country_short: "IE",
        business_type: "individual",
        representative: {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone: "+353",
          address: null,
          relationship: {
            owner: false,
            director: false,
            executive: false,
          },
          verification: {

          }
        },
        persons: []
      }
    }

    data.processor_data = processorData
    return data
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e){
    let data = this.state.data
    let value = e.target.value

    if(value === "true"){
      value = true
    }
    if(value === "false"){
      value = false
    }

    data[e.target.name] = value
    this.setState({ data, error: null })
  }

  _isFormValid(){
    let {
      data,
    } = this.state

    let error = null

    if(data.shipping_enabled == null){
      error = "Please select what you are selling"
    }
    else if(data.shipping_enabled && data.shipping_methods == null){
      error = "Please select a delivery option"
    }
    else if((data.shipping_enabled && data.shipping_methods) && data.shipping_methods.indexOf("delivery") > -1 && data.shipping_fees_type == null){
      error = "Please select a shipping cost option"
    }
    else if(data.shipping_fees_type !== 'none' && data.shipping_free_threshold == "null"){
      error = "Please select whether you offer free shipping or not"
    }
    else if((data.shipping_enabled && data.shipping_methods) && data.shipping_methods.indexOf("collection") > -1){
      let location = data.location
      if(!location){
        error = "You must enter a collection point to enable collection"
      }
      else if(!location.city || !location.state || !location.country){
        error = "Please enter a more specific collection point"
      }
    }

    if(error){
      throw { error, message: error}
    }

    return true
  }

  _isConnectFormValid(){
    let {
      data,
    } = this.state

    if(window.General.PlatformPaymentMethod.processor == "boipa"){
      return this.isBoipaConnectFormValid()
    }

    let {
      address,
      dob,
      phone
    } = data.processor_data.representative

    let error = null

    if(!address){
      error = "Please enter a more specific home address"
    }
    else if(!address.city || !address.state || !address.country){
      error = "Please enter a more specific home address"
    }
    else if(!dob || !dob.year){
      error = "Please enter a date of birth"
    }
    else if(!phone || phone.length < 9){
      error = "Please enter a valid phone number"
    }

    if(error){
      throw { error, message: error}
    }

    return true
  }

  isBoipaConnectFormValid(){
    let {
      data,
    } = this.state

    let {
      merch_id,
      brand_id,
      password
    } = data.processor_data.terminals?.default || {}

    let error = null

    if(!merch_id){
      error = "Please enter a Merchant ID"
    }
    else if(!brand_id){
      error = "Please enter a Brand ID"
    }
    else if(!password){
      error = "Please enter a Password"
    }

    if(error){
      throw { error, message: error}
    }

    return true
  }

  _isUpdateFormValid(){
    let {
      data,
    } = this.state

    return true
  }

  async save(){
    this._isFormValid()

    if(!this.props.shop){
      this._isConnectFormValid()
      return this._createShop()
    }

    this._isUpdateFormValid()
    return this._updateShop()
  }

  _createShop(){
    let {
      data
    } = this.state

    if(data.location == null){
      delete data.location
    }

    if(data.shipping_methods == null){
      data.shipping_methods = "delivery"
    }
    if(data.shipping_fees_type == null){
      data.shipping_fees_type = "none"
    }
    if(data.shipping_free_threshold == "null"){
      data.shipping_free_threshold = null
    }

    this.setState({ loading: true })
    return Backend.createShop(data)
    .then(shop => {
      this.setState({ loading: false })
      return shop
    })
    .catch(error => {
      this.setState({ loading: false })
      throw error
    })
  }

  _updateShop(){
    let {
      data,
      paymentForm
    } = this.state

    if(data.location == null){
      delete data.location
    }

    let shop = null
    this.setState({ loading: true })
    return Backend.updateShop(this.props.shop, data)
    .then(response => {
      this.setState({ loading: false })
      shop = response
      if(paymentForm){
        return Backend.updateForm(paymentForm)
      }
      return shop
    })
    .then(() => {
      return shop
    })
    .catch(error => {
      this.setState({ loading: false })
      throw error
    })
  }

  _askDeliveryCharge(){
    let {
      data
    } = this.state
    return data.shipping_enabled && data.shipping_methods && data.shipping_methods != "collection"
  }

  _askLocation(){
    let {
      data
    } = this.state

    return (data.shipping_enabled && data.shipping_methods)  && data.shipping_methods.indexOf("collection") > -1
  }

  _askFreeDelivery(){
    let {
      data
    } = this.state

    return this._askDeliveryCharge() && data.shipping_fees_type !== 'none' && data.shipping_fees_type !== null
  }

  _askFlatShippingFee(){
    let {
      data
    } = this.state

    return data.shipping_fees_type === 'flat'
  }

  _askShippingFreeThreshold(){
    let {
      data
    } = this.state

    return this._askFreeDelivery() && data.shipping_free_threshold != null && data.shipping_free_threshold != "null"
  }

  _renderKYC(){
    let {
      data,
      maxDate,
      loading,
    } = this.state

    if(this.props.shop){
      return null
    }

    let processorData = data.processor_data

    console.log("@@@", window.General.PlatformPaymentMethod.processor)
    if(window.General.PlatformPaymentMethod.processor == "boipa"){
      let terminals = processorData.terminals || {}
      data.processor_data.terminals = terminals

      let defaultTerminal = terminals.default || {}
      data.processor_data.terminals.default = defaultTerminal
      console.log("YYY", defaultTerminal)

      return (
        <>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Merchant ID</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="merch_id"
                className="form-control"
                defaultValue={ defaultTerminal.merch_id }
                onChange={e => {
                  defaultTerminal.merch_id = e.target.value || null
                  data.processor_data.terminals.default = defaultTerminal
                  this.setState({ data })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Brand ID</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="brand_id"
                className="form-control"
                defaultValue={ defaultTerminal.brand_id }
                onChange={e => {
                  defaultTerminal.brand_id = e.target.value || null
                  data.processor_data.terminals.default = defaultTerminal
                  this.setState({ data })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Password</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="password"
                className="form-control"
                defaultValue={ defaultTerminal.password }
                onChange={e => {
                  defaultTerminal.password = e.target.value || null
                  data.processor_data.terminals.default = defaultTerminal
                  this.setState({ data })
                }}
              />
            </div>
          </div>
        </>
      )
    }

    return (
        <>
          <LocationInput
            className="form-control form-control-solid"
            allowManualInput={true}
            location={data.processor_data.representative.address}
            placeholder="Your Home Address"
            onUpdated={address => {
              address = {
                line1: address.address_line_1,
                line2: address.address_line_2,
                city: address.city,
                state: address.state,
                country: address.country_short,
                country_short: address.country_short,
                postal_code: address.postal_code
              }
              data.processor_data.country_short = address.country_short
              data.processor_data.representative.address = address
              this.setState({ data })
            }}
          />

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Your Date Of Birth</label>
            <div className="col-lg-9 my-auto">
              <Flatpickr
                className="form-control form-control-solid"
                options={{
                  maxDate,
                  enableTime: false,
                  noCalendar: false,
                  dateFormat: "Y-m-d",
                }}
                onChange={dates => {
                  let date = moment(dates[0])
                  let dob = {
                    year: date.format("YYYY"),
                    day: date.format("DD"),
                    month: date.format("MM")
                  }
                  data.processor_data.representative.dob = dob
                  this.setState({ data })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Your Mobile Phone</label>
              <div className="col-lg-9 my-auto">
                <PhoneInput
                  inputClass="form-control form-control-solid w-100"
                  defaultCountry={'IE'}
                  value={data.processor_data.representative.phone}
                  onChange={(value, phoneData) => {
                    data.processor_data.representative.phone = "+"+value
                    this.setState({ data })
                  }}
                  inputProps={{ autocomplete: 'off' }}
                />
              </div>
          </div>
        </>
    )
  }

  _getPriceField(paymentForm){
    for(var i = 0; i < paymentForm.form_fields.length; i++){
      const row = paymentForm.form_fields[i]
      for(var j = 0; j < row.length; j++){
        const field = row[j]
        if(field.type == "price"){
          return field
        }
      }
    }
    return null
  }

  _renderCurrencySelector(){
    let { data } = this.state

    return (
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">Select Your Currency</label>
        <div className="col-lg-9 my-auto">
          <Select
            value={CURRENCIES.find(currency => currency.value == data.currency)}
            className="async-select-paginate"
            classNamePrefix="async-select-paginate"
            name="currency"
            onChange={option => {
              data.currency = option.value
              this.setState({ data })
            }}
            options={CURRENCIES}
          />
        </div>
      </div>
    )
  }

  _renderPaymentFields(){
    let { data, paymentForm, shop } = this.state

    if(!paymentForm){
      return this._renderCurrencySelector()
    }

    let priceField = this._getPriceField(paymentForm)

    if(!priceField){
      return null
    }

    let askPrice = priceField.price != null
    return (
      <>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Show Payment Widget</label>
          <div className="col-lg-9 my-auto">
            <Select
              value={SHOW_PAYMENT_WIDGET_OPTIONS.find(paymentWidgetOption => {
                return paymentWidgetOption.value !== data.hide_payment_widget
              })}
              className="async-select-paginate"
              classNamePrefix="async-select-paginate"
              name="currency"
              onChange={option => {
                data.hide_payment_widget = !option.value
                this.setState({ data })
              }}
              options={SHOW_PAYMENT_WIDGET_OPTIONS}
            />
          </div>
        </div>

        { !data.hide_payment_widget &&
          <>
            { this._renderCurrencySelector() }

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">How Do You Want To Charge?</label>
              <div className="col-lg-9 my-auto">
                <Select
                  value={PAYMENT_OPTIONS.find(paymentOption => {
                    let currentValue = priceField.price == null ? "variable" : "fixed"
                    return paymentOption.value === currentValue
                  })}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  name="currency"
                  onChange={option => {
                    priceField.price = option.value == "fixed" ? "" : null
                    this.setState({ paymentForm })
                  }}
                  options={PAYMENT_OPTIONS}
                />
              </div>
            </div>

            { askPrice &&
             <div className="form-group row">
               <label className="col-lg-3 col-form-label">Fixed Price</label>
               <div className="col-lg-9 my-auto">
                 <div className="input-group input-group-solid">
                   <span className="input-group-addon">
                     { Currency.getSymbol(data.currency) }
                   </span>
                   <input
                     name="flat_shipping_fee"
                     className="form-control form-control-solid kt-selectpicker"
                     placeholder="Price"
                     defaultValue={ priceField.price ? priceField.price / 100 : ""}
                     onChange={e => {
                       let value = Math.round(parseFloat(e.target.value) * 100)
                       priceField.price = value
                       this.setState({ paymentForm })
                     }}
                   />
                 </div>
               </div>
             </div>
           }
          </>
        }
      </>
    )
  }


  _renderContent(){
    let {
      show,
      data,
      error,
      maxDate,
      loading,
      mode,
    } = this.state

    let askShippingMethods = data.shipping_enabled
    let askDeliveryCharge = this._askDeliveryCharge()
    let askLocation = this._askLocation()
    let askFreeDelivery = this._askFreeDelivery()
    let askShippingFreeThreshold = this._askShippingFreeThreshold()
    let askFlatShippingFee = this._askFlatShippingFee()

    return (
      <>
        { mode == "payment" && this._renderPaymentFields() }

        { mode == "shop" &&
          <>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Show Cart</label>
              <div className="col-lg-9 my-auto">
                <Select
                  value={SHOW_CART_OPTIONS.find(cartOption => {
                    return cartOption.value !== data.hide_cart
                  })}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  name="currency"
                  onChange={option => {
                    data.hide_cart = !option.value
                    this.setState({ data })
                  }}
                  options={SHOW_CART_OPTIONS}
                />
              </div>
            </div>

            { this._renderCurrencySelector() }

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">What Are You Selling?</label>
              <div className="col-lg-9 my-auto">
                <Select
                  value={SHIPPING_OPTIONS.find(option => option.value === data.shipping_enabled)}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  onChange={option => {

                    if(option.value === false){
                      data.shipping_enabled = false
                      data.shipping_fees_type = null
                      data.shipping_free_threshold = null
                    }
                    else{
                      data.shipping_enabled = true
                    }
                    this.setState({ data })
                  }}
                  options={SHIPPING_OPTIONS}
                />
              </div>
            </div>

            { askShippingMethods &&
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Delivery Options</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={SHIPPING_METHODS.find(option => option.value === data.shipping_methods)}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    placeholder="Please Select A Delivery Option"
                    onChange={option => {
                      data.shipping_methods = option.value
                      if(data.shipping_methods === "delivery"){
                        data.location = null
                      }
                      this.setState({ data })
                    }}
                    options={SHIPPING_METHODS}
                  />
                </div>
              </div>
            }

            { askLocation &&
                <LocationInput
                  className="form-control form-control-solid"
                  location={data.location}
                  placeholder="Collection Point Address"
                  allowManualInput={true}
                  onUpdated={address => {
                    data.location = address
                    this.setState({ data })
                  }}
                />
            }

            { askDeliveryCharge &&
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">How Do You Want To Manage Shipping Costs</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={SHIPPING_FEES_OPTIONS.find(option => option.value === data.shipping_fees_type)}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    placeholder="Please Select An Option"
                    onChange={option => {
                      data.shipping_fees_type = option.value
                      this.setState({ data })
                    }}
                    options={SHIPPING_FEES_OPTIONS}
                  />
                </div>
              </div>
            }

            { askFlatShippingFee &&
             <div className="form-group row">
               <label className="col-lg-3 col-form-label">Flat Shipping Cost</label>
               <div className="col-lg-9 my-auto">
                 <div className="input-group input-group-solid">
                   <span className="input-group-addon">
                     { Currency.getSymbol(data.currency) }
                   </span>
                   <input
                     name="flat_shipping_fee"
                     className="form-control form-control-solid kt-selectpicker"
                     defaultValue={ data.flat_shipping_fee / 100}
                     onChange={e => {
                       let value = Math.round(parseFloat(e.target.value) * 100)
                       data.flat_shipping_fee  = value
                       this.setState({ data })
                     }}
                   />
                 </div>
               </div>
             </div>
           }

            { askFreeDelivery &&
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Free Delivery Available?</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={SHIPPING_FREE_THRESHOLD_OPTIONS.find(option => {
                      return option.value === data.shipping_free_threshold > 0 ? true : null
                    })}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    placeholder="Please Select An Option"
                    onChange={option => {
                      if(option.value == null){
                        data.shipping_free_threshold = null
                      }
                      else{
                        data.shipping_free_threshold = 10000
                      }
                      this.setState({ data })
                    }}
                    options={SHIPPING_FREE_THRESHOLD_OPTIONS}
                  />
                </div>
              </div>
            }

            { askShippingFreeThreshold &&
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">At what price do you offer Free Delivery?</label>
                <div className="col-lg-9 my-auto">
                  <div className="input-group input-group-solid">
                    <span className="input-group-addon">
                      { Currency.getSymbol(data.currency) }
                    </span>
                    <input
                      name="shipping_free_threshold"
                      className="form-control form-control-solid kt-selectpicker"
                      defaultValue={ data.shipping_free_threshold / 100}
                      onChange={e => {
                        let value = e.target.value ? parseInt(e.target.value) * 100 : 0
                        data.shipping_free_threshold  = value
                        this.setState({ data })
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          </>
        }
      </>
    )
  }

  _renderSection(title, content){
    let {
      loading
    } = this.state

    if(this.props.card){
      return (
        <div className="kt-portlet">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                {title}
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            { content }
          </div>
        </div>
      )
    }

    return (
      <Div
        className="kt-wizard-v2__content"
        data-ktwizard-type="step-content"
        data-ktwizard-state="current"
        disabled={loading}
        spinner={false}
      >
        <div className="kt-heading kt-heading--md">
          { title }
        </div>
        <div className="kt-form__section kt-form__section--first">
          <div className="kt-wizard-v2__form">
            { content }
          </div>
        </div>
      </Div>
    )
  }

  render(){
    let {
      data,
      title,
      loading,
      confirmButtonText
    } = this.state

    return (
      <>
        <Div
          className={this.props.containerClassName}
          disabled={loading}
          spinner={true}
          loadingText={this.props.loadingText}
        >
          { this._renderSection(title, this._renderContent()) }

          { !this.props.shop &&
            this._renderSection("Verification", (
              <>
                <ExplainerAlert
                  text={`${window.General.Branding.Name} uses ${window.General.Branding.ProcessorName} in order to process payments, please enter your details and connect your bank.`}
                />
                { this._renderKYC() }
              </>
            ))

          }

          <div className="kt-portlet__foot" style={{ borderTop: 0 }}>
            <div className="kt-form__actions pull-right">
              <button
                type="button"
                className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                disabled={loading}
                onClick={() => this.props.onConfirmPressed()}
              >
                { confirmButtonText }
              </button>
            </div>
          </div>

        </Div>
      </>
    )
  }
}

ActivateShopForm.defaultProps = {
  title: "Start Selling",
  card: true,
  mode: "shop",
  loadingText: "Loading...",
  confirmButtonText: "Update",
  containerClassName: "col-lg-8"
}


const COUNTRYES = [
  {name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Belgium', code: 'BE'},
  {name: 'Brazil ', code: 'BR'},
  {name: 'Canada', code: 'CA'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'Germany', code: 'DE'},
  {name: 'Hong Kong', code: 'HK'},
  {name: 'Ireland', code: 'IE'},
  {name: 'Japan', code: 'JP'},
  {name: 'Luxembourg', code: 'LU'},
  {name: 'Mexico ', code: 'MX'},
  {name: 'Netherlands', code: 'NL'},
  {name: 'New Zealand', code: 'NZ'},
  {name: 'Norway', code: 'NO'},
  {name: 'Singapore', code: 'SG'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States', code: 'US'},
  {name: 'Italy', code: 'IT'},
  {name: 'Portugal', code: 'PT'}
]
