import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import AddProductEngagementCard from './components/cards/AddProductEngagementCard'
import PromoteShopEngagementCard from './components/cards/PromoteShopEngagementCard'

import SalesTable from './components/tables/SalesTable'

export default class Sales extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      showAddProductEngagementCard: true,
      showPromoteShopEngagementCard: true
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    this.setState({ loading: true })
    Backend.getShop(AuthManager.currentWebsite)
    .then(shop => {
      this.setState({
        shop,
        loading: false
      })
    })
    .catch(error => {
      //Notify.error(error.message)
      this.setState({ loading: false })
    })
  }


  render() {
    let {
      shop,
      loading,
    } = this.state

    if(loading){
      return null
    }

    if(!shop || shop.products_no === 0){
      return (
        <div className="row">
          <AddProductEngagementCard
            onCtaPressed={() =>  this.props.history.push(`/products/add`)}
          />
        </div>
      )
    }

    if(shop.sales_no == 0){
      return (
        <div className="row">
          <PromoteShopEngagementCard
            onCtaPressed={() => {
              let url = "//"+shop.website.slug + "." + global.General.WebsiteNakedDomain + "/shop"
              window.open(url);
            }}
          />
        </div>
      )
    }

    return (
      <div className="row">
        <SalesTable/>
      </div>
    )
  }
}
