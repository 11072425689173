import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import Select from '../common/Select';
import Div from "../layouts/Div"

import LocationInput from "../common/LocationInput"

import Person from "./Person"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import General from "../../../utils/General"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

export default class ConnectForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      loading: true,
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    Backend.getConnectData()
    .then(data => {
      if(data == null){
        data = {
          country_short: "IE",
          currency: "EUR",
          business_type: null,
          relationship: {
            location: {}
          },
          persons: [],
          company: {}
        }
      }

      this.setState({ data, loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _handleChange(e){
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _handleChangeCompany(e){
    let data = this.state.data
    let value = e.target.value
    if(e.target.value === "on" || e.target.value === "off" ){
      value = e.target.checked
    }
    data.company[e.target.name] = value
    this.setState({ data, error: null })
  }

  handleSave(){
    let {
      data
    } = this.state

    this.setState({ loading: true })

    Backend.updateConnect(data)
    .then(data => {

      this.setState({ data, loading: false })
      Notify.success("Details updated successfully")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _renderMembers(){
    let {
      data
    } = this.state

    if(data.business_type === "individual"){
      return this._renderIndividual()
    }

    if(data.business_type !== "company"){
      return null
    }

    let persons = data.persons
    return (
      <>
        <div className="row">
          { persons.map((person, index) => {
              return (
                <>
                  <Person
                    data={person}
                    typeEditable={true}
                    canDelete={true}
                    type="person"
                    title={`Person #${index + 1}`}
                    onUpdated={person => {
                      data.persons[index] = person
                      this.setState({ data })
                    }}
                    onDeletePressed={() => {
                      data.persons.splice(index, 1)
                      this.setState({ data })
                    }}
                  />
                  <div className="col-md-6" hidden/>
                </>
              )
            })
          }
        </div>
        <div className="row mb-4">
          <div className="col">
            <a
              className="btn btn--primary btn-label pull-right"
              onClick={() => {
                let aa = Person.getData()
                data.persons.push(aa)
                this.setState({ data })
              }}
            >
              Add Person
            </a>
          </div>
          <div className="col-md-3 col-lg-3"  hidden>
            <h2>Any{data.persons.length == 0 ? " " : " Other "}Directors / Execs?</h2>
          </div>
        </div>
      </>
    )
  }

  _renderIndividual(){
    let {
      data
    } = this.state
    return (
      <div className="row">
        <Person
          data={data.representative}
          type="individual"
          title="Personal Information"
          onUpdated={representative => {
            data.representative = representative
            this.setState({ data })
          }}
        />
        <div className="col-md-3 col-lg-3" hidden>
          <h2>Tell us about yourself.</h2>
          <p>For security and fraud prevention we need to get a few details from you. These details will not be made public to your customers.</p>
        </div>
      </div>
    )
  }

  _renderCompanyInfo(){
    let {
      data
    } = this.state

    let company = data.company || {}


    if(data.business_type !== "company"){
      return null
    }

    let address = company.address
    let location = (address && address.line1) ? {
      ...address,
      raw: `${address.line1}, ${address.line2}, ${address.City}, ${address.state}, ${address.country}`,
      address_line_1: address.line1,
      address_line_2: address.line2,
    } : null

    return (
      <div className="row">
        <div className="col">

          <div className="kt-heading kt-heading--md">
            Company
          </div>
          <div className="kt-form__section kt-form__section--first">

            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={company.name}
                onChange={e => this._handleChangeCompany(e)}
              />
            </div>

            <div className="form-group">
              <label>Phone</label>
              <PhoneInput
                inputClass="form-control w-100"
                country={data.country_short.toLowerCase()}
                value={company.phone}
                onChange={(value, phoneData) => {
                  company.phone = "+"+value
                  data.company = company
                  this.setState({ data })
                }}
                inputProps={{ autocomplete: 'off' }}
              />
            </div>

            <div className="form-group">
              <label>Tax ID</label>
              <input
                type="text"
                name="tax_id"
                className="form-control"
                value={company.tax_id}
                onChange={e => this._handleChangeCompany(e)}
              />
            </div>

            <div className="form-group">
              <LocationInput
                className="form-control"
                allowManualInput={true}
                location={location}
                onUpdated={address => {
                  address = {
                    line1: address.address_line_1,
                    line2: address.address_line_2,
                    city: address.city,
                    state: address.state,
                    country: address.country,
                    country_short: address.country_short,
                    postal_code: address.postal_code
                  }
                  data.address = address
                  this.setState({ data })
                }}
              />
            </div>

          </div>

        </div>


        <div className="col-md-3 col-lg-3" hidden>
          <h2>Tell us about your company.</h2>
          <p>For security and fraud prevention we need to get a few details from you. These details will not be made public to your customers.</p>
        </div>

      </div>
    )
  }

  _renderCompanyRepresentative(){
    let {
      data
    } = this.state

    let company = data.company || {}

    if(data.business_type !== "company"){
      return null
    }

    return (
      <div className="row">
        <Person
          data={data.representative}
          type="representative"
          title="Company Representative"
          onUpdated={representative => {
            data.representative = representative
            this.setState({ data })
          }}
        />

        <div className="col-md-3 col-lg-3" hidden>
          <h2>Main Point of Contact.</h2>
          <p>This person is the nominated contact who'll be informed of any issues and can provide further details of the company if need be,</p>
        </div>

      </div>
    )
  }

  _renderCompanyExtra(){
    let {
      data
    } = this.state

    let company = data.company || {}

    if(data.business_type !== "company"){
      return null
    }

    return (
      <div className="row mb-5">
        <div className="col">

          <div className="kt-heading kt-heading--md">
            Submission
          </div>
          <div className="kt-form__section kt-form__section--first">

            <div className="col-md-12">
              <label>Details Provided:</label>
              <br/>
              <div class="kt-checkbox-list">
                <label class="kt-checkbox kt-checkbox--brand">
                  <input
                    type="checkbox"
                    name="directors_provided"
                    checked={company.directors_provided}
                    onChange={e => this._handleChangeCompany(e)}
                  />
                    Information on all Directors
                  <span></span>
                </label>
              </div>
              <div class="kt-checkbox-list">
                <label class="kt-checkbox kt-checkbox--brand">
                  <input
                    type="checkbox"
                    name="executives_provided"
                    checked={company.executives_provided}
                    onChange={e => this._handleChangeCompany(e)}
                  />
                    Information on all Executives
                  <span></span>
                </label>
              </div>
              <div class="kt-checkbox-list">
                <label class="kt-checkbox kt-checkbox--brand">
                  <input
                    type="checkbox"
                    name="owners_provided"
                    checked={company.owners_provided}
                    onChange={e => this._handleChangeCompany(e)}
                  />
                    Information on all Owners
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  _renderRequirements(requirements){
    let {
      data,
    } = this.state

    if(data == null){
      return null
    }

    let requirementsDue = requirements.eventually_due.map(requirement => {
      switch(requirement){
        case "external_account":                             return "Bank Account"
        case "individual.verification.additional_document":  return "Address Verification"
        case "individual.verification.document":             return "ID Verification"
        default:                                             return General.toTitleCase(requirement)
      }
    })

    return (
      <>
        <div className="kt-heading kt-heading--md">
          Status
        </div>
        <div className="kt-form__section kt-form__section--first">

          { requirementsDue.length === 0 &&
            <p>Your business is fully verified</p>
          }
          { requirementsDue.length > 0 &&
            <>
              { !data.charges_enabled &&
                <p class="shop-charges-disabled"><b>Your shop has been disabled from accepting payments/purchases</b></p>
              }
              { data.charges_enabled &&
                <p class="shop-charges-enabled"><b>Your shop is in danger of losing the ability to accept payments/purchases</b></p>
              }
              <p>We require the following information to verify your shop:</p>
              <ul>
                { requirementsDue.map(requirement => (
                    <li>{requirement}</li>
                  ))
                }
              </ul>
            </>
          }
        </div>
      </>
    )
  }

  _renderContent(){
    let {
      data
    } = this.state

    if(data == null){
      return null
    }

    let countrySelected = COUNTRYES.find(country => country.code === data.country_short)
    let countrySelectedOption = {
      label: countrySelected.name,
      value: countrySelected.code
    }

    return (
      <>
        { this._renderRequirements(data.requirements)}
        { data.requirements.eventually_due.length > 0 &&
          <>
            <div className="kt-heading kt-heading--md">
              Basic
            </div>
            <div className="kt-form__section kt-form__section--first">
              <div className="kt-wizard-v2__form">
                <div className="form-group">
                  <label>Country</label>
                  <Select
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    value={countrySelectedOption}
                    options={COUNTRYES.map(country => {
                      return {
                        label: country.name,
                        value: country.code
                      }
                    })}
                    isDisabled
                  />
                </div>

                <div className="form-group">
                  <label>Business Type</label>
                  <Select
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    value={BUSINESS_TYPES.find(type => type.value === data.business_type)}
                    options={BUSINESS_TYPES}
                    onChange={option => {
                      data.business_type = option.value
                      this.setState({ data })
                    }}
                  />
                </div>

              </div>

              <div className="col-md-3 col-lg-3" hidden>
                <h2>Basic Info</h2>
                <p>We need a few details to help us secure your shop.</p>
              </div>

            </div>

            { this._renderCompanyInfo() }

            { this._renderCompanyRepresentative()}

            { this._renderMembers()}

            { this._renderCompanyExtra() }

            { data.business_type &&

              <div className="row">
                <div className="col-md-9">
                  <a
                    href=""
                    className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                    onClick={e => {
                      e.preventDefault()
                      this.handleSave()
                    }}
                  >
                    Update
                  </a>
                </div>
              </div>
            }
          </>
        }
      </>
    )
  }

  render(){
    let {
      show,
      data,
      error,
      loading,
    } = this.state

    return (
      <Div
        spinner={true}
        disabled={loading}
      >
        { this._renderContent() }
      </Div>
    )
  }
}

const BUSINESS_TYPES = [
  { label: 'Individual', value: 'individual'},
  { label: 'Company', value: 'company'},
]

const COUNTRYES = [
  //{name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Belgium', code: 'BE'},
  //{name: 'Brazil ', code: 'BR'},
  //{name: 'Canada', code: 'CA'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'Germany', code: 'DE'},
  //{name: 'Hong Kong', code: 'HK'},
  {name: 'Ireland', code: 'IE'},
  //{name: 'Japan', code: 'JP'},
  {name: 'Luxembourg', code: 'LU'},
  //{name: 'Mexico ', code: 'MX'},
  {name: 'Netherlands', code: 'NL'},
  //{name: 'New Zealand', code: 'NZ'},
  {name: 'Norway', code: 'NO'},
  //{name: 'Singapore', code: 'SG'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States', code: 'US'},
  {name: 'Italy', code: 'IT'},
  {name: 'Portugal', code: 'PT'}
]
