import React from 'react'
import Modal from 'react-bootstrap/Modal'
import slug from 'limax';

import Select from '../common/Select'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Flatpickr from "react-flatpickr";
import Switch from "react-switch";
import Notify from "../../../utils/Notify";
import Currency from "../../../utils/Currency";
import moment from "moment";

const TYPES = [
  {
    label: "Percentage",
    value: "percentage"
  },
  {
    label: "Fixed Amount",
    value: "fixed_amount"
  },
  {
    label: "Free Shipping",
    value: "free_shipping"
  },
]

export default class CouponModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      shop: props.shop,
      loading: false,
      error: null,
      data: props.coupon ? props.coupon : {website: AuthManager.currentWebsite.id, active: true}
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _isFormValid(){
    let {
      code,
      type,
      amount,
      usage_limit,
      min_threshold,
      max_threshold,
    } = this.state.data

    let error = null
    if(code == null || code.length < 5){
      error = "Please enter a 5 character code"
    }else if(type == null || type.length === 0){
      error = "Please select a type"
    }else if(type !== "free_shipping" && (amount == null || amount === 0)){
      error = "Please enter a valid amount"
    }else if(type === "percentage" && amount > 100){
      error = "Please enter a valid percentage"
    }else if(usage_limit == null){
      error = "Please enter a valid usage limit"
    }else if(min_threshold && max_threshold){
      if(min_threshold >= max_threshold){
        error = "Please ensure the min spend is less than the mac spend"
      }
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleSave(){
    let {
      data
    } = this.state

    if(!this._isFormValid()){
      return
    }

    if(data.id){
      this._handleUpdateCoupon()
    }else{
      this._handleAddCoupon()
    }
  }

  _handleAddCoupon(){
    let {
      data
    } = this.state

    this.setState({ loading: true })
    Backend.addCoupon(data)
    .then(coupon => {
      this.setState({ loading: false })
      this.props.onAdded(coupon)
      Notify.success("Coupon Created")
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _handleUpdateCoupon(){
    let {
      data
    } = this.state

    this.setState({ loading: true })
    Backend.updateCoupon(data)
      .then(coupon => {
        this.setState({ loading: false })
        this.props.onAdded(coupon)
        Notify.success("Coupon Updated")
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  render() {
    let {
      show,
      shop,
      loading,
      data,
      error
    } = this.state

    let editMode = data.id != null
    let title = editMode ? 'Update Coupon' : 'Create Coupon'
    let amount = data.type === "percentage" ? data.amount : data.amount/100
    let symbol = data.type === "percentage" ? '(%)' : `(${Currency.getSymbol(shop.currency)})`
    let minSpend = data.min_threshold ? data.min_threshold/100 : ''
    let maxSpend = data.max_threshold ? data.max_threshold/100 : ''

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <div className="form-group row">
              <span className="error-message">{ error }</span>
            </div>
          }

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Code</label>
            <div className="col-lg-8 my-auto">
              <input
                type="text"
                name="code"
                className="form-control form-control-solid"
                maxLength={5}
                value={ data.code }
                onChange={e => this._handleChange(e)}
                disabled={editMode}
              />
              <small>A coupon must be 5 characters.</small>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Type</label>
            <div className="col-lg-8 my-auto">
              <Select
                value={TYPES.find(type => type.value === data.type)}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                name="type"
                placeholder="Please Select A Type"
                onChange={option => {
                  data.type = option.value
                  this.setState({ data })
                }}
                options={TYPES}
                isDisabled={editMode}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Active</label>
            <div className="col-lg-8 my-auto">
              <Switch
                className="react-switch c-switch float-right"
                onChange={checked => {
                  data.active = checked
                  this.setState({ data })
                }}
                checked={data.active}
                aria-labelledby="neat-label"
                onColor="#333333"
                offColor="#F3F6F9"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Amount <small>{symbol}</small></label>
            <div className="col-lg-8 my-auto">
              <input
                type="number"
                name="amount"
                className="form-control form-control-solid"
                value={ amount }
                onChange={e => {
                  if(data.type === "percentage"){
                    data.amount = e.target.value
                  }else if(data.type === "fixed_amount"){
                    data.amount = Math.round(parseFloat(e.target.value) * 100)
                  }
                  this.setState({data})
                }}
                disabled={!data.type || data.type === "free_shipping"}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Limit</label>
            <div className="col-lg-8 my-auto">
              <input
                type="number"
                name="usage_limit"
                className="form-control form-control-solid"
                value={ data.usage_limit }
                onChange={e => this._handleChange(e)}
              />
              <small>(-1 For Infinite)</small>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Min Spend</label>
            <div className="col-lg-8 my-auto">
              <input
                type="number"
                name="min_threshold"
                className="form-control form-control-solid"
                value={ minSpend }
                onChange={e => {
                  data.min_threshold = Math.round(parseFloat(e.target.value) * 100)
                  this.setState({data})
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Max Spend</label>
            <div className="col-lg-8 my-auto">
              <input
                type="number"
                name="max_threshold"
                className="form-control form-control-solid"
                value={ maxSpend }
                onChange={e => {
                  data.max_threshold = Math.round(parseFloat(e.target.value) * 100)
                  this.setState({data})
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-4 col-form-label">Expiry Date</label>
            <div className="col-lg-8 my-auto">
                <div className='react-flatpickr full-width'>
                  <Flatpickr
                    className="form-control form-control-solid"
                    value={data.expires_at ? moment(data.expires_at).toDate() : null}
                    options={{
                      enableTime: false,
                      noCalendar: false,
                      dateFormat: "d/m/Y",
                      static: true,
                    }}
                    onChange={dates => {
                      data.expires_at = moment(dates[0]).format('YYYY-MM-DD')
                      this.setState({data})
                    }}
                  />
              </div>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._handleSave()}
          >
            {title}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
