import React, { Component } from "react";

import LazyLoadingList from "../common/LazyLoadingList";

import LoadingCard from "../LoadingCard";

import ConversationListItem from "./ConversationListItem";

import General from "../../../utils/General";
import Backend from "../../../utils/Backend";

export class ConversationsList extends Component {
  constructor(props) {
    super(props);

    let preSelectedConversationId = General.getQueryParam("chat_id");

    this.state = {
      searchTerm: "",
      preSelectedConversationId,
    };

    this.lazyLoadingList = React.createRef();
  }

  componentDidMount() {
    let { preSelectedConversationId } = this.state;

    if (!preSelectedConversationId) {
      return;
    }

    Backend.getConversation(preSelectedConversationId).then((conversation) => {
      this.props.onClick(conversation);
    });
  }

  refresh() {
    this.lazyLoadingList.current.refresh();
  }

  _updateSearchTerm = General.debounce(
    (searchTerm) => {
      this.setState({
        searchTerm,
      });
    },
    1000,
    false
  );

  _renderPlaceholderCards() {
    return (
      <>
        <LoadingCard />
        <LoadingCard />
      </>
    );
  }

  _renderEmptyState() {
    return (
      <div className="no-content">
        <p>Ooops, no chats found...</p>
        <button
          class="btn btn-primary"
          onClick={() => (window.location.href = "/visitors")}
        >
          Start A New Chat
        </button>
      </div>
    );
  }

  render() {
    let { searchTerm, preSelectedConversationId } = this.state;
    return (
      <div className="kt-portlet kt-portlet--last">
        <div className="kt-portlet__body">
          <div className="search kt-input-icon kt-input-icon--left">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={(e) => this._updateSearchTerm(e.target.value)}
            />
            <span className="kt-input-icon__icon kt-input-icon__icon--left">
              <span>
                <i className="la la-search"></i>
              </span>
            </span>
          </div>

          <div className="kt-widget kt-widget--users kt-mt-20">
            <div className="kt-scroll kt-scroll--pull">
              <div className={`kt-widget__items`}>
                <LazyLoadingList
                  ref={this.lazyLoadingList}
                  endpoint={`${window.Api.Conversations}?search_term=${searchTerm}`}
                  onItemUpdated={(conversations) => {
                    if (
                      this.props.selectedConversation == null &&
                      conversations.length > 0 &&
                      !preSelectedConversationId
                    ) {
                      this.props.onClick(conversations[0]);
                    }
                  }}
                  renderItem={(conversation) => {
                    return (
                      <ConversationListItem
                        conversation={conversation}
                        onUpdated={() => this.lazyLoadingList.current.refresh()}
                        onClick={() => this.props.onClick(conversation)}
                        selected={
                          this.props.selectedConversation?.id ===
                          conversation.id
                        }
                      />
                    );
                  }}
                  renderNoContent={() => this._renderEmptyState()}
                  renderInitialLoading={() => this._renderPlaceholderCards()}
                  renderLoadingMore={() => this._renderPlaceholderCards()}
                />
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default ConversationsList;
