import React from 'react'

import ActivateShopForm from "../components/product/ActivateShopForm"

import Div from "../components/layouts/Div"

import Backend from "../../utils/Backend"
import Notify from "../../utils/Notify"

export default class Settings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      shop: props.shop
    }

    this.activateShopForm = React.createRef()
  }

  _handleUpdateShop(){

    this.setState({ loading: true })

    this.activateShopForm.current.save()
    .then(() => {
      this.setState({ loading: false })
      Notify.success("Shop Settings Updated")
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render(){
    let {
      shop,
      loading,
    } = this.state

    return (
      <ActivateShopForm
        ref={this.activateShopForm}
        shop={shop}
        containerClassName=""
        card={false}
        title="Shop Settings"
        confirmTitle="Update"
        onConfirmPressed={() => this._handleUpdateShop()}
      />
    )
  }
}
