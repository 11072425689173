import React from 'react'
import Switch from "react-switch";

import SubscriptionActivateModal from "./components/modal/SubscriptionActivateModal";

import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Website from "../utils/Website"
import Notify from "../utils/Notify"
import General from "../utils/General"
import Currency from "../utils/Currency"
import AddOns from "../utils/AddOns"

export default class Subscription extends React.Component {

  constructor(props){
    super();
    let subscription = General.clone(AuthManager.currentUser.company.subscription)
    subscription.add_ons = AddOns.fillDetails(subscription.add_ons)
    subscription.base_package_amount = this._getBasePackagePrice(subscription)
    subscription.one_time_upgrade_amount = null
    if(subscription.active && subscription.interval == "one_time"){
      subscription.one_time_upgrade_amount = 0
    }
    this.state = {
      newSubscription: subscription,
      currentSubscription: General.clone(subscription)
    };
  }

  componentDidMount(){
    this._load()
  }

  _formatCurrency(amount, currency){
    if(amount === 0){
      return "Free"
    }

    return Currency.format(amount, currency)
  }

  _load(){
    Backend.getPackages()
    .then(packages => {

      packages.sort((a, b) => {
        a = General.clone(a)
        b = General.clone(b)

        a.add_ons = AddOns.fillDetails(a.add_ons)
        b.add_ons = AddOns.fillDetails(b.add_ons)

        let aTotal = a.amount + this._getTotalAddOnsPrice(a, false)
        let bTotal = b.amount + this._getTotalAddOnsPrice(b, false)

        return aTotal > bTotal ? 1 : -1
      })

      this.setState({ packages })
    })
  }

  _selectPackage(pkg){
    let { newSubscription, currentSubscription } = this.state

    if(pkg.id == currentSubscription.package){
      this.setState({ newSubscription: General.clone(currentSubscription) })
      return
    }
    newSubscription.package = pkg.id
    newSubscription.add_ons = pkg.add_ons
    newSubscription.add_ons = AddOns.fillDetails(newSubscription.add_ons)

    newSubscription.currency = pkg.currency
    newSubscription.interval = pkg.interval
    newSubscription.one_time_upgrade_amount = null

    newSubscription.amount = pkg.amount
    newSubscription.base_package_amount = this._getBasePackagePrice(newSubscription)

    this.setState({ newSubscription })
  }

  _updatePrice(subscription){

    subscription.amount = subscription.base_package_amount
    subscription.amount += this._getTotalAddOnsPrice(subscription)

    return subscription
  }

  _getBasePackagePrice(subscription){

    let totalAddOnPrice = this._getTotalAddOnsPrice(subscription)

    return subscription.amount - totalAddOnPrice
  }

  _getPriceChange(){
    let { newSubscription, currentSubscription } = this.state

    let currentAddOnPrice = this._getTotalAddOnsPrice(currentSubscription)
    let newAddOnPrice = this._getTotalAddOnsPrice(newSubscription)

    return newAddOnPrice - currentAddOnPrice
  }

  _getTotalAddOnsPrice(subscription, checkEnabled=true){
    return subscription.add_ons.reduce((total, addOn) => {
      if((checkEnabled && addOn.enabled) || !checkEnabled){
        total += addOn.price
      }
      return total
    }, 0)
  }

  _renderOverview(){
    let { newSubscription } = this.state

    let { active, interval } = newSubscription

    let recurring = interval !== "one_time"
    let content = recurring ? this._renderRecurringOverview() : this._renderOneTimeOverview()
    return (
      <div className="card card-custom gutter-b card-stretch stats-break-down">
        <div className="card-header border-0 pt-4">
          <div className="card-title font-weight-bolder">
            <div className="card-label">
              Pricing Summary
              <div className={`font-size-sm font-weight-bold text-${active ? "success" : "danger"} mt-2`}>
                { active ? "Active" : "Inactive"}
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-body p-0 d-flex flex-column"
          style={{position: 'relative'}}
        >
          <div className="subscription-overview">
            { content }
          </div>
        </div>
      </div>
    )
  }

  _renderRecurringOverview(){
    let { newSubscription } = this.state
    let { active, interval, currency } = newSubscription

    let formattedInterval = General.toTitleCase(interval)
    return (
      <>
        <div className="subscription-overview-current">

          <h5 className="subscription-overview-current-title">{`${formattedInterval}ly Subscription`}</h5>

          <p className="subscription-overview-current-price">{this._formatCurrency(newSubscription.amount, currency)}</p>

        </div>

        { this._renderActivateSubscriptionButton() }
      </>
    )
  }

  _renderOneTimeOverview(){
    let { newSubscription } = this.state
    let { active, currency } = newSubscription

    return (
      <>
        <div className="subscription-overview-current">

          <h5 className="subscription-overview-current-title">One Time Payment</h5>

          <p className="subscription-overview-current-price">{this._formatCurrency(newSubscription.amount, currency)}</p>

        </div>

        { this._renderActivateSubscriptionButton() }

      </>
    )
  }

  _renderActivateSubscriptionButton(title=null){
    let { currentSubscription, newSubscription, loading } = this.state

    if(currentSubscription.active && JSON.stringify(currentSubscription) === JSON.stringify(newSubscription)){
      return null
    }

    if(!title){
      title = newSubscription.active ? "Upgrade" : "Activate"
    }

    let disabledDueToUpgradeAmount = false
    if(newSubscription.interval === "one_time" && newSubscription.one_time_upgrade_amount != null){
      if(newSubscription.one_time_upgrade_amount < 0){
        disabledDueToUpgradeAmount = true
      }
      else if(newSubscription.one_time_upgrade_amount == 0){
        title += ` (Free)`
      }
      else{
        title += ` (Pay ${Currency.format(newSubscription.one_time_upgrade_amount, newSubscription.currency)})`
      }
    }

    return (
      <>
        <button
          className="btn btn-primary mt-3 w-100"
          onClick={() => this.setState({ showSubscriptionActivateModal: true })}
          disabled={loading || disabledDueToUpgradeAmount}
        >
          {title}
        </button>
        { disabledDueToUpgradeAmount &&
          <span className="mt-3">
            ** Please change to add ons of equivalent value or more
          </span>
        }
      </>
    )
  }

  _renderPackages(){
    let { packages, currentSubscription, newSubscription } = this.state
    if(!packages || !currentSubscription.switchable){
      return null
    }

    return (
      <>
        <h2>Packages</h2>
        <div className="row">
          {
            packages.map((pkg, index) => {
              let isCurrentPackage = pkg.id === currentSubscription.package
              let isSelectedPackage = pkg.id === newSubscription.package

              let recurring = pkg.payment_type === "recurring"
              let price = pkg.amount

              if(isCurrentPackage){
                price = currentSubscription.amount
              }

              price = this._formatCurrency(price, pkg.currency)

              let priceLabel = "Once Off"
              if(recurring){
                priceLabel = `per ${pkg.interval}`
              }


              let o = General.clone(pkg)
              o.add_ons = AddOns.fillDetails(o.add_ons)
              let oTotal = this._getTotalAddOnsPrice(o, false)
              if(oTotal > 0){
                price = `From ${price}`
              }

              let packageClassName = "package"
              if(isCurrentPackage){
                packageClassName += " package-active"
              }
              if(isSelectedPackage){
                packageClassName += " package-selected"
              }
              return (
                <a
                  className="col-4"
                  onClick={() => this._selectPackage(pkg)}
                >
                  <div className={`card card-custom mb-3 ${packageClassName}`}>
                    { isCurrentPackage &&
                      <div className="package-active-label">
                        Active
                      </div>
                    }
                    <div className="card-body text-center">
                      <h4>
                        { pkg.name }
                      </h4>
                      <h5>
                        { price }
                      </h5>
                      <span>
                        { priceLabel }
                      </span>
                    </div>
                  </div>
                </a>
              )
            })
          }
        </div>
      </>
    )
  }

  _renderAddOns(){
    let { showSubscriptionActivateModal, newSubscription, currentSubscription } = this.state

    return (
      <div className='addons'>
        <h2>Add Ons</h2>
        <div className="row">
        {
          newSubscription.add_ons.map((addOn, index) => {
            let currentSubscriptionAddOn = currentSubscription.add_ons.find(o => o.key == addOn.key)

            let included = addOn.price == 0 && addOn.enabled
            let unavailable = !addOn.enabled && !addOn.toggleable
            let purchasable = !addOn.enabled && addOn.toggleable
            let showBanner = unavailable || purchasable

            let formattedPrice = this._formatCurrency(addOn.price, newSubscription.currency)
            let priceTitle = included ? "Included" : formattedPrice
            if(unavailable){
              priceTitle = ""
            }
            let priceTitleClassName = included ? "font-weight-light" : ""

            let bannerTitle = purchasable ? `Premium - ${formattedPrice}` : "Switch Package"
            let bannerBtnClassName = purchasable ? "brand" : "warning"

            let cardBorderClassName = ""
            if(showBanner){
              cardBorderClassName = `card-border card-border-${bannerBtnClassName}`
            }

            return (
              <div className="col-4 addon">
                <div className={`card card-custom mb-3 ${cardBorderClassName}`}>
                  { showBanner &&
                    <span className={`addons-item-premium btn btn-${bannerBtnClassName} btn-sm`}>{bannerTitle}</span>
                  }
                  <div className="card-body">
                    <div className="row">

                      <div className="col-9">
                        <h4>
                          {addOn.title}
                        </h4>
                        <p className="text-dark-50 mb-0">
                          {addOn.description}
                        </p>
                      </div>

                        <div className="col-3 text-center justify-content-center">
                          <i className={`addons-item-icon ${addOn.icon} la-4x mx-0 my-3`}/>

                          <h4 className={`addons-item-price mb-3 ${priceTitleClassName}`}>{priceTitle}</h4>
                            { !included &&
                              <div className="button ml-6">
                                <Switch
                                  checked={addOn.enabled}
                                  onChange={(checked) => {
                                    addOn.enabled = checked
                                    newSubscription.add_ons[index] = addOn
                                    newSubscription = this._updatePrice(newSubscription)
                                    if(newSubscription.interval === "one_time" && newSubscription.package == currentSubscription.package && currentSubscription.active){
                                      let upgradeAmount = newSubscription.amount - currentSubscription.amount
                                      newSubscription.one_time_upgrade_amount = upgradeAmount
                                    }
                                    else{
                                      newSubscription.one_time_upgrade_amount = null
                                    }

                                    this.setState({ newSubscription })
                                  }}
                                  disabled={!addOn.toggleable}
                                  onColor="#333333"
                                  offColor="#E5E5E5"
                                  checkedIcon={null}
                                  uncheckedIcon={null}
                                  className="react-switch"
                                  id="material-switch"
                                />
                              </div>
                            }
                        </div>

                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }

  render() {

    let { showSubscriptionActivateModal, newSubscription, currentSubscription } = this.state

    return (
      <div className="row pages manage-addons">
        <div className="col-3">
          { this._renderOverview()}
        </div>
        <div className="col-9">
          { this._renderPackages() }
          { this._renderAddOns() }
        </div>
        <SubscriptionActivateModal
          show={showSubscriptionActivateModal}
          subscription={newSubscription}
          onCancel={() => this.setState({ showSubscriptionActivateModal: false })}
          onSuccess={() => {
            let location = window.location;
            let url =
              location.protocol + "//" + location.host + location.pathname;
            url += "?subscribed=true";
            window.location.href = url;
          }}
        />
      </div>
    )

  }

}
