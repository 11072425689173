import React from 'react'

import Backend from '../utils/Backend'

import EmailsTable from './components/tables/EmailsTable'

export default class Emails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }


  render() {

    return (
      <EmailsTable/>
    )
  }
}
