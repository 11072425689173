import React from "react"

import moment from 'moment'

import BaseTable from "./BaseTable"

import AuthManager from '../../../utils/AuthManager'
import Currency from '../../../utils/Currency'
import Notify from '../../../utils/Notify'

import CouponModal from "../modal/CouponModal";
import CouponActionsCell from "./cells/CouponActionsCell";

const STATUSES = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "Percentage",
    value: "percentage"
  },
  {
    label: "Fixed Amount",
    value: "fixed_amount"
  },
  {
    label: "Free Shipping",
    value: "free_shipping"
  },
]

export default class CouponsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      shop: props.shop,
      showCouponModal: props.showCouponModal
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _getColumns() {
    let {
      shop
    } = this.state

    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: coupon => moment(coupon.created_at).format('DD/MM/YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Code',
        id: 'code',
        accessor: 'code'
      },
      {
        Header: 'Value',
        id: 'amount',
        Cell: rowInfo => {
          let coupon = rowInfo.original
          let amountValue = "Free Shipping"
          if(coupon.type === "fixed_amount"){
            amountValue = Currency.format(coupon.amount, shop.currency)
          }else if(coupon.type === "percentage"){
            amountValue = `${coupon.amount}%`
          }
          return amountValue
        }
      },
      {
        Header: 'Limit',
        id: 'usage_limit',
        accessor: 'usage_limit'
      },
      {
        Header: 'Used',
        id: 'amount_used',
        accessor: 'used_amount'
      },
      {
        Header: 'Expires',
        id: 'expires_at',
        accessor: coupon => coupon.expires_at ? moment(coupon.expires_at).format('DD/MM/YYYY') : "",
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Active',
        id: 'status',
        Cell: rowInfo => {
          let coupon = rowInfo.original

          let label = "Active"
          let type = "success"

          if(!coupon.active){
            label = "Inactive"
            type = "danger"
          }

          return (
            <span
              className={`kt-badge kt-badge--${type} kt-badge--inline kt-badge--pill`}
            >
              { label }
            </span>
          )
        }
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          return (
            <CouponActionsCell
              coupon={rowInfo.original}
              onEditPressed={coupon => {
                this.setState({ showCouponModal: true, selectedCoupon: coupon })
              }}
            />
          )
        }
      }
    ]
  }

  render() {
    let {
      shop,
      refunding,
      selectedCoupon,
      showCouponModal,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Vouchers}
          params={{
            website_id: AuthManager.currentWebsite.id
          }}
          title="Coupons"
          columns={columns}
          filters={STATUSES}
          searchFilterName="type"
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          renderToolbar={() => {
            if(AuthManager.currentUser.user.role !== "company_member"){
              return null
            }
            return (
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">&nbsp;
                  <button
                    type="button"
                    className="btn btn-brand btn-icon-sm"
                    onClick={() => {
                      this.setState({showCouponModal: true})
                    }}
                  >
                    <i className="flaticon2-plus"/>
                    Add Coupon
                  </button>
                </div>
              </div>
            )
          }}
        />
        {
          showCouponModal &&
            <CouponModal
              show={showCouponModal}
              shop={shop}
              coupon={selectedCoupon}
              onHide={() => {
                this.setState({showCouponModal: false, selectedCoupon: null})
              }}
              onAdded={() => {
                this.setState({showCouponModal: false, selectedCoupon: null})
                this.table.current.refresh()
              }}
            />
        }
      </>
    )
  }
}
