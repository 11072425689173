import React from 'react';

const HalfHeight = ({ className }) => {
    return (
        <svg className={className} width="648" height="379" viewBox="0 0 648 379" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2316_636)">
                <rect width="648" height="379" rx="20" fill="#404040" />
                <path d="M225 70H422.5" stroke="white" stroke-width="15" stroke-linecap="round" />
                <path d="M167 104H300" stroke="#818181" stroke-width="10" stroke-linecap="round" />
                <path d="M424 104H480" stroke="#818181" stroke-width="10" stroke-linecap="round" />
                <path d="M330 104H394" stroke="#818181" stroke-width="10" stroke-linecap="round" />
                <rect x="167" y="139" width="145" height="46" rx="10" fill="#19E68A" />
                <path d="M208 161.5H270" stroke="white" stroke-width="10" stroke-linecap="round" />
                <rect x="328" y="139" width="145" height="46" rx="10" fill="#767676" />
                <path d="M369 161.5H431" stroke="white" stroke-width="10" stroke-linecap="round" />
                <g opacity="0.59">
                    <path d="M595.751 168.917L562.034 209.166C550.929 222.421 560.354 242.61 577.646 242.61H740.71C756.357 242.61 766.159 225.699 758.382 212.121L719.94 145.013C713.027 132.945 696.32 131.203 687.065 141.585L660.941 170.892C653.735 178.976 641.462 180.007 633.009 173.238L624.092 166.098C615.45 159.177 602.861 160.429 595.751 168.917Z" fill="#818181" />
                    <circle opacity="0.18" cx="24.5" cy="36.5" r="155.5" fill="#19E68A" />
                    <circle cx="24.2067" cy="37.1199" r="60.0403" fill="#19E68A" />
                </g>
                <rect x="-14" y="233" width="688" height="236" fill="#F4F0EC" />
                <path d="M199 316H306" stroke="#C4C4C4" stroke-width="10" stroke-linecap="round" />
                <path d="M330 316H383" stroke="#C4C4C4" stroke-width="10" stroke-linecap="round" />
                <path d="M406 316H459" stroke="#C4C4C4" stroke-width="10" stroke-linecap="round" />
                <path d="M246 292H401" stroke="#818181" stroke-width="10" stroke-linecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_2316_636">
                    <rect width="648" height="379" rx="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default HalfHeight;