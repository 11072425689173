import React from "react"
import LinearProgress from '@material-ui/core/LinearProgress'

import { Calendar, momentLocalizer } from 'react-big-calendar'

import AsyncSelect from "./common/AsyncSelect";

import SubmissionModal from "./modal/SubmissionModal";

import Notify from "../../utils/Notify"
import Backend from "../../utils/Backend"
import AuthManager from "../../utils/AuthManager"

import moment from 'moment'

const localizer = momentLocalizer(moment)

export default class BookingsCalendar extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      submissions: []
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    let { selectedResource } = this.state
    this.setState({ loading: true })
    Backend.getBookings(selectedResource)
    .then((submissions) => {
      this.setState({submissions, loading: false})
    })
    .catch((error) => {
      this.setState({loading: false})
      Notify.error(error.message)
    })
  }

  _getResourceOption(resource){
    if(!resource){
      return null
    }

    return {
      value: resource.id,
      label: resource.name,
      data: resource
    }
  }

  _renderToolbar(){
    return null
  }

  render() {
    let {
      loading,
      submissions,
      selectedResource,
      selectedSubmission,
      showSubmissionModal,
    } = this.state

    return (
      <>
        <div className="bookings-calendar">
  				<div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
  					<div className="kt-portlet__head">
  						<div className="kt-portlet__head-label">
  							<h3 className="kt-portlet__head-title">
  								Bookings Calendar <span class="text-success">(all new bookings will appear in your calendar)</span>
  							</h3>
  						</div>
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <div className="mr-2">Resource:</div>
                  <AsyncSelect
                    isClearable
                    endpoint={window.Api.Resources}
                    placeholder="Filter to Resource"
                    filter={`website_id=${AuthManager.currentWebsite.id}`}
                    value={this._getResourceOption(selectedResource)}
                    onSelected={resource => {
                      this.setState({
                        selectedResource: resource
                      }, () => this._load())
                    }}
                    getOptions={resources => {
                      return resources.map(o => this._getResourceOption(o))
                    }}
                  />
                </div>
              </div>
  					</div>
            { loading &&
              <LinearProgress />
            }

  					<div className="kt-portlet__body">
  						<div className="tab-content">
  							<div className="tab-pane active" id="kt_widget4_tab1_content">
  								<div className="kt-widget4">
                    <Calendar
                      localizer={localizer}
                      events={submissions}
                      defaultView="week"
                      scrollToTime={moment().set({hour: 8, minute: 30}).toDate()}
                      startAccessor={submission => {
                        return new Date(submission.booking.starts_at)
                      }}
                      endAccessor={submission => {
                        return new Date(submission.booking.ends_at)
                      }}
                      titleAccessor={submission => {
                        let { booking } = submission
                        let { service, resource } = booking.service_resource
                        let title = `${resource.name} - ${service.name}`
                        return title
                      }}
                      style={{ height: 650 }}
                      onSelectEvent={submission => {
                        this.setState({
                          showSubmissionModal: true,
                          selectedSubmission: submission,
                        })
                      }}
                      dayLayoutAlgorithm="no-overlap"
                      formats={{
                        timeGutterFormat: (date, culture, localizer) => moment(date).format("h A")
                      }}
                      messages={{
                        noEventsInRange: 'There are no bookings in this range.',
                      }}
                    />
  								</div>
  							</div>
  						</div>
  					</div>
  				</div>
        </div>
        { showSubmissionModal &&
          <SubmissionModal
            show={showSubmissionModal}
            submissionId={selectedSubmission.id}
            viewOnly={true}
            onUpdated={() => this.setState({selectedSubmission: null, showSubmissionModal: false})}
            onHide={() => this.setState({selectedSubmission: null, showSubmissionModal: false})}
          />
        }
      </>
    )
  }
}

BookingsCalendar.defaultProps = {
  className: "col-xl-12",
}
