import React from 'react'
import $ from 'jquery'

import moment from "moment"

import { Helmet } from "react-helmet";

import AuthManager from '../utils/AuthManager'
import Email from '../utils/Email'
import Notify from '../utils/Notify'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Website from '../utils/Website'
import Currency from '../utils/Currency'

import ColorPicker from './components/ColorPicker'
import Separator from "./components/common/Separator"

import CardEntryForm from "./components/common/CardEntryForm"

import Div from './components/layouts/Div'

import ImagePickerModal from './components/modal/ImagePickerModal'

import FontBoxWidth from './components/icons/login/v2/font/width.jpg'

import ProgressBar from 'react-bootstrap/ProgressBar';

import SiteBuilder, {
  BUTTON_STYLES,
  FONTS,
  LOGO_POSITIONS,
  BANNER_STYLES,
  BLOCKS,
  ADD_ONS
} from '../utils/SiteBuilder'

const INITIAL_LOADING_STEP_TIME_DELAY = 450
const SUBSEQUENT_LOADING_STEP_TIME_DELAY = 1200
const MIN_LOADING_TIME = 8000


export default class Create extends React.Component {
  constructor(props) {
    super(props)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get('s') || "organic"
    const premium = params.get("p") != null
    const u = params.get("u") || "s"

    this.state = {
      premium,
      source,
      color: null,
      email: '',
      password: '',
      isLoading: false,
      blocks: [],
      similarImages: []
    }

    this.cardEntryForm = React.createRef()
  }

  componentDidMount(){
    this._setPackage()
  }

  _setPackage(){
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const pkgSlug = this.props.match.params.pkg_slug

    Backend.getPackageBySlug(pkgSlug)
    .then(pkg => {

      delete pkg.add_ons["package"]
      for (const key in pkg.add_ons) {

          let data = ADD_ONS.find(o => o.key == key) || {}
          pkg.add_ons[key] = {
            ...data,
            ...pkg.add_ons[key],
            visible: false
          }

      }

      this.setState({ pkg })
    })
  }

  _isSignUpFormValid(){
    let {
      first_name,
      last_name,
      email,
      phone_number,
      agree,
      source,
      referrer,
      password,
      company_name,
      image,
      slug,
      errorSlug,
      color,
      font,
      blocks,
      addOns,
      buttonStyle,
      logoPosition,
      bannerStyle,
      pkg,
    } = this.state

    let error = null


    if(!color){
      error = "Please select a brand colour"
    }
    else if(!buttonStyle){
      error = "Please select a button style"
    }
    else if(!font){
      error = "Please select a font"
    }
    else if(!logoPosition){
      error = "Please select a logo position"
    }
    else if(!bannerStyle){
      error = "Please select a main banner style"
    }
    else if(!image){
      error = "Please select an image"
    }
    else if(company_name == null || company_name.length == 0){
      error = "Please enter a valid company name"
    }
    else if(errorSlug){
      error = "This website url is not available, please try a different one"
    }
    else if(!slug || slug == ""){
      error = "Please enter a valid website url"
    }
    else if(first_name == null || first_name.length == 0){
      error = "Please enter a valid first name"
    }
    // else if(last_name == null || last_name.length == 0){
    //   error = "Please enter a valid last name"
    // }
    else if(email == null || email.length == 0){
      error = "Please enter a valid email"
    }
    else if(password == null || password.length == 0){
      error = "Please enter a valid password"
    }
    else if(pkg.collect_referrer && !referrer){
      error = "Please enter how you heard about us"
    }
    else if(agree == null || !agree){
      error = "You must agree to the terms and conditions"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _isFree(){
    let { pkg } = this.state
    return pkg.amount == 0 || pkg.payment_flow != "sign_up"
  }

  _signUpPressed(e){
    e.preventDefault()

    if(this._isFree()){
      this._createSite()
    }
    else{
      this.setState({ loading: true })
      this.cardEntryForm.current.collectCardDetails()
    }
  }

  _createSite(){
    let { cardData } = this.state

    if(!this._isSignUpFormValid()){
      return
    }

    let startTime = moment()
    this.setState({ isLoading: true })

    let siteBuilder = new SiteBuilder(this.state)
    let data = siteBuilder.getBaseDetails()
    data.homepage.content_blocks = siteBuilder.getContentBlocks(this.state)
    if(cardData){
      data.subscription = {
        ...data.subscription,
        ...cardData,
        active: true,
      }
    }

    let website = null
    Backend.createSite(data)
    .then(response => {
      if(response.requires_action){
        this.cardEntryForm.current._handleAction(response)
        return
      }
      const urlParams = new URL(response.one_time_login_url).searchParams;
      const hash = urlParams.get('h');
      let url = Website.getNativeDomain(response.website) + "?edit=true&h="+hash

      let endTime = moment()

      let elapsedSeconds = moment(endTime).diff(moment(startTime), 'seconds')

      let wait = MIN_LOADING_TIME - (elapsedSeconds * 1000)
      if(wait < 0){
        wait = 0
      }
      setTimeout(() => {
        window.location = url
      }, wait)
    })
    .catch(error => {
      this.setState({
        isLoading: false,
      })
      Notify.error(error.message)
    })
  }

  _getCompanyDescription = General.debounce(() => {
    let {
      company_name,
      company_description
    } = this.state

    if(!company_name || !company_description){
      return
    }

    return Backend.generateText(`${company_name} - ${company_description}`)
    .then(response => {
      this.setState({
        company_about_us: response.body,
        company_our_mission: response.mission,
        company_services: {
          services: response.services,
          highlight: response.highlight,
        },
      })
    })
    .catch(error => {
      console.log("****", error)
    })
  }, 500)

  _setSlug = General.debounce(() => {
    let {
      slug,
      company_name
    } = this.state

    if(slug){
      return
    }

    return Backend.slugify(company_name)
    .then(response => {
      let errorSlug = false
      if(slug && slug != company_name){
        errorSlug = true
      }
      if(!slug){
        slug = response.slug
      }
      this.setState({ errorSlug, slug, isLoading: false }, () => {
        this._getCompanyDescription()
      })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoading: false })
    })
  }, 500)

  _checkSlug(){
    let {
      slug
    } = this.state

    this.setState({ isLoadingSlug: true })
    Backend.slugify(slug)
    .then(response => {
      if(response.slug != slug){
        this.setState({ errorSlug: true, isLoadingSlug: false })
      }
      else{
        this.setState({ errorSlug: false, isLoadingSlug: false })
      }
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoadingSlug: false })
    })
  }

  _renderMeta() {
    let { pkg, website } = this.state;

    if (pkg == null) {
      return null;
    }

    let favIconUrl = window.General.Branding.Logo;

    let title = `${window.General.Branding.Name} | ${pkg.name}`;
    return (
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={pkg.cta || "Create A Website In 30 Seconds..."}
        />
        <link rel="icon" href={favIconUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={favIconUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:image" content={favIconUrl} />
      </Helmet>
    );
  }

  _renderCardEntryForm(){
    let {
      pkg
    } = this.state

    if(this._isFree()){
      return null
    }

    return (
      <CardEntryForm
        uiType={"horizontal"}
        ref={this.cardEntryForm}
        showEmail={false}
        onCollected={cardData => {
          this.setState({ cardData }, () => this._createSite())
        }}
        onCollectionFailed={error => {
          Notify.error(error.message)
          this.setState({ loading: false })
        }}
      />
    )
  }

  _renderCheckMarkSvg() {
    return (
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    )
  }

  _renderloadingText(){

    let points = [
      "Configuring Your Style",
      "Adding Graphics & Photos",
      "Creating Your Banner",
      "Enabling Your Add-Ons",
      "Writing Your Content (AI)"
    ]

    return (
      <div className="loading-text">
        <ul className="points">
          <li className='p-default'>{this._renderCheckMarkSvg()} <span>Building Your Site</span></li>
          {points.map(point => {
            return (
              <li>{this._renderCheckMarkSvg()} <span>{point}</span></li>
            );
          })}
          <li className="b-text">
              Drum Roll Please <span>...</span>
          </li>
        </ul>
      </div>
    )
  }

  _renderContent(){
    let {
      slug,
      errorSlug,
      isLoading,
      isLoadingSlug,
      image,
      color,
      font,
      pkg,
      blocks,
      addOns,
      buttonStyle,
      logoPosition,
      imageLoading,
      bannerStyle,
    } = this.state

    let className = "input-group-append-bg-dark "

    if(slug){
      className += errorSlug ? "input-group-append-bg-dark-error" : "input-group-append-bg-dark"
    }

    let price = ""
    if(!this._isFree()){
      price = Currency.format(pkg.amount, pkg.currency)
      if(pkg.interval === "month"){
        price += " per month"
      }
      else if(pkg.interval === "year"){
        price += " per year"
      }
      price = ` (${price})`
    }

    return (
      <form
        class="form"
        novalidate="novalidate"
      >
        <Div
          disabled={isLoading}
          spinner={true}
          disableViewClassName="disable-view-absolute"
          loadingText={ this._renderloadingText() }
          onReady={() => {
            setTimeout(function () {
              $('.loading-text .points li.p-default').addClass('active');
            }, INITIAL_LOADING_STEP_TIME_DELAY);
            let counter = 0;
            setInterval(function () {
              let element = $('.loading-text .points li:not(.p-default)');
              let c = counter % $(element).length;
              $(element).eq(c).addClass('active');
              ++counter
            }, SUBSEQUENT_LOADING_STEP_TIME_DELAY);
          }}
        >
          <div class="pb-13 pt-lg-0 pt- d-none d-md-block">
            <p className="font-weight-bold font-size-h4 form-title">You can change all of these options later...</p>
          </div>

          {/* Starts : 01 - Brand Colour */}
          <div class="form-group">
            <label>
              <span className='title'>01 - Brand Colour</span>
              <span className='sub-title'>Select your main website colour</span>
            </label>
            <div className='field'>
              <ColorPicker
                color={ color }
                onColorSelected={color => this.setState({ color })}
                COLORS={['#19E68A', '#3d85c6', '#e69138', '#fa6160', '#ffba00', '#674EA7', '#333333',]}
              />
            </div>
          </div>
          {/* End */}

          {/* Starts : 02 - Button Style */}
          <div class="form-group">
            <label>
              <span className='title'>02 - Button Style</span>
              <span className='sub-title'>Pick the style of your buttons</span>
            </label>
            <div className='field'>
              <div className='choose button-style'>
                  {BUTTON_STYLES.map((item) => (
                    <div
                      key={item.value}
                      className={`btn ${item.css} ${buttonStyle === item.value ? ' selected flaticon2-check-mark' : ' '}`}
                      onClick={() =>
                        this.setState({ buttonStyle: item.value })
                      }
                    >
                        <span>{item.text} Button</span>
                    </div>
                  ))}
                </div>
            </div>
          </div>
          {/* End */}

          {/* Starts : 03 - Font */}
          <div class="form-group">
            <label>
              <span className='title'>03 - Font</span>
              <span className='sub-title'>Choose your main font for headings</span>
            </label>
            <div className='field'>
              <div className='choose main-font'>
                {FONTS.map((item) => (
                  <div
                    key={item.value}
                    className={`btn ${item.css} ${font === item.value ? ' selected flaticon2-check-mark' : ' '}`}
                    onClick={() => this.setState({ font: item.value })}
                  >
                      <img className='w-100 h-auto' src={FontBoxWidth} />
                      <span>{item.text}<br></br>Font</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End */}

          {/* Starts : 04 - Logo Position */}
          <div class="form-group">
            <label>
              <span className='title'>04 - Logo Position</span>
              <span className='sub-title'>Where do you want your logo positioned</span>
            </label>
            <div className='field'>
              <div className='choose logo-position'>
                {LOGO_POSITIONS.map((item) => (
                  <div
                    key={item.value}
                    className={`graphic ${item.css} ${logoPosition === item.value ? ' selected flaticon2-check-mark' : ' '}`}
                    onClick={() => this.setState({ logoPosition: item.value })}
                  >
                      <div className='image'>{item.image}</div>
                      <div className='text'>{item.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End */}

          {/* Starts : 05 - Main Banner Style */}
          <div class="form-group">
            <label>
              <span className='title'>05 - Main Banner Style</span>
              <span className='sub-title'>Choose your home page banner style</span>
            </label>
            <div className='field'>
              <div className='choose main-banner-style'>
                {BANNER_STYLES.map((item) => (
                  <div
                    key={item.value}
                    className={`graphic ${item.css} ${bannerStyle === item.value ? ' selected flaticon2-check-mark' : ' '}`}
                    onClick={() =>
                      this.setState({ bannerStyle: item.value })
                    }
                  >
                      <div className='image'>{item.image}</div>
                      <div className='text'>{item.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End */}

          {/* Starts : 06 - Banner Image */}
          <div class="form-group error">
            <label>
              <span className='title'>06 - Banner Image</span>
              <span className='sub-title'>Select an image for your banner background</span>
            </label>
            <div className='field'>
              <div
                className="choose-image d-flex justify-content-center align-items-center cursor-pointer"
                onClick={() => this.setState({ showImagePicker: true })}
              >
                { !image &&
                  <>
                    <div className="choose-image-overlay"/>
                    <div className="bg-text">
                      <p className="my-auto">Select A Suitable Image</p>
                    </div>
                  </>
                }
                { (image || imageLoading) &&
                  <div className="w-100 shine shine-overlay">
                    { image &&
                      <>
                      <div className="uploaded-image" style={{ backgroundImage: "url("+image.original+")" }} ></div>
                      </>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          {/* End */}

          <Separator
            text="Website Blocks"
            css="color-charcoal v2"
          />

          <div class="form-group">
            <label>
              <span className='sub-title'>What blocks and panels would you like to add to your website?</span>
            </label>
            <div className='field'>
              <div className='choose-multiple-options'>

                {BLOCKS.map((item) => (
                  <div key={item.id} class="form-group option">
                    <div className='row'>
                      <div className='col-auto'>
                        <span class="material-icons-outlined">
                          {item.icon}
                        </span>
                      </div>
                      <label class="col col-md-3">
                        <span className='title'>{item.title}</span>
                      </label>
                      <div class="col-auto col-md-3">
                        <span class="kt-switch">
                          <label>
                            <input
                              type="checkbox"
                              checked={blocks.findIndex(o => o === item.key) > -1}
                              name=""
                              id={item.title}
                              onChange={e => {
                                let index = blocks.findIndex(o => o === item.key)
                                if(index === -1){
                                  blocks.push(item.key)
                                }
                                else{
                                  blocks.splice(index, 1)
                                }
                                this.setState({ blocks })
                              }}
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>

          <Separator
            text="Website Content (AI)"
            css="color-charcoal v2"
          />

          <div class="form-group">
            <div>
              <label>
                <span className='sub-title'>We'll write your content for you and you can change it later…</span>
              </label>
            </div>
            <label>
              <span className='title'>Website / Business Name</span>
            </label>
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              placeholder="Website / Business Name"
              name="company_name"
              autocomplete="off"
              onChange={e => this.setState({ company_name: e.target.value })}
              onBlur={() => this._setSlug()}
            />
          </div>

          <div class="form-group">
            <div>
              <label>
                <span className='title'>Explain Your Website Or Business In 1 Short Sentence</span>
              </label>
            </div>
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              placeholder="Explain Your Website Or Business In 1 Short Sentence"
              name="company_name"
              autocomplete="off"
              onChange={e => this.setState({ company_description: e.target.value })}
              onBlur={() => this._getCompanyDescription()}
            />
          </div>

          <Separator
            text="Choose Add-Ons"
            css="color-charcoal v2"
          />

          <div class="form-group">
            <label>
              <span className='sub-title'>What Add-Ons do you want to enable?</span>
            </label>
            <div className='field'>
              <div className='choose-multiple-options'>

                {
                  ADD_ONS.map((obj, index) => {
                    let key = obj.key
                    let addOn = pkg.add_ons[key]

                    if(!addOn.enabled && !addOn.toggleable){
                      return null
                    }

                    let hiddendAddOns = ["files", "team", "web_designer"]
                    if(hiddendAddOns.indexOf(key) > -1){
                      return null
                    }

                    return (
                      <div key={addOn.id} class="form-group option">
                        <div className='row'>
                          <div className='col-auto'>
                            <span class="material-icons-outlined">
                              {addOn.icon}
                            </span>
                          </div>
                          <label class="col col-md-3 col-form-label">
                            <span className='title'>{addOn.title}</span>
                          </label>
                          <div class="col-auto col-md-3">
                            <span class="kt-switch">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={addOn.toggleable ? addOn.enabled : addOn.visible}
                                  name=""
                                  id={addOn.title}
                                  onChange={e => {
                                    if(addOn.toggleable){
                                      addOn.enabled = !addOn.enabled
                                    }
                                    else{
                                      addOn.visible = !addOn.visible
                                    }

                                    let amount = addOn.enabled ? addOn.price : -addOn.price
                                    pkg.amount += amount

                                    pkg.add_ons[key] = addOn
                                    this.setState({ pkg })
                                  }}
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>

          <Separator
            text="Make It Yours"
            css="color-charcoal v2"
          />

          <div className='row'>
            <div className='col-md'>
              <div class="form-group">
                <label>
                  <span className='title'>First Name</span>
                </label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  autocomplete="off"
                  onChange={e => this.setState({ first_name: e.target.value })}
                />
              </div>
            </div>
            <div className='col-md'>
              <div class="form-group error">
                <label>
                  <span className='title'>Email</span>
                </label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </div>
            </div>
            <div className='col-md'>
              <div class="form-group">
                <label>
                  <span className='title'>Password</span>
                </label>
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password"
                  autocomplete="off"
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </div>
            </div>
          </div>

          { pkg.collect_referrer &&
            <div class="form-group">
              <label>
                <span className='title'>How Did You Find Out About {window.General.Branding.Name}?</span>
              </label>
              <input
                class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                type="text"
                placeholder={`How Did You Find Out About ${window.General.Branding.Name}?`}
                name="how_did_you_find"
                autocomplete="off"
                onChange={e => this.setState({ referrer: e.target.value || null })}
              />
              <div class="fv-plugins-message-container">
              </div>
            </div>
          }

          { this._renderCardEntryForm() }

          <div class="form-group fv-plugins-icon-container">

            <label class="kt-checkbox c-checkbox mb-0">
              <input
                type="checkbox"
                name="agree"
                className="mr-1"
                onClick={e => this.setState({ agree: e.target.checked })}
              />I Agree to the
              <a
                href="javascript:void(0);"
                target="_blank"
                className="text-primary ml-1"
              >
                terms and conditions
              </a>
              <span></span>
            </label>

            <div class="fv-plugins-message-container">
            </div>

          </div>

          <div class="form-group mb-0">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 w-100"
              onClick={(e) => this._signUpPressed(e)}
              disabled={isLoading}
            >
              { pkg.submit_cta || "Create My Website" } { price}
            </button>
          </div>

        </Div>
      </form>
    )
  }

  render() {
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      code,
      pkg,
      showImagePicker
    } = this.state

    if(!pkg){
      return null
    }

    let loginContentClassName = "login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden w-100"

    if(isLoading){
      loginContentClassName += " login-content-loading"
    }

    let siteBuilder = new SiteBuilder(this.state)
    const progress = siteBuilder.getProgress();

    // aside points
    let asidePoints = {
      title: pkg.subline || "Build Your Own Site",
      points: pkg.benefits.map(benefit => benefit.title)
    }

    let loginAsideClassName = "login-aside d-flex flex-column flex-row-auto pt-lg-40 pt-10 pl-25 pr-25 ";
    if(pkg.overlay){
      loginAsideClassName += " dark-overlay"
    }

    let loginAsideBgColor = "#fff1e5"
    if(pkg.secondary_color){
      loginAsideBgColor =  pkg.secondary_color
    }

    if(!pkg.cta_background_color){
      pkg.cta_background_color =  pkg.primary_color
    }

    return (
        <>
          {this._renderMeta()}

          <div className="metr_v702 d-flex flex-column flex-root">
            <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white v2" id="kt_login">

              {/* begin : Login Aside */}
              <div className={loginAsideClassName}>
                <div>
                <div className="d-flex flex-column-auto flex-column text-center">
                  <a href="/account" className="mb-10">
                    <img src={window.General.Branding.Logo} alt="Logo" className="branding-logo" />
                  </a>
                  <h3 className="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10">{pkg.title || "Create A Website In 30 Seconds..."}</h3>
                </div>
                <div className='aside-progress-bar'>
                  <ProgressBar
                    striped
                    animated
                    now={progress}
                    //label={`${now}%`}
                    className="wrapper"
                    barContainerClassName="container"
                    completedClassName="barCompleted"
                    labelClassName="label"
                  />
                  <h5>{progress}% Completed</h5>
                </div>
                <div className='aside-points'>
                  <div
                    className={`details`}
                  >
                    <h4 className='font-weight-bolder text-center'>
                      <span>{asidePoints.title}</span>
                    </h4>
                    <div class="d-flex flex-center">
                      <ul class="list-unstyled">
                        {asidePoints.points.map((point) => {
                          return (
                            <li>
                              <i class="symbol flaticon2-check-mark"></i>
                              <span>{point}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                </div>
                <div className='btm-link'>
                  Already Have A Site? <a href='/account'>Sign In</a>
                </div>
              </div>
              {/* end : Login Aside */}

              {/* <!--begin::Content--> */}
              <div className={loginContentClassName}>


                <div className="d-flex flex-column-fluid flex-center">

                  {/* <!--begin::Signup--> */}
                  <div class="login-form login-signup"
                    style={{
                      display: "block"
                    }}
                  >
                    { this._renderContent() }
                  </div>
                  {/* <!--end::Signup--> */}

                </div>
                {/* <!--end::Content body--> */}

              </div>
              {/* end::Content */}

            </div>

            <ImagePickerModal
              show={showImagePicker}
              onImagesSelected={(image, similarImages) =>  {
                this.setState({ imageLoading: true, showImagePicker: false })
                Backend.addImage(image, false)
                .then(image => {
                  this.setState({ image, similarImages, imageLoading: false })
                })
                .catch(error => {
                  Notify.error(error.message)
                  this.setState({ imageLoading: false })
                })
              }}
              onHide={() => this.setState({ showImagePicker: false })}
            />
          </div>

          <style dangerouslySetInnerHTML={{ __html: `
                :root {
                  --pkg_primary_color: ` + pkg.primary_color + ` ;
                  --pkg_title_color: ` + pkg.title_color + ` ;
                  --pkg_cta_background_color: ` + pkg.cta_background_color + ` ;
                  --pkg_cta_text_color: ` + pkg.cta_text_color + ` ;
                  --login_aside_bg_color: ` + loginAsideBgColor + ` ;
                }
            `}}
          />
        </>
    )
  }
}
