import React from 'react'

import Div from "../components/layouts/Div"
import TextEditor from "../components/common/TextEditor";

import Backend from "../../utils/Backend"
import Notify from "../../utils/Notify"


export default class Branding extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      form: props.form,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    Backend.getPaymentForm()
    .then(form => {
      this.setState({ form })
    })
  }

  _update(){
    let {
      data,
      form
    } = this.state

    this.setState({ loading: true })
    return Backend.updateForm(form)
    .then(() => {
      Notify.success("Branding Updated!")
      this.setState({ loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
      throw error
    })
  }

  render(){
    let { form, loading } = this.state

    if(!form){
      return <p>Loading...</p>
    }

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
          spinner={false}
        >

          <div className="kt-heading kt-heading--md">
            Branding
          </div>

          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Payment Widget Title</label>
                <div className="col-lg-9 my-auto">
                  <input
                    className="form-control form-control-solid kt-selectpicker"
                    placeholder=""
                    defaultValue={form.name}
                    onChange={e => {
                      form.name = e.target.value || null
                      this.setState({ form })
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Payment Button Title</label>
                <div className="col-lg-9 my-auto">
                  <input
                    className="form-control form-control-solid kt-selectpicker"
                    placeholder=""
                    defaultValue={form.submit_text}
                    onChange={e => {
                      form.submit_text = e.target.value || null
                      this.setState({ form })
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Confirmation Modal Title</label>
                <div className="col-lg-9 my-auto">
                  <input
                    className="form-control form-control-solid kt-selectpicker"
                    placeholder=""
                    defaultValue={form.popup_title}
                    onChange={e => {
                      form.popup_title = e.target.value || null
                      this.setState({ form })
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Confirmation Modal Body</label>
                <div className="col-lg-9 my-auto">
                  <input
                    className="form-control form-control-solid kt-selectpicker"
                    placeholder=""
                    defaultValue={form.popup_body}
                    onChange={e => {
                      form.popup_body = e.target.value || null
                      this.setState({ form })
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Confirmation Email Subject</label>
                <div className="col-lg-9 my-auto">
                  <input
                    className="form-control form-control-solid kt-selectpicker"
                    placeholder=""
                    defaultValue={form.email_subject}
                    onChange={e => {
                      form.email_subject = e.target.value || null
                      this.setState({ form })
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Confirmation Email Body</label>
                <div className="col-lg-9 my-auto">
                  <TextEditor
                    label={false}
                    text={form.email_body}
                    onUpdated={(text) => {
                      form.email_body = text;
                      this.setState({ form });
                    }}
                  />
                </div>
              </div>

            </div>
          </div>

        </Div>

        <div className="kt-portlet__foot" style={{ borderTop: 0 }}>
          <div className="kt-form__actions pull-right">
            <button
              type="button"
              className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
              disabled={loading}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </div>

      </>

    )
  }
}

Branding.defaultProps = {
  title: "Branding",
  loadingText: "Loading...",
  confirmButtonText: "Update",
  containerClassName: "col-lg-8"
}
